import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { TravellerFields } from '../../../../TravellerForm/types';
import { useTranslation } from 'react-i18next';
var checkIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM6.11364 11.7761L9.45455 14.9104L17.8864 7L19 8.04478L9.45455 17L5 12.8209L6.11364 11.7761Z", fill: "#5FCA43" })));
var Header = function (_a) {
    var _b;
    var passengerId = _a.passengerId, label = _a.label, numberIcon = _a.numberIcon, correct = _a.correct, onClick = _a.onClick, passengerFieldsMap = _a.passengerFieldsMap, open = _a.open, showErrorNotice = _a.showErrorNotice;
    var theme = useTheme('PassengerForm').Header;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var t = useTranslation('Passenger').t;
    return (React.createElement("div", { className: cn(theme.header, (_b = {},
            _b[theme.filled] = correct,
            _b[theme.collapsed] = !open,
            _b[theme.header_error] = showErrorNotice,
            _b)), onClick: onClick },
        React.createElement("div", { className: theme.number }, numberIcon || passengerId + 1),
        React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.passengerName }, label),
            isMobile && showErrorNotice && React.createElement("div", { className: theme.error }, t('Please, check passenger data')),
            isMobile && correct && passengerFieldsMap && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    passengerFieldsMap[TravellerFields.Gender],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.BirthDate],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.Nationality]),
                passengerFieldsMap['docType'] && (React.createElement("div", null,
                    passengerFieldsMap['docType'],
                    ": ",
                    passengerFieldsMap['docNumber'])),
                passengerFieldsMap['docExpiryDate'] && (React.createElement("div", null,
                    t('docExpiryDate'),
                    ": ",
                    passengerFieldsMap['docExpiryDate']))))),
        correct && !numberIcon && checkIcon));
};
export default Header;
