import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { useTheme } from '../../theme';
import Money from '../../Money/components/Money';
import { initI18n } from '../../utils';
initI18n('AdditionalServiceWrapper');
var AdditionalServiceWrapper = function (_a) {
    var className = _a.className, headerClassName = _a.headerClassName, textClassName = _a.textClassName, contentClassName = _a.contentClassName, wrapperClassName = _a.wrapperClassName, footerClassName = _a.footerClassName, children = _a.children, header = _a.header, subheader = _a.subheader, text = _a.text, totalPrice = _a.totalPrice, isLoading = _a.isLoading, onConfirm = _a.onConfirm, onCancel = _a.onCancel, onClear = _a.onClear, readOnly = _a.readOnly;
    var css = useTheme('AdditionalServiceWrapper').AdditionalServiceWrapper;
    var t = useTranslation('AdditionalServiceWrapper').t;
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: cn(css.header, headerClassName) },
            header,
            subheader && React.createElement("div", { className: css.subheader }, subheader)),
        React.createElement("div", { className: cn(css.content, contentClassName) },
            text && React.createElement("div", { className: cn(textClassName, css.content_text) }, text),
            React.createElement("div", { className: cn(wrapperClassName, css.content_wrapper) }, children),
            React.createElement("div", { className: cn(footerClassName, css.footer) },
                totalPrice && (React.createElement("div", { className: css.total },
                    React.createElement("span", { className: css.total__price },
                        t('Total'),
                        ":",
                        React.createElement(Money, { money: totalPrice })),
                    React.createElement(Button, { className: css.total__clear, disabled: isLoading, variant: "text", onClick: onClear }, t('Clear')))),
                React.createElement("div", { className: css.controls },
                    !isLoading && (React.createElement(Button, { className: cn(css.button, css.cancel), onClick: onCancel }, t('Cancel'))),
                    !readOnly && (React.createElement(Button, { className: css.button, classes: {
                            disabled: css.button_disabled
                        }, onClick: onConfirm, isLoading: isLoading, disabled: isLoading }, t('Confirm'))))))));
};
export default AdditionalServiceWrapper;
