import { __read } from "tslib";
import * as React from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { memo, useEffect, useState } from 'react';
import { DATA_LAYER_KEY } from '../../analytics';
import { getLocale } from '../../utils';
import { useConfig } from '../../context';
var isProd = process.env.NODE_ENV === 'production';
var Analytics = memo(function () {
    if (!isProd) {
        return null;
    }
    var yandexMetrikaId = useConfig().global.yandexMetrikaId;
    // default websky metrika account
    var metrikaAccounts = [94079362];
    if (yandexMetrikaId) {
        metrikaAccounts.push(parseInt(yandexMetrikaId, 10));
    }
    var _a = __read(useState([]), 2), accountsToInitialize = _a[0], setAccountsToInitialize = _a[1];
    useEffect(function () {
        setTimeout(function () {
            // setTimout because when this component uses in multiple places we need time gap between yandex metrica initializations
            var alreadyInitializedAccounts = window.yandex_metrika_accounts || [];
            setAccountsToInitialize(metrikaAccounts.filter(function (account) { return !alreadyInitializedAccounts.includes(account); }));
        }, 0);
    }, []);
    return (React.createElement(React.Fragment, null, !!accountsToInitialize.length && (React.createElement(YMInitializer, { version: "2", accounts: accountsToInitialize, options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
            ecommerce: DATA_LAYER_KEY,
            params: {
                locale: getLocale()
            }
        } }))));
});
export default Analytics;
