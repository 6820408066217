import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Disclaimer from '../../../Disclaimer/Disclaimer';
import { InfoIcon } from '../../../FlightCard/components/Icons';
import { getPassengerName } from '../../../Modules/Exchange/components/Exchange/utils';
import { useConfig } from '../../../context';
import { useAnimalState } from './hooks';
import AdditionalServiceWrapper from '../../../AdditionalServiceWrapper';
import Control from '../Control/Control';
var PetPopup = function (_a) {
    var _b, _c;
    var segments = _a.segments, services = _a.services, travellers = _a.travellers, isLoading = _a.isLoading, onConfirm = _a.onConfirm, onClear = _a.onClear, onClose = _a.onClose, readOnly = _a.readOnly;
    var css = useTheme('CheckoutAdditionalService').PetPopup;
    var t = useTranslation('Checkout').t;
    var baggageAnimalsURL = (_c = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.global.companyInfo) === null || _c === void 0 ? void 0 : _c.baggageAnimalsURL;
    var _d = useAnimalState(services, segments, travellers), state = _d.state, servicesBySegment = _d.servicesBySegment, selectedServices = _d.selectedServices, servicesToSave = _d.servicesToSave, hasChanges = _d.hasChanges, totalPrice = _d.totalPrice, onChange = _d.onChange, onClearState = _d.onClear;
    var onSaveHandler = useCallback(function () {
        if (hasChanges) {
            onConfirm(servicesToSave);
        }
        else {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    }, [hasChanges, servicesToSave]);
    var onClearHandler = useCallback(function () {
        onClear(selectedServices.map(function (service) { return (__assign(__assign({}, service), { count: 0 })); }));
        onClearState();
    }, [onClear, selectedServices]);
    var onChangeHandler = useCallback(function (service) { return function (travellerId, checked) {
        onChange({
            travellerId: travellerId,
            segmentId: service.segmentId,
            segmentIds: service.segmentIds,
            serviceId: checked ? service.serviceId : null
        });
    }; }, [servicesBySegment]);
    return (React.createElement(AdditionalServiceWrapper, { headerClassName: css.header, wrapperClassName: css.content, header: t('Animal'), text: React.createElement(React.Fragment, null,
            React.createElement("ul", { className: css.terms }, t('pet_popup_rules', { returnObjects: true })
                .filter(Boolean)
                .map(function (rule, index) { return (React.createElement("li", { key: index }, rule)); })),
            baggageAnimalsURL && (React.createElement("a", { href: baggageAnimalsURL, target: "_blank" },
                React.createElement(Disclaimer, { className: css.disclaimer, wrapperClassName: css.disclaimer__wrapper, icon: InfoIcon }, t('Open the full text of the conditions of carriage'))))), totalPrice: totalPrice, isLoading: isLoading, onConfirm: onSaveHandler, onClear: onClearHandler, onCancel: onClose, readOnly: readOnly },
        React.createElement("div", { className: css.segments }, servicesBySegment.map(function (_a, index) {
            var _b = __read(_a, 2), direction = _b[0], service = _b[1];
            var segmentId = service.segmentId;
            var serviceId = service.serviceId;
            var segmentIds = service.segmentIds;
            var allowedPassengersSet = new Set(service.service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
            var serviceTravellers = travellers.filter(function (traveller) { return allowedPassengersSet.has(traveller.id); });
            var price = service.price;
            if (segmentIds.length > 1) {
                price = __assign(__assign({}, price), { amount: service.price.amount * segmentIds.length });
            }
            return (React.createElement(Control, { key: index, type: "checkbox", info: direction, date: service.date, optionClassName: css.option, options: serviceTravellers.map(function (traveller) {
                    var _a, _b, _c, _d, _e;
                    var isSelected = state[segmentId][traveller.id] === serviceId;
                    var confirmedCount = (_e = (_d = (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (selectedService) { return selectedService.serviceId === serviceId; })) === null || _d === void 0 ? void 0 : _d.confirmedCount) !== null && _e !== void 0 ? _e : 0;
                    var isCanModify = service.service.canBeAdded || (isSelected && !confirmedCount);
                    return {
                        id: traveller.id,
                        label: getPassengerName(traveller).toLowerCase(),
                        value: isSelected ? 'selected' : undefined,
                        disabled: isLoading || confirmedCount > 0 || !isCanModify,
                        price: price
                    };
                }), onChange: onChangeHandler(service), isDisabled: isLoading, isActive: true }));
        }))));
};
export default PetPopup;
