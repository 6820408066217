import { __assign } from "tslib";
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMultiCity } from '../../../store/segments/selectors';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { getTotalPassengersCount } from '../../../store/passengers/selectors';
import { useForm } from 'react-final-form';
import { Search } from '../../../../Icons';
import { useIsWithSelectableCurrencies } from '../hooks';
var StartSearch = React.memo(function (props) {
    var _a;
    var css = useTheme('SearchForm').StartSearch;
    var t = useTranslation('SearchForm').t;
    var resumeValidation = useForm().resumeValidation;
    var withSelectableCurrencies = useIsWithSelectableCurrencies();
    var proMode = useConfig().SearchForm.proMode;
    return (React.createElement("div", { className: classnames(css.startSearch, (_a = {},
            _a[css.startSearch_iconMode] = props.mode === 'icon',
            _a[css.startSearch_multiCity] = props.isMultiCity,
            _a[css.startSearch_withCurrency] = withSelectableCurrencies,
            _a[css.startSearch_advanced] = proMode,
            _a)) },
        React.createElement(Button, { type: "submit", size: "large", variant: "flat", disabled: props.passengersCount === 0, onClick: resumeValidation }, props.mode === 'icon' ? React.createElement(React.Fragment, null, Search) : React.createElement("span", { className: css.text },
            " ",
            t('Search'),
            " "))));
});
var mapStateToProps = function (state, props) {
    return __assign(__assign({}, props), { isMultiCity: isMultiCity(state), passengersCount: getTotalPassengersCount(state) });
};
export default connect(mapStateToProps)(StartSearch);
