import * as React from 'react';
import Modal from '../../../Modal';
import Button from '../../../BaseComponents/Button';
import { ButtonVariant } from '../../../BaseComponents/Button/types';
import { useTheme } from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { WarningRounded } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
export var InsuranceUpsaleModal = function (_a) {
    var _b;
    var insurance = _a.insurance, onAgree = _a.onAgree, onDisagree = _a.onDisagree, isOpen = _a.isOpen, isLoading = _a.isLoading;
    var theme = useTheme('InsurancePage').InsuranceUpsaleModal;
    var t = useTranslation('InsurancePage').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement(Modal, { bottomDrawer: isMobile, open: isOpen, classes: {
            paper: theme.root
        } },
        React.createElement("span", { className: theme.iconWrapper }, WarningRounded),
        React.createElement("h3", { className: theme.header }, t('You lose protection and peace of mind in unforeseen situations.')),
        React.createElement("div", { className: theme.listWrapper },
            React.createElement("div", { className: cn(theme.listContent, theme.listContent_main), dangerouslySetInnerHTML: {
                    __html: (_b = insurance === null || insurance === void 0 ? void 0 : insurance.description) !== null && _b !== void 0 ? _b : t('InsuranceUpsaleMainDescription')
                } }),
            React.createElement("div", { className: theme.listContent, dangerouslySetInnerHTML: {
                    __html: t('InsuranceUpsaleAdditionalDescription')
                } })),
        React.createElement(Button, { className: theme.button, variant: ButtonVariant.Action, isLoading: isLoading, onClick: onAgree }, t('Buy insurance')),
        React.createElement(Button, { className: theme.button, variant: ButtonVariant.Secondary, disabled: isLoading, onClick: onDisagree }, t('I assume all risks and Cancel insurance'))));
};
