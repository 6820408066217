import { __assign } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { Dialog, DialogContent } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { initI18n } from '../../../utils';
import classNames from 'classnames';
import { GrowTransition, SlideUpTransition } from '../Transitions';
import { useSwipeDown } from '../hooks';
import CloseButton from '../../../BaseComponents/Button/components/CloseButton/CloseButton';
import { CloseButtonVariant } from '../../../BaseComponents/Button/types';
initI18n('ModalX');
var ModalX = function (_a) {
    var classes = _a.classes, children = _a.children, isOpen = _a.isOpen, onClose = _a.onClose, fullScreen = _a.fullScreen, _b = _a.scroll, scroll = _b === void 0 ? 'paper' : _b, title = _a.title, footer = _a.footer, ignoreClose = _a.ignoreClose;
    var theme = useTheme('BaseComponents').ModalX;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var closeButton = !ignoreClose ? (React.createElement(CloseButton, { onClick: onClose, variant: CloseButtonVariant.ClosePopup, classes: { root: classNames(theme.closeButton, classes === null || classes === void 0 ? void 0 : classes.closeButton) } })) : null;
    var _c = useSwipeDown({
        scroll: scroll,
        onClose: onClose,
        ignoreClose: ignoreClose,
        isMobile: isMobile,
        isOpen: isOpen
    }), handlers = _c.handlers, dialogRef = _c.dialogRef, contentRef = _c.contentRef, swipeBlockRef = _c.swipeBlockRef, swipeFxRef = _c.swipeFxRef, paperRef = _c.paperRef;
    var dialogHandlers = scroll === 'body' ? null : __assign({}, handlers);
    var swipeBlockHandlers = scroll === 'body' ? __assign({}, handlers) : null;
    return (React.createElement("div", null,
        React.createElement(Dialog, __assign({ keepMounted: true }, dialogHandlers, { ref: dialogRef, fullScreen: fullScreen, scroll: scroll, fullWidth: true, TransitionComponent: isMobile || fullScreen ? SlideUpTransition : GrowTransition, PaperProps: {
                ref: paperRef
            }, open: isOpen, onClose: ignoreClose ? null : onClose, classes: {
                root: theme.root,
                paper: classNames(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper, fullScreen ? theme.paper__fullScreen : null),
                paperScrollBody: theme.paper_paperScrollBody
            } }),
            isMobile && !ignoreClose && (React.createElement("div", __assign({ ref: swipeBlockRef, className: theme.swipeBlock }, swipeBlockHandlers),
                React.createElement("div", { className: theme.swipeBlock_line, ref: swipeFxRef }))),
            closeButton,
            title,
            React.createElement(DialogContent, { classes: { root: classNames(theme.dialogContent_root, classes === null || classes === void 0 ? void 0 : classes.dialogContent_root) }, ref: contentRef },
                React.createElement("div", { className: theme.wrapper }, children)),
            footer)));
};
export default ModalX;
