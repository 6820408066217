import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import Modal from '../../../../Modal';
import { Button, Choosed } from '../../../../index';
import { useTheme } from '../../../../theme';
var RefundInfoModal = function (props) {
    var open = props.open, onAgree = props.onAgree, onDisagree = props.onDisagree, renderAgreeText = props.renderAgreeText, renderDisagreeText = props.renderDisagreeText, renderText = props.renderText;
    var css = useTheme('Refund').RefundInfoModal;
    var renderContent = useCallback(function () { return (React.createElement("div", { className: css.content },
        React.createElement("span", { className: css.icon }, Choosed),
        React.createElement("div", { className: css.text }, renderText()),
        React.createElement("div", { className: css.buttons },
            React.createElement(Button, { className: css.button, onClick: onAgree }, renderAgreeText()),
            onDisagree && renderDisagreeText && (React.createElement(Button, { className: cn(css.button, css.no), onClick: onDisagree }, renderDisagreeText()))))); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: open, classes: { paper: css.modal }, maxWidth: 'sm' }, renderContent())));
};
export default RefundInfoModal;
