import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import AirplaneInfo from '../AirplaneInfo/AirplaneInfo';
import { SunSides } from '@websky/graphql';
import { getDurationString } from '../../../Results/utils';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import AircraftPopup from '../../../AircraftPopup';
import Link from '../../../Link';
import { useCallback } from 'react';
import { InfoDetails } from '../../../Icons';
import Amenities from '../../../Amenities';
initI18n('SeatMap');
var Airplane = function (_a) {
    var _b, _c, _d;
    var _e, _f, _g;
    var aircraft = _a.aircraft, sunInfo = _a.sunInfo, routeTime = _a.routeTime, onSeatsDetailsClick = _a.onSeatsDetailsClick, segment = _a.segment, className = _a.className;
    var theme = useTheme('SeatMap').AirplaneStyles;
    var duration = routeTime ? getDurationString(routeTime) : null;
    var isNoneSide = sunInfo === SunSides.NoneSide;
    var t = useTranslation('SeatMap').t;
    var labels = (_b = {
            '': ''
        },
        _b[SunSides.LeftSide] = i18n.t('SeatMap:90% on the left side'),
        _b[SunSides.RightSide] = i18n.t('SeatMap:90% on the right side'),
        _b);
    var renderButton = useCallback(function (onClick) { return (React.createElement("div", { className: theme.airplane__name },
        React.createElement(Link, { action: onClick }, aircraft.name))); }, [aircraft]);
    return (React.createElement("div", { className: cn(theme.airplane, className, (_c = {},
            _c[theme.airplane_noInfo] = !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.name) && !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon),
            _c)) },
        segment && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.segment },
                segment.segmentInfo.departure.airport.city.name,
                ' — ',
                segment.segmentInfo.arrival.airport.city.name))),
        React.createElement("div", { className: theme.airplane__info },
            (aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon) && React.createElement("img", { src: aircraft.icon, alt: aircraft.name, className: theme.airplane__image }),
            React.createElement("div", { className: theme.airplane__info_wrapper },
                aircraft && (React.createElement(AircraftPopup, { id: aircraft.id, operatingIata: (_f = (_e = segment === null || segment === void 0 ? void 0 : segment.segmentInfo) === null || _e === void 0 ? void 0 : _e.operatingAirline) === null || _f === void 0 ? void 0 : _f.iata, name: aircraft.name, renderButton: renderButton })),
                ((_g = segment === null || segment === void 0 ? void 0 : segment.amenities) === null || _g === void 0 ? void 0 : _g.length) > 0 && (React.createElement(Amenities, { className: theme.airplane__amenities, amenities: segment.amenities })))),
        React.createElement("div", { className: cn(theme.details, (_d = {},
                _d[theme.details_one] = isNoneSide,
                _d)) },
            React.createElement(AirplaneInfo, { text: duration, type: "time" }),
            !isNoneSide && React.createElement(AirplaneInfo, { text: labels[sunInfo], type: "sun" })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.seatsDetails, onClick: onSeatsDetailsClick },
                React.createElement("div", { className: theme.seatsDetails_icon }, InfoDetails),
                t('Seat details')))));
};
export default Airplane;
