import * as React from 'react';
import { useCallback } from 'react';
import Amenities from '../../../Amenities';
import { FlightSegmentStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import FlightInfo from './FlightInfo/FlightInfo';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../utils';
import StopsInfo from './StopsInfo/StopsInfo';
import Point from './Point/Point';
import RouteArrow from './RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
import ImageWithFallback from '../../../ImageWithFallback';
var Segment = function (_a) {
    var _b;
    var segmentWithTransfer = _a.segment, first = _a.first, last = _a.last, stops = _a.stops, totalDuration = _a.totalDuration, nextDay = _a.nextDay, renderFlightInfo = _a.renderFlightInfo, multipleSegments = _a.multipleSegments, renderPlaneInfo = _a.renderPlaneInfo, stopsSeparator = _a.stopsSeparator, isReturnFlight = _a.isReturnFlight;
    var segment = segmentWithTransfer.segment, transferDuration = segmentWithTransfer.transferDuration;
    var canceled = segment.status === FlightSegmentStatus.Canceled;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var hasOperatingCarrier = ['A9', 'RM'].includes(iataCode);
    var operationCarrier = hasOperatingCarrier && segment.marketingAirline.iata !== segment.operatingAirline.iata
        ? segment.operatingAirline.name
        : null;
    var theme = useTheme('DesktopFlightRoute').SegmentStyles;
    var t = useTranslation('DesktopFlightInfo').t;
    var renderPlane = function () {
        var _a;
        var _b, _c;
        return (React.createElement("div", { className: cn(theme.plane, (_a = {}, _a[theme.plane_return] = isReturnFlight, _a)) },
            React.createElement(ImageWithFallback, { src: (_b = segment.aircraft) === null || _b === void 0 ? void 0 : _b.icon, fallbackSrc: 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png', alt: (_c = segment.aircraft) === null || _c === void 0 ? void 0 : _c.name })));
    };
    var renderPlaneInfoHandler = useCallback(function () {
        var _a, _b;
        if (renderPlaneInfo) {
            return renderPlaneInfo(segment);
        }
        return (React.createElement("div", { className: theme.planeInfo },
            React.createElement("div", { className: theme.aircraft }, segment.aircraft.name),
            ((_b = (_a = segment === null || segment === void 0 ? void 0 : segment.flightInfo) === null || _a === void 0 ? void 0 : _a.amenities) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("div", { className: theme.amenities },
                React.createElement(Amenities, { amenities: segment.flightInfo.amenities })))));
    }, [segment, renderPlaneInfo]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.segment, (_b = {},
                _b[theme.segment_additional] = !first,
                _b[theme.canceled] = canceled,
                _b[theme.oneStop] = stops.length === 1,
                _b)) },
            renderFlightInfo ? (renderFlightInfo({ segment: segment, nextDay: nextDay, singleFlight: false })) : (React.createElement(FlightInfo, { segment: segment, nextDay: nextDay })),
            React.createElement("div", { className: theme.route__info },
                React.createElement(Point, { time: segment.departure.time, airport: segment.departure.airport, thin: !first }),
                React.createElement(RouteArrow, { timeInRoute: getDurationString(segment.duration), flightNumber: "".concat(segment.marketingAirline.iata, "-").concat(segment.flightNumber) }),
                React.createElement(Point, { time: segment.arrival.time, airport: segment.arrival.airport, arrival: true, thin: !last }),
                (transferDuration || stops.length > 0) && !canceled && (React.createElement(StopsInfo, { stops: stops, stopCityName: segment.arrival.airport.city.name, transferDuration: transferDuration, separator: stopsSeparator })),
                last && multipleSegments && !stops.length && (React.createElement("div", { className: theme.totalDuration },
                    React.createElement("span", null, totalDuration)))),
            React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
                React.createElement(React.Fragment, null, !canceled ? (React.createElement("div", { className: theme.planeContainer }, segment.aircraft && (React.createElement(React.Fragment, null,
                    renderPlane(),
                    renderPlaneInfoHandler(),
                    operationCarrier && (React.createElement("div", { className: theme.operatingCarrier },
                        t('Operating carrier'),
                        ": ",
                        operationCarrier)))))) : (React.createElement("div", { className: theme.canceled__warning }, t('Canceled')))))),
        last && multipleSegments && React.createElement("div", { className: theme.totalDuration__decorator })));
};
export default Segment;
