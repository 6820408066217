import { __assign } from "tslib";
import * as React from 'react';
import { Flight } from '../../../../index';
import { useTheme } from '../../../../theme';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../../../renderProps';
var SelectedFlight = function (_a) {
    var flight = _a.flight, confirmed = _a.confirmed, canceled = _a.canceled, orderId = _a.orderId, subsidized = _a.subsidized;
    var theme = useTheme('Checkout').CheckoutStyles;
    var getFlightProps = React.useCallback(function (id, group) {
        return {
            id: id,
            segments: group.segments.map(function (groupSegment) {
                return flight.segments.find(function (segment) { return segment.segment.id === groupSegment.id; });
            }),
            pricesForFareGroups: [
                {
                    fareFamily: __assign({}, group.fareFamily),
                    prices: [
                        {
                            originalPrice: group.price,
                            price: group.price,
                            priceInMiles: group.priceInMiles,
                            flight: {
                                fares: [],
                                canBePaidByMiles: false,
                                id: id,
                                subsidized: subsidized
                            }
                        }
                    ]
                }
            ]
        };
    }, [flight, subsidized]);
    var getSelectedFaresProps = React.useCallback(function (id, group) {
        var _a, _b;
        return [
            {
                fareGroupId: ((_a = group.fareGroup) === null || _a === void 0 ? void 0 : _a.id) || ((_b = group.fareFamily) === null || _b === void 0 ? void 0 : _b.id),
                prices: [
                    {
                        flight: {
                            fares: [],
                            id: id,
                            subsidized: subsidized
                        },
                        priceInMiles: group.priceInMiles,
                        price: group.price
                    }
                ]
            }
        ];
    }, [flight]);
    var t = useTranslation('Checkout').t;
    return (React.createElement("div", { className: theme.selectedFlight__container },
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.selectedFlight__header }, t(flight.segmentGroups.length > 1 ? 'Flights' : 'Flight'))),
        flight.segmentGroups.map(function (group, index) {
            return (React.createElement(OverrideComponent, { key: index, componentProps: {
                    orderId: orderId,
                    key: index,
                    isSelected: true,
                    flight: getFlightProps(flight.id, group),
                    selectedFares: getSelectedFaresProps(flight.id, group),
                    canceled: canceled,
                    paid: confirmed,
                    isPreOpenAvailable: false,
                    isPreOpen: false,
                    isReturnFlight: group.groupId === '1'
                }, component: {
                    renderCheckoutSelectedFlight: Flight
                } }));
        })));
};
export default SelectedFlight;
