import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import LoyaltyConnect from '../../../../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import Modal from '../../../../../../Modal';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { getUserValue } from '../../../../../../utils';
import { useTheme } from '../../../../../../theme';
import { useConfigRedirects } from '../../../../../../hooks';
var LoyaltyCard = function (props) {
    var css = useTheme('SearchForm').PayByMiles;
    var onGoToAccountSettings = useConfigRedirects().onGoToAccountSettings;
    var _a = __read(React.useState(false), 2), isLoyaltyConnectOpen = _a[0], setLoyaltyConnectOpen = _a[1];
    var _b = useGetAccountInfoQuery({ fetchPolicy: 'no-cache' }), data = _b.data, refetch = _b.refetch;
    var userInfo = React.useMemo(function () {
        var _a;
        if (!(data === null || data === void 0 ? void 0 : data.CurrentUser) || !((_a = data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.LastName),
            firstName: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.FirstName),
            email: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.Email)
        };
    }, [data === null || data === void 0 ? void 0 : data.CurrentUser]);
    var onLoyaltyConnectCloseHandler = function () {
        props.setLoyalty('');
        setLoyaltyConnectOpen(false);
    };
    var onLoyaltyConnectCardSavedHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoyaltyConnectOpen(false);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, refetch()];
                case 2:
                    data_1 = (_b.sent()).data;
                    if ((_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) {
                        props.setLoyalty(data_1.CurrentUser.userFfpInfo.cardNumber, true);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.log(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var requestLoyaltyCard = function () {
        var _a, _b;
        if (((_a = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) && !((_b = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded)) {
            props.setLoyalty(data.CurrentUser.userFfpInfo.cardNumber);
        }
        else {
            setLoyaltyConnectOpen(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        props.children(requestLoyaltyCard),
        React.createElement(Modal, { maxWidth: "sm", classes: { root: css.loyalty__modal }, open: isLoyaltyConnectOpen, onClose: onLoyaltyConnectCloseHandler },
            React.createElement(LoyaltyConnect, { userInfo: userInfo, onLoyaltyCardSaved: onLoyaltyConnectCardSavedHandler, onClose: onLoyaltyConnectCloseHandler, onGoToSettings: onGoToAccountSettings, showRegisterAction: !!(data === null || data === void 0 ? void 0 : data.CurrentUser), authType: "authenticate" }))));
};
export default LoyaltyCard;
