import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TravellerFieldEnum, useCheckinSaveOrderServicesMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import { getCheckinOrderId, getCheckinHasStarted, hasMealServices, hasBaggageServices, getCheckinNotStarted, hasInsurances, hasTransfers, getCheckinIsBlockedForPayment, getIsAllTravellersHavePreseating } from './store/order/selectors';
import { fillCheckinOrder } from './store/order/actions';
import { getPassengerFirstName, getPassengerLastName } from '../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter, getUserValue } from '../../utils';
export var getTravellerRetryLink = function (traveller) {
    var _a, _b;
    var lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
    var ticket = (_b = (_a = traveller.tickets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.number;
    return "/search/".concat(ticket, "/").concat(lastName);
};
export var getTravellerName = function (traveller) {
    var parts = [];
    traveller.values.forEach(function (_a) {
        var type = _a.type, value = _a.value;
        if ((type === TravellerFieldEnum.FirstName ||
            type === TravellerFieldEnum.LastName ||
            type === TravellerFieldEnum.MiddleName) &&
            !!value) {
            var travellerFirstName = getPassengerFirstName(traveller);
            var travellerLastName = getPassengerLastName(traveller);
            if (travellerFirstName && travellerLastName) {
                parts[traveller.id] = [];
                parts[traveller.id].push(capitalizeFirstLetter(travellerLastName.value.toLowerCase()), capitalizeFirstLetter(travellerFirstName.value.toLowerCase()));
            }
        }
    });
    return parts[traveller.id].join(' ');
};
export var CheckinStep;
(function (CheckinStep) {
    CheckinStep["Passengers"] = "";
    CheckinStep["Meal"] = "meal";
    CheckinStep["Baggage"] = "baggage";
    CheckinStep["Seats"] = "seats";
    CheckinStep["Payment"] = "payment";
    CheckinStep["Extras"] = "extras";
})(CheckinStep || (CheckinStep = {}));
export var useSteps = function () {
    var history = useHistory();
    var orderId = useSelector(getCheckinOrderId);
    var checkinHasStarted = useSelector(getCheckinHasStarted);
    var checkinIsBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    var hasMeals = useSelector(hasMealServices);
    var hasBaggage = useSelector(hasBaggageServices);
    var hasInsurance = useSelector(hasInsurances);
    var hasTransfer = useSelector(hasTransfers);
    var isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    var result = {
        setStep: function (step) {
            history.push("/".concat(orderId, "/").concat(step));
        },
        getNextStep: function (currentStep) {
            var newURL = "/".concat(orderId, "/");
            if (checkinIsBlockedForPayment) {
                newURL += CheckinStep.Payment;
            }
            else if (checkinHasStarted) {
                switch (currentStep) {
                    case CheckinStep.Passengers:
                        newURL += isAllTravellersHavePreseating
                            ? CheckinStep.Passengers
                            : hasBaggage
                                ? CheckinStep.Baggage
                                : hasMeals
                                    ? CheckinStep.Meal
                                    : CheckinStep.Seats;
                        break;
                    case CheckinStep.Baggage:
                        newURL += hasMeals ? CheckinStep.Meal : CheckinStep.Seats;
                        break;
                    case CheckinStep.Meal:
                        newURL += CheckinStep.Seats;
                        break;
                    case CheckinStep.Seats:
                        newURL += hasTransfer || hasInsurance ? CheckinStep.Extras : CheckinStep.Payment;
                        break;
                    case CheckinStep.Extras:
                        newURL += CheckinStep.Payment;
                        break;
                }
            }
            return newURL;
        },
        goToNextStep: function (currentStep, replace) {
            if (replace === void 0) { replace = false; }
            var newURL = result.getNextStep(currentStep);
            if (replace) {
                history.replace(newURL);
            }
            else {
                history.push(newURL);
            }
        },
        previousStep: function (currentStep, replace) {
            if (replace === void 0) { replace = false; }
            var newURL = "/".concat(orderId, "/");
            if (checkinHasStarted) {
                switch (currentStep) {
                    case CheckinStep.Baggage:
                        newURL += '';
                        break;
                    case CheckinStep.Meal:
                        newURL += hasBaggage ? CheckinStep.Baggage : '';
                        break;
                    case CheckinStep.Seats:
                        newURL += hasMeals ? CheckinStep.Meal : hasBaggage ? CheckinStep.Baggage : '';
                        break;
                    case CheckinStep.Extras:
                        newURL += CheckinStep.Seats;
                        break;
                    case CheckinStep.Payment:
                        newURL += hasTransfer || hasInsurance ? CheckinStep.Extras : CheckinStep.Seats;
                        break;
                }
            }
            if (replace) {
                history.replace(newURL);
            }
            else {
                history.push(newURL);
            }
        }
    };
    return result;
};
export var useServiceRequest = function () {
    var dispatch = useDispatch();
    var orderId = useSelector(getCheckinOrderId);
    var _a = __read(useCheckinSaveOrderServicesMutation(), 1), save = _a[0];
    var request = function (services) { return __awaiter(void 0, void 0, Promise, function () {
        var _a, data, errors;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, save({
                        variables: {
                            params: {
                                id: orderId,
                                services: services
                            }
                        }
                    })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors) {
                        throw errors;
                    }
                    else if (data && data.CheckinSaveOrderServices) {
                        dispatch(fillCheckinOrder(data.CheckinSaveOrderServices));
                        return [2 /*return*/, data.CheckinSaveOrderServices];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * This is a hack to support old RunServiceRequest thing.
     * Do not use this intentionally.
     *
     * @deprecated
     */
    var runServiceRequest = function (passengers) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, request(passengers.map(function (passenger) { return ({
                        travellerId: passenger.passengerId,
                        seats: passenger.seats
                            ? passenger.seats.map(function (seat) { return ({
                                segmentId: seat.segment.id,
                                letter: seat.letter,
                                row: seat.row
                            }); })
                            : [],
                        gdsServices: {
                            setServices: passenger.setServices
                        }
                    }); }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return {
        runServiceRequest: runServiceRequest
    };
};
export var getRelatedSegments = function (groups, segmentId) {
    if (segmentId === void 0) { segmentId = null; }
    var result = new Set();
    if (segmentId === null) {
        groups.forEach(function (group) {
            result.add(group[0]);
        });
    }
    else {
        groups.some(function (group) {
            if (group.includes(segmentId)) {
                group.forEach(function (segmentId) { return result.add(segmentId); });
                return true;
            }
            return false;
        });
    }
    return result;
};
export var createServicesForRequest = function (selectedServices, baggageServices) {
    var passengers = new Map();
    for (var passengerId in selectedServices) {
        if (selectedServices.hasOwnProperty(passengerId)) {
            var passengerServices = selectedServices[passengerId];
            var ignoreServiceId = null;
            var _loop_1 = function (serviceId) {
                if (passengerServices.hasOwnProperty(serviceId)) {
                    var servicesBySegments = passengerServices[serviceId];
                    var relatedService = baggageServices === null || baggageServices === void 0 ? void 0 : baggageServices.find(function (baggageService) { return baggageService.id === serviceId; });
                    var _loop_2 = function (segmentId) {
                        if (servicesBySegments.hasOwnProperty(segmentId)) {
                            var count = servicesBySegments[segmentId];
                            if (ignoreServiceId === serviceId) {
                                ignoreServiceId = null;
                                return "continue";
                            }
                            if (!passengers.has(passengerId)) {
                                passengers.set(passengerId, {
                                    travellerId: passengerId,
                                    gdsServices: {
                                        setServices: []
                                    }
                                });
                            }
                            var passenger = passengers.get(passengerId);
                            // Sometimes service should be added to a multiple segments at the same time.
                            // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
                            // rather than to a particular segment. in this case, just ignore next segment in selectedServices
                            if (relatedService === null || relatedService === void 0 ? void 0 : relatedService.allowedSegments.some(function (allowedSegment) {
                                return allowedSegment.includes(segmentId);
                            })) {
                                ignoreServiceId = serviceId;
                                passenger.gdsServices.setServices.push({
                                    serviceId: serviceId,
                                    count: count,
                                    segmentIds: __spreadArray([], __read(relatedService === null || relatedService === void 0 ? void 0 : relatedService.allowedSegments.find(function (allowedSegment) {
                                        return allowedSegment.includes(segmentId);
                                    })), false)
                                });
                            }
                            else {
                                passenger.gdsServices.setServices.push({
                                    serviceId: serviceId,
                                    count: count,
                                    segmentIds: [segmentId]
                                });
                            }
                        }
                    };
                    for (var segmentId in servicesBySegments) {
                        _loop_2(segmentId);
                    }
                }
            };
            for (var serviceId in passengerServices) {
                _loop_1(serviceId);
            }
        }
    }
    return __spreadArray([], __read(passengers.values()), false);
};
export var useCheckinAvailabilityInfo = function (pollInterval) {
    var _a, _b;
    if (pollInterval === void 0) { pollInterval = 60000; }
    var _c = __read(useState(false), 2), showExpireModal = _c[0], setShowExpireModal = _c[1];
    var isCheckinNew = useSelector(getCheckinNotStarted);
    var isCheckinStarted = useSelector(getCheckinHasStarted);
    var checkinOrderId = useSelector(getCheckinOrderId);
    var _d = useGetCheckinOrderQuery({
        variables: {
            id: checkinOrderId
        },
        skip: !checkinOrderId
    }), startPolling = _d.startPolling, stopPolling = _d.stopPolling, loading = _d.loading, data = _d.data;
    useEffect(function () {
        if (checkinOrderId) {
            startPolling(pollInterval);
        }
        return stopPolling;
    }, [checkinOrderId]);
    var isAvailable = (_b = (_a = data === null || data === void 0 ? void 0 : data.CheckinOrder) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.some(function (segment) { var _a; return (_a = segment.checkinInfo) === null || _a === void 0 ? void 0 : _a.checkinAvailable; });
    var open = useCallback(function () { return setShowExpireModal(true); }, []);
    var close = useCallback(function () { return setShowExpireModal(false); }, []);
    useEffect(function () {
        if ((isCheckinStarted || isCheckinNew) && checkinOrderId && !loading && !isAvailable) {
            setShowExpireModal(true);
            stopPolling();
        }
    }, [isAvailable]);
    return { loading: loading, isAvailable: isAvailable, showExpireModal: showExpireModal, open: open, close: close, stopPolling: stopPolling };
};
