import * as React from 'react';
import Group from './Group/Group';
import { useTheme } from '../../../../theme';
import { getGroupsMap } from './utils';
import Disabilities from '../Disabilities/Disabilities';
import Accompanying from './Group/Accompanying/Accompanying';
import { OverrideComponent } from '../../../../renderProps';
var FormFields = function (props) {
    var theme = useTheme('PassengerForm').FormFields;
    var fieldGroups = React.useMemo(function () {
        return getGroupsMap(props.fields, props.passengerId, props.isChangePassengers);
    }, [props.fields, props.isChangePassengers]);
    return (React.createElement("div", null,
        !props.inline && React.createElement("div", { className: theme.label }, props.label),
        React.createElement("div", { className: theme.container }, fieldGroups.map(function (group, index) {
            return (React.createElement(OverrideComponent, { key: group.type, componentProps: {
                    key: group.type,
                    fields: group.fields,
                    groupType: group.type,
                    passengerId: props.passengerId,
                    passengerType: props.passengerType,
                    passengerDocType: props.passengerDocType,
                    disabled: props.disabled,
                    onTravellerSelect: props.onTravellerSelect,
                    isAuthorized: props.isAuthorized,
                    autofocus: props.autofocus && index === 0,
                    onSignUpLoyaltyProgram: props.onSignUpLoyaltyProgram,
                    selectedProfileId: props.selectedProfileId,
                    isChangeFiles: props.isChangeFiles,
                    onSwap: props.onSwap,
                    onToggleChangeFiles: props.onToggleChangeFiles
                }, component: { PassengerFormGroup: Group } }));
        })),
        React.createElement(Disabilities, null),
        React.createElement(Accompanying, { passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.disabled, accompanyingFields: props.accompanyingFields })));
};
export default FormFields;
