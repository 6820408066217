import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var Step = function (_a) {
    var _b;
    var step = _a.step, _c = _a.isActive, isActive = _c === void 0 ? false : _c, _d = _a.isPassed, isPassed = _d === void 0 ? false : _d, id = _a.id;
    var css = useTheme('Stepbar').Step;
    return (React.createElement("div", { className: cn(css.step, (_b = {},
            _b[css.step_active] = isActive,
            _b[css.step_passed] = isPassed,
            _b[css.step_clickable] = isPassed && !!step.onClick,
            _b)), onClick: isPassed && step.onClick ? step.onClick : null },
        ' ',
        React.createElement("span", { className: css.id }, id),
        React.createElement("span", { className: css.label }, step.label)));
};
export default Step;
