import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { initI18n } from '../../utils';
import MealService from '../../MealService';
import MealRecommendation from '../../ServiceRecommendation/components/Meal/Component';
import { MealWrapper } from './MealWrapper';
import { useToggleable } from '../../hooks';
initI18n('MealPage');
var MealPage = function (_a) {
    var meals = _a.meals, segments = _a.segments, selectedMeals = _a.selectedMeals, recommendedMeals = _a.recommendedMeals, passengers = _a.passengers, onMealAdd = _a.onMealAdd, onMealRemove = _a.onMealRemove, onMealAddForPassengers = _a.onMealAddForPassengers, onMealClear = _a.onMealClear, onMealConfirm = _a.onMealConfirm, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, onRecommendationConfirm = _a.onRecommendationConfirm, onRecommendationDisagree = _a.onRecommendationDisagree, _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.showRecommendations, showRecommendations = _c === void 0 ? false : _c, isLoading = _a.isLoading;
    var setInitialSelectedMeals = useToggleable(false).toggle;
    var initialSelectedMeals = React.useMemo(function () {
        return __spreadArray([], __read(selectedMeals), false);
    }, [setInitialSelectedMeals]);
    return (React.createElement(React.Fragment, null,
        showRecommendations && (React.createElement(MealRecommendation, { segments: segments, passengers: passengers, recommendedMeals: recommendedMeals, onConfirm: function () {
                onRecommendationConfirm(recommendedMeals);
                setInitialSelectedMeals();
            }, onDisagree: onRecommendationDisagree })),
        React.createElement(MealService, { meals: meals, recommendedMeals: recommendedMeals, segments: segments, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, mealRenderer: function (meal, segmentId, isSingleMeal) { return (React.createElement(MealWrapper, { fullSize: isSingleMeal, key: meal.id, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, passengers: passengers, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onMealConfirm: function () {
                    onMealConfirm(meal, segmentId);
                    setInitialSelectedMeals();
                }, onMealAddForPassengers: onMealAddForPassengers, onMealClear: onMealClear, onMealOpen: setInitialSelectedMeals, readonly: readonly, isRecommend: recommendedMeals === null || recommendedMeals === void 0 ? void 0 : recommendedMeals.some(function (recommend) { return recommend.meal.id === meal.id; }), isLoading: isLoading })); } })));
};
export default MealPage;
