import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { collectPassengerInfo } from '../../utils';
import Disabilities from '../Disabilities/Disabilities';
import Radio from '../../../Radio/components/Radio';
import Button from '../../../Button';
var Passenger = function (props) {
    var _a;
    var css = useTheme('PassengersDetails').PassengersDetails;
    var t = useTranslation('OrderCard').t;
    var _b = collectPassengerInfo(props.traveller), travellerName = _b.travellerName, mainInfo = _b.mainInfo, additionalInfo = _b.additionalInfo;
    var onLinkTo = function () { return props.onLinkTo(props.traveller.id); };
    var onSetRelinkingMode = function () { return props.onSetRelinkingMode(props.traveller); };
    return (React.createElement("div", { className: cn((_a = {}, _a[css.accompanied] = props.isAccompanied, _a)) },
        React.createElement("div", { className: css.passenger },
            props.isRelinkingMode ? (React.createElement(Radio, { checked: props.selectedAsAccompanying, className: css.passenger__checkbox, onClick: onLinkTo, disabled: props.isDisabled })) : (React.createElement("div", { className: css.number }, props.index)),
            React.createElement("div", { className: css.content },
                React.createElement("div", { className: css.item_content },
                    React.createElement("div", { className: css.name, onClick: props.isRelinkingMode ? onLinkTo : null },
                        React.createElement("div", null, travellerName),
                        props.isRelinkingAvailable && props.isAccompanied && props.onSetRelinkingMode && (React.createElement("div", { className: css.name__link, onClick: onSetRelinkingMode }, t('Change link to adult')))),
                    React.createElement("div", { className: css.info },
                        React.createElement("span", null, mainInfo.join(', ')),
                        React.createElement("span", null, additionalInfo.join(', ')),
                        !props.isRelinkingMode && React.createElement(Disabilities, { disabilities: props.traveller.disabilities }))),
                props.onChangePassenger && (React.createElement(Button, { className: css.button, onClick: props.onChangePassenger }, t('Change')))))));
};
export default Passenger;
