import { __assign, __rest } from "tslib";
import * as React from 'react';
import MUIButton from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { ButtonVariant } from '../types';
import { useComputedStyleProperty } from '../../hooks';
import Loader from './Loader/Loader';
import { Add } from '../../../Icons';
var Button = function (_a) {
    var _b;
    var variant = _a.variant, classes = _a.classes, disabled = _a.disabled, isLoading = _a.isLoading, children = _a.children, endIcon = _a.endIcon, _c = _a.disableElevation, disableElevation = _c === void 0 ? true : _c, props = __rest(_a, ["variant", "classes", "disabled", "isLoading", "children", "endIcon", "disableElevation"]);
    var theme = useTheme('BaseComponents').Button;
    var _d = useComputedStyleProperty('--variant', variant), buttonVariant = _d.value, elRef = _d.elRef;
    var isOptionActionPlusIcon = buttonVariant === ButtonVariant.OptionActionPlusIcon;
    return (React.createElement(MUIButton, __assign({ classes: __assign(__assign({}, classes), { root: cn(theme.root, theme[buttonVariant], classes === null || classes === void 0 ? void 0 : classes.root, (_b = {},
                _b[theme.loading] = isLoading,
                _b)), disabled: cn(theme.disabled, classes === null || classes === void 0 ? void 0 : classes.disabled), endIcon: cn(theme.endIcon, classes === null || classes === void 0 ? void 0 : classes.endIcon) }), disabled: disabled || isLoading, endIcon: isOptionActionPlusIcon && !isLoading ? Add : endIcon, disableElevation: disableElevation, ref: elRef }, props), isLoading ? React.createElement(Loader, null) : children));
};
export default Button;
