import { LoginInputType } from './types';
import { emailRegex, phoneRegex } from '../utils';
import { removeSpaces } from '../PhoneInput/utils';
export var detectInputType = function (value) {
    if (emailRegex.test(value)) {
        return LoginInputType.Email;
    }
    else if (phoneRegex.test(removeSpaces(value))) {
        return LoginInputType.Phone;
    }
    else {
        return LoginInputType.Text;
    }
};
