import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Unvoluntary, StepType } from '../../types';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter } from '../../../utils';
import { isRefundPassenger } from '../../utils';
import { useTheme } from '../../../theme';
import { usePassengerNames } from '../Passenger/hooks';
import { useSteps } from '../Refund/hooks';
var Breadcrumbs = function (props) {
    var _a;
    var passengers = props.passengers, refundInfo = props.refundInfo, renderAfter = props.renderAfter;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Breadcrumbs;
    var getPassengerName = usePassengerNames().getPassengerName;
    var currentStep = useSteps().currentStep;
    var renderPassengers = useCallback(function () {
        if (!passengers.length) {
            return null;
        }
        var passengerName;
        if (isRefundPassenger(passengers[0])) {
            passengerName = passengers.map(getPassengerName).join(', ');
        }
        else {
            passengerName = passengers
                .map(function (passenger) {
                return "".concat(capitalizeFirstLetter(getPassengerFirstName(passenger).value.toLowerCase()), " ").concat(capitalizeFirstLetter(getPassengerLastName(passenger).value.toLowerCase()));
            })
                .join(', ');
        }
        return passengerName.split(',').map(function (name, index) { return (React.createElement("div", { title: name, className: cn(css.breadcrumb), key: index },
            React.createElement("div", { className: css.text }, name),
            renderAfter ? renderAfter('passenger') : null)); });
    }, [passengers]);
    var renderRefundInfo = useCallback(function () {
        if (!refundInfo) {
            return null;
        }
        var reasonStepText = refundInfo.reason === Unvoluntary.Unvoluntary ? t('Voluntary refund') : t('Involuntary refund');
        if (refundInfo.reason !== Unvoluntary.Unvoluntary) {
            reasonStepText += ' - ';
        }
        if (refundInfo.reason !== Unvoluntary.Unvoluntary) {
            reasonStepText += t(refundInfo.reason);
        }
        return (React.createElement("div", { title: reasonStepText, className: cn(css.breadcrumb) },
            React.createElement("div", { className: css.text }, reasonStepText),
            renderAfter ? renderAfter('refundInfo') : null));
    }, [refundInfo]);
    return (React.createElement("div", { className: cn(css.breadcrumbs, props.className, (_a = {},
            _a[css.passengersStep] = currentStep.type === StepType.Passengers,
            _a)) },
        renderPassengers(),
        " ",
        renderRefundInfo()));
};
export default Breadcrumbs;
