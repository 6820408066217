import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Button from '../../../Button';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var SureModalContent = function (_a) {
    var className = _a.className, title = _a.title, description = _a.description, onSuccess = _a.onSuccess, onClose = _a.onClose;
    var css = useTheme('SureModal').SureModalContent;
    var t = useTranslation('Loyalty').t;
    var _b = __read(useState(false), 2), isLoading = _b[0], setLoading = _b[1];
    var onSuccessHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, onSuccess()];
                case 1:
                    _a.sent();
                    setLoading(false);
                    setTimeout(onClose, 500);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.header }, title),
        React.createElement("div", { className: css.text }, description),
        React.createElement("div", { className: css.footer },
            !isLoading && (React.createElement(Button, { className: cn(css.button, css.button_cancel), onClick: onClose }, t('No'))),
            React.createElement(Button, { className: css.button, isLoading: isLoading, onClick: onSuccessHandler }, t('Yes')))));
};
export default SureModalContent;
