import { __assign, __awaiter, __extends, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de, enGB, kk, ru, hy, ka, tr, cs, be } from 'date-fns/locale';
import { format as originalFormat, parseISO, formatDistance as originalFormatDistance } from 'date-fns';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { TravellerFieldEnum, GdsServiceProductStatus, SsrStatus, Gender } from '@websky/graphql';
import { Locale } from './types';
import { Currency } from './enums';
import { getCurrency, getLocaleFromLocalStorage, getShowTranslationKeysFromLocalStorage, setLocaleToLocalStorage } from './cache';
import memes from './memes';
import { useMediaQuery } from 'react-responsive';
import dataLayerAnalytics from './DataLayerAnalytics/DataLayerAnalytics';
import tg from './Locales/tg';
dataLayerAnalytics.init({
    currency: getCurrency()
});
export var STAY_LOGGED_IN = 'STAY_LOGGED_IN';
export var API_DATE_FORMAT = 'yyyy-MM-dd';
export var SHOW_RECAPTCHA = 'SHOW_RECAPTCHA';
export var TABLET_MIN_WIDTH = 1024;
export var TABLET_MIDDLE_WIDTH = 768;
export var MOBILE_MIN_WIDTH = 600;
export var LOCALES = new Set([
    Locale.Russian,
    Locale.English,
    Locale.German,
    Locale.Kazakh,
    Locale.French,
    Locale.Chinese,
    Locale.Armenian,
    Locale.Georgian,
    Locale.Turkish,
    Locale.Tajik,
    Locale.Czech,
    Locale.Belarusian
]);
export var convertICULocale = function (locale) {
    /**
     * ICU format locale for kazakh is kk, but on tickets.scat.kz but the site uses kz,
     * in this function we convert it
     */
    var localeLangMap = new Map([['kk', 'kz']]);
    if (!localeLangMap.has(locale)) {
        return locale;
    }
    else {
        return localeLangMap.get(locale);
    }
};
export var getInfo = function (options) {
    if (options === void 0) { options = {}; }
    return ({
        info: __assign({ inline: true, source: true, maxPropsIntoLine: 1, maxPropArrayLength: 2 }, options)
    });
};
export var isDemo = document.location.href.includes('demo.websky.tech');
export var createWithThemeDecorator = function (Consumer) {
    return function (Component) {
        var NewComponent = /** @class */ (function (_super) {
            __extends(class_1, _super);
            function class_1() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            class_1.prototype.render = function () {
                var _this = this;
                return React.createElement(Consumer, null, function (context) { return React.createElement(Component, __assign({}, _this.props, { theme: context })); });
            };
            return class_1;
        }(React.Component));
        NewComponent.displayName = Component.displayName;
        return NewComponent;
    };
};
export var createTheme = function (defaultTheme) {
    var Context = React.createContext(defaultTheme);
    return {
        Context: Context,
        Consumer: Context.Consumer,
        Provider: Context.Provider,
        withTheme: createWithThemeDecorator(Context.Consumer),
        useTheme: function () { return useContext(Context); }
    };
};
export var sum = function () {
    var money = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        money[_i] = arguments[_i];
    }
    var result = { amount: 0, currency: Currency.USD };
    money.forEach(function (obj) {
        if (result.amount !== 0) {
            result.amount += obj.amount;
        }
        else {
            result = __assign({}, obj);
        }
    });
    return result;
};
export var getBrowserLocale = function () {
    var _a;
    var locale = typeof navigator !== 'undefined'
        ? navigator.languages
            ? navigator.languages[0]
            : (_a = navigator.language) !== null && _a !== void 0 ? _a : navigator['userLanguage']
        : Locale.Russian;
    return locale.split('-')[0].replace(/"/g, '');
};
var defaultResources = {};
var currentLocale = getLocaleFromLocalStorage();
export var currentCurrency = getCurrency();
// No cached locale - pull from local storage.
if (!currentLocale) {
    currentLocale = getLocaleFromLocalStorage();
}
// No cached locale - pull from local storage.
if (!currentCurrency) {
    currentCurrency = getCurrency();
}
// No local storage locale - pull from browser settings.
if (!currentLocale) {
    var browserLocale = getBrowserLocale();
    if (LOCALES.has(browserLocale)) {
        currentLocale = browserLocale;
        setLocaleToLocalStorage(currentLocale);
    }
}
// Load default resources.
LOCALES.forEach(function (locale) {
    if (!defaultResources[locale]) {
        defaultResources[locale] = {};
    }
    if (!defaultResources[locale].translation) {
        defaultResources[locale].translation = require("../locales/".concat(locale, "/translation.json"));
    }
});
var showKeysPostProcessor = {
    type: 'postProcessor',
    name: 'showKeys',
    process: function (value, key, options) {
        if (!options.ns || (options.ns === 'translation' && key[0].includes(':'))) {
            return "{{".concat(key[0], "}}");
        }
        return "{{".concat(options.ns, ":").concat(key[0], "}}");
    }
};
var postProcess = [];
if (getShowTranslationKeysFromLocalStorage()) {
    postProcess.push('showKeys');
    var styleNode = document.createElement('style');
    styleNode.innerText = '#root *{text-transform: unset !important}';
    document.head.appendChild(styleNode);
}
i18n.use(showKeysPostProcessor)
    .use(initReactI18next)
    .init({
    resources: defaultResources,
    lng: currentLocale,
    fallbackLng: Locale.Russian,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    },
    postProcess: postProcess
});
export var getLocale = function () { return i18n.language; };
export var changeLocale = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!LOCALES.has(locale)) return [3 /*break*/, 2];
                setLocaleToLocalStorage(locale);
                currentLocale = locale;
                return [4 /*yield*/, i18n.changeLanguage(locale)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
export var initI18n = memes(function (moduleName) {
    LOCALES.forEach(function (locale) {
        try {
            var resources = require("../locales/".concat(locale, "/").concat(moduleName, ".json"));
            i18n.addResourceBundle(locale, moduleName, resources, true, false);
        }
        catch (e) { }
    });
    window['__webskyEngineReady'] = true;
});
export var extendI18n = function (newResources) {
    for (var locale in newResources) {
        if (newResources.hasOwnProperty(locale)) {
            for (var moduleName in newResources[locale]) {
                if (newResources[locale].hasOwnProperty(moduleName)) {
                    i18n.addResourceBundle(locale, moduleName, newResources[locale][moduleName], true, true);
                }
            }
        }
    }
};
var userLocation;
export var getUserLocation = function (onSuccess, onError) {
    if (userLocation) {
        onSuccess(userLocation);
    }
    else if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (_a) {
            var coords = _a.coords;
            if (coords.longitude && coords.latitude) {
                userLocation = {
                    longitude: coords.longitude.toString(),
                    latitude: coords.latitude.toString()
                };
                onSuccess(userLocation);
            }
        }, function (error) { return onError && onError(error.message); });
    }
    else if (onError) {
        onError('GeoLocation API is not available for that kind of browsers');
    }
};
export var format = function (date, format, options) {
    var localeObject;
    switch (currentLocale) {
        case Locale.Russian:
            localeObject = ru;
            break;
        case Locale.English:
            localeObject = enGB;
            break;
        case Locale.Tajik:
            localeObject = tg;
            break;
        case Locale.Georgian:
            localeObject = ka;
            break;
        case Locale.Armenian:
            localeObject = hy;
            break;
        case Locale.German:
            localeObject = de;
            break;
        case Locale.Kazakh:
            localeObject = kk;
            break;
        case Locale.Turkish:
            localeObject = tr;
            break;
        case Locale.Czech:
            localeObject = cs;
            break;
        case Locale.Belarusian:
            localeObject = be;
            break;
    }
    var parsedDate = typeof date === 'string' ? parseISO(date) : date;
    var formattedDate = originalFormat(parsedDate, format, __assign(__assign({}, options), { locale: localeObject }));
    return formattedDate.endsWith('.') ? formattedDate.substr(0, formattedDate.length - 1) : formattedDate;
};
export var formatDistance = function (date, baseDate, options) {
    var localeObject;
    switch (currentLocale) {
        case Locale.Russian:
            localeObject = ru;
            break;
        case Locale.English:
            localeObject = enGB;
            break;
        case Locale.Georgian:
            localeObject = ka;
            break;
        case Locale.Armenian:
            localeObject = hy;
            break;
        case Locale.German:
            localeObject = de;
            break;
        case Locale.Kazakh:
            localeObject = kk;
            break;
        case Locale.Turkish:
            localeObject = tr;
            break;
        case Locale.Czech:
            localeObject = cs;
            break;
        case Locale.Belarusian:
            localeObject = be;
            break;
    }
    return originalFormatDistance(date, baseDate, __assign(__assign({}, options), { locale: localeObject }));
};
export var removeDotsFromDate = function (dateString) {
    return dateString.replace('.', '');
};
export var debounce = function (func, wait) {
    var timeout;
    return function () {
        var newArgs = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newArgs[_i] = arguments[_i];
        }
        var later = function () {
            timeout = null;
            func.apply(void 0, __spreadArray([], __read(newArgs), false));
        };
        clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
    };
};
export var mergeCSS = function (originalTheme, newTheme) {
    var result = JSON.parse(JSON.stringify(originalTheme));
    for (var moduleName in newTheme) {
        if (newTheme.hasOwnProperty(moduleName) && originalTheme.hasOwnProperty(moduleName)) {
            var components = newTheme[moduleName];
            for (var componentName in components) {
                if (components.hasOwnProperty(componentName) &&
                    originalTheme[moduleName].hasOwnProperty(componentName)) {
                    var cssClasses = components[componentName];
                    for (var cssClassKey in cssClasses) {
                        if (cssClasses.hasOwnProperty(cssClassKey) &&
                            originalTheme[moduleName][componentName].hasOwnProperty(cssClassKey)) {
                            result[moduleName][componentName][cssClassKey] += " ".concat(cssClasses[cssClassKey]);
                        }
                    }
                }
            }
        }
    }
    return result;
};
export var decapitalizeFirstLetter = memes(function (string) {
    return string.charAt(0).toLowerCase() + string.substr(1);
});
var capitalizeFirstLetterSplittedBy = function (string, separator) {
    if (string.includes(separator)) {
        return string
            .split(separator)
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
            .join(separator);
    }
    return string;
};
export var capitalizeFirstLetter = memes(function (string) {
    var lowerString = string.toLowerCase();
    var newString = lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
    newString = capitalizeFirstLetterSplittedBy(newString, ' ');
    return newString;
});
export var capitalizeFirstLetterInName = memes(function (string) {
    var newString = capitalizeFirstLetter(string);
    return capitalizeFirstLetterSplittedBy(newString, '-');
});
export var downloadFile = function (href, name) {
    var element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', name);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
export var downloadPDF = function (base64, name) {
    downloadFile('data:application/pdf;base64,' + base64, name);
};
export var downloadPkpass = function (base64, name) {
    downloadFile('data:application/vnd.apple.pkpass;base64,' + base64, name);
};
export var enableSentry = function () {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            allowUrls: [
                'https://static.flyredwings.com/',
                'https://cdn.websky.aero/',
                'https://cdn-eu1.websky.aero/',
                'https://cdn.eu2.websky.tech/',
                'https://cdn.web.websky.tech/'
            ]
        });
    }
};
export var captureEventSentry = function (event) {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.captureMessage(event.detail.message, Sentry.Severity.Error);
    }
};
export var captureErrorSentry = function (message, level) {
    if (level === void 0) { level = Severity.Error; }
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.captureMessage(message, level);
    }
};
export var removeTrailingSlash = function (string) {
    var stringLength = string.length;
    return string.charAt(stringLength - 1) === '/' ? string.substr(0, stringLength - 1) : string;
};
export var setsAreEqual = function (a, b) {
    var e_1, _a;
    if (a.size !== b.size) {
        return false;
    }
    try {
        for (var a_1 = __values(a), a_1_1 = a_1.next(); !a_1_1.done; a_1_1 = a_1.next()) {
            var index = a_1_1.value;
            if (!b.has(index)) {
                return false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (a_1_1 && !a_1_1.done && (_a = a_1.return)) _a.call(a_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
};
export var isOdd = function (int) { return (typeof int === 'string' ? parseInt(int, 10) : int) % 2 === 1; };
export var useIsMobile = function () {
    return useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
};
export var useIsDesktop = function () {
    return useMediaQuery({ minWidth: TABLET_MIN_WIDTH });
};
export var scrollTo = function (top) {
    if (window.hasOwnProperty('scrollTo')) {
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        });
    }
};
var isSeatSegmentsGuard = function (segment) {
    return (segment.hasOwnProperty('isSeatsAvailableInCheckinOnly') ||
        segment.hasOwnProperty('services') ||
        segment.hasOwnProperty('sunSide') ||
        segment.hasOwnProperty('decks'));
};
export var segmentTabFabric = function (segment) {
    if (isSeatSegmentsGuard(segment)) {
        return {
            id: segment.segmentInfo.id,
            title: "".concat(segment.segmentInfo.departure.airport.city.name, " \u2014 ").concat(segment.segmentInfo.arrival.airport.city.name)
        };
    }
    return {
        id: segment.id,
        title: "".concat(segment.departure.airport.city.name, " \u2014 ").concat(segment.arrival.airport.city.name)
    };
};
export var getPassengerNames = function (passenger) {
    var firstName = getUserValue(passenger, TravellerFieldEnum.FirstName);
    var lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
    var middleName = getUserValue(passenger, TravellerFieldEnum.MiddleName);
    return { firstName: firstName, lastName: lastName, middleName: middleName };
};
export var getPassengerAvatar = function (passenger) {
    var _a = getPassengerNames(passenger), firstName = _a.firstName, lastName = _a.lastName;
    if (firstName || lastName) {
        return [firstName[0], lastName[0]].filter(Boolean).join('').toUpperCase();
    }
    return (parseInt(passenger.id) + 1).toString();
};
initI18n('PassengerTypes');
export var getPassengerTypeLabel = function (type, supplierType, count) {
    var options = { count: count };
    if (supplierType && i18n.exists("PassengerTypes:".concat(supplierType))) {
        return i18n.t("PassengerTypes:".concat(supplierType), options);
    }
    return i18n.t("PassengerTypes:".concat(type), options);
};
export var concatPassengerNames = function (lastName, firstName, middleName) {
    return [lastName, firstName, middleName].filter(Boolean).map(capitalizeFirstLetterInName).join(' ');
};
export var getPassengerLabel = function (passenger) {
    var _a = getPassengerNames(passenger), firstName = _a.firstName, lastName = _a.lastName, middleName = _a.middleName;
    if (firstName && lastName) {
        return concatPassengerNames(lastName, firstName, middleName);
    }
    return getPassengerTypeLabel(passenger.type, passenger.supplierTravellerType);
};
export var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var phoneRegex = /^((8|\+7|\+374)[\- ]?)?(\(?\d{3}\)[\- ]?)?[\d\- ]{7,10}$/;
export var seatNumberRegex = /[0-9]{1,4}[A-Z]/;
/**
 * Create a bubbling CustomEvent and dispatch on target.
 *
 * @param target HTMLElement
 * @param event
 */
export var emitEvent = function (target, event) {
    try {
        target.dispatchEvent(new CustomEvent(event.type, {
            detail: event.data,
            bubbles: true
        }));
    }
    catch (e) {
        console.warn('Cannot to emit event ' + event.type);
    }
};
export var isRequireCurrencyParam = function () {
    var _a, _b, _c;
    return ['A9', 'RM', '5G', '2S', 'JI', 'SZ', 'DV', 'B2'].includes((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
};
export var getTravellersMap = memes(function (travellers) {
    var map = new Map(), travellerTypesCount = new Map();
    travellers.forEach(function (traveller) {
        var _a, _b, _c, _d;
        var firstName = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.values) === null || _a === void 0 ? void 0 : _a.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _b === void 0 ? void 0 : _b.value, lastName = (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.values) === null || _c === void 0 ? void 0 : _c.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _d === void 0 ? void 0 : _d.value;
        var fullName = lastName && firstName ? [lastName, firstName].map(capitalizeFirstLetter).join(' ') : null;
        var typeNumber = (travellerTypesCount.get(traveller.type) || 0) + 1;
        map.set(traveller.id, {
            id: traveller.id,
            name: fullName,
            type: traveller.type,
            supplierTravellerType: traveller.supplierTravellerType,
            typeNumber: typeNumber
        });
        travellerTypesCount.set(traveller.type, typeNumber);
    });
    return map;
});
export var getTotalPrice = function (items, getItemPrice) {
    var totalPrice = {
        amount: 0,
        currency: getCurrency()
    };
    if (!!(items === null || items === void 0 ? void 0 : items.length)) {
        return items.reduce(function (acc, item) {
            var _a;
            var itemPrice = getItemPrice(item);
            return {
                amount: ((itemPrice === null || itemPrice === void 0 ? void 0 : itemPrice.amount) || 0) + acc.amount,
                currency: (_a = itemPrice === null || itemPrice === void 0 ? void 0 : itemPrice.currency) !== null && _a !== void 0 ? _a : acc.currency
            };
        }, totalPrice);
    }
    return totalPrice;
};
export var getCheckInUrl = function (checkInURL, orderId, ticket, lastName, reserveEngines) {
    var formattedCheckInURL = removeTrailingSlash(checkInURL);
    if ((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)) {
        var checkinURL = removeTrailingSlash(reserveEngines.checkin);
        var locale = convertICULocale(getLocale());
        var url = new URL(checkinURL);
        url.searchParams.set('lang', locale);
        url.searchParams.set('ticketNumber', ticket);
        url.searchParams.set('lastName', lastName);
        url.searchParams.set('submit', '');
        url.hash = 'search';
        return url.toString();
    }
    else if (lastName && ticket) {
        return "".concat(formattedCheckInURL, "#/search/").concat(ticket, "/").concat(lastName);
    }
    return "".concat(formattedCheckInURL, "#/").concat(orderId);
};
var variationMap = new Map([
    [[1], 0],
    [[2, 3, 4], 1],
    [[0, 5, 6, 7, 8, 9], 2]
]);
export var getI18nCountVariation = function (count, variation) {
    var _a, _b;
    if (variation === void 0) { variation = variationMap; }
    var locale = getLocale();
    if (locale === 'ru') {
        var actualCount_1 = count >= 10 && count <= 20 ? 0 : count % 10;
        return (_b = (_a = __spreadArray([], __read(variation.entries()), false).find(function (_a) {
            var _b = __read(_a, 1), numbers = _b[0];
            return numbers.includes(actualCount_1);
        })) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : 0;
    }
    return count === 0 ? 0 : 2;
};
export var getMinPrice = function (items, getItemPrice) {
    if (!!items.length) {
        return items.reduce(function (minPrice, item) {
            var _a;
            var price = getItemPrice(item);
            return {
                amount: price.amount < minPrice.amount ? price.amount : minPrice.amount,
                currency: (_a = price === null || price === void 0 ? void 0 : price.currency) !== null && _a !== void 0 ? _a : minPrice.currency
            };
        }, {
            amount: Infinity,
            currency: getCurrency()
        });
    }
    else {
        return {
            amount: 0,
            currency: getCurrency()
        };
    }
};
export var isUnconfirmedService = function (status) {
    return [
        GdsServiceProductStatus.Rejected,
        GdsServiceProductStatus.Canceled,
        GdsServiceProductStatus.Problematic
    ].includes(status);
};
export var getUserValue = function (user, type) {
    var _a, _b, _c;
    return (_c = (_b = (_a = user.values) === null || _a === void 0 ? void 0 : _a.find(function (value) { return value.type === type; })) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
};
export var isCheckoutOrderGuard = function (order) {
    return order.hasOwnProperty('locator');
};
export var isResultsStateGuard = function (state) {
    return 'legs' in state;
};
export var isCheckoutStateGuard = function (state) {
    return 'passengers' in state;
};
export var isExchangeStateGuard = function (state) {
    return 'flightsToExchange' in state;
};
export var getHasRequestedServices = function (travellers, ssrData) {
    return travellers.some(function (traveller) {
        var _a, _b, _c, _d, _e;
        var hasRequestedServices = (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { var _a; return (_a = service.products) === null || _a === void 0 ? void 0 : _a.some(function (product) { return (product === null || product === void 0 ? void 0 : product.status) === GdsServiceProductStatus.Requested; }); });
        var hasRequestedSeats = (_e = (_d = traveller.services) === null || _d === void 0 ? void 0 : _d.seats) === null || _e === void 0 ? void 0 : _e.some(function (service) { var _a; return ((_a = service.product) === null || _a === void 0 ? void 0 : _a.status) === GdsServiceProductStatus.Requested; });
        var hasRequestedSsrData = ssrData === null || ssrData === void 0 ? void 0 : ssrData.some(function (ssrData) { return ssrData.status === SsrStatus.OnRequest; });
        return hasRequestedServices || hasRequestedSeats || hasRequestedSsrData;
    });
};
// TODO: remove this when php is fixed
export var convertGenderToCorrectValue = function (gender) {
    var _a;
    var genderMap = {
        M: Gender.Male,
        F: Gender.Female,
        O: Gender.Other
    };
    return (_a = genderMap[gender]) !== null && _a !== void 0 ? _a : gender;
};
