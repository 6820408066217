import { __assign, __rest } from "tslib";
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '../../theme';
import PaginationItem from '@mui/material/PaginationItem';
var WxPagination = function (props) {
    var css = useTheme('WxPagination').WxPagination;
    var _a = props.variant, variant = _a === void 0 ? 'text' : _a, pages = props.pages, onHandleChange = props.onHandleChange, children = props.children, rest = __rest(props, ["variant", "pages", "onHandleChange", "children"]);
    var handleChange = function (event, value) {
        onHandleChange(value);
    };
    return (React.createElement(Pagination, __assign({ classes: {
            root: css.root,
            ul: css.ul
        }, count: pages, page: props.page, onChange: handleChange, renderItem: function (item) { return (React.createElement(PaginationItem, __assign({ classes: {
                icon: css.icon,
                disabled: css.icon,
                root: css.button,
                page: css.page,
                selected: css.selected
            } }, item))); } }, rest)));
};
export default WxPagination;
