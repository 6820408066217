import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Banner } from '../Banner/Banner';
import { Card } from '../Card/Card';
import { useInsuranceProduct } from './InsuranceProduct.hook';
import { useTheme } from '../../../theme';
import IconButton from '../../../BaseComponents/Button/components/IconButton/IconButton';
import { Add, Download } from '../../../Icons';
import { InsurancePrice } from '../InsurancePrice/InsurancePrice';
import { SelectForm } from '../SelectForm/SelectForm';
import Link from '../../../Link';
import { InfoIcon } from '../../../FlightCard/components/Icons';
import { OptionsList } from '../OptionsList/OptionsList';
export var InsuranceProduct = function (_a) {
    var _b, _c;
    var _d;
    var onToggle = _a.onToggle, insuranceProgram = _a.insuranceProgram, productState = _a.productState, className = _a.className, popular = _a.popular, policyUrl = _a.policyUrl, onSelect = _a.onSelect, onRemove = _a.onRemove, hideForAllPassengersTextBecauseSinglePassenger = _a.hideForAllPassengersTextBecauseSinglePassenger, isMainProgramTouched = _a.isMainProgramTouched;
    var t = useTranslation('InsurancePage').t;
    var theme = useTheme('InsurancePage').InsuranceProduct;
    var model = useInsuranceProduct(insuranceProgram, onToggle, popular, productState);
    var infoLink = useMemo(function () {
        if (!insuranceProgram.urlToOffer && !model.isConfirmed) {
            return null;
        }
        return (React.createElement("div", { className: theme.linksWrapper },
            insuranceProgram.urlToOffer && (React.createElement(Link, { className: theme.moreInfo, action: insuranceProgram.urlToOffer, target: '_blank' },
                React.cloneElement(InfoIcon, {
                    className: theme.infoIcon
                }),
                t('More details'))),
            model.isConfirmed && (React.createElement(Link, { className: theme.moreInfo, action: policyUrl, target: '_blank' },
                React.cloneElement(Download, {
                    className: theme.infoIcon
                }),
                t('Download police')))));
    }, [insuranceProgram.urlToOffer, policyUrl, model.isConfirmed]);
    return (React.createElement(Card, { className: cn(theme.border, theme.shadow, (_b = {},
            _b[theme.popular] = popular,
            _b[theme.confirmed] = model.isConfirmed,
            _b), className) },
        React.createElement(Banner, { className: theme.banner, code: insuranceProgram.code, popular: popular }),
        React.createElement("div", { className: theme.content },
            model.showIconButton && (React.createElement(IconButton, { onClick: model.handleToggle, isSuccess: model.isSelected || model.isConfirmed, className: cn(theme.selectButton, (_c = {},
                    _c[theme.confirmed] = model.isConfirmed,
                    _c)) }, Add)),
            React.createElement("div", { className: theme.title }, insuranceProgram.name),
            insuranceProgram.description && (React.createElement("div", { className: theme.description, dangerouslySetInnerHTML: { __html: insuranceProgram.description } })),
            !!((_d = insuranceProgram.options) === null || _d === void 0 ? void 0 : _d.length) && (React.createElement(OptionsList, { className: theme.options, options: insuranceProgram.options.map(function (_a) {
                    var name = _a.name;
                    return name;
                }) })),
            !popular && infoLink,
            popular && !model.isConfirmed && (React.createElement(SelectForm, { className: theme.formControl, onSelect: function () { return onSelect(insuranceProgram.code); }, onRemove: function () { return onRemove(insuranceProgram.code); }, selected: model.isSelected, price: insuranceProgram.totalPrice, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger, isTouched: isMainProgramTouched })),
            !popular && (React.createElement(InsurancePrice, { className: theme.footer, selected: model.isSelected, price: insuranceProgram.totalPrice, variant: popular ? 'mostPopular' : 'regular', onClick: !model.isConfirmed ? model.handleToggle : undefined, onRemove: model.handleToggle, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })),
            popular && infoLink)));
};
