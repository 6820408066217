import * as React from 'react';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
var noticeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 11V17H11V11H13ZM12 20C7.58999 20 3.99999 16.41 3.99999 12C3.99999 7.59 7.58999 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7V9H11V7H13Z", fill: "#808080" })));
var errorIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 12C20 16.4183 16.4183 20 12 20C10.2575 20 8.64515 19.4429 7.33129 18.4971L18.4971 7.33129C19.4429 8.64515 20 10.2575 20 12ZM5.86515 17.1348L17.1348 5.86515C15.7454 4.70094 13.9545 4 12 4C7.58172 4 4 7.58172 4 12C4 13.9545 4.70094 15.7454 5.86515 17.1348ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z", fill: "#E02222" })));
var timeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z", fill: "#E02222" })));
var rejectIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 12C20 16.4183 16.4183 20 12 20C10.2575 20 8.64515 19.4429 7.33129 18.4971L18.4971 7.33129C19.4429 8.64515 20 10.2575 20 12ZM5.86515 17.1348L17.1348 5.86515C15.7454 4.70094 13.9545 4 12 4C7.58172 4 4 7.58172 4 12C4 13.9545 4.70094 15.7454 5.86515 17.1348ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z", fill: "#E02222" })));
var Notice = function (_a) {
    var header = _a.header, text = _a.text, type = _a.type, html = _a.html;
    var theme = useTheme('Exchange').Notice;
    var getIcon = function () {
        switch (type) {
            case 'error':
                return errorIcon;
            case 'notice':
                return noticeIcon;
            case 'reject':
                return rejectIcon;
            case 'time':
                return timeIcon;
        }
    };
    var getNoticeTypeStyle = function () {
        switch (type) {
            case 'error':
                return theme.error;
            case 'notice':
                return theme.notice;
            case 'reject':
                return theme.reject;
            case 'time':
                return theme.time;
        }
    };
    return (React.createElement("div", { className: cn(theme.container, getNoticeTypeStyle()) },
        React.createElement("div", { className: cn(theme.icon) }, getIcon()),
        React.createElement("div", null,
            React.createElement("div", { className: theme.header }, header),
            React.createElement("div", null, text),
            React.createElement("div", { className: theme.html, dangerouslySetInnerHTML: { __html: html } }))));
};
export default Notice;
