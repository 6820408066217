import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { format, removeDotsFromDate } from '../../../utils';
import OrderCities from '../../../OrderCard/components/OrderPreview/OrderCities/OrderCities';
var BookingPreview = function (_a) {
    var accessCode = _a.accessCode, travellersAmount = _a.travellersAmount, flight = _a.flight;
    var css = useTheme('AddBooking').BookingPreview;
    var t = useTranslation('Account').t;
    var departure = flight.segmentGroups[0].segments[0].departure;
    var arrival = flight.segmentGroups[flight.segmentGroups.length - 1].segments[0].arrival;
    var formatDate = function (date) { return removeDotsFromDate(format(date, 'dd MMM, yyyy')); };
    var date = React.useMemo(function () {
        if (flight.segmentGroups.length > 1) {
            if (departure.date === arrival.date) {
                return "".concat(formatDate(departure.date), ", ").concat(departure.time, " - ").concat(arrival.time);
            }
            else {
                return "".concat(formatDate(departure.date), " - ").concat(formatDate(arrival.date));
            }
        }
        return formatDate(departure.date);
    }, [flight.segmentGroups]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.info_field },
            React.createElement("div", null, accessCode),
            React.createElement("div", { className: css.info_field__travellers },
                travellersAmount,
                " ",
                t('Passenger', { count: travellersAmount }))),
        React.createElement("div", { className: cn(css.info_field, css.info_field__cities) },
            React.createElement(OrderCities, { flight: flight, bookingPreview: true })),
        React.createElement("div", { className: css.info_field }, date)));
};
export default BookingPreview;
