import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { getAvailableInsuranceCodesByPrograms, getAvailableSelectedInsuranceCodes, getOrder } from '../Checkout/store/order/selectors';
import { getTotalPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { fillOrder } from '../Checkout/store/order/actions';
import { Currency } from '../enums';
import { getSelectedInsuranceSaveCodes } from './utils';
import { saveOrderServicesParamsFactory } from './store/utils';
import { useInsuranceUpsaleModal } from './components/InsuranceUpsaleModal/InsuranceUpsaleModal.hook';
import { useConfig } from '../context';
import { get, set } from '../cache';
export var useInsurancePageWrapper = function (_a) {
    var strategy = _a.strategy, insurancePrograms = _a.insurancePrograms;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var orderPriceToPay = useSelector(getTotalPriceToPay);
    var availableInsuranceCodes = useSelector(getAvailableInsuranceCodesByPrograms(insurancePrograms));
    var selectedInsuranceCodes = useSelector(getAvailableSelectedInsuranceCodes(availableInsuranceCodes));
    var _b = __read(useState(false), 2), isNeedToSendSaveRequest = _b[0], setIsNeedToSendSaveReq = _b[1];
    var _c = __read(useSaveOrderServicesMutation(), 2), saveOrderServices = _c[0], mutationResult = _c[1];
    var _d = __read(useState(), 2), saveOrderServicesParams = _d[0], setSaveOrderServicesParams = _d[1];
    var selectedSaveServicesCodes = useMemo(function () {
        return getSelectedInsuranceSaveCodes(saveOrderServicesParams, availableInsuranceCodes);
    }, [saveOrderServicesParams, availableInsuranceCodes]);
    var insuranceCodesToSave = useMemo(function () {
        return __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(selectedSaveServicesCodes), false), __read(selectedInsuranceCodes), false))), false);
    }, [selectedSaveServicesCodes, selectedInsuranceCodes]);
    var insuranceUpsaleModal = useInsuranceUpsaleModal({
        strategy: strategy,
        availableInsuranceCodes: availableInsuranceCodes,
        selectedInsuranceCodes: insuranceCodesToSave
    });
    useEffect(function () {
        var _a;
        if ((_a = mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.data) === null || _a === void 0 ? void 0 : _a.SaveOrderServices) {
            dispatch(fillOrder(mutationResult.data.SaveOrderServices));
        }
    }, [mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.data]);
    var _e = __read(useState({
        amount: 0,
        currency: Currency.RUB
    }), 2), insurancePriceToPay = _e[0], setInsurancePriceToPay = _e[1];
    var saveOrderServicesWithParams = useCallback(function (codes) {
        var travellerIds = order.travellers.map(function (traveller) { return traveller.id; });
        var saveOrderParams = saveOrderServicesParamsFactory(travellerIds, codes);
        return saveOrderServices({
            variables: {
                params: __assign(__assign({}, saveOrderParams.params), { id: order.id })
            }
        });
    }, [order.travellers, saveOrderServices]);
    var saveOrderServicesRequest = useCallback(function (codes) {
        if (codes === void 0) { codes = []; }
        var selectedCodesSet = new Set(__spreadArray(__spreadArray([], __read(codes), false), __read(insuranceCodesToSave), false));
        return saveOrderServicesWithParams(__spreadArray([], __read(selectedCodesSet), false));
    }, [insuranceCodesToSave, saveOrderServicesWithParams]);
    var handleAgree = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insuranceUpsaleModal.onAgree(function (insuranceCodes) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(insuranceCodes === null || insuranceCodes === void 0 ? void 0 : insuranceCodes.length)) {
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, saveOrderServicesRequest(insuranceCodes)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [saveOrderServicesRequest, insuranceUpsaleModal.onAgree]);
    var handleDisagree = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insuranceUpsaleModal.onDisagree(function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!isNeedToSendSaveRequest) {
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, saveOrderServicesRequest()];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [isNeedToSendSaveRequest, saveOrderServicesRequest, insuranceUpsaleModal.onDisagree]);
    var saveOrderServicesCallback = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insuranceUpsaleModal.onContinue(function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!isNeedToSendSaveRequest) return [3 /*break*/, 2];
                                    return [4 /*yield*/, saveOrderServicesRequest()];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    callback();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [isNeedToSendSaveRequest, saveOrderServicesRequest, insuranceUpsaleModal.onContinue]);
    return {
        // methods
        saveOrderServices: saveOrderServices,
        saveOrderServicesRequest: saveOrderServicesRequest,
        saveOrderServicesWithParams: saveOrderServicesWithParams,
        saveOrderServicesCallback: saveOrderServicesCallback,
        setInsurancePriceToPay: setInsurancePriceToPay,
        setIsNeedToSendSaveReq: setIsNeedToSendSaveReq,
        setSaveOrderServicesParams: setSaveOrderServicesParams,
        closeUpsaleModal: insuranceUpsaleModal.onCloseUpsaleModal,
        onContinue: insuranceUpsaleModal.onContinue,
        handleAgree: handleAgree,
        handleDisagree: handleDisagree,
        // data
        order: order,
        orderId: order.id,
        orderPriceToPay: orderPriceToPay,
        insurancePriceToPay: insurancePriceToPay,
        mutationResult: mutationResult,
        saveOrderServicesParams: saveOrderServicesParams,
        priorityInsurance: insuranceUpsaleModal.priorityInsurance,
        isNeedToSendSaveRequest: isNeedToSendSaveRequest,
        isUpsaleOpen: insuranceUpsaleModal.isUpsaleOpen,
        isLoading: mutationResult.loading
    };
};
function getStrategyKey(strategy, orderId) {
    return "".concat(strategy, "_").concat(orderId);
}
export var useInsuranceUpsaleConfig = function () {
    var updateId = useRef(0);
    var order = useSelector(getOrder);
    var upsaleStrategy = useConfig().global.insurance.upsaleStrategy;
    var _a = __read(useMemo(function () {
        var shown = [];
        var unShown = [];
        upsaleStrategy.forEach(function (strategyKey) {
            var existKey = get("".concat(strategyKey, "_").concat(order.id));
            if (existKey) {
                shown.push(strategyKey);
            }
            else {
                unShown.push(strategyKey);
            }
        });
        return [shown, unShown];
    }, [upsaleStrategy, order.id, updateId.current]), 2), shownStrategies = _a[0], unShownStrategies = _a[1];
    var getIsNeedToShow = useCallback(function (strategy) {
        return upsaleStrategy.includes(strategy) && unShownStrategies.includes(strategy);
    }, [upsaleStrategy, unShownStrategies]);
    var onApplyStrategy = useCallback(function (strategy) {
        set(getStrategyKey(strategy, order.id), true);
        updateId.current++;
    }, [order.id, updateId.current]);
    var onCancelStrategy = useCallback(function (strategy) {
        set(getStrategyKey(strategy, order.id), false);
        updateId.current++;
    }, [order.id, updateId.current]);
    return {
        // data
        upsaleStrategy: upsaleStrategy,
        shownStrategies: shownStrategies,
        unShownStrategies: unShownStrategies,
        // methods
        getIsNeedToShow: getIsNeedToShow,
        onApplyStrategy: onApplyStrategy,
        onCancelStrategy: onCancelStrategy
    };
};
