import * as Sentry from '@sentry/browser';
export { Severity as Level } from '@sentry/browser';
export var Log = function (breadCrumb) {
    if (!Object.values(breadCrumb).length) {
        return;
    }
    if (process.env.NODE_ENV === 'development') {
        console.log('SENTRY_EVENT_SEND');
    }
    Sentry.addBreadcrumb(breadCrumb);
};
