import { __assign } from "tslib";
import { useMobileSegmentInfo } from '../../hooks';
import { usePagination } from '../../../Pagination/hooks';
import { getTransferInfo } from '../../utils';
import i18n from 'i18next';
import { initI18n } from '../../../utils';
initI18n('MobileSegmentInfo');
export var useMobileSegmentFlatInfo = function (componentProps) {
    var showAirplaneInfo = componentProps.showAirplaneInfo;
    var flightInfo;
    if (showAirplaneInfo) {
        flightInfo = componentProps.segments.map(function (segment) { return ({
            aircraftName: segment.segment.aircraft.name,
            flightNumber: "".concat(segment.segment.marketingAirline.iata, "-").concat(segment.segment.flightNumber),
            imageUrl: segment.segment.aircraft.icon,
            id: segment.segment.aircraft.id
        }); });
    }
    var mobileSegmentInfo = useMobileSegmentInfo(componentProps);
    var pagination = usePagination(0);
    var transferInfo = getTransferInfo(componentProps.segments);
    var transferString = transferInfo.transfersCount
        ? "".concat(transferInfo.transfersCount, " ").concat(i18n.t('MobileSegmentInfo:stop'), ": ").concat(transferInfo.iata.join(', '))
        : i18n.t('MobileSegmentInfo:Nonstop');
    return __assign(__assign(__assign({}, mobileSegmentInfo), pagination), { aircrafts: flightInfo !== null && flightInfo !== void 0 ? flightInfo : [], transferInfo: getTransferInfo(componentProps.segments), transferString: transferString });
};
