import { format } from 'date-fns';
import { SPECIAL_CATEGORIES } from '../../../SearchForm/utils';
import { AviaPassengerType } from '@websky/graphql';
var PROMO_REGEX = 'PROMO([a-zA-Zа-яА-Я0-9]{1,10})';
export var getIATA = function (data) {
    return data.substr(0, 3);
};
export var isDateGuard = function (date) {
    return !!date.getTime;
};
export var getAbsoluteDate = function (isoString) {
    return isoString.substr(0, 19);
};
export var getDateWithoutTimezone = function (date) {
    return dateConverter(date, false);
};
export var getDateWithTimezone = function (date) {
    return dateConverter(date, true);
};
var dateConverter = function (date, withTimezone) {
    if (isDateGuard(date)) {
        var dateWithoutTimezone = new Date(withTimezone ? date.getTime() : date.getTime() + date.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
    else {
        var newDate = new Date(date);
        var dateWithoutTimezone = new Date(withTimezone ? newDate.getTime() : newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
};
export var getDate = function (data) {
    var year = data.slice(0, 4);
    var month = data.slice(4, 6);
    var day = data.slice(6, 8);
    return getDateWithoutTimezone("".concat(year, "-").concat(month, "-").concat(day));
};
export var getPassenger = function (data) {
    var type = data.substr(0, 3), count = parseInt(data.substr(3, 1)), isExtendedPassengerType = SPECIAL_CATEGORIES.includes(type);
    return {
        passengerType: isExtendedPassengerType ? AviaPassengerType.ADT : type,
        extendedPassengerType: isExtendedPassengerType ? type : null,
        count: count
    };
};
export var isPassengerNext = function (data) {
    return /^(ADT|GCF|CLD|INF|INS|МЛА|SCM|SCF|PDA|YTH|SRC)(.)*\d/.test(data);
};
export var hasPromo = function (value) {
    return RegExp(PROMO_REGEX).test(value);
};
export var extractPromo = function (value) {
    var _a;
    return (_a = value.match(PROMO_REGEX)) === null || _a === void 0 ? void 0 : _a[1];
};
export var isPromoOrCurrencyNext = function (data) {
    return /^((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD)?$/.test(data);
};
export var extractPromoCodeAndCurrency = function (fastSearch) {
    var matchedResult = fastSearch.match(/((PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD)?$/);
    return {
        promoCode: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[3]) || '',
        payByMiles: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[4]) === 'PAYBYMILES',
        currency: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[5]) || ''
    };
};
export var isPayByMiles = function (fastSearch) { return /PAYBYMILES$/.test(fastSearch); };
export var extractCurrency = function (fastSearch) {
    var _a;
    var matchedResult = fastSearch.match(/(RUB|USD|KZT)$/);
    return ((_a = matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult.slice(-1)) === null || _a === void 0 ? void 0 : _a[0]) || '';
};
export var fastSearchParse = function (fastSearch) {
    var str = fastSearch;
    var segments = [], passengers = [];
    while (!isPassengerNext(str) && !isPromoOrCurrencyNext(str) && str.length) {
        var departure = getIATA(str), arrival = getIATA(str.slice(3)), date = getDate(str.slice(6));
        segments.push({
            arrival: {
                iata: arrival
            },
            departure: {
                iata: departure
            },
            date: format(date, 'yyyy-MM-dd')
        });
        str = str.slice(14);
    }
    while (isPassengerNext(str)) {
        passengers.push(getPassenger(str));
        str = str.slice(4);
    }
    if (!passengers.length) {
        passengers.push({
            passengerType: AviaPassengerType.ADT,
            count: 1
        });
    }
    var result = {
        segments: segments,
        passengers: passengers
    };
    var _a = extractPromoCodeAndCurrency(fastSearch), promoCode = _a.promoCode, payByMiles = _a.payByMiles, currency = _a.currency;
    if (promoCode) {
        result.promotionCode = promoCode;
    }
    if (currency) {
        result.currency = currency;
    }
    result.ffpMode = payByMiles;
    return result;
};
export var isFastSearchCorrect = function (fastSearch) {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|МЛА|CLD)\d)|((SCF|SCM|YTH|SRC)\d)+)((GCF|CLD|INF|INS)\d)*)?((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|UZS|AZN|TRY|RSD|ILS|CNY)?$/.test(fastSearch);
};
export var _WZ_isFastSearchCorrect = function (fastSearch) {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|PDA|CLD|INF|INS)\d)+)|((SCF|SCM|МЛА|CLD|INF|INS)\d)+)?(PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))?(RUB|USD|KZT)?$/.test(fastSearch);
};
