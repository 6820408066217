import { __read, __spreadArray } from "tslib";
export function getServiceKey(service, type) {
    var segmentIds = service.segmentIds.join('-');
    if (type === 'radio') {
        return [service.passengerId, segmentIds].join('/');
    }
    return [service.serviceId, service.passengerId, segmentIds].join('/');
}
export function getSelectedServicesByType(state, action) {
    var _a = action.payload, items = _a.items, type = _a.type;
    var selectedServicesMap = new Map();
    state.selectedServices.forEach(function (service) {
        var key = getServiceKey(service, type);
        selectedServicesMap.set(key, service);
    });
    items.forEach(function (newService) {
        var key = getServiceKey(newService, type);
        selectedServicesMap.set(key, newService);
    });
    return __spreadArray([], __read(selectedServicesMap.values()), false);
}
