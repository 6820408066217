import { useGetUserProfileQuery } from '@websky/graphql';
import { currentUserFactory } from './utils';
import { useConfig } from '../context';
var EVENT_NAME = 'webskyCurrentUser';
export var useCurrentUser = function () {
    var _a, _b;
    var currentUserInGlobalScope = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.currentUserInGlobalScope;
    useGetUserProfileQuery({
        fetchPolicy: 'no-cache',
        skip: !currentUserInGlobalScope,
        onCompleted: function (data) {
            if (!(data === null || data === void 0 ? void 0 : data.CurrentUser)) {
                return;
            }
            var userData = currentUserFactory(data.CurrentUser);
            var event = new CustomEvent(EVENT_NAME, {
                detail: userData
            });
            window[EVENT_NAME] = userData;
            document.dispatchEvent(event);
        }
    });
};
