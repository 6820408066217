import { createAction } from 'redux-actions';
export var ADD_SERVICE = 'ADD_SERVICE';
export var REMOVE_SERVICE = 'REMOVE_SERVICE';
export var ADD_SERVICE_NS = 'ADD_SERVICE_NS'; // _NS  - No Saga
export var REMOVE_SERVICE_NS = 'REMOVE_SERVICE_NS';
export var SET_SERVICE = 'SET_SERVICE';
export var SAVE_SERVICES = 'SAVE_SERVICES';
export var SET_ALL_SELECTED_SERVICES = 'SET_ALL_SELECTED_SERVICES';
export var DELETE_ALL_TYPES_ON_SEGMENT = 'DELETE_ALL_TYPES_ON_SEGMENT';
export var DELETE_ALL_TYPES_ON_SEGMENT_NS = 'DELETE_ALL_TYPES_ON_SEGMENT_NS';
export var CLEAR_ALL_SERVICES = 'CLEAR_ALL_SERVICES';
export var SET_MULTIPLE_SELECTED_SERVICES = 'SET_MULTIPLE_SELECTED_SERVICES';
export var SET_MULTIPLE_SELECTED_SERVICES_NS = 'SET_MULTIPLE_SELECTED_SERVICES_NS';
export var addServiceAction = createAction(ADD_SERVICE);
export var removeServiceAction = createAction(REMOVE_SERVICE);
export var addServiceAction_NS = createAction(ADD_SERVICE_NS); // _NS - No Saga
export var removeServiceAction_NS = createAction(REMOVE_SERVICE_NS);
export var setServiceAction = createAction(SET_SERVICE);
export var saveServicesAction = createAction(SAVE_SERVICES);
export var setMultipleSelectedServices = createAction(SET_MULTIPLE_SELECTED_SERVICES);
export var setMultipleSelectedServicesAction_NS = createAction(SET_MULTIPLE_SELECTED_SERVICES_NS);
export var setAllSelectedServices = createAction(SET_ALL_SELECTED_SERVICES);
export var deleteAllTypesOnSegment = createAction(DELETE_ALL_TYPES_ON_SEGMENT);
export var deleteAllTypesOnSegmentAction_NS = createAction(DELETE_ALL_TYPES_ON_SEGMENT_NS);
