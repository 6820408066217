import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from '@websky/core/src';
import { useConfig } from '@websky/core/src/context';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@websky/core/src/theme';
import FareConditions from '@websky/core/src/FareConditions/components/Component';
import Modal from '@websky/core/src/Modal';
var RequestFormLabel = function (_a) {
    var flightId = _a.flightId, orderId = _a.orderId;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').RequestForm;
    var exarePrivacyPolicyURL = useConfig().global.companyInfo.exarePrivacyPolicyURL;
    var _b = __read(useState(false), 2), isFareModalOpen = _b[0], setIsFareModalOpen = _b[1];
    var openFareModal = function (e) {
        e.preventDefault();
        setIsFareModalOpen(true);
    };
    var closeFareModal = function () {
        setIsFareModalOpen(false);
    };
    return (React.createElement("span", null,
        t('I agree with the'),
        "\u00A0",
        React.createElement("span", { className: css.terms, onClick: openFareModal }, t('terms of tariff')),
        exarePrivacyPolicyURL && (React.createElement(React.Fragment, null,
            "\u00A0",
            t('And'),
            "\u00A0",
            React.createElement(Link, { target: '_blank', action: exarePrivacyPolicyURL }, t('Privacy Policy')))),
        React.createElement(Modal, { open: isFareModalOpen, onClose: closeFareModal },
            React.createElement(FareConditions, { flightId: flightId, orderId: orderId }))));
};
var isRefundState = function (state) {
    return state.orderId !== undefined;
};
var mapStateToProps = function (state) { return ({
    flightId: isRefundState(state) ? state.flightId : state.order.flight.id,
    orderId: isRefundState(state) ? state.orderId : state.order.id
}); };
var connector = connect(mapStateToProps);
export default connector(RequestFormLabel);
