import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useRestrictionsConditionForKV } from '../hooks';
var SeatAdditionalInfo = function (_a) {
    var className = _a.className, restrictions = _a.restrictions, permissions = _a.permissions, additional = _a.additional;
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var t = useTranslation('SeatMap').t;
    if (!(restrictions === null || restrictions === void 0 ? void 0 : restrictions.length) && !(permissions === null || permissions === void 0 ? void 0 : permissions.length) && !(additional === null || additional === void 0 ? void 0 : additional.length)) {
        return null;
    }
    return (React.createElement("div", { className: cn(theme.additionalInfo, className) },
        (additional === null || additional === void 0 ? void 0 : additional.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('Information for the place'),
                ":"),
            React.createElement("ul", null, additional.map(function (info) { return (React.createElement("li", { key: info }, info)); })))),
        (restrictions === null || restrictions === void 0 ? void 0 : restrictions.length) > 0 && useRestrictionsConditionForKV() && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('This seat is forbidden for'),
                ":"),
            React.createElement("ul", null, restrictions.map(function (restriction) { return (React.createElement("li", { key: restriction }, t("Restriction_".concat(restriction)))); })))),
        (permissions === null || permissions === void 0 ? void 0 : permissions.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('This seat is allowed for'),
                ":"),
            React.createElement("ul", null, permissions.map(function (permission) { return (React.createElement("li", { key: permission }, t("Restriction_".concat(permission)))); }))))));
};
export default SeatAdditionalInfo;
