import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Categories from '../Categories/Categories';
import Warning from '../Warning/Warning';
import { useTheme } from '../../../theme';
import { ALL_DISHES_CATEGORY_ID, SELECTED_DISHES_CATEGORY_ID } from '../../constants';
import { getCategories, removeDuplicatesByName } from '../../utils';
import { useConfig } from '../../../context';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { isDemo } from '../../../utils';
var ServiceWrapper = function (_a) {
    var _b;
    var activeSegmentId = _a.activeSegmentId, meals = _a.meals, _c = _a.selectedMeals, selectedMeals = _c === void 0 ? [] : _c, _d = _a.initialSelectedMeals, initialSelectedMeals = _d === void 0 ? [] : _d, mealRenderer = _a.mealRenderer, recommendedMeals = _a.recommendedMeals;
    var css = useTheme('MealService').ServiceWrapper;
    var _e = __read(useState(false), 2), hasSelectedMeals = _e[0], setHasSelectedMeals = _e[1];
    var _f = useConfig().Engine, mealWarning = _f.mealWarning, mealDefaultFilterButtonPlacement = _f.mealDefaultFilterButtonPlacement;
    var categories = useMemo(function () { return getCategories(meals, activeSegmentId); }, [meals, activeSegmentId]);
    var _g = __read(useState(mealDefaultFilterButtonPlacement === 'end' ? 0 : ALL_DISHES_CATEGORY_ID), 2), category = _g[0], setCategory = _g[1];
    var mealIsSelected = function (meal) {
        return initialSelectedMeals.find(function (selectedMeal) {
            return meal.id === selectedMeal.mealId && selectedMeal.segmentId === activeSegmentId;
        });
    };
    var filteredMeals = useMemo(function () {
        var filtered = meals.filter(function (meal) {
            return (meal.segmentsId.includes(activeSegmentId) &&
                (category === ALL_DISHES_CATEGORY_ID ||
                    meal.category === categories[category] ||
                    (hasSelectedMeals && category === SELECTED_DISHES_CATEGORY_ID && mealIsSelected(meal))));
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [meals, activeSegmentId, category, selectedMeals]);
    var numOfSelectedMeals = useMemo(function () {
        var result = 0;
        selectedMeals.forEach(function (meal) {
            if (meal.segmentId === activeSegmentId) {
                result += meal.count;
            }
        });
        return result;
    }, [selectedMeals, activeSegmentId]);
    useEffect(function () {
        setHasSelectedMeals(initialSelectedMeals.some(function (selectedMeal) { return selectedMeal.segmentId === activeSegmentId; }));
        // if user remove meal on selected dishes tab, return to first category
        if (category === SELECTED_DISHES_CATEGORY_ID && !hasSelectedMeals) {
            setCategory(ALL_DISHES_CATEGORY_ID);
        }
    }, [selectedMeals, activeSegmentId, category, hasSelectedMeals]);
    useEffect(function () {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealsList, { segmentId: activeSegmentId });
    }, [activeSegmentId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Categories, { categories: categories, onSelect: setCategory, selectedCategory: category, numOfSelectedMeals: numOfSelectedMeals, defaultGroupPlacement: mealDefaultFilterButtonPlacement }),
        mealWarning && React.createElement(Warning, { warning: mealWarning }),
        React.createElement("div", { className: cn(css.meals, (_b = {},
                _b[css.meals_singleMeal] = filteredMeals.length === 1,
                _b)) }, filteredMeals.map(function (meal) { return mealRenderer(meal, activeSegmentId, filteredMeals.length === 1); }))));
};
export default ServiceWrapper;
