import * as React from 'react';
import { groupSeats } from '../utils';
import { SeatSelection } from '../../Icons';
import ServiceSummary from '../../ServiceSummary';
import { CartContext } from '../Cart';
import { useTranslation } from 'react-i18next';
import { CartService } from '../types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import SeatSummary from '../../ServiceSummary/services/SeatSummary';
import { CheckoutSteps } from '../../MobileStepbar/types';
var Seats = function (_a) {
    var _b;
    var travellers = _a.travellers, onServiceDelete = _a.onServiceDelete, renderService = _a.renderService;
    var cartContext = React.useContext(CartContext);
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (travellerId, serviceId, segmentId) {
        onServiceDelete(CartService.Seats, {
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            serviceId: serviceId,
            passengerId: travellerId,
            segmentId: segmentId
        });
    };
    var seats = React.useMemo(function () {
        return travellers
            .filter(function (traveller) { var _a; return !!((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length); })
            .map(function (traveller) {
            var seats = groupSeats(traveller), options = [];
            Object.values(seats[traveller.id]).forEach(function (seat) {
                if (seat.additional) {
                    seat.additional.forEach(function (seat) {
                        var _a;
                        var segment = cartContext.segmentsById.get((_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id);
                        options.push({
                            id: seat.seat.number,
                            name: "".concat(segment.iataDirection, ", ").concat(seat.seat.number),
                            price: seat.seat.price,
                            included: seat.isConfirmed,
                            onDelete: function () { return onDeleteHandler(traveller.id, seat.seat.number, seat.segment.id); }
                        });
                    });
                }
            });
            return {
                items: options,
                traveller: cartContext.travellersById.get(traveller.id),
                headerType: 'travellerName',
                headerIcon: SeatSelection
            };
        });
    }, travellers);
    var group = {
        header: t('Seats'),
        icon: SeatSelection,
        totalPrice: (_b = cartContext.totalPrices) === null || _b === void 0 ? void 0 : _b.seats
    };
    if (!seats.length && !renderService) {
        return null;
    }
    return (React.createElement(SeatSummary, { travellers: travellers, travellersById: cartContext.travellersById, segmentsById: cartContext.segmentsById, onServiceDelete: onServiceDelete }, function (content) {
        var _a;
        return (_a = renderService === null || renderService === void 0 ? void 0 : renderService(CheckoutSteps.Seats, group, content)) !== null && _a !== void 0 ? _a : (React.createElement(ServiceSummary, { group: group }, content));
    }));
};
export default Seats;
