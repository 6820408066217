import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Choosed, DeleteIcon } from '../../../../Icons';
import Button from '../../../../Button/Button';
import { Statuses } from '../../../types';
import { LoginType, useAccountExistenceQuery, useConfirmMethodMutation, useSaveMethodMutation, useSendPersonalDetailsMutation, useSendSecureCodeMutation } from '@websky/graphql';
import AuthDialog from '../AuthDialog/AuthDialog';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
export var ModalTypesEnum;
(function (ModalTypesEnum) {
    ModalTypesEnum["Remove"] = "Remove";
    ModalTypesEnum["Connect"] = "Connect";
    ModalTypesEnum["Change"] = "Change";
})(ModalTypesEnum || (ModalTypesEnum = {}));
var AuthMethod = function (props) {
    var _a;
    var css = useTheme('Account').InfoField, t = useTranslation('Account').t;
    var _b = __read(useState(props.value), 2), inputValue = _b[0], setInputValue = _b[1], _c = __read(useState(null), 2), loyaltyCard = _c[0], setLoyaltyCard = _c[1], _d = __read(useState(null), 2), error = _d[0], setError = _d[1], _e = __read(useState(null), 2), verificationError = _e[0], setVerificationError = _e[1], _f = __read(useState(null), 2), modalType = _f[0], setModalType = _f[1], _g = __read(useState(false), 2), isOpenedModal = _g[0], setIsOpenedModal = _g[1];
    var _h = __read(useSaveMethodMutation(), 1), saveMethod = _h[0];
    var _j = __read(useSendPersonalDetailsMutation(), 1), updateUserProfile = _j[0];
    var _k = __read(useConfirmMethodMutation(), 1), confirmMethod = _k[0];
    var _l = __read(useSendSecureCodeMutation(), 1), sendCode = _l[0];
    var checkAccountExistence = useAccountExistenceQuery({
        skip: true
    });
    var errors = { error: error, verificationError: verificationError };
    var cleanInputValue = props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue;
    var isSubmitAvailable = !!inputValue
        ? props.type === LoginType.Phone
            ? clearPhoneNumber(props.value) !== clearPhoneNumber(inputValue)
            : props.value !== inputValue
        : false;
    var onUpdateUserProfile = useCallback(function (pinCode) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, updateUserProfile({
                            variables: {
                                params: {
                                    loyaltyCards: [__assign(__assign({}, loyaltyCard), { pinCode: pinCode })]
                                }
                            }
                        })];
                case 1:
                    data = (_d.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.UpdateUserProfile)) return [3 /*break*/, 4];
                    if (!(modalType === ModalTypesEnum.Change)) return [3 /*break*/, 3];
                    return [4 /*yield*/, props.onDelete(props.id)];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    props.refetchInfo();
                    (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationSuccess);
                    onCloseModal();
                    return [3 /*break*/, 5];
                case 4:
                    (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.VerificationError);
                    setVerificationError(Error(t('Incorrect card number or password')));
                    _d.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_1 = _d.sent();
                    setVerificationError(e_1);
                    (_c = props.setStatus) === null || _c === void 0 ? void 0 : _c.call(props, Statuses.VerificationError);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [modalType, loyaltyCard, updateUserProfile, props.refetchInfo, props.setStatus]);
    var onConfirmedMethod = useCallback(function (secureCode) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, confirmMethod({
                            variables: {
                                params: {
                                    loginType: props.type,
                                    login: cleanInputValue,
                                    secureCode: secureCode
                                }
                            }
                        })];
                case 1:
                    data = (_f.sent()).data;
                    if (!((_a = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _a === void 0 ? void 0 : _a.result)) return [3 /*break*/, 4];
                    if (!(modalType === ModalTypesEnum.Change)) return [3 /*break*/, 3];
                    return [4 /*yield*/, props.onDelete(props.id)];
                case 2:
                    _f.sent();
                    _f.label = 3;
                case 3:
                    props.refetchInfo();
                    (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.VerificationSuccess);
                    onCloseModal();
                    return [3 /*break*/, 5];
                case 4:
                    (_c = props.setStatus) === null || _c === void 0 ? void 0 : _c.call(props, Statuses.VerificationError);
                    setVerificationError(Error((_d = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _d === void 0 ? void 0 : _d.message));
                    _f.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_2 = _f.sent();
                    setVerificationError(e_2);
                    (_e = props.setStatus) === null || _e === void 0 ? void 0 : _e.call(props, Statuses.VerificationError);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [modalType, cleanInputValue, confirmMethod, props.refetchInfo, props.setStatus]);
    var onSubmit = useCallback(function (field) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationLoading);
                    if (!(props.type === LoginType.FFP)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onUpdateUserProfile(field.confirmationCode)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onConfirmedMethod(field.confirmationCode)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onUpdateUserProfile, onConfirmedMethod, props.setStatus]);
    var onContinueClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accountExistence, _a, data, errors_1, error_1;
        var _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    _k.trys.push([0, 5, , 6]);
                    (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.Loading);
                    return [4 /*yield*/, checkAccountExistence.refetch({
                            params: {
                                login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue,
                                loginType: props.type
                            }
                        })];
                case 1:
                    accountExistence = _k.sent();
                    if ((_d = (_c = accountExistence === null || accountExistence === void 0 ? void 0 : accountExistence.data) === null || _c === void 0 ? void 0 : _c.AccountExistence) === null || _d === void 0 ? void 0 : _d.result) {
                        throw Error("".concat(props.type, " is already in use by another account"));
                    }
                    if (!(props.type === LoginType.FFP)) return [3 /*break*/, 2];
                    setLoyaltyCard({ airlineCode: props.iataCode, cardNumber: inputValue });
                    (_e = props.setStatus) === null || _e === void 0 ? void 0 : _e.call(props, Statuses.Verification);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, saveMethod({
                        variables: {
                            params: {
                                loginType: props.type,
                                login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue
                            }
                        }
                    })];
                case 3:
                    _a = _k.sent(), data = _a.data, errors_1 = _a.errors;
                    if (errors_1 === null || errors_1 === void 0 ? void 0 : errors_1.length) {
                        setError(errors_1[0]);
                    }
                    else if ((_f = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _f === void 0 ? void 0 : _f.result) {
                        (_g = props.setStatus) === null || _g === void 0 ? void 0 : _g.call(props, Statuses.Verification);
                    }
                    else {
                        throw Error((_h = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _h === void 0 ? void 0 : _h.message);
                    }
                    _k.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _k.sent();
                    setError(error_1);
                    (_j = props.setStatus) === null || _j === void 0 ? void 0 : _j.call(props, Statuses.default);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var resendCode = function () {
        return sendCode({
            variables: {
                params: {
                    login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue,
                    loginType: props.type
                }
            }
        });
    };
    var onOpenModal = function (type) {
        setIsOpenedModal(true);
        setModalType(type);
    };
    var onCloseModal = function () {
        var _a;
        setError(null);
        setVerificationError(null);
        setIsOpenedModal(false);
        setModalType(null);
        setInputValue(props.value);
        (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.default);
    };
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onDelete(props.id)];
                case 1:
                    _a.sent();
                    onCloseModal();
                    return [2 /*return*/];
            }
        });
    }); };
    var isSocialAuth = React.useMemo(function () { return [LoginType.Facebook, LoginType.Google, LoginType.Vkontakte, LoginType.TinkoffID].includes(props.type); }, [props.type]);
    return (React.createElement("div", { className: cn(css.field, props.className), "data-type": props.type },
        React.createElement("div", { className: css.field__info },
            props.icon && React.createElement("div", { className: css.field__icon }, props.icon),
            React.createElement("div", null,
                React.createElement("div", { className: css.field__name },
                    t(props.type),
                    " ",
                    props.confirmed && Choosed),
                React.createElement("div", { className: css.field__user },
                    props.value && React.createElement("span", { className: css.field__value }, props.value),
                    React.createElement("span", { className: css.field__status }, props.confirmed ? t('Connected') : t('Not connected'))))),
        React.createElement("div", { className: css.field__buttons }, props.confirmed ? (React.createElement(React.Fragment, null,
            !isSocialAuth && (React.createElement(Button, { className: css.field__change, onClick: function () { return onOpenModal(ModalTypesEnum.Change); }, variant: "text" }, t('Edit'))),
            props.isRemovable && (React.createElement("span", { className: css.field__delete, onClick: function () { return onOpenModal(ModalTypesEnum.Remove); } }, (_a = props.deleteIcon) !== null && _a !== void 0 ? _a : DeleteIcon)))) : (React.createElement(Button, { className: css.field__connect, onClick: function () { return onOpenModal(ModalTypesEnum.Connect); }, variant: "flat" }, t('Connect')))),
        React.createElement(AuthDialog, { isOpen: isOpenedModal, onClose: onCloseModal, onSubmit: onSubmit, onDelete: onDelete, onChange: setInputValue, type: modalType, status: props.status, fieldType: props.type, fieldValue: props.value, errors: errors, setStatus: props.setStatus, setError: setError, setVerificationError: setVerificationError, resendCode: resendCode, onContinue: onContinueClick, isSubmitAvailable: isSubmitAvailable })));
};
export default AuthMethod;
