import { __assign } from "tslib";
import { addYears, parse } from 'date-fns';
import * as React from 'react';
import { useTheme } from '../../theme';
import { parseHighlightedDates, parseSelectedDays } from '../utils';
import { MonthsWrapper } from './MonthsWrapper/MonthsWrapper';
import { initI18n } from '../../utils';
initI18n('Datepicker');
export var minDefaultDate = '1910-01-01';
var RendererFunctions = {
    dayRenderer: function (date, className) {
        return React.createElement("div", { className: className }, date.getDate());
    },
    monthRenderer: function (params) {
        return (React.createElement(React.Fragment, null,
            params.monthHeader,
            params.weeklyHeader,
            params.daysByWeeks.map(function (day, index) { return (React.createElement("div", { key: index, className: params.weekClassName }, day)); })));
    },
    monthHeaderAsPlainText: true
};
export var RendererFunctionsContext = React.createContext(RendererFunctions);
export var Datepicker = function (props) {
    var monthCount = props.monthCount ? props.monthCount : 1;
    var initialDate = props.openDate ? props.openDate : new Date();
    var css = useTheme('Datepicker').PopupDatepickerStyles;
    var customFunctionsRenderer = __assign({}, RendererFunctions);
    if (props.dayRenderer) {
        customFunctionsRenderer.dayRenderer = props.dayRenderer;
    }
    if (props.monthRenderer) {
        customFunctionsRenderer.monthRenderer = props.monthRenderer;
    }
    customFunctionsRenderer.monthHeaderAsPlainText = !props.monthHeaderAsSelector;
    return (React.createElement("div", { className: css.block, ref: props.getRef ? props.getRef : null },
        props.children && React.createElement("div", null, props.children),
        React.createElement(RendererFunctionsContext.Provider, { value: customFunctionsRenderer },
            React.createElement(MonthsWrapper, { getParentRef: props.getParentRef, initialDate: initialDate, monthCount: monthCount, monthsToScroll: props.monthsToScroll, onSelect: props.onSelect, onHover: props.onHover, hoverDates: props.hoverDates, minDate: props.minDate ? props.minDate : parse(minDefaultDate, 'yyyy-MM-dd', new Date()), maxDate: props.maxDate ? props.maxDate : addYears(initialDate, 1), selectedDates: parseSelectedDays(props.selectedDates ? props.selectedDates : []), highlightedDates: parseHighlightedDates(props.highlightedDates) }))));
};
export default Datepicker;
