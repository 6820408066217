var _a;
import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CheckoutSteps } from '../types';
import { ThemeProvider, useTheme } from '../../theme';
import { useCartPrices } from '../../Cart/hooks';
import { useToggleable } from '../../hooks';
import { useMobileStepbar } from '../hooks';
import Cart from '../../Cart/Cart';
import StepbarHeader from './StepbarHeader/StepbarHeader';
import StepbarServiceSummary from './StepbarServiceSummary/StepbarServiceSummary';
import { Booking, Payment } from '../../Icons';
import Dropdown from '../../Dropdown';
var ICON_BY_STEP_MAP = (_a = {},
    _a[CheckoutSteps.Order] = Booking,
    _a[CheckoutSteps.BoardingPass] = Booking,
    _a[CheckoutSteps.Payment] = Payment,
    _a);
var MobileStepbar = function (_a) {
    var _b = _a.alwaysOpen, alwaysOpen = _b === void 0 ? false : _b, order = _a.order, currentStep = _a.currentStep, stepbarConfig = _a.stepbarConfig, onServiceDelete = _a.onServiceDelete;
    var css = useTheme('MobileStepbar').MobileStepbarStyles;
    var location = useLocation();
    var _c = useToggleable(alwaysOpen), isOpen = _c.isOpen, toggle = _c.toggle, close = _c.close;
    var cartPrices = useCartPrices();
    var _d = useMobileStepbar(order, stepbarConfig), cartServiceProps = _d.cartServiceProps, cartServices = _d.cartServices, availableSteps = _d.availableSteps, additionalSteps = _d.additionalSteps, isBookedOrConfirmed = _d.isBookedOrConfirmed, isCheckoutOrder = _d.isCheckoutOrder;
    useEffect(function () {
        close();
    }, [location.pathname]);
    var onClickStep = useCallback(function (onClick) { return function () {
        close();
        onClick();
    }; }, [isOpen]);
    var renderService = useCallback(function (type, group, content) {
        var _a, _b;
        var actualType = type;
        var serviceSummary = group;
        var serviceClick;
        var isExtras = actualType === CheckoutSteps.Extras;
        // hide extras for checkout order
        if (isExtras && isCheckoutOrder && !content) {
            return null;
        }
        var isPassengers = actualType === CheckoutSteps.Passengers;
        var isOrderBookedOrConfirmedStep = isPassengers && isCheckoutOrder && isBookedOrConfirmed;
        // after booking, the Passenger step essentially becomes the Order step, so we replace the type and information
        if (isOrderBookedOrConfirmedStep && !additionalSteps.includes(CheckoutSteps.Order)) {
            actualType = CheckoutSteps.Order;
            serviceSummary = __assign(__assign({}, serviceSummary), { header: (_b = (_a = stepbarConfig[CheckoutSteps.Order]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : serviceSummary.header, icon: Booking });
        }
        var stepIndex = availableSteps.findIndex(function (step) { return step.type === actualType; });
        var stepByType = availableSteps[stepIndex];
        if (stepByType === null || stepByType === void 0 ? void 0 : stepByType.onClick) {
            serviceClick = onClickStep(stepByType.onClick);
        }
        if (isOrderBookedOrConfirmedStep && serviceClick) {
            serviceClick = null;
        }
        var isPassed = stepIndex < currentStep;
        var isActive = stepIndex === currentStep;
        var isSelected = isPassengers ? (isPassed || isActive) && !!content : !!content;
        return (React.createElement(StepbarServiceSummary, { type: actualType, group: serviceSummary, isPassed: isPassed, isActive: isActive, isSelected: isSelected, onClick: serviceClick }, content));
    }, [currentStep, availableSteps, additionalSteps, isBookedOrConfirmed, isCheckoutOrder]);
    var renderServices = useCallback(function () {
        if (additionalSteps.length) {
            return additionalSteps.map(function (additionalStep) {
                var stepIndex = availableSteps.findIndex(function (step) { return step.type === additionalStep; });
                var stepByType = availableSteps[stepIndex];
                return (React.createElement(StepbarServiceSummary, { key: additionalStep, type: additionalStep, group: {
                        header: stepByType.label,
                        icon: ICON_BY_STEP_MAP[additionalStep]
                    }, isPassed: stepIndex < currentStep, isActive: stepIndex === currentStep, isSelected: false }));
            });
        }
        return null;
    }, [currentStep, availableSteps, additionalSteps]);
    return (React.createElement(Dropdown, { isOpen: true, onClose: close },
        React.createElement("div", { className: css.stepbar },
            React.createElement(StepbarHeader, { currentStep: currentStep, steps: availableSteps, totalPrice: cartPrices.total, isOpen: isOpen, onToggle: !alwaysOpen ? toggle : null }),
            React.createElement(Collapse, { in: isOpen },
                React.createElement(ThemeProvider, { value: {
                        Cart: {
                            Cart: { wrapper: css.stepbar__cartWrapper }
                        }
                    } },
                    React.createElement(Cart, __assign({}, cartServiceProps, { prices: cartPrices, cartServices: cartServices, renderService: renderService, renderServices: renderServices, onServiceDelete: onServiceDelete, showPassengers: true, hideToolbar: true })))))));
};
export default MobileStepbar;
