import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import Warnings from '../../../Warnings';
import { Link } from '../../../index';
import { useConfig } from '../../../context';
import OrderCard from '../../../OrderCard';
import WxPagination from '../../../WxPagination';
import { usePagination } from '../../../WxPagination/utils';
import { useTheme } from '../../../theme';
import ProfileControls from '../../../ProfileControls';
import { OrderStatus, SortOrder, SortAttribute, useGetOrdersListPaginatedQuery } from '@websky/graphql';
import SimpleLoader from '../../../SimpleLoader';
var ORDERS_PER_PAGE = 4;
var Orders = memo(function () {
    var _a, _b, _c, _d, _e;
    var css = useTheme('AccountModule').Account;
    var t = useTranslation('Account').t;
    var _f = useConfig(), engineURL = _f.Account.engineURL, companyInfo = _f.global.companyInfo;
    var _g = __read(useState(ORDERS_PER_PAGE), 2), totalOrdersCount = _g[0], setTotalOrdersCount = _g[1];
    var _h = usePagination(totalOrdersCount, ORDERS_PER_PAGE), pagesCount = _h.pagesCount, paginationAvailable = _h.paginationAvailable, handleChangePage = _h.handleChangePage, page = _h.page;
    var _j = useGetOrdersListPaginatedQuery({
        variables: {
            pagination: {
                pageSize: ORDERS_PER_PAGE,
                pageNum: page
            },
            selectionParams: {
                sortParameters: {
                    sortOrder: SortOrder.DESC,
                    byAttribute: SortAttribute.Id
                },
                filterParameters: {
                    bookingStatus: [OrderStatus.Booked, OrderStatus.Confirmed, OrderStatus.Cancelled]
                }
            }
        },
        fetchPolicy: 'no-cache'
    }), ordersData = _j.data, ordersLoading = _j.loading, refetchOrders = _j.refetch;
    useEffect(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages) {
            var totalPages = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.totalPages;
            var totalOrdersCount_1 = totalPages ? totalPages * ORDERS_PER_PAGE : null;
            setTotalOrdersCount(totalOrdersCount_1);
        }
    }, [(_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages]);
    var onOrderClickHandler = function (orderId) {
        window.location.href = "".concat(engineURL, "#/booking/").concat(orderId);
    };
    var orders = (_e = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(function (edge) { return edge.node; })) !== null && _e !== void 0 ? _e : [];
    return (React.createElement("div", { className: css.orders__wrapper },
        ordersLoading && React.createElement(SimpleLoader, null),
        companyInfo.webskySearchOrder && (React.createElement(Warnings, { className: css.warning },
            React.createElement("span", null,
                t('Orders that were booked on a previous version of the site are available'),
                "\u00A0",
                React.createElement(Link, { className: css.link, action: "".concat(companyInfo.webskySearchOrder), target: '_blank' }, t('this link'))))),
        React.createElement("h1", { className: cn(css.route__title, css.orders__title) }, t('Orders')),
        orders.map(function (order) {
            return (React.createElement(OrderCard, { key: order.id, order: order, isCheckinOpen: false, type: 'account', onClick: onOrderClickHandler, onDeleted: refetchOrders }));
        }),
        !!paginationAvailable && React.createElement(WxPagination, { pages: pagesCount, onHandleChange: handleChangePage }),
        React.createElement(ProfileControls, { className: css.profileControls, onRefetchOrders: refetchOrders })));
});
export default Orders;
