import * as React from 'react';
import ServiceSummary from '../../ServiceSummary';
import { CheckedBaggage } from '../../Icons';
import { CartContext } from '../Cart';
import { useTranslation } from 'react-i18next';
import BaggageSummary from '../../ServiceSummary/services/BaggageSummary';
import { CheckoutSteps } from '../../MobileStepbar/types';
var Baggage = function (_a) {
    var _b;
    var flight = _a.flight, travellers = _a.travellers, services = _a.services, onServiceDelete = _a.onServiceDelete, renderService = _a.renderService;
    var cartConfig = React.useContext(CartContext);
    var t = useTranslation('Cart').t;
    var group = {
        header: t('Baggage'),
        icon: CheckedBaggage,
        totalPrice: (_b = cartConfig.totalPrices) === null || _b === void 0 ? void 0 : _b.baggage
    };
    return (React.createElement(BaggageSummary, { flight: flight, travellers: travellers, services: services, travellersById: cartConfig.travellersById, onServiceDelete: onServiceDelete }, function (content) {
        var _a;
        return (_a = renderService === null || renderService === void 0 ? void 0 : renderService(CheckoutSteps.Baggage, group, content)) !== null && _a !== void 0 ? _a : (React.createElement(ServiceSummary, { group: group }, content));
    }));
};
export default Baggage;
