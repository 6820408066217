import { __awaiter, __extends, __generator, __read } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableServices, getHasAvailableSeats, getHasRequestedOrderServices, getIsReadonly, getIsRedirectFromMetasearch, getOrder, getPaymentIsRequired, getShowServicesBeforeBooking } from '../../store/order/selectors';
import { CheckinGoal, OrderGoal, reachGoal } from '../../../analytics';
import { downloadPDF, getCheckInUrl, MOBILE_MIN_WIDTH } from '../../../utils';
import { useConfig } from '../../../context';
import { OrderDocumentType, useCheckinCreateOrderMutation, useCheckinRulesQuery, useOrderDocumentQuery, useResendItineraryRecieptMutation, OrderStatus, TravellerFieldEnum, useUpdateOrderMutation, useResetOrderPaymentBlockMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import { useConditionalPolling, useToggleable } from '../../../hooks';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import { getSteps, removeStepsWhenRedirectFromMetasearch } from '../../steps';
import { StepsEnum } from '../../../types';
import { fillOrder } from '../../store/order/actions';
import { useIsCheckinAvailable } from '../../hooks';
var OrderIsArchivedTicketDownloadError = /** @class */ (function (_super) {
    __extends(OrderIsArchivedTicketDownloadError, _super);
    function OrderIsArchivedTicketDownloadError() {
        var _this = _super.call(this, 'Your order was archived. Please contact with client support to get a receipt') || this;
        Object.setPrototypeOf(_this, OrderIsArchivedTicketDownloadError.prototype);
        return _this;
    }
    return OrderIsArchivedTicketDownloadError;
}(Error));
export { OrderIsArchivedTicketDownloadError };
export var useDownloadOrderDocument = function () {
    var order = useSelector(getOrder);
    var _a = __read(useState(null), 2), _error = _a[0], setError = _a[1];
    var _b = useToggleable(false), loading = _b.isOpen, setLoading = _b.open, setNotLoading = _b.close;
    var _c = useToggleable(false), warningIsOpen = _c.isOpen, warningOpen = _c.open, warningClose = _c.close;
    var _d = useOrderDocumentQuery({
        skip: true
    }), refetchDocuments = _d.refetch, data = _d.data, error = _d.error;
    var handleDownload = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, er_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (loading) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    if (order.isArchived) {
                        throw new OrderIsArchivedTicketDownloadError();
                    }
                    setLoading();
                    reachGoal(OrderGoal.ItineraryReceiptDownload);
                    return [4 /*yield*/, refetchDocuments({
                            params: {
                                orderId: order.id,
                                documentType: OrderDocumentType.ItineraryReceipt
                            }
                        })];
                case 2:
                    data_1 = _b.sent();
                    if ((_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _a === void 0 ? void 0 : _a.OrderDocument) {
                        downloadPDF(data_1.data.OrderDocument, 'tickets.pdf');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    er_1 = _b.sent();
                    if (er_1 instanceof Error) {
                        setError(er_1);
                    }
                    warningOpen();
                    return [3 /*break*/, 5];
                case 4:
                    setNotLoading();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [order.status]);
    return {
        handleDownload: handleDownload,
        document: data === null || data === void 0 ? void 0 : data.OrderDocument,
        loading: loading,
        error: error || _error,
        warningIsOpen: warningIsOpen,
        warningClose: warningClose
    };
};
export var useCheckinStart = function () {
    var _a;
    var order = useSelector(getOrder);
    var _b = useToggleable(false), isRulesModalOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = __read(useState(), 2), checkinOrderId = _c[0], setCheckinOrderId = _c[1];
    var _d = __read(useState(false), 2), checkinCreationInProgress = _d[0], setCheckinCreationInProgress = _d[1];
    var isCheckinAvailable = useIsCheckinAvailable(order);
    var _e = __read(useCheckinCreateOrderMutation(), 1), createCheckin = _e[0];
    var _f = __read(useResetOrderPaymentBlockMutation(), 1), resetOrderPaymentBlock = _f[0];
    var getCheckinOrder = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'no-cache' }).refetch;
    var _g = useConfig(), _h = _g.Engine, checkInEnabled = _h.checkInEnabled, checkInURL = _h.checkInURL, _j = _g.global, iataCode = _j.companyInfo.iataCode, reserveEngines = _j.reserveEngines;
    var rulesData = useCheckinRulesQuery({
        variables: {
            id: order.id
        },
        skip: !checkInEnabled || !checkInURL || !isCheckinAvailable || iataCode === 'N4'
    });
    var goToCheckin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var traveller, ticket, lastName, orderId, checkinOrderCreated, _a, data, errors, orderId_1, result, _b, data_2, errors_1, e_1;
        var _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    traveller = order.travellers[0];
                    ticket = ((_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.tickets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.number) || order.locator;
                    lastName = (_e = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _e === void 0 ? void 0 : _e.value;
                    orderId = order.id;
                    if (!((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin))) return [3 /*break*/, 1];
                    window.open(getCheckInUrl(checkInURL, orderId, ticket, lastName, reserveEngines), '_blank');
                    return [3 /*break*/, 10];
                case 1:
                    setCheckinCreationInProgress(true);
                    setCheckinOrderId('');
                    checkinOrderCreated = false;
                    _f.label = 2;
                case 2:
                    _f.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, createCheckin({
                            variables: {
                                params: { aviaOrderId: order.id }
                            }
                        })];
                case 3:
                    _a = _f.sent(), data = _a.data, errors = _a.errors;
                    if (!(!errors && data && data.CheckinCreateOrder)) return [3 /*break*/, 7];
                    orderId_1 = data.CheckinCreateOrder.id;
                    result = data.CheckinCreateOrder;
                    if (!data.CheckinCreateOrder.isBlockedForPayment) return [3 /*break*/, 6];
                    return [4 /*yield*/, resetOrderPaymentBlock({ variables: { id: orderId_1 } })];
                case 4:
                    _f.sent();
                    return [4 /*yield*/, getCheckinOrder({ id: orderId_1 })];
                case 5:
                    _b = _f.sent(), data_2 = _b.data, errors_1 = _b.errors;
                    if (data_2.CheckinOrder && !errors_1) {
                        result = data_2.CheckinOrder;
                    }
                    _f.label = 6;
                case 6:
                    setCheckinOrderId(result.id);
                    checkinOrderCreated = true;
                    if (checkInEnabled && checkInURL) {
                        if (iataCode === 'N4') {
                            reachGoal(CheckinGoal.Create);
                            window.open(getCheckInUrl(checkInURL, result.id), '_blank');
                        }
                        else {
                            open();
                        }
                    }
                    _f.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    e_1 = _f.sent();
                    console.warn('Could not create check-in order. Proceed with passenger credentials.');
                    return [3 /*break*/, 9];
                case 9:
                    setCheckinCreationInProgress(false);
                    if (!checkinOrderCreated) {
                        window.open(getCheckInUrl(checkInURL, orderId, ticket, lastName), '_blank');
                    }
                    _f.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    }); };
    var acceptRules = useCallback(function () {
        if (checkinOrderId) {
            reachGoal(CheckinGoal.Create);
            window.open(getCheckInUrl(checkInURL, checkinOrderId), '_blank');
        }
        close();
    }, [checkinOrderId, isRulesModalOpen, close]);
    return {
        isCheckinAvailable: isCheckinAvailable,
        acceptRules: acceptRules,
        goToCheckin: goToCheckin,
        checkinRules: (_a = rulesData === null || rulesData === void 0 ? void 0 : rulesData.data) === null || _a === void 0 ? void 0 : _a.CheckinRules,
        isRulesModalOpen: isRulesModalOpen,
        checkinCreationInProgress: checkinCreationInProgress,
        closeCheckinRulesModal: close
    };
};
export var useResendTickets = function (orderId) {
    var CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER = 1500;
    var RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER = 2000;
    var _a = __read(useState(ResendTicketsStages.Default), 2), resendStage = _a[0], setResendStage = _a[1];
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = __read(useResendItineraryRecieptMutation(), 2), resendEmail = _c[0], _d = _c[1], data = _d.data, loading = _d.loading, error = _d.error;
    var handleResendEmail = useCallback(function (email) {
        setResendStage(ResendTicketsStages.Loading);
        resendEmail({
            variables: {
                params: {
                    email: email,
                    orderId: orderId
                }
            }
        });
    }, [isOpen, resendStage, orderId, resendEmail]);
    useEffect(function () {
        var _a;
        var resendResult = data === null || data === void 0 ? void 0 : data.ResendItineraryReciept;
        if (error || ((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            setResendStage(ResendTicketsStages.Error);
            return;
        }
        if (!loading && !error && resendResult) {
            setResendStage(ResendTicketsStages.Success);
            setTimeout(close, CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER);
            setTimeout(function () {
                setResendStage(ResendTicketsStages.Default);
            }, RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER);
        }
    }, [data, loading, error]);
    return {
        openModal: open,
        closeModal: close,
        isModalOpen: isOpen,
        resendStage: resendStage,
        handleResendEmail: handleResendEmail,
        loading: loading
    };
};
export var usePromoCodesData = function () {
    var _a = __read(useState(null), 2), data = _a[0], setData = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = useToggleable(false), isOpen = _c.isOpen, setOpen = _c.setOpen, close = _c.close;
    return {
        data: data,
        setData: setData,
        isOpen: isOpen,
        setOpen: setOpen,
        close: close,
        loading: loading,
        setLoading: setLoading
    };
};
var SYSTEM_PAGES = ['payment/successfull', 'payment/failed', 'refund', 'exchange', 'change-passenger-docs'];
export var useSteps = function () {
    var order = useSelector(getOrder);
    var availableServices = useSelector(getAvailableServices), _a = useConfig().global, stepsAvailable = _a.stepsAvailable, servicesAvailable = _a.servicesAvailable;
    var isPaymentRequired = useSelector(getPaymentIsRequired), hasAvailableSeats = useSelector(getHasAvailableSeats), showServicesBeforeBooking = useSelector(getShowServicesBeforeBooking), isReadonly = useSelector(getIsReadonly);
    var location = useLocation(), match = useRouteMatch(), baseUrl = match.url;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isRedirectFromMetasearch = useSelector(getIsRedirectFromMetasearch);
    var steps = useMemo(function () {
        var steps = getSteps({
            order: order,
            availableServices: availableServices,
            isPaymentRequired: isPaymentRequired,
            isReadonly: isReadonly,
            hasAvailableSeats: hasAvailableSeats,
            showServicesBeforeBooking: showServicesBeforeBooking,
            configStepAvailable: stepsAvailable,
            configServicesAvailable: servicesAvailable
        });
        if (isMobile && isRedirectFromMetasearch) {
            return removeStepsWhenRedirectFromMetasearch(steps);
        }
        return steps;
    }, [
        order,
        availableServices,
        isPaymentRequired,
        isReadonly,
        hasAvailableSeats,
        showServicesBeforeBooking,
        stepsAvailable,
        isMobile,
        isRedirectFromMetasearch
    ]);
    var getFirstAvailableStep = useCallback(function () {
        if (order.status === OrderStatus.Confirmed || order.status === OrderStatus.Cancelled) {
            return steps.find(function (step) { return step.type === StepsEnum.Order; });
        }
        return steps.find(function (step) { return !step.hidden; });
    }, [order.status, steps]);
    var currentStep = useMemo(function () {
        return steps.find(function (step) { return location.pathname === "".concat(baseUrl, "/").concat(step.url); });
    }, [steps, location.pathname]);
    var isSystemPage = SYSTEM_PAGES.some(function (path) { return location.pathname.includes(path); });
    if ((!currentStep || currentStep.hidden) && !isSystemPage) {
        var currentStepIndex_1 = steps.findIndex(function (step) { return location.pathname === "".concat(baseUrl, "/").concat(step.url); }), nextAvailableStep_1 = currentStepIndex_1 === -1
            ? getFirstAvailableStep()
            : steps.find(function (step, index) { return !step.hidden && index > currentStepIndex_1; });
        setTimeout(function () {
            var _a;
            window.location.hash = "/booking/".concat(order.id, "/").concat((_a = nextAvailableStep_1 === null || nextAvailableStep_1 === void 0 ? void 0 : nextAvailableStep_1.url) !== null && _a !== void 0 ? _a : getFirstAvailableStep());
        });
    }
    var nextStep = useCallback(function () {
        var currentPath = window.location.hash;
        var currentStepIndex = steps.findIndex(function (step) { return currentPath.search(step.url) >= 0; }), nextStep = steps.find(function (step, index) { return !step.hidden && index > currentStepIndex; });
        if (nextStep) {
            window.location.hash = "/booking/".concat(order.id, "/").concat(nextStep.url);
        }
    }, [window, steps]);
    var prevStep = useCallback(function () {
        var currentPath = window.location.hash;
        var currentStepIndex = steps.findIndex(function (step) { return currentPath.search(step.url) >= 0; });
        for (var i = currentStepIndex - 1; i >= 0; i--) {
            if (!steps[i].hidden) {
                window.location.hash = "/booking/".concat(order.id, "/").concat(steps[i].url);
                return;
            }
        }
    }, [window, steps]);
    var setStep = useCallback(function (stepType) {
        var nextStep = steps.findIndex(function (step) { return step.type === stepType; });
        window.location.hash = "/booking/".concat(order.id, "/").concat(steps[nextStep].url);
    }, []);
    return {
        steps: steps.filter(function (step) { return !step.hidden; }),
        currentStep: currentStep,
        nextStep: nextStep,
        prevStep: prevStep,
        setStep: setStep
    };
};
export var useUpdateTravellerServices = function () {
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var hasRequestedOrderServices = useSelector(getHasRequestedOrderServices);
    var _a = __read(useUpdateOrderMutation(), 1), updateOrder = _a[0];
    var onUpdateOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateOrder({ variables: { id: order.id } })];
                case 1:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.UpdateOrder) {
                        dispatch(fillOrder(data.UpdateOrder));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [order === null || order === void 0 ? void 0 : order.id, updateOrder]);
    useConditionalPolling(onUpdateOrder, hasRequestedOrderServices, {
        interval: 5000,
        progressiveIntervalPerQuery: true
    });
};
