import React from 'react';
import { CommunicationDetailPageWrapper } from '../CommunicationDetailPageWrapper/CommunicationDetailPageWrapper';
import { PaximizerCommunicationSectionEnum, useGetPaxMyPromoCodeTeasersContentQuery } from '@websky/graphql';
import { useRouteMatch } from 'react-router-dom';
import { useCommunicationDetail } from '../../hooks';
export var PromoCodesDetailPage = function () {
    var _a, _b;
    var params = useRouteMatch().params;
    var _c = useGetPaxMyPromoCodeTeasersContentQuery({
        variables: {
            messageId: params.detailId
        }
    }), data = _c.data, loading = _c.loading, error = _c.error;
    useCommunicationDetail(PaximizerCommunicationSectionEnum.my_promo_codes, data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent);
    return (React.createElement(CommunicationDetailPageWrapper, { loading: loading, error: !!(error === null || error === void 0 ? void 0 : error.graphQLErrors.length), title: (_a = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent) === null || _a === void 0 ? void 0 : _a.title, body: (_b = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent) === null || _b === void 0 ? void 0 : _b.body }));
};
