import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { groupRefundServicesByPassenger, groupServicesByType } from './utils';
import Baggage from './Services/Baggage/Baggage';
import Meal from './Services/Meal/Meal';
import { useTheme } from '../../../../../theme';
import Seat from './Services/Seat/Seat';
import Stub from './Services/Stub/Stub';
var SelectedServices = function (_a) {
    var className = _a.className, order = _a.order, gdsServicesRefund = _a.gdsServicesRefund, notification = _a.notification, isLoading = _a.isLoading;
    var groupServices = React.useMemo(function () {
        var _a, _b;
        if ((_a = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.gdsServicesRefundInfo) {
            var servicesByType = groupServicesByType((_b = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.gdsServicesRefundInfo);
            var refundServicesByType_1 = new Map();
            servicesByType.forEach(function (value, key) {
                refundServicesByType_1.set(key, groupRefundServicesByPassenger(value));
            });
            return refundServicesByType_1;
        }
        return null;
    }, [gdsServicesRefund]);
    var getServiceCardByType = function (refundServices, type) {
        var props = {
            passengers: order.travellers,
            services: refundServices,
            flight: order.flight
        };
        switch (type) {
            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                return React.createElement(Baggage, __assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                return React.createElement(Meal, __assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                return React.createElement(Seat, __assign({}, props));
        }
        return null;
    };
    var renderServicesCard = React.useMemo(function () {
        var services = [];
        groupServices === null || groupServices === void 0 ? void 0 : groupServices.forEach(function (value, key) {
            if (Object.keys(value).length > 0) {
                services.push(getServiceCardByType(value, key));
            }
        });
        if (services.length) {
            return services.map(function (component, key) {
                return React.createElement(React.Fragment, { key: key }, component);
            });
        }
        return null;
    }, [groupServices]);
    var theme = useTheme('Exchange').SelectedServices;
    if (isLoading) {
        return (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement("div", { className: theme.selectedServices },
                React.createElement(Stub, null),
                React.createElement(Stub, null),
                React.createElement(Stub, null))));
    }
    if (!renderServicesCard) {
        return null;
    }
    return (React.createElement("div", { className: cn(theme.container, className) },
        notification,
        React.createElement("div", { className: theme.selectedServices }, renderServicesCard)));
};
export default SelectedServices;
