import { __assign } from "tslib";
export var isCheckoutOrder = function (order) {
    return order.flight !== undefined;
};
export var isCheckinOrder = function (order) {
    return order.segments !== undefined;
};
export var convertCartServiceProps = function (order) {
    if (isCheckoutOrder(order)) {
        return {
            flight: order.flight,
            customer: order.customer,
            travellers: order.travellers,
            services: order.additionalServices
        };
    }
    return {
        flight: {
            seatMap: order.seatMap,
            segments: order.segments.map(function (segment) { return ({
                segment: __assign(__assign({}, segment), { stops: [], messages: [] }),
                transferDuration: null
            }); }),
            segmentGroups: []
        },
        customer: order.customer,
        travellers: order.travellers.map(function (passenger) { return (__assign(__assign({}, passenger), { services: __assign(__assign({}, passenger.services), { brandIncludedServices: { services: [] } }), supplierTravellerType: null, needOthsSsr: null, subsidyInfo: null, fares: null })); }),
        services: order.additionalServices
    };
};
