import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import Input from '../../../../Input';
import SubmitButton from '../SubmitButton/SubmitButton';
import Resend from '../../../../Contacts/components/Resend/Resend';
import { useAuthenticateMutation, useSendSecureCodeMutation, LoginType } from '@websky/graphql';
import { STAY_LOGGED_IN } from '../../../../utils';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
var CodeForm = function (_a) {
    var login = _a.login, loginType = _a.loginType, onSuccess = _a.onSuccess, stayLoggedIn = _a.stayLoggedIn;
    var _b = useTheme('LoginPage'), css = _b.CodeForm, baseCss = _b.BaseComponent;
    var t = useTranslation('LoginPage').t;
    var _c = __read(useState(false), 2), incorrectCode = _c[0], setIncorrectCode = _c[1];
    var _d = __read(React.useState(false), 2), codeSent = _d[0], setCodeSent = _d[1];
    var _e = __read(useAuthenticateMutation(), 2), authenticate = _e[0], loading = _e[1].loading;
    var _f = __read(useSendSecureCodeMutation(), 2), sendCode = _f[0], codeSending = _f[1].loading;
    var resend = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCodeSent(false);
                    return [4 /*yield*/, sendCode({
                            variables: {
                                params: {
                                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                                    loginType: loginType
                                }
                            }
                        })];
                case 1:
                    result = _a.sent();
                    if (result) {
                        setCodeSent(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [login, loginType]);
    var validate = useCallback(function (values) {
        var errors = {};
        if (!values.code) {
            errors['code'] = t('Required');
        }
        return errors;
    }, []);
    var onSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (stayLoggedIn) {
                        localStorage.setItem(STAY_LOGGED_IN, 'true');
                    }
                    else {
                        localStorage.removeItem(STAY_LOGGED_IN);
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                                    loginType: loginType,
                                    secureCode: values['code'],
                                    oneSession: !stayLoggedIn
                                }
                            }
                        })];
                case 2:
                    _a.sent();
                    setIncorrectCode(false);
                    onSuccess();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setIncorrectCode(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [login, loginType, onSuccess]);
    var message = useMemo(function () {
        switch (loginType) {
            case LoginType.Email:
                return t('An e-mail with code has been sent to you.');
            case LoginType.Phone:
                return t('Code sent to your phone.');
            default:
                return null;
        }
    }, [loginType]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title }, t("Enter code_".concat(loginType))),
        React.createElement("div", { className: css.message }, message),
        React.createElement(Form, { validate: validate, onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: css.input_wrapper },
                            React.createElement(Field, { name: 'code', render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    var error = incorrectCode ? t('Please, check the code') : meta.error;
                                    var notValid = !!error && meta.touched;
                                    return (React.createElement(Input, { label: t("Verification code_".concat(loginType)), TextFieldProps: __assign(__assign({}, input), { onChange: function (ev) {
                                                if (incorrectCode) {
                                                    setIncorrectCode(false);
                                                }
                                                input.onChange(ev);
                                            }, error: notValid, helperText: notValid ? error : null, InputProps: {
                                                classes: {
                                                    input: baseCss.input
                                                }
                                            } }) }));
                                } })),
                        React.createElement("div", { className: css.button_wrapper },
                            React.createElement(SubmitButton, { loading: loading }, t("Continue_".concat(loginType)))))));
            } }),
        loginType !== LoginType.FFP && (React.createElement(Resend, { className: css.resend, labelClassName: css.resend__label, sendCode: resend, codeSent: codeSent, codeSending: codeSending }))));
};
export default CodeForm;
