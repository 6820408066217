import * as React from 'react';
import cn from 'classnames';
import { InsurancePageContext } from '../../InsurancePageContext';
import { useInsurancePage } from './InsurancePage.hook';
import { InsuranceProduct } from '../InsuranceProduct/InsuranceProduct';
import { Card } from '../Card/Card';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import SimpleLoader from '../../../SimpleLoader';
export var InsurancePage = function (_a) {
    var className = _a.className, availablePrograms = _a.availablePrograms, onTotalInsurancePriceChange = _a.onTotalInsurancePriceChange, onSaveOrderServicesParamsChange = _a.onSaveOrderServicesParamsChange, onServicesChanged = _a.onServicesChanged;
    var theme = useTheme('InsurancePage').InsurancePage;
    var t = useTranslation('InsurancePage').t;
    var model = useInsurancePage({
        availablePrograms: availablePrograms,
        onSaveOrderServicesParamsChange: onSaveOrderServicesParamsChange,
        onTotalInsurancePriceChange: onTotalInsurancePriceChange,
        onServicesChanged: onServicesChanged
    });
    return (React.createElement(InsurancePageContext.Provider, { value: model },
        React.createElement(Card, { className: cn(theme.wrapper, className) },
            React.createElement(Card, { className: theme.header },
                React.createElement("h4", { className: theme.title }, t('Insure your flight')),
                React.createElement("p", { className: theme.subheader }, t('To make your travels memorable only with pleasant excitement, take out an insurance policy that will protect you during your trip.'))),
            React.createElement(Card, { className: theme.content },
                model.mainPrograms.length > 0 && (React.createElement(Card, { className: theme.mainInsurancesList }, model.mainPrograms.map(function (importantProgram) { return (React.createElement(InsuranceProduct, { key: importantProgram.code, hideForAllPassengersTextBecauseSinglePassenger: model.doNotRenderForAllPassengersText, productState: model.getProductState(importantProgram.code), policyUrl: model.getPolicyUrl(importantProgram.code), onSelect: model.select, onRemove: model.remove, onToggle: model.toggle, insuranceProgram: importantProgram, isMainProgramTouched: model.isMainProgramTouched, popular: true })); }))),
                model.insurancesWithExcludedMainPrograms.length > 0 && (React.createElement(Card, { className: theme.insurancesList }, model.insurancesWithExcludedMainPrograms.map(function (insurance) { return (React.createElement(InsuranceProduct, { hideForAllPassengersTextBecauseSinglePassenger: model.doNotRenderForAllPassengersText, key: insurance.code, productState: model.getProductState(insurance.code), policyUrl: model.getPolicyUrl(insurance.code), onSelect: model.select, onRemove: model.remove, onToggle: model.toggle, insuranceProgram: insurance, popular: false })); })))),
            model.isLoading && React.createElement(SimpleLoader, null))));
};
