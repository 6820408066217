import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTheme } from '../../../../theme';
import RetrieveBooking from '../../../../RetrieveBooking';
import { Mode } from '../../../../RetrieveBooking/components/RetrieveBooking';
import { useParams } from 'react-router-dom';
import GroupOrder from '../GroupOrder/GroupOrder';
import { useFindGroupOrderMutation, useFindOrderMutation } from '@websky/graphql';
import { useSecureAccess } from '../../../../SecureAccess/SecureAccess.hook';
import SecureAccess from '../../../../SecureAccess/SecureAccess';
var FindOrder = function (_a) {
    var initialId = _a.initialId, onSuccess = _a.onSuccess, callSubmit = _a.callSubmit;
    var css = useTheme('Checkout').FindOrder;
    var _b = __read(useFindGroupOrderMutation(), 2), unlockGroupOrder = _b[0], findGroupOrderLoading = _b[1].loading;
    var _c = __read(useFindOrderMutation(), 2), unlockOrder = _c[0], findOrderLoading = _c[1].loading;
    var secureAccess = useSecureAccess();
    var _d = useParams(), id = _d.id, secret = _d.secret;
    var _e = __read(React.useState(null), 2), groupOrderParams = _e[0], setGroupOrderParams = _e[1];
    var _f = __read(React.useState(null), 2), groupOrderResponse = _f[0], setGroupOrderResponse = _f[1];
    var onSubmit = useCallback(function (id, secret, secure2fa) {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, errors, isGroupOrder, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, unlockOrder({
                                errorPolicy: 'all',
                                variables: {
                                    params: {
                                        id: id,
                                        secret: secret,
                                        secret2fa: secure2fa,
                                        saveInProfile: false
                                    }
                                }
                            })];
                    case 1:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        if (errors) {
                            isGroupOrder = errors.some(function (error) {
                                var _a, _b;
                                return (((_a = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _a === void 0 ? void 0 : _a.includes('group order')) ||
                                    ((_b = error === null || error === void 0 ? void 0 : error.message) === null || _b === void 0 ? void 0 : _b.includes('group order')));
                            });
                            if (secureAccess.isNeedToOpenSecureAccessModal(errors)) {
                                secureAccess.open();
                                secureAccess.setSubmitParams({ params: { id: id, secret: secret } });
                            }
                            if (isGroupOrder) {
                                secureAccess.close();
                                startFindGroupOrder(id, secret, [])
                                    .then(function (data) {
                                    if (data) {
                                        setGroupOrderParams({
                                            id: id,
                                            secret: secret
                                        });
                                        setGroupOrderResponse(data);
                                        resolve(true);
                                    }
                                })
                                    .catch(reject)
                                    .finally(function () { return secureAccess.close(); });
                            }
                            else {
                                reject(errors[0]);
                            }
                        }
                        else if (data === null || data === void 0 ? void 0 : data.FindOrder) {
                            resolve(true);
                            onSuccess(data.FindOrder);
                        }
                        else {
                            resolve(false);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        reject(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    }, [initialId]);
    var startFindGroupOrder = function (id, secret, passengers, secure2fa) {
        return new Promise(function (resolve, reject) {
            unlockGroupOrder({
                errorPolicy: 'all',
                variables: {
                    params: {
                        id: id,
                        secret: secret,
                        travellersDataForSplit: passengers,
                        secret2fa: secure2fa
                    }
                }
            }).then(function (response) {
                var _a;
                if (secureAccess.isNeedToOpenSecureAccessModal(response.errors)) {
                    secureAccess.setSubmitParams({ params: { id: id, secret: secret, travellersDataForSplit: passengers } });
                    secureAccess.open();
                }
                if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.FindGroupOrder) {
                    resolve(response.data.FindGroupOrder);
                }
                else {
                    reject(response.errors);
                }
            });
        });
    };
    var onAcceptHandler = function (passengers) {
        return startFindGroupOrder(groupOrderParams.id, groupOrderParams.secret, passengers);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SecureAccess, { isOpen: secureAccess.isOpen, showError: !findOrderLoading && !findGroupOrderLoading && secureAccess.showEror, isLoading: findOrderLoading || findGroupOrderLoading, onSubmit: function (secure2fa) {
                secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)
                    ? startFindGroupOrder(secureAccess.submitParams.params.id, secureAccess.submitParams.params.secret, secureAccess.submitParams.params.travellersDataForSplit, secure2fa)
                    : onSubmit(secureAccess.submitParams.params.id, secureAccess.submitParams.params.secret, secure2fa);
                secureAccess.setShowError(true);
            }, onResend: function () {
                return secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)
                    ? startFindGroupOrder(secureAccess.submitParams.params.id, secureAccess.submitParams.params.secret, secureAccess.submitParams.params.travellersDataForSplit)
                    : onSubmit(secureAccess.submitParams.params.id, secureAccess.submitParams.params.secret);
            } }),
        groupOrderResponse ? (React.createElement(GroupOrder, { groupOrder: groupOrderResponse, onSuccess: function (order) { return onSuccess(order, false); }, onAccept: onAcceptHandler, setGroupOrder: setGroupOrderResponse, groupOrderParams: groupOrderParams })) : (React.createElement("div", { className: css.wrapper },
            React.createElement(RetrieveBooking, { mode: Mode.Checkout, initialId: id !== null && id !== void 0 ? id : initialId, initialSecret: secret, submitWithInitialValues: id && secret && callSubmit, onSubmit: onSubmit })))));
};
export default FindOrder;
