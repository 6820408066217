import * as React from 'react';
import cn from 'classnames';
import { UserLoyaltyCardLevels } from '@websky/graphql';
import { useTheme } from '../../../theme';
var Card = function (_a) {
    var _b;
    var className = _a.className, cardHolder = _a.cardHolder, level = _a.level, loyaltyName = _a.loyaltyName, number = _a.number;
    var css = useTheme('LoyaltyInfo').Card;
    var fullName = '';
    if ((cardHolder === null || cardHolder === void 0 ? void 0 : cardHolder.firstName) && (cardHolder === null || cardHolder === void 0 ? void 0 : cardHolder.lastName)) {
        fullName = "".concat(cardHolder.firstName, " ").concat(cardHolder.lastName);
    }
    var isAuthorized = level && loyaltyName && number;
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.authorized] = isAuthorized,
            _b[css.red] = level === UserLoyaltyCardLevels.red,
            _b[css.silver] = level === UserLoyaltyCardLevels.silver,
            _b[css.gold] = level === UserLoyaltyCardLevels.gold,
            _b[css.platinum] = level === UserLoyaltyCardLevels.platinum,
            _b[css.nickel] = level === UserLoyaltyCardLevels.nickel,
            _b[css.base] = level === UserLoyaltyCardLevels.base,
            _b[css.bronze] = level === UserLoyaltyCardLevels.bronze,
            _b[css.basic] = level === UserLoyaltyCardLevels.bronze,
            _b[css.compliment] = level === UserLoyaltyCardLevels.compliment,
            _b[css.appreciation] = level === UserLoyaltyCardLevels.appreciation,
            _b[css.gratitude] = level === UserLoyaltyCardLevels.gratitude,
            _b)) }, isAuthorized && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.header },
            React.createElement("span", null, loyaltyName),
            React.createElement("span", { className: css.level }, level)),
        React.createElement("div", { className: css.info },
            React.createElement("span", null, fullName),
            React.createElement("span", { className: css.number }, number))))));
};
export default Card;
