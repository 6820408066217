import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Download } from '../../../../Icons';
import Button from '../../../../Button';
var DownloadInsurances = function (_a) {
    var selectedInsurances = _a.selectedInsurances;
    var theme = useTheme('AdditionalServices').DownloadInsurances;
    var t = useTranslation('Checkout').t;
    var confirmedInsurances = selectedInsurances.filter(function (insurance) { return !!insurance.policyUrl; });
    return (React.createElement("div", { className: theme.wrapper }, confirmedInsurances.map(function (insurance, index) { return (React.createElement("div", { key: index, className: theme.item },
        React.createElement("span", { className: theme.name }, insurance.passengerName.toLowerCase()),
        React.createElement("a", { href: insurance.policyUrl, target: "_blank" },
            React.createElement(Button, { className: theme.button },
                t('Download'),
                Download)))); })));
};
export default DownloadInsurances;
