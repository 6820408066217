import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { InfoMessageTypeEnum, useCheckinStartMutation, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { getCheckinHasCompleted, getCheckinOrder, getIsAllTravellersHavePreseating, getSegments } from '../../../../store/order/selectors';
import BoardingPassDesk from '../../../../../../BoardingPassDesk';
import BoardingPassMob from '../../../../../../BoardingPassMob';
import { useTheme } from '../../../../../../theme';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import { fillCheckinOrder } from '../../../../store/order/actions';
import Button from '../../../../../../Button';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
import { NotIncluded, StatusMessage } from '../../../../../../index';
import Timelimit from './Timelimit/Timelimit';
import { MOBILE_MIN_WIDTH, seatNumberRegex } from '../../../../../../utils';
import { useCheckinCancelModal, useCheckinTraveller, useSegmentSelect } from './hooks';
import SelectSegmentsModal from './SelectSegmentsModal/SelectSegmentsModal';
import { CheckinGoal, reachGoal } from '../../../../../../analytics';
import Modal from '../../../../../../Modal';
import VisaForm from './VisaForm/VisaForm';
import PassengersHeader from './PassengersHeader/PassengersHeader';
import Notification from '../../../../../../Notification';
import { OverrideComponent } from '../../../../../../renderProps';
import { hasSegmentBoardingPass } from './utils';
import CheckinAddDocumentsService from '../../CheckinAddDocuments/components/CheckinAddDocumentsService/CheckinAddDocumentsService';
import { useCheckinAddDocumentsService } from '../../CheckinAddDocuments/components/CheckinAddDocumentsService/hooks';
import PassengersContent from './PassengersContent/PassengersContent';
import Toolbar from '../../../../../../Toolbar';
var Passengers = function (_a) {
    var _b, _c;
    var onCheckinStart = _a.onCheckinStart, onRefetchOrder = _a.onRefetchOrder;
    var css = useTheme('Checkin').Passengers;
    var t = useTranslation('Checkin').t;
    var order = useSelector(getCheckinOrder);
    var isConfirmed = useSelector(getCheckinHasCompleted);
    var segments = useSelector(getSegments);
    var isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    var dispatch = useDispatch();
    var _d = __read(useState(null), 2), error = _d[0], setError = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useState(false), 2), displayVisaForm = _f[0], setDisplayVisaForm = _f[1];
    var _g = __read(useState(new Set()), 2), segmentsFailedBoardingPasses = _g[0], setSegmentsFailedBoardingPasses = _g[1];
    var _h = __read(useCheckinStartMutation(), 1), startCheckin = _h[0];
    var _j = __read(useResetOrderPaymentBlockMutation(), 1), resetOrderPaymentBlock = _j[0];
    var buttonIsDisabled = !order.travellers.length || isLoading;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _k = useCheckinCancelModal(), handleAgree = _k.handleAgree, showCheckinCancelModalIfNeeded = _k.showCheckinCancelModalIfNeeded, isCheckinCancelModalOpen = _k.isCheckinCancelModalOpen;
    var _l = useCheckinAddDocumentsService(order.id), isCheckinAddDocumentsOpen = _l.isOpen, setCheckinAddDocumentsOpen = _l.setOpen, isNeedToOpenAddDocumentsService = _l.isNeedToOpen, onDocumentsUpdated = _l.onDocumentsUpdated;
    var isCheckinOnlyInAirport = useMemo(function () {
        return order.segments.some(function (segment) { return segment.checkinInfo.checkInSubStatus === 'OnlyAirportCheckIn'; });
    }, [order]);
    useEffect(function () {
        if (isCheckinOnlyInAirport) {
            setCheckinStartError({
                name: 'CheckinStartError',
                message: t('Checkin available only in airport')
            });
            buttonIsDisabled = true;
        }
    }, [isCheckinOnlyInAirport]);
    var closeErrorModal = function () { return setError(null); };
    var setCheckinStartError = function (error) {
        var errorMessage = error.debugMessage || error.message;
        var invalidSeatError = 'Invalid seat number';
        if (errorMessage.includes(invalidSeatError)) {
            setError({
                error: __assign(__assign({}, error), { message: t(invalidSeatError, { seatNumber: errorMessage.match(seatNumberRegex)[0] }) }),
                content: t('An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.')
            });
        }
        else {
            setError({
                error: error,
                content: t('An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.')
            });
        }
    };
    var _m = useCheckinTraveller(showCheckinCancelModalIfNeeded, setError), checkinTravellerLoading = _m.isLoading, onAddTraveller = _m.onAddTraveller, onRemoveTraveller = _m.onRemoveTraveller, onCancelCheckinPassenger = _m.onCancelCheckinPassenger;
    var _o = useSegmentSelect(setIsLoading, setCheckinStartError, onCheckinStart), onStart = _o.onStart, isSegmentsSelectionOpen = _o.isSegmentsSelectionOpen, onSelect = _o.onSelect, selectedSegments = _o.selectedSegments;
    var onStartCheckin = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, order_1, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setDisplayVisaForm(false);
                    setIsLoading(true);
                    onStart();
                    if (segments.length > 1 && !selectedSegments.size) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, startCheckin({
                            variables: { id: order.id }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinStart);
                    if (!errors) return [3 /*break*/, 3];
                    setCheckinStartError(errors[0]);
                    return [3 /*break*/, 7];
                case 3:
                    if (!result) return [3 /*break*/, 7];
                    reachGoal(CheckinGoal.Start);
                    if (!result.isBlockedForPayment) return [3 /*break*/, 6];
                    return [4 /*yield*/, resetOrderPaymentBlock({ variables: { id: result.id } })];
                case 4:
                    _b.sent();
                    return [4 /*yield*/, onRefetchOrder()];
                case 5:
                    order_1 = _b.sent();
                    if (order_1) {
                        result = order_1;
                    }
                    _b.label = 6;
                case 6:
                    dispatch(fillCheckinOrder(result));
                    if (!result.allTravellersHavePreseating) {
                        onCheckinStart();
                    }
                    _b.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    e_1 = _b.sent();
                    setCheckinStartError(e_1);
                    return [3 /*break*/, 9];
                case 9:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order.id, segments, selectedSegments]);
    var onCheckinStartHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var travellersWithVisa;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    travellersWithVisa = order.travellers.find(function (traveller) { return traveller.visaRequired && traveller.visaValues.length; });
                    if (!isNeedToOpenAddDocumentsService) return [3 /*break*/, 1];
                    setCheckinAddDocumentsOpen(true);
                    return [3 /*break*/, 4];
                case 1:
                    if (!travellersWithVisa) return [3 /*break*/, 2];
                    setDisplayVisaForm(true);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onStartCheckin()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var availableTimelimit = !isConfirmed &&
        ((_b = order.segments.find(function (segment) { return segment.checkinInfo.checkinAvailable && segment.checkinInfo.webCloseTime; })) === null || _b === void 0 ? void 0 : _b.checkinInfo.webCloseTime);
    var getStatusMessageText = function () {
        var segmentsWithoutBoardingPasses = segments.filter(function (segment) { return !hasSegmentBoardingPass(segment, segmentsFailedBoardingPasses); });
        var canDownloadBoardingPass = segmentsWithoutBoardingPasses.length < segments.length;
        if (segmentsWithoutBoardingPasses.length && canDownloadBoardingPass) {
            var flights = segmentsWithoutBoardingPasses.map(function (segment) {
                return "".concat(segment.marketingAirline.iata, "-").concat(segment.flightNumber, " ").concat(segment.departure.airport.title, " \u2013 ").concat(segment.arrival.airport.title);
            });
            return t('You can now download and print your boarding passes. You will need them at the airport.\n Please notice, for flight {{flight}} you must receive your boarding pass at the airport', { flight: flights.join(', '), count: segmentsWithoutBoardingPasses.length === 1 ? 1 : 2 });
        }
        else if (!segmentsWithoutBoardingPasses.length && canDownloadBoardingPass) {
            return t('You can now download and print your boarding passes. You will need them at the airport.');
        }
        else {
            return t('Passengers:Please notice, you must receive your boarding passes at the airport');
        }
    };
    return order ? (React.createElement("div", { className: css.wrapper },
        React.createElement(PassengersHeader, null),
        React.createElement(CheckinAddDocumentsService, { onDocumentsUpdated: onDocumentsUpdated, isOpen: isCheckinAddDocumentsOpen }),
        React.createElement("div", { className: css.content },
            React.createElement(WarningModal, { title: t('Oops...'), content: error === null || error === void 0 ? void 0 : error.content, onClose: closeErrorModal, onButtonClick: closeErrorModal, errorMessage: (_c = error === null || error === void 0 ? void 0 : error.error) === null || _c === void 0 ? void 0 : _c.message, buttonText: t('OK, thank you'), isOpen: !!error }),
            React.createElement(SelectSegmentsModal, { fullScreen: isMobile, open: isSegmentsSelectionOpen, onSegmentSelect: onSelect, selectedSegments: selectedSegments, onConfirm: onStart }),
            displayVisaForm && React.createElement(VisaForm, { onVisaSubmitted: onStartCheckin }),
            isAllTravellersHavePreseating && (React.createElement("div", { className: css.notifications },
                React.createElement(Notification, { className: css.notification, iconClassName: css.notification__icon, titleClassName: css.notification__title, textClassName: css.notification__text, contextClassName: css.notification__context, title: t('Attention'), icon: NotIncluded, status: InfoMessageTypeEnum.Warning, type: "context", infoMessage: {
                        text: t('Seats have already been assigned by the airline and check-in is only available at the counter at the airport'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'notification'
                    } }))),
            React.createElement(OverrideComponent, { componentProps: {}, component: { CheckinOrderAdditionalBanner: function () { return null; } } }),
            React.createElement("div", { className: css.segments },
                !isMobile && availableTimelimit && (React.createElement(Timelimit, { timelimit: availableTimelimit, info: "(".concat(t('airport local time'), ")") })),
                isConfirmed && (React.createElement(StatusMessage, { type: "success", title: t('Check-in Successful'), text: getStatusMessageText() })),
                React.createElement(MediaQuery, { minWidth: "mobile" }, order.segments.map(function (segment, index) { return (React.createElement(BoardingPassDesk, { key: index, className: css.segment, segment: segment })); })),
                !isConfirmed && (React.createElement(MediaQuery, { maxWidth: "mobile" }, order.segments.map(function (segment, index) { return (React.createElement(BoardingPassMob, { key: index, className: css.segment, segment: segment })); })))),
            React.createElement(PassengersContent, { segmentsFailedBoardingPasses: segmentsFailedBoardingPasses, onAddTraveller: onAddTraveller, onRemoveTraveller: onRemoveTraveller, onCheckinCancel: onCancelCheckinPassenger, setIsLoading: setIsLoading, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses }),
            React.createElement(Modal, { open: isCheckinCancelModalOpen, onClose: handleAgree, maxWidth: 'sm', classes: { scrollBody: css.checkinCancelModal } },
                React.createElement("div", { className: css.checkinCancelModal__content },
                    React.createElement("div", { className: css.checkinCancelModal__text }, t('Checkin canceled, you will be redirected to the beginning', {})),
                    React.createElement("div", { className: css.checkinCancelModal__buttons },
                        React.createElement(Button, { onClick: handleAgree }, t('OK')))))),
        !isConfirmed && !isAllTravellersHavePreseating && !isCheckinOnlyInAirport && (React.createElement(Toolbar, { totalPrice: null, buttonDisabled: buttonIsDisabled, onButtonClick: onCheckinStartHandler })),
        (isLoading || checkinTravellerLoading) && React.createElement(SimpleLoader, null))) : null;
};
export default Passengers;
