import { __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { MinFlightPrice } from './MinFlightPrice/MinFlightPrice';
import { getMinPrices } from '../../../Results/utils';
import { SelectedFlightPrice } from './SelectedFlightPrice/SelectedFlightPrice';
import { getSeatsLeftCount } from './utils';
var FlightPrice = function (_a) {
    var best = _a.best, singleFlight = _a.singleFlight, isSelected = _a.isSelected, flight = _a.flight, selectedFares = _a.selectedFares, preselectedFlightClass = _a.preselectedFlightClass, canceled = _a.canceled, onClear = _a.onClear, hideOnlyPrice = _a.hideOnlyPrice, showSeatsLeftForAllFares = _a.showSeatsLeftForAllFares;
    var _b = __read(useMemo(function () {
        var _a, _b, _c, _d, _e;
        var minPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, false, preselectedFlightClass);
        var minOriginalPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, true, preselectedFlightClass);
        return [
            minPrice[0].price,
            ((_b = (_a = minOriginalPrice[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) > ((_d = (_c = minPrice[0]) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.amount) && ((_e = minOriginalPrice[0]) === null || _e === void 0 ? void 0 : _e.price),
            minPrice[0].priceInMiles,
            flight.pricesForFareGroups.some(function (group) { var _a; return (_a = group.prices) === null || _a === void 0 ? void 0 : _a.some(function (price) { var _a; return (_a = price.flight) === null || _a === void 0 ? void 0 : _a.subsidized; }); })
        ];
    }, [flight, preselectedFlightClass]), 4), price = _b[0], originalPrice = _b[1], priceInMiles = _b[2], hasSubsidizedFare = _b[3];
    var seatsLeft = !isSelected ? getSeatsLeftCount(flight) : null;
    var _c = __read(useMemo(function () {
        if (!isSelected) {
            return [null, null];
        }
        var fareFamily = flight.pricesForFareGroups[0].fareFamily;
        return [fareFamily, selectedFares[0]];
    }, [flight, selectedFares, isSelected]), 2), fareFamily = _c[0], selectedFare = _c[1];
    if (selectedFare) {
        return (React.createElement(SelectedFlightPrice, { fareFamily: fareFamily, selectedFare: selectedFare, canceled: canceled, priceInMiles: priceInMiles, onClear: onClear, hideOnlyPrice: hideOnlyPrice, subsidized: hasSubsidizedFare }));
    }
    else {
        return (React.createElement(MinFlightPrice, { singleFlight: singleFlight, best: best, originalPrice: originalPrice, price: price, priceInMiles: priceInMiles, hideOnlyPrice: hideOnlyPrice, subsidized: hasSubsidizedFare, seatsLeft: seatsLeft }));
    }
};
export default FlightPrice;
