import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import Tooltip from '../../../Tooltip';
import { PassengerType } from '../../../enums';
import { infoIcon, minusIcon, mobileMinusIcon, mobilePlusIcon, plusIcon } from '../../icons';
import { useTheme } from '../../../theme';
export var Passenger = React.memo(function (props) {
    var _a, _b, _c, _d;
    var t = useTranslation('Passengers').t;
    var titles = (_a = {},
        _a[PassengerType.Adult] = t('Adults'),
        _a[PassengerType.Teenager] = t('Teenagers'),
        _a[PassengerType.Child] = t('Children'),
        _a[PassengerType.Infant] = t('Infants'),
        _a[PassengerType.InfantWithSeat] = t('Infants with seat'),
        _a[PassengerType.YoungAdult] = t('Young adult'),
        _a[PassengerType.RetireeM] = t('Senior (men)'),
        _a[PassengerType.RetireeF] = t('Senior (female)'),
        _a[PassengerType.Retiree_F7] = t('Senior'),
        _a[PassengerType.Teenager_F7] = t('Teenagers'),
        _a[PassengerType.InfantWithSeat_RVT] = t('Infants with seat'),
        _a);
    var hints = (_b = {},
        _b[PassengerType.Adult] = t('12+ years'),
        _b[PassengerType.Teenager] = t('12-18 years'),
        _b[PassengerType.Child] = t('2-11 years'),
        _b[PassengerType.Infant] = t('Up to 2 years'),
        _b[PassengerType.InfantWithSeat] = t('Up to 2 years'),
        _b[PassengerType.YoungAdult] = t('12-23 years'),
        _b[PassengerType.RetireeF] = t('55+ years'),
        _b[PassengerType.RetireeM] = t('60+ years'),
        _b[PassengerType.Teenager_GCF] = t('12-18 years'),
        _b[PassengerType.Retiree_F7] = t('men 60+ female 55+'),
        _b[PassengerType.Teenager_F7] = t('12-23 years'),
        _b[PassengerType.InfantWithSeat_RVT] = t('Up to 2 years'),
        _b);
    var descriptions = (_c = {},
        _c[PassengerType.Adult] = t('Adults over twelve years old'),
        _c[PassengerType.Teenager] = t('Young peoples from twelve to eighteen years old'),
        _c[PassengerType.Child] = t('Children from two to eleven years old'),
        _c[PassengerType.Infant] = t('Infants up to two years old, without seat'),
        _c[PassengerType.InfantWithSeat] = t('Infants up to two years old, with seat'),
        _c[PassengerType.YoungAdult] = t('Young peoples 12-23 years old'),
        _c[PassengerType.RetireeM] = t('Senior men up 60 years'),
        _c[PassengerType.RetireeF] = t('Senior women up 55 years'),
        _c[PassengerType.Teenager_GCF] = t('Young peoples 12-18 years old'),
        _c[PassengerType.Retiree_F7] = t('Senior men up 60 years, Senior women up 55 years'),
        _c[PassengerType.Teenager_F7] = t('12-23 years'),
        _c[PassengerType.InfantWithSeat_RVT] = t('Infants up to two years old, with seat'),
        _c);
    var css = useTheme('Passengers').PassengerStyles, hint = hints[props.passenger.type], description = descriptions[props.passenger.type];
    return (React.createElement("div", { className: css.passenger, "data-passenger-type": props.passenger.type },
        React.createElement("div", { className: css.info },
            React.createElement("div", { className: css.passengerType }, titles[props.passenger.type]),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Tooltip, { title: description },
                    React.createElement("div", { className: css.age },
                        hint,
                        React.createElement("span", { className: css.info__icon }, infoIcon)))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.age }, hint),
                React.createElement("div", { className: css.description }, description))),
        React.createElement("div", { className: css.controls },
            React.createElement(ButtonBase, { className: css.decrease, onClick: function () { return props.removePassenger(props.passenger.type); }, disabled: !props.decreaseIsEnabled },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, minusIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, mobileMinusIcon)),
            React.createElement("div", { className: cn(css.counter, (_d = {},
                    _d[css.counter_disabled] = props.passenger.count === 0,
                    _d)), "data-passenger-type": props.passenger.type }, props.passenger.count),
            React.createElement(ButtonBase, { className: css.increase, onClick: function () { return props.addPassenger(props.passenger.type); }, disabled: !props.increaseIsEnabled },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, plusIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, mobilePlusIcon)))));
}, function (prev, next) {
    return (next.passenger.count === prev.passenger.count &&
        next.passenger.type === prev.passenger.type &&
        next.removePassenger === prev.removePassenger &&
        next.addPassenger === prev.addPassenger &&
        next.increaseIsEnabled === prev.increaseIsEnabled &&
        next.decreaseIsEnabled === prev.decreaseIsEnabled);
});
