import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import SegmentTabComponent from './components/SegmentTab/SegmentTab';
import FullscreenDialogClose from '../FullscreenDialog/components/Close/Close';
var SegmentTabs = function (props) {
    var _a;
    var css = useTheme('SegmentTabs').SegmentTabs;
    return (React.createElement("div", { className: cn(css.wrapper, props.wrapperClassName, (_a = {},
            _a[css.wrapper_sticky] = props.sticky,
            _a[css.wrapper_center] = props.center,
            _a[css.wrapper_withClose] = props.onClose,
            _a)) },
        React.createElement("div", { id: "segment-tabs", className: css.inner },
            React.createElement("div", { className: cn(css.title, props.titleClassName) }, props.title),
            React.createElement("div", { className: cn(css.segments, props.segmentsClassName) },
                React.createElement("div", { className: css.alignmentStub }),
                props.segments.map(function (segment, index) {
                    var _a;
                    return (React.createElement(SegmentTabComponent, { key: segment.id, index: index + 1, scrollToActive: props.scrollToActive, isActive: segment.id === props.activeSegment, isCompact: (_a = props.isCompact) !== null && _a !== void 0 ? _a : props.segments.length > 2, onClick: props.onSegmentClick.bind(null, segment.id), segment: segment, className: props.classNameTab, countSeatsSelected: segment.countSeatsSelected }));
                }),
                React.createElement("div", { className: css.alignmentStub })),
            props.onClose && React.createElement(FullscreenDialogClose, { onClose: props.onClose, closeClassName: css.close }))));
};
export default SegmentTabs;
