import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { initI18n, scrollTo } from '../../utils';
import { useTheme } from '../../theme';
import AdditionalServiceBanner from '../../AdditionalServiceBanner/AdditionalServiceBanner';
import SimpleLoader from '../../SimpleLoader';
import IncludedBaggage from '../../Baggage/IncludedBaggage/components/IncludedBaggage';
import { getServicesSavingState } from '../../Checkout/store/servicesIsSaving/selectors';
import { factorySelectedUpgrades } from '../../Baggage/IncludedBaggage/utils';
import AdditionalBaggage from '../../Baggage/AdditionalBaggage/components/AdditionalBaggage';
import SpecialEquipment from '../../Baggage/SpecialEquipment';
import OverweightBaggage from '../../Baggage/OverweightBaggage';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { filterIncludedBaggageByRfics } from '../../Baggage/UpgradeBaggageDialog/utils';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { getOrder } from '../../Checkout/store/order/selectors';
import { getSelectedServicesState } from '../../Checkout/store/selectedServices/selectors';
import AvailableServicesCountContextProvider from '../../AdditionalServices/context';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
initI18n('BaggagePage');
var BaggagePage = function (props) {
    var _a;
    var theme = useTheme('BaggagePage').BaggagePage;
    var order = useSelector(getOrder);
    var selectedServicesState = useSelector(getSelectedServicesState);
    var selectedServices = useMemo(function () {
        return factorySelectedUpgrades(props.selectedBaggage);
    }, [props.selectedBaggage]);
    var sortedAdditionalBaggage = React.useMemo(function () { return props.additionalBaggage.sort(function (a, b) { var _a, _b; return ((_a = a.value) === null || _a === void 0 ? void 0 : _a.amount) - ((_b = b.value) === null || _b === void 0 ? void 0 : _b.amount); }); }, [props.additionalBaggage]);
    React.useEffect(function () {
        scrollTo(0);
    }, []);
    var servicesSaving = useSelector(getServicesSavingState);
    var baggages = React.useMemo(function () { return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(props.additionalBaggage), false), __read(props.upgradeBaggage), false), __read(props.specialBaggage), false), __read(props.overweightBaggage), false); }, [props.additionalBaggage, props.upgradeBaggage, props.specialBaggage, props.overweightBaggage]);
    var setAdditionalBaggage = function (segments) {
        var services = [];
        segments.forEach(function (service) {
            var existsService = baggages.find(function (baggage) { return service.serviceId === baggage.id; });
            if (existsService) {
                var selectedService = {
                    id: existsService.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    price: existsService.price,
                    confirmedCount: 0,
                    name: existsService.name
                };
                services.push({
                    passengerId: service.passengerId,
                    count: service.count,
                    serviceId: service.serviceId,
                    service: selectedService,
                    segmentId: service.segmentIds[0],
                    allowedSegments: [service.segmentIds]
                });
            }
        });
        props.setAdditionalBaggage(services);
    };
    useEffect(function () {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.BaggageList);
    }, []);
    var filteredIncludedBaggageByRfics = filterIncludedBaggageByRfics(props.baggage, props.includedBaggage, selectedServices);
    return (React.createElement("div", { className: theme.baggage },
        props.isLoading && React.createElement(SimpleLoader, null),
        !props.hideBanner && React.createElement(AdditionalServiceBanner, { service: "baggage" }),
        React.createElement(AvailableServicesCountContextProvider, { value: { order: order, selectedServices: selectedServicesState } },
            React.createElement("div", { className: theme.items },
                React.createElement("div", { className: theme.newBaggage },
                    (filteredIncludedBaggageByRfics.length > 0 || ((_a = props.upgradeBaggage) === null || _a === void 0 ? void 0 : _a.length) > 0) && (React.createElement(IncludedBaggage, { className: theme.included_baggage, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, includedBaggage: filteredIncludedBaggageByRfics, upgradeServices: props.upgradeBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, isLoading: servicesSaving, dynamicBaggagePricing: props.dynamicBaggagePricing })),
                    sortedAdditionalBaggage.length > 0 && (React.createElement(AdditionalBaggage, { className: theme.extra_baggage, additionalBaggage: sortedAdditionalBaggage, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, selectedServices: selectedServices, isLoading: servicesSaving, dynamicBaggagePricing: props.dynamicBaggagePricing, onChange: console.log, onConfirm: setAdditionalBaggage, disabled: props.disabled })))),
            React.createElement("div", { className: cn(theme.items, theme.additional) },
                props.overweightBaggage.length > 0 && (React.createElement(OverweightBaggage, { className: cn(theme.additionalBaggage, theme.overweight_baggage), passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, services: props.overweightBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: props.disabled, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: servicesSaving })),
                props.specialBaggage.length > 0 && (React.createElement(SpecialEquipment, { className: cn(theme.additionalBaggage, theme.equipment_baggage), passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, services: props.specialBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: props.disabled, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: servicesSaving }))))));
};
export default BaggagePage;
