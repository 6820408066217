import { __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import i18n from 'i18next';
import { subMonths, parseISO, isBefore, isAfter } from 'date-fns';
import SearchFormDatepicker from '../../../../../../SearchFormDatepicker';
import Tooltip from '../../../../../../Tooltip/Tooltip';
import { useConfig } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
import { getMinPricesRequestVariables, getPrices } from '../../../../../utils';
import { safeStartOfMonth } from '../../../../../../PriceGraph/utils';
import { getDateWithoutTimezone, getDateWithTimezone } from '../../../../../../Engine/components/FastSearch/utils';
import Value from './Value/Value';
import { AviaPassengerType, useFlightsMinPricesInPeriodQuery, useGetAvailableDatesQuery } from '@websky/graphql';
import { getMinMaxPrice, isSelectedOnlyDepartureDate } from './utils';
import { useSelector } from 'react-redux';
import { CURRENCY_KEY } from '../../../../../../cache';
import { isMultiCity as isMultiCitySelector } from '../../../../../store/segments/selectors';
import { OverrideComponent, useRenderers } from '../../../../../../renderProps';
export var DatepickerTab;
(function (DatepickerTab) {
    DatepickerTab["Outbound"] = "outbound";
    DatepickerTab["Return"] = "return";
})(DatepickerTab || (DatepickerTab = {}));
var startDate = new Date();
var validate = function (value, allValues, meta) {
    if (!meta.data.departure) {
        return i18n.t('SearchForm:Please select departure date');
    }
};
var lastVisibleError = '';
var Datepicker = function (_a) {
    var _b;
    var valueClassName = _a.valueClassName, selectedDates = _a.selectedDates, onDateChange = _a.onDateChange, dateTo = _a.dateTo, _c = _a.dateBack, dateBack = _c === void 0 ? null : _c, _d = _a.withDateBack, withDateBack = _d === void 0 ? false : _d, clearDates = _a.clearDates, segmentId = _a.segmentId, locations = _a.locations, passengers = _a.passengers, showPrices = _a.showPrices, renderValue = _a.renderValue, disablePrices = _a.disablePrices;
    var css = useTheme('SearchForm').Datepicker;
    var hasSelectedDates = !!selectedDates.length;
    var _e = __read(useState(dateTo ? DatepickerTab.Return : DatepickerTab.Outbound), 2), routeType = _e[0], setRouteType = _e[1];
    var _f = __read(useState(new Date()), 2), openDate = _f[0], setOpenDate = _f[1];
    var _g = __read(useState([null, null]), 2), hoverDates = _g[0], setHoverDates = _g[1];
    var _h = __read(useState(false), 2), pricesLoading = _h[0], setPricesLoading = _h[1];
    var _j = __read(useState(false), 2), isRouteTypeSetManually = _j[0], setIsRouteTypeSetManually = _j[1];
    var _k = __read(useState(null), 2), previousManuallyRoute = _k[0], setPreviousManuallyRoute = _k[1];
    var _l = __read(useState(false), 2), isThisFirstSelectableDate = _l[0], setIsThisFirstSelectableDate = _l[1];
    var _m = __read(useState(null), 2), minPricesTo = _m[0], setMinPricesTo = _m[1];
    var _o = __read(useState(null), 2), minPricesBack = _o[0], setMinPricesBack = _o[1];
    var isMultiCity = useSelector(isMultiCitySelector);
    var OverrideSearchFormDatepicker = (_b = useRenderers().SearchFormDatePicker) !== null && _b !== void 0 ? _b : SearchFormDatepicker;
    var showPriceGraph = useConfig().SearchForm.showPriceGraph;
    var onHover = useCallback(function (hoverDate) {
        if (!selectedDates[0] && !selectedDates[1]) {
            setHoverDates([hoverDate, null]);
        }
        if (routeType === DatepickerTab.Outbound) {
            if (isBefore(hoverDate, selectedDates[1])) {
                setHoverDates([hoverDate, selectedDates[1]]);
            }
            else {
                setHoverDates([null, null]);
            }
        }
        if (routeType === DatepickerTab.Return) {
            if (isAfter(hoverDate, selectedDates[0])) {
                setHoverDates([selectedDates[0], hoverDate]);
            }
            else {
                setHoverDates([null, null]);
            }
        }
    }, [selectedDates, isRouteTypeSetManually, routeType, isThisFirstSelectableDate, previousManuallyRoute]);
    var scheduleTo = useGetAvailableDatesQuery({
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph,
        variables: {
            departure: locations.departure ? locations.departure.iata : '',
            arrival: locations.arrival ? locations.arrival.iata : ''
        }
    }).data;
    var scheduleBack = useGetAvailableDatesQuery({
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph || routeType === 'outbound',
        variables: {
            departure: locations.arrival ? locations.arrival.iata : '',
            arrival: locations.departure ? locations.departure.iata : ''
        }
    }).data;
    var alternativeHighlighting = routeType === 'outbound'
        ? scheduleTo && scheduleTo.FlightsSchedule && scheduleTo.FlightsSchedule.flightProbability > 0.5
        : scheduleBack && scheduleBack.FlightsSchedule && scheduleBack.FlightsSchedule.flightProbability > 0.5;
    var refetchMinPrices = useFlightsMinPricesInPeriodQuery({ skip: true }).refetch;
    var highlightingClassName;
    if (showPrices) {
        highlightingClassName = css.highlightedDate_withPrice;
    }
    else {
        highlightingClassName = alternativeHighlighting ? css.highlightedDate_lite : css.highlightedDate;
    }
    var getAvailableDatesFromSchedule = function () {
        if (routeType === 'outbound' && scheduleTo && scheduleTo.FlightsSchedule) {
            return scheduleTo.FlightsSchedule.datesData.map(function (scheduleItem) {
                return getDateWithoutTimezone(new Date(scheduleItem.date));
            });
        }
        else if (routeType === 'return' && scheduleBack && scheduleBack.FlightsSchedule) {
            return scheduleBack.FlightsSchedule.datesData.map(function (scheduleItem) {
                return getDateWithoutTimezone(new Date(scheduleItem.date));
            });
        }
        else {
            return [];
        }
    };
    var getAvailableDates = function () {
        var e_1, _a;
        var prices = routeType === 'outbound' ? minPricesTo : minPricesBack;
        var availableDates = [];
        if (showPrices) {
            if (!prices) {
                return [];
            }
            try {
                for (var _b = __values(Object.entries(prices)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                    if (value.price.amount || value.hasFlight) {
                        availableDates.push(getDateWithTimezone(new Date(key)));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return availableDates;
        }
        else {
            return getAvailableDatesFromSchedule();
        }
    };
    var getDatesWithMinPrices = useMemo(function () {
        var e_2, _a;
        var _b;
        var dates = [];
        var prices = routeType === 'outbound' ? minPricesTo : minPricesBack;
        if (!prices) {
            return [];
        }
        var _c = __read(getMinMaxPrice(prices), 2), min = _c[0], max = _c[1];
        var minPrice = min < max ? min + ((max - min) * 20) / 100 : min;
        if (prices && minPrice) {
            try {
                for (var _d = __values(Object.entries(prices)), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var _f = __read(_e.value, 2), key = _f[0], value = _f[1];
                    if (((_b = value.price) === null || _b === void 0 ? void 0 : _b.amount) <= minPrice) {
                        dates = __spreadArray(__spreadArray([], __read(dates), false), [getDateWithTimezone(new Date(key))], false);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return dates;
    }, [routeType, minPricesTo, minPricesBack]);
    var setDate = function (date, isBackDate) {
        var config = {};
        // departure
        if (!isBackDate) {
            if (selectedDates[1] && isAfter(date, selectedDates[1])) {
                config.return = null;
            }
            config.departure = date;
        } /*return */
        else {
            config.return = date;
            config.departure = selectedDates[0];
            if (selectedDates[0] && isBefore(date, selectedDates[0])) {
                config.departure = date;
                config.return = null;
            }
        }
        if (withDateBack) {
            onDateChange(config.departure, false);
            onDateChange(config.return, true);
            if (!isBackDate && withDateBack && !config.return) {
                setRouteType(DatepickerTab.Return);
            }
        }
        else {
            onDateChange(date);
        }
        return config;
    };
    var handlerClear = useCallback(function () {
        setRouteType(DatepickerTab.Outbound);
        clearDates();
    }, [clearDates]);
    if (!openDate) {
        setOpenDate(!withDateBack && selectedDates.length ? selectedDates[selectedDates.length - 1] : new Date());
    }
    var clearOpenDate = function () {
        setOpenDate(!withDateBack && selectedDates.length ? selectedDates[selectedDates.length - 1] : new Date());
    };
    var passengerTypesToSend = [
        AviaPassengerType.ADT,
        AviaPassengerType.CLD,
        AviaPassengerType.INF,
        AviaPassengerType.INS
    ];
    var onPricesEnd = function (date, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var data, prices, pricesKeys, lastMonth, prevMonth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPricesLoading(true);
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers.filter(function (passenger) { return passengerTypesToSend.includes(passenger.passengerType); }), date, routeType !== DatepickerTab.Outbound)
                        })];
                case 1:
                    data = (_a.sent()).data;
                    prices = getPrices(data);
                    if (routeType === DatepickerTab.Outbound) {
                        setMinPricesTo(prices);
                        if (direction === 'prev') {
                            pricesKeys = Object.keys(prices);
                            lastMonth = pricesKeys[pricesKeys.length - 1];
                            prevMonth = safeStartOfMonth(subMonths(parseISO(lastMonth), 1));
                            setOpenDate(prevMonth);
                        }
                    }
                    else {
                        setMinPricesBack(prices);
                    }
                    setPricesLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handlerClickDatepickerTabOutbound = useCallback(function () {
        setRouteType(DatepickerTab.Outbound);
        setPreviousManuallyRoute(DatepickerTab.Outbound);
        setIsRouteTypeSetManually(true);
        if (isSelectedOnlyDepartureDate(selectedDates)) {
            setIsThisFirstSelectableDate(true);
        }
    }, [selectedDates]);
    var handlerClickDatepickerTabReturn = useCallback(function () {
        if (!selectedDates[0]) {
            return;
        }
        setRouteType(DatepickerTab.Return);
        setPreviousManuallyRoute(DatepickerTab.Return);
        setIsThisFirstSelectableDate(false);
    }, [selectedDates[0]]);
    var handlerCalendarOpen = useCallback(function () {
        setRouteType(DatepickerTab.Outbound);
        setPreviousManuallyRoute(DatepickerTab.Outbound);
        setIsRouteTypeSetManually(true);
        if (isSelectedOnlyDepartureDate(selectedDates) || selectedDates.length === 0) {
            setIsThisFirstSelectableDate(true);
        }
    }, [selectedDates]);
    var isReadyToClose = useCallback(function (date) {
        var filterSelectedDates = selectedDates.filter(function (date) { return !!date; });
        if (filterSelectedDates.length) {
            var isBeforeDate = isBefore(date, filterSelectedDates[0]);
            if (isBeforeDate && !isThisFirstSelectableDate) {
                return false;
            }
            return routeType === DatepickerTab.Return;
        }
        return false;
    }, [selectedDates, isThisFirstSelectableDate, routeType]);
    var fetchPrices = function () { return __awaiter(void 0, void 0, void 0, function () {
        var minPricesToResp, minPricesBackResp, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!locations.arrival || !locations.departure) {
                        return [2 /*return*/];
                    }
                    if (locations.arrival.iata === locations.departure.iata) {
                        return [2 /*return*/];
                    }
                    setPricesLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers, dateTo, false)
                        })];
                case 2:
                    minPricesToResp = (_a.sent()).data;
                    if (!!isMultiCity) return [3 /*break*/, 4];
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers, dateTo, true)
                        })];
                case 3:
                    minPricesBackResp = (_a.sent()).data;
                    setMinPricesBack(getPrices(minPricesBackResp));
                    _a.label = 4;
                case 4:
                    setMinPricesTo(getPrices(minPricesToResp));
                    setPricesLoading(false);
                    return [3 /*break*/, 7];
                case 5:
                    e_3 = _a.sent();
                    console.log(e_3.message);
                    return [3 /*break*/, 7];
                case 6:
                    setPricesLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onChangeLocalStorage = function (e) {
        if (e.key === CURRENCY_KEY && e.oldValue !== e.newValue) {
            fetchPrices();
        }
    };
    useEffect(function () {
        fetchPrices();
        window.addEventListener('storage', onChangeLocalStorage);
        return function () {
            window.removeEventListener('storage', onChangeLocalStorage);
        };
    }, [locations.departure && locations.departure.iata, locations.arrival && locations.arrival.iata]);
    useEffect(function () {
        if (withDateBack && selectedDates.length === 0 && routeType === DatepickerTab.Return) {
            setRouteType(DatepickerTab.Outbound);
        }
    }, [withDateBack]);
    var isShowPriceGraph = !pricesLoading && (!showPrices || showPriceGraph) && !!Object.keys(minPricesTo || {}).length;
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, function (formState) { return (React.createElement(Field, { name: "dates_".concat(segmentId), validate: validate, subscription: {
            data: true,
            error: true
        } }, function (fieldState) {
        var _a;
        var onSelect = function (date, isBackDate) {
            setHoverDates([null, null]);
            var config = setDate(date, isBackDate);
            setRouteType(DatepickerTab.Return);
            setPreviousManuallyRoute(DatepickerTab.Return);
            setIsThisFirstSelectableDate(false);
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        };
        // We need this for filling out the search form from the outside.
        if ((dateTo || dateBack) && !fieldState.meta.data.departure) {
            var config = {};
            if (dateTo) {
                config.departure = dateTo;
            }
            if (dateBack) {
                config.return = dateBack;
            }
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        }
        if (!dateTo || !dateBack) {
            var config = {};
            config.departure = dateTo;
            config.return = dateBack;
            formState.form.mutators.setFieldData(fieldState.input.name, {
                departure: dateTo,
                return: dateBack
            });
        }
        if (fieldState.meta.error) {
            lastVisibleError = fieldState.meta.error;
        }
        return (React.createElement(Tooltip, { title: lastVisibleError, open: !!fieldState.meta.error && formState.submitFailed },
            React.createElement("div", { className: css.datepicker },
                React.createElement(OverrideSearchFormDatepicker, { monthCount: 3, showProgress: showPrices && pricesLoading, onSelect: onSelect, onHover: withDateBack ? onHover : null, hoverDates: hoverDates, onClear: handlerClear, clearOpenDate: clearOpenDate, openDate: openDate, selectedDates: selectedDates.filter(function (date) { return !!date; }), minDate: startDate, isClearable: hasSelectedDates, isDoneable: hasSelectedDates, inputClassName: css.input, routeType: routeType, onOpen: handlerCalendarOpen, inputFocusedClassName: css.input_focused, outsideClickIgnoreClass: segmentId > 0
                        ? "".concat(css.dates.split(' ')[0], "_").concat(segmentId)
                        : css.dates.split(' ')[0], showPriceGraph: isShowPriceGraph, onPriceGraphPricesEnd: onPricesEnd, pricesTo: minPricesTo, pricesBack: minPricesBack, showPriceMatrix: false, isDateToSelected: !!dateTo, isDateBackSelected: !!dateBack, datepickerAlternativeHighlighting: alternativeHighlighting, useThemeWithPrices: showPrices, highlightedDates: (_a = {},
                        _a[highlightingClassName] = getAvailableDates(),
                        _a[css.highlighted_minPrice] = getDatesWithMinPrices,
                        _a), closeAfterSelectReturnDate: isReadyToClose, onClickOutboundDate: handlerClickDatepickerTabOutbound, onClickReturnDate: handlerClickDatepickerTabReturn, valueRenderer: function (isOpen, open, close) {
                        var _a, _b;
                        var valueProps = {
                            className: valueClassName,
                            dateTo: (_a = hoverDates[0]) !== null && _a !== void 0 ? _a : dateTo,
                            dateBack: (_b = hoverDates[1]) !== null && _b !== void 0 ? _b : dateBack,
                            isOpen: isOpen,
                            open: open,
                            close: close,
                            withDateBack: withDateBack,
                            routeType: routeType,
                            setRouteType: setRouteType,
                            onClear: handlerClear,
                            segmentId: segmentId,
                            setIsRouteTypeSetManually: setIsRouteTypeSetManually,
                            onClickDatepickerTabReturn: handlerClickDatepickerTabReturn,
                            onClickDatepickerTabOutbound: handlerClickDatepickerTabOutbound
                        };
                        if (renderValue) {
                            return renderValue(valueProps);
                        }
                        return (React.createElement(OverrideComponent, { componentProps: valueProps, component: { SearchFormDatepickerValue: Value } }));
                    } }))));
    })); }));
};
export default Datepicker;
