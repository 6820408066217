import * as React from 'react';
import SelectSegmentsModal from '../../../Modules/Checkin/components/Checkin/Order/Passengers/SelectSegmentsModal/SelectSegmentsModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
var SelectCancelSegments = function (props) {
    var t = useTranslation('RegisteredPassenger').t;
    var theme = useTheme('RegisteredPassenger').SelectCancelSegmentsModal;
    return (React.createElement(SelectSegmentsModal, { closeClassName: theme.closeButton, onClose: props.onClose, header: React.createElement(React.Fragment, null, t('Cancel check-in')), passedGroupTitle: React.createElement(React.Fragment, null, t('Select segments to cancel')), selectedSegments: props.selectedSegments, onSegmentSelect: props.onSegmentSelect, onConfirm: props.onConfirm, open: props.open, type: "cancel" }));
};
export default SelectCancelSegments;
