import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
var RouteArrow = function (_a) {
    var _b;
    var timeInRoute = _a.timeInRoute, flightNumber = _a.flightNumber, mobile = _a.mobile, stopCount = _a.stopCount;
    var theme = useTheme('DesktopFlightRoute').RouteArrowStyles;
    var t = useTranslation('DesktopFlight').t;
    return (React.createElement("div", { className: cn(theme.container, (_b = {},
            _b[theme.mobile] = mobile,
            _b)) },
        React.createElement("div", { className: cn(theme.route) },
            React.createElement("div", { className: theme.circle }),
            React.createElement("div", { className: theme.timeInRoute }, timeInRoute),
            React.createElement("div", { className: cn(theme.circle, theme.circle_right) })),
        flightNumber && !stopCount && React.createElement("div", { className: theme.flightNumber }, flightNumber),
        stopCount > 0 && (React.createElement("div", { className: cn(theme.flightNumber, theme.stop) },
            stopCount,
            " ",
            t(stopCount > 1 ? 'stops' : 'stop')))));
};
export default RouteArrow;
