import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import cn from 'classnames';
import { FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { InsurancePrice } from '../InsurancePrice/InsurancePrice';
import { initI18n } from '../../../utils';
initI18n('InsurancePage');
export var SelectForm = function (_a) {
    var _b, _c, _d;
    var className = _a.className, selected = _a.selected, price = _a.price, onSelect = _a.onSelect, onRemove = _a.onRemove, hideForAllPassengersTextBecauseSinglePassenger = _a.hideForAllPassengersTextBecauseSinglePassenger, isTouched = _a.isTouched;
    var t = useTranslation('InsurancePage').t;
    var theme = useTheme('InsurancePage').SelectForm;
    var _e = __read(useState(isTouched !== undefined ? "".concat(selected) : selected ? 'true' : null), 2), value = _e[0], setValue = _e[1];
    var radioItems = [
        {
            value: 'true',
            classes: {
                label: theme.labelContainer
            },
            label: (React.createElement("div", { className: cn(theme.label, (_b = {}, _b[theme.labelMute] = value === 'false', _b)) },
                React.createElement("span", null, t('I want protect my trip.')),
                React.createElement(InsurancePrice, { className: theme.price, contentClassName: theme.priceContent, selected: selected, price: price, variant: 'mostPopular', hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })))
        },
        {
            value: 'false',
            classes: {
                label: theme.labelContainer
            },
            label: (React.createElement("div", { className: cn(theme.label, (_c = {}, _c[theme.labelMute] = value === 'true', _c)) }, t('I do not want to insure my life or luggage and assume all risks')))
        }
    ];
    return (React.createElement(FormControl, { className: cn(className, (_d = {},
            _d[theme.singleTravellerInOrder] = hideForAllPassengersTextBecauseSinglePassenger,
            _d)) },
        React.createElement(RadioGroup, { name: "select-insurance-form", onChange: function (e) {
                if (e.target.value === 'true') {
                    onSelect();
                }
                else {
                    onRemove();
                }
                setValue(e.target.value);
            }, defaultValue: 'true' }, radioItems.map(function (item) { return (React.createElement(FormControlLabel, { key: item.value, value: item.value, className: theme.root, control: React.createElement(Radio, { className: theme.radio, classes: {
                    checked: theme.checked
                }, value: item.value, checked: value === item.value }), classes: item.classes, label: item.label })); }))));
};
