import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { LoginType, useGetAccountInfoQuery, useRemoveAuthMethodMutation } from '@websky/graphql';
import { useConfig } from '../../../context';
import { Statuses } from '../../types';
import Loader from '../../../Loader';
import WarningModal from '../../../WarningModal';
import AuthMethod from './AuthMethod/AuthMethod';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
initI18n('Account');
var AuthMethods = function () {
    var css = useTheme('Account').Account, t = useTranslation('Account').t, _a = __read(useState(Statuses.default), 2), authStatus = _a[0], setAuthStatus = _a[1], _b = __read(useState(null), 2), userSettingsError = _b[0], setUserSettingsError = _b[1];
    var _c = useConfig().global, authMethods = _c.authMethods, iataCode = _c.companyInfo.iataCode;
    var accountInfo = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache'
    });
    var _d = __read(useRemoveAuthMethodMutation(), 1), removeMethod = _d[0];
    var refetchAccountInfo = function () {
        accountInfo.refetch().then();
    };
    var clearUserSettingsError = useCallback(function () {
        setUserSettingsError(null);
    }, []);
    var onRemoveAuthMethod = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeMethod({
                            variables: {
                                params: id
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        setUserSettingsError(errors[0]);
                    }
                    else if (data.DeleteAuthMethod && data.DeleteAuthMethod.result) {
                        refetchAccountInfo();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    setUserSettingsError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _e = __read(React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var phone = (_c = (_b = (_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.find(function (authM) { return authM.loginType === LoginType.Phone && !!authM.value && authM.confirmed; }), email = (_f = (_e = (_d = accountInfo.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.authMethods) === null || _f === void 0 ? void 0 : _f.find(function (authM) { return authM.loginType === LoginType.Email && !!authM.value && authM.confirmed; }), ffp = (_j = (_h = (_g = accountInfo.data) === null || _g === void 0 ? void 0 : _g.CurrentUser) === null || _h === void 0 ? void 0 : _h.authMethods) === null || _j === void 0 ? void 0 : _j.find(function (authM) { return authM.loginType === LoginType.FFP && !!authM.value && authM.confirmed; });
        return [phone, email, ffp];
    }, [accountInfo]), 3), accountPhone = _e[0], accountEmail = _e[1], accountFFP = _e[2];
    var isRemovableMethod = React.useMemo(function () {
        return [accountPhone, accountEmail, accountFFP].filter(function (method) { return method === null || method === void 0 ? void 0 : method.confirmed; }).length > 1;
    }, [accountPhone, accountEmail, accountFFP]);
    if (accountInfo.loading) {
        return (React.createElement("div", { className: css.loader__wrapper },
            React.createElement(Loader, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.account__content },
            authMethods.email && (React.createElement(AuthMethod, { id: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.id, className: css.account__field, status: authStatus, type: LoginType.Email, value: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod })),
            authMethods.phone && (React.createElement(AuthMethod, { id: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.id, className: css.account__field, status: authStatus, type: LoginType.Phone, value: formatPhoneNumberIntl("+".concat(accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.value)), setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod })),
            authMethods.ffp && (React.createElement(AuthMethod, { id: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.id, className: css.account__field, status: authStatus, type: LoginType.FFP, value: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod, iataCode: iataCode }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default AuthMethods;
