import * as React from 'react';
import { memo } from 'react';
import MealComponent from '../../Meal';
import memes from '../../memes';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { useAvailableCountForTravellerOnSegment } from '../../AdditionalServices/hooks';
var reduceSelectedMealsCount = memes(function (total, selectedMeal, meal, passenger, segmentId) {
    return meal.id === selectedMeal.mealId &&
        segmentId === selectedMeal.segmentId &&
        passenger.id === selectedMeal.passengerId
        ? total + selectedMeal.count
        : total;
});
export var MealWrapper = memo(function (_a) {
    var selectedMeals = _a.selectedMeals, initialSelectedMeals = _a.initialSelectedMeals, meal = _a.meal, segmentId = _a.segmentId, passengers = _a.passengers, onMealAdd = _a.onMealAdd, onMealRemove = _a.onMealRemove, onMealConfirm = _a.onMealConfirm, onMealAddForPassengers = _a.onMealAddForPassengers, onMealClear = _a.onMealClear, onMealOpen = _a.onMealOpen, _b = _a.readonly, readonly = _b === void 0 ? false : _b, fullSize = _a.fullSize, isRecommend = _a.isRecommend, isLoading = _a.isLoading;
    var isCountChanged = React.useMemo(function () {
        var filteredSelectedMeals = selectedMeals
            .filter(function (it) { return it.segmentId === segmentId && it.mealId === meal.id; })
            .map(function (it) {
            return { passengerId: it.passengerId, count: it.count };
        });
        var filteredInitialSelectedMeals = initialSelectedMeals
            .filter(function (it) { return it.segmentId === segmentId && it.mealId === meal.id; })
            .map(function (it) {
            return { passengerId: it.passengerId, count: it.count };
        });
        return JSON.stringify(filteredSelectedMeals) !== JSON.stringify(filteredInitialSelectedMeals);
    }, [selectedMeals]);
    var onMealAddHandler = function (meal, passengerId, count) {
        onMealAdd(meal, segmentId, passengerId, count);
    };
    var onMealAddForPassengerHandler = function (meal) {
        onMealAddForPassengers(meal, segmentId, meal.allowedPassengers, 1);
    };
    var onMealRemoveHandler = function (meal, passengerId, count) {
        onMealRemove(meal, segmentId, passengerId, count);
    };
    var onCloseHandler = function (meal, segmentId) {
        if (isCountChanged) {
            onMealClear(meal, segmentId);
            initialSelectedMeals.forEach(function (initialSelectedMeal) {
                if (initialSelectedMeal.segmentId === segmentId && meal.id === initialSelectedMeal.mealId) {
                    onMealAddHandler(meal, initialSelectedMeal.passengerId, initialSelectedMeal.count);
                }
            });
        }
    };
    var onConfirmHandler = function (meal, segmentId) {
        if (isCountChanged) {
            onMealConfirm(meal, segmentId);
        }
    };
    var onPopupOpened = function () {
        onMealOpen === null || onMealOpen === void 0 ? void 0 : onMealOpen();
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealItem, { servicesIds: [meal.id], segmentId: segmentId });
    };
    var getMaxAvailable = function (passengerId) {
        return useAvailableCountForTravellerOnSegment(meal.id, passengerId, segmentId);
    };
    return (React.createElement(MealComponent, { key: "".concat(meal.id, "_").concat(segmentId), meal: meal, fullSize: fullSize, onAddForAll: onMealAddForPassengerHandler, onIncrease: function (meal, passenger) { return onMealAddHandler(meal, passenger.id, 1); }, onDecrease: function (meal, passenger) { return onMealRemoveHandler(meal, passenger.id, 1); }, onClear: function (meal) { return onMealClear(meal, segmentId); }, onConfirm: function (meal) { return onConfirmHandler(meal, segmentId); }, onClose: function (meal) { return onCloseHandler(meal, segmentId); }, readonly: readonly || !meal.canBeAdded, onPopupOpened: onPopupOpened, isCountChanged: isCountChanged, counters: passengers
            .filter(function (passenger) { return meal.allowedPassengers.some(function (passengerId) { return passengerId === passenger.id; }); })
            .map(function (passenger) {
            var _a, _b;
            return ({
                passenger: {
                    id: passenger.id,
                    name: passenger.name
                },
                count: selectedMeals.reduce(function (total, selectedMeal) {
                    return reduceSelectedMealsCount(total, selectedMeal, meal, passenger, segmentId);
                }, 0),
                minAvailable: (_b = (_a = selectedMeals.find(function (sm) {
                    return sm.segmentId === segmentId &&
                        sm.passengerId === passenger.id &&
                        sm.mealId === meal.id;
                })) === null || _a === void 0 ? void 0 : _a.confirmedCount) !== null && _b !== void 0 ? _b : 0,
                maxAvailable: getMaxAvailable(passenger.id)
            });
        }), isLoading: isLoading }));
});
