import { isValidPhoneNumber } from 'libphonenumber-js';
var formatPhoneNumberIntl = require('react-phone-number-input').formatPhoneNumberIntl;
var parsePhoneNumberFromString = require('libphonenumber-js').parsePhoneNumberFromString;
export var clearPhoneNumber = function (phone) {
    if (!phone) {
        return '';
    }
    return phone.replace(/[+()\- ]/g, '');
};
export var removeSpaces = function (value) { return value.replace(/\s/g, ''); };
export var isCorrectPhoneNumber = function (phone) {
    var phoneNumber = parsePhoneNumberFromString(phone, 'RU');
    if (!phoneNumber) {
        return false;
    }
    return isValidPhoneNumber(clearPhoneNumber(phone), phoneNumber.country);
};
export var parsePhone = function (value) {
    var parsedPhone;
    if (value.startsWith('8') && clearPhoneNumber(value).length === 11 && parsePhoneNumberFromString(value, 'RU')) {
        parsedPhone = parsePhoneNumberFromString(value, 'RU').formatNational();
    }
    else if (value.startsWith('9') && parsePhoneNumberFromString(value, 'RU')) {
        parsedPhone = parsePhoneNumberFromString(value, 'RU').formatNational();
    }
    else {
        parsedPhone = formatPhoneNumberIntl(value) || formatPhoneNumberIntl("+".concat(value));
    }
    return parsedPhone;
};
export var isRusNumber = function (value) {
    var rawValue = clearPhoneNumber(value);
    return ((value.startsWith('8') && rawValue.length === 11 && !!parsePhoneNumberFromString(value, 'RU')) ||
        (value.startsWith('9') && rawValue.length === 10 && !!parsePhoneNumberFromString(value, 'RU')));
};
