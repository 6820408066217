import { __assign } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Passengers from '@websky/core/src/Checkout/components/Checkout/routes/Passengers';
import { getIsFareLockSelected } from '@websky/core/src/Checkout/store/order/selectors';
import { ExareStatus } from '@websky/graphql';
var PassengersRoute = function (props) {
    var order = props.order, baseUrl = props.baseUrl;
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    if (order.exareInfo.lastExchange && order.exareInfo.lastExchange.status === ExareStatus.AwaitingPayment) {
        return React.createElement(Redirect, { to: "".concat(baseUrl, "/exchange/payment") });
    }
    else if (order.isBlockedForPayment && !isFareLockSelected && order.exareInfo.lastExchange) {
        return React.createElement(Redirect, { to: "".concat(baseUrl, "/exchange/request") });
    }
    return React.createElement(Passengers, __assign({}, props));
};
export default PassengersRoute;
