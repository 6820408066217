import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import Input from '../../Input';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import Tooltip from '../../Tooltip';
import ClarifyDocumentModal from './ClarifyDocumentModal/ClarifyDocumentModal';
import { useClarifyDocumentModal } from './ClarifyDocumentModal/hooks';
import { useRetrieveBookingValidation } from '../hooks';
import Notification from '../../Notification';
import { InfoQuestion } from '../../Icons';
var onlyAirportCheckInError = 'Invalid sub status: OnlyAirportCheckIn';
initI18n('RetrieveBooking');
export var Mode;
(function (Mode) {
    Mode[Mode["Checkout"] = 0] = "Checkout";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
var RetrieveBooking = function (_a) {
    var _b;
    var mode = _a.mode, submitWithInitialValues = _a.submitWithInitialValues, className = _a.className, title = _a.title, initialId = _a.initialId, initialSecret = _a.initialSecret, onSubmit = _a.onSubmit, idTitle = _a.idTitle, secretTitle = _a.secretTitle, hideSecretHint = _a.hideSecretHint;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState(false), 2), codeIsWrong = _d[0], setCodeIsWrong = _d[1];
    var _e = __read(useState(false), 2), isBlockedForPayment = _e[0], setIsBlockedForPayment = _e[1];
    var _f = __read(useState(false), 2), isOnlyAirportCheckin = _f[0], setIsOnlyAirportCheckin = _f[1];
    var css = useTheme('RetrieveBooking').RetrieveBooking;
    var _g = useTranslation('RetrieveBooking'), t = _g.t, i18n = _g.i18n;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _h = useClarifyDocumentModal(), isOpen = _h.isOpen, openModal = _h.open, searchParams = _h.searchParams, setSearchParams = _h.setSearchParams, close = _h.close, needToShowClarifyModal = _h.needToShowClarifyModal;
    var _j = useRetrieveBookingValidation(), lastNameValidation = _j.lastNameValidation, ticketNumberValidation = _j.ticketNumberValidation, validateByOrderNumber = _j.validateByOrderNumber, ticketNumberFormat = _j.ticketNumberFormat;
    var submitForm = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, onSubmit(values['id'], values['secret'], values['extraSecret'])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, [onSubmit]);
    var inputVariant = isMobile ? 'standard' : 'filled';
    var handleSubmit = useCallback(function (values, form) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setCodeIsWrong(false);
                    setIsLoading(true);
                    setIsOnlyAirportCheckin(false);
                    if (mode === Mode.Checkin && form.hasOwnProperty('getFieldState')) {
                        // clear warning on submit
                        form.getFieldState('secret').data.warning = null;
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, submitForm(values)];
                case 2:
                    result = _b.sent();
                    if (result) {
                        if (typeof result !== 'boolean') {
                            setIsBlockedForPayment(result.isBlockedForPayment);
                        }
                    }
                    else {
                        setCodeIsWrong(true);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.warn(e_1);
                    if (!!((_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.find(function (error) {
                        var _a, _b;
                        return ((_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.includes(onlyAirportCheckInError)) ||
                            ((_b = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _b === void 0 ? void 0 : _b.includes(onlyAirportCheckInError));
                    }))) {
                        setIsOnlyAirportCheckin(true);
                    }
                    else if (needToShowClarifyModal(e_1)) {
                        setSearchParams(values);
                        openModal();
                    }
                    else {
                        setCodeIsWrong(true);
                    }
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [submitForm]);
    var handleClarifySubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, submitForm(values)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, !!result];
                case 2:
                    e_2 = _a.sent();
                    console.warn(e_2);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [submitForm]);
    var renderTooltip = useCallback(function (meta) {
        if (meta.data.warning && mode === Mode.Checkin) {
            return (React.createElement(Tooltip, { tooltipClassName: css.tooltip, title: t(meta.data.warning), open: true },
                React.createElement("div", { className: css.tooltip__icon }, InfoQuestion)));
        }
        if (isMobile || hideSecretHint) {
            return null;
        }
        return (React.createElement(Tooltip, { title: t('You can find security code in your booking confirmation email') },
            React.createElement("div", { className: css.tooltip__icon }, InfoQuestion)));
    }, [mode, isMobile, hideSecretHint]);
    useEffect(function () {
        if (submitWithInitialValues) {
            handleSubmit({ id: initialId, secret: initialSecret }, {});
        }
    }, [submitWithInitialValues]);
    return (React.createElement("div", { className: cn(css.container, className, (_b = {},
            _b[css.container_checkin] = mode === Mode.Checkin,
            _b)) },
        React.createElement("div", { className: css.title }, title !== null && title !== void 0 ? title : t('Retrieve booking')),
        React.createElement(ClarifyDocumentModal, { searchParams: searchParams, onSubmit: handleClarifySubmit, open: isOpen, onClose: close }),
        React.createElement(Form, { onSubmit: handleSubmit }, function (_a) {
            var _b;
            var handleSubmit = _a.handleSubmit, valid = _a.valid;
            return (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "id", initialValue: initialId, parse: ticketNumberFormat, validate: function (value) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                return ticketNumberValidation(value);
                            }
                            if (mode === Mode.Checkout) {
                                if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                    return undefined;
                                }
                                else {
                                    return ticketNumberValidation(value) || validateByOrderNumber(value);
                                }
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: idTitle || t('Booking ID'), variant: inputVariant, TextFieldProps: __assign(__assign({}, input), { helperText: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (meta.error || meta.submitError), error: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (!!meta.error || !!meta.submitError) }) }));
                        } })),
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "secret", initialValue: initialSecret, validate: function (value, allValues, meta) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                return lastNameValidation(value);
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(React.Fragment, null,
                                React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: secretTitle || t('Security code'), variant: inputVariant, TextFieldProps: __assign(__assign({}, input), { helperText: (isBlockedForPayment &&
                                            t('The order is being paid at the moment, please try again later')) ||
                                            (((meta.modified && meta.touched) || meta.submitFailed) &&
                                                (meta.error || meta.submitError)), error: isBlockedForPayment ||
                                            (((meta.modified && meta.touched) || meta.submitFailed) &&
                                                (!!meta.error || !!meta.submitError)) }) }),
                                renderTooltip(meta)));
                        } })),
                codeIsWrong && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                        text: t('Booking not found. Please, check the information entered or use your e-ticket number for identification'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    }, type: "context" })),
                isOnlyAirportCheckin && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                        text: t('Online check-in is not available. Check-in only at the airport'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    }, type: "context" })),
                React.createElement("div", { className: cn(css.form__row, css.form__row_footer) },
                    mode === Mode.Checkin && i18n.exists('RetrieveBooking:checkinHint') && (React.createElement("div", { className: css.checkinHint }, t('RetrieveBooking:checkinHint'))),
                    React.createElement(Button, { className: cn(css.button, (_b = {},
                            _b[css.button_disabled] = !valid,
                            _b)), type: "submit", disabled: !valid, isLoading: isLoading }, t('Open')))));
        })));
};
export default RetrieveBooking;
