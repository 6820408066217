import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { FormSpy, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import LocationAutocomplete from '../../../LocationAutocomplete';
import Value from '../Value/Value';
import Tooltip from '../../../Tooltip';
import { LocationType } from '../../types';
import { useIsMobile } from '../../../utils';
var Location = function (_a) {
    var className = _a.className, inputClassName = _a.inputClassName, placeholder = _a.placeholder, locations = _a.locations, onSelect = _a.onSelect, type = _a.type;
    var theme = useTheme('ScheduleForm').Location;
    var t = useTranslation('ScheduleForm').t;
    var location = locations[type];
    var isMobile = useIsMobile();
    var renderInput = useCallback(function (_a) {
        var input = _a.input;
        return function (isOpen, isLoading, inputProps) {
            var onChange = function (e) {
                var _a;
                input.onChange(e);
                (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
            };
            var onFocus = function (e) {
                var _a;
                input.onFocus(e);
                (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onFocus) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
            };
            var onBlur = function (e) {
                var _a;
                input === null || input === void 0 ? void 0 : input.onBlur(e);
                (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onBlur) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
            };
            var fieldProps = __assign(__assign(__assign({}, input), inputProps), { onChange: onChange, onFocus: onFocus, onBlur: onBlur });
            var fieldClassName = cn(theme.input, inputClassName);
            var fieldPlaceholder = !location ? placeholder : null;
            return (React.createElement(React.Fragment, null,
                isMobile ? (React.createElement("div", { className: fieldClassName, onClick: inputProps.onFocus }, fieldPlaceholder)) : (React.createElement("input", __assign({}, fieldProps, { className: fieldClassName, placeholder: !isOpen ? fieldPlaceholder : null, autoComplete: "off" }))),
                React.createElement(Value, { location: location, value: inputProps.value, placeholder: t(type === LocationType.Departure
                        ? 'From, enter city or country'
                        : 'To, enter city or country'), isOpen: isOpen && !isMobile, isLoading: isLoading })));
        };
    }, [className, location, isMobile]);
    var onSelectHandler = useCallback(function (location) { return onSelect(location, type); }, [onSelect, type]);
    var validate = useCallback(function (value, allValues, meta) {
        var data = meta.data;
        if (!data || !data[type]) {
            return t('Please select {{type}} airport', {
                type: t(type)
            });
        }
        if (data && data.arrival && data.departure && data.arrival.iata === data.departure.iata) {
            return t('Please select different airports');
        }
    }, [type]);
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, function (formState) { return (React.createElement(Field, { name: type, validate: validate, subscription: {
            data: true,
            error: true
        }, render: function (field) {
            if (location !== null || typeof field.meta.data[type] !== 'undefined') {
                formState.form.mutators.setField(field.input.name, locations);
            }
            return (React.createElement(Tooltip, { title: field.meta.error, open: !!field.meta.error && formState.submitFailed },
                React.createElement(LocationAutocomplete, { className: className, type: type, locations: locations, onSelect: onSelectHandler, inputRenderer: renderInput(field) })));
        } })); }));
};
export default Location;
