import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useReadCommunicationMessageQuery } from '@websky/graphql';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NOTIFICATIONS_PER_PAGE } from './constants';
import { CategoryContext } from './components/CommunicationBase';
import { useDispatch } from 'react-redux';
import { decreaseUnreadCountByCategory, increaseUnreadCountByCategory } from './store/actions';
import { ApolloError } from 'apollo-boost';
export var useInfinityScrollPagination = function (refetch, loading) {
    var _a = __read(useState(true), 2), hasMore = _a[0], setHasMore = _a[1];
    var _b = __read(useState([]), 2), items = _b[0], setItems = _b[1];
    var _c = __read(useState(0), 2), pageLoaded = _c[0], setPageLoaded = _c[1];
    var _d = __read(useState(0), 2), total = _d[0], setTotal = _d[1];
    var resetAll = function () {
        setPageLoaded(0);
        setItems([]);
        setHasMore(true);
    };
    var loadMore = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (loading || !hasMore) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, refetch({ offset: items.length, count: NOTIFICATIONS_PER_PAGE })];
                case 1:
                    res = _a.sent();
                    setTotal(res.total);
                    if (res.items.length === 0 || res.total === res.items.length) {
                        setHasMore(false);
                    }
                    if (res.items.length) {
                        setItems(__spreadArray(__spreadArray([], __read(items), false), __read(res.items), false));
                    }
                    setPageLoaded(pageLoaded + 1);
                    return [2 /*return*/];
            }
        });
    }); }, [items]);
    return {
        items: items,
        hasMore: hasMore,
        total: total,
        loadMore: loadMore,
        pageLoaded: pageLoaded,
        resetAll: resetAll
    };
};
export var useCommunicationList = function (sectionType, refetchRequest, loading) {
    var dispatch = useDispatch();
    var appliedReadAll = useContext(CategoryContext).appliedReadAll;
    var readMessage = useReadCommunicationMessageQuery({ skip: true }).refetch;
    var _a = __read(useState(''), 2), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = __read(useState({}), 2), readIds = _b[0], setReadIds = _b[1];
    var refetchWrapper = function (variables) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refetchRequest(variables)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    e_1 = _a.sent();
                    onError(e_1);
                    return [2 /*return*/, { total: 0, items: [] }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onError = function (e) {
        var _a, _b;
        var message = 'Unknown error';
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message)) {
            message = e.graphQLErrors[0].message;
        }
        else if (e instanceof Error && e.message) {
            message = e.message;
        }
        setErrorMessage(message);
    };
    var _c = useInfinityScrollPagination(refetchWrapper, loading), loadMore = _c.loadMore, hasMore = _c.hasMore, items = _c.items, pageLoaded = _c.pageLoaded, total = _c.total;
    var markAsRead = function (messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dispatch(decreaseUnreadCountByCategory(sectionType));
                    setReadIds(__assign(__assign({}, readIds), (_a = {}, _a[messageId] = true, _a)));
                    return [4 /*yield*/, readMessage({
                            messageId: messageId,
                            sectionId: sectionType
                        })];
                case 1:
                    data = (_c.sent()).data;
                    if (!data.PaximizerCommunicationReadMessage) {
                        setReadIds(__assign(__assign({}, readIds), (_b = {}, _b[messageId] = false, _b)));
                        dispatch(increaseUnreadCountByCategory(sectionType));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var communicationsList = useMemo(function () {
        return items.map(function (item) {
            return __assign(__assign({}, item), { read: appliedReadAll ? true : item.read });
        });
    }, [items, appliedReadAll]);
    return {
        readIds: readIds,
        markAsRead: markAsRead,
        items: communicationsList,
        loadMore: loadMore,
        errorMessage: errorMessage,
        pagination: {
            pageLoaded: pageLoaded,
            hasMore: hasMore,
            total: total
        }
    };
};
export var useCommunicationDetail = function (sectionType, data) {
    var dispatch = useDispatch();
    var readMessage = useReadCommunicationMessageQuery({
        variables: {
            messageId: data === null || data === void 0 ? void 0 : data.id,
            sectionId: sectionType
        },
        skip: true
    }).refetch;
    useEffect(function () {
        if (data && !data.read) {
            setReadMessageRequest();
        }
    }, [data === null || data === void 0 ? void 0 : data.read]);
    var setReadMessageRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, readMessage()];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(decreaseUnreadCountByCategory(sectionType));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
