import { getOrder, getSegments } from './order/selectors';
import { getSelectedPassengerIds } from './passengers/selectors';
import { createSelector } from 'reselect';
import { getFlightIdsToExchange } from './flightsToExchange/selectors';
import { ALL_FLIGHT } from './flightsToExchange/reducers';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../../utils';
export var getSelectedPassengers = createSelector([getOrder, getSelectedPassengerIds], function (order, ids) {
    if (order) {
        return order.travellers.filter(function (traveller) { return ids.indexOf(traveller.id) !== -1; });
    }
    return [];
});
export var getFlightsToExchange = createSelector([getSegments, getFlightIdsToExchange], function (segments, ids) {
    var _a;
    if (((_a = ids === null || ids === void 0 ? void 0 : ids[0]) === null || _a === void 0 ? void 0 : _a.key) === ALL_FLIGHT) {
        return {
            flightsByIds: segments,
            flightsToExchange: ids.filter(function (_a) {
                var key = _a.key;
                return key !== 'ALL_FLIGHT';
            })
        };
    }
    return {
        flightsByIds: segments.filter(function (segment) { return ids.find(function (_a) {
            var key = _a.key;
            return key === segment.groupId;
        }); }),
        flightsToExchange: ids
    };
});
export var getIsAllFlights = createSelector(getFlightIdsToExchange, function (ids) {
    var flightIds = ids.map(function (_a) {
        var key = _a.key;
        return key;
    });
    return flightIds.includes(ALL_FLIGHT);
});
export var isNewExchangeSelector = createSelector([getFlightsToExchange, getSelectedPassengerIds], function (flights, passengers) {
    return !!flights.flightsByIds.length && !!passengers.length;
});
export var getNewFlightSearchParams = createSelector([getFlightsToExchange, getSelectedPassengers], function (flightsToExchange, passengers) {
    var newSegmentParams = flightsToExchange.flightsByIds.map(function (flight, key) {
        var _a;
        var date = flight.segments[0].departure.date;
        if ((_a = flightsToExchange.flightsToExchange[key]) === null || _a === void 0 ? void 0 : _a.date) {
            date = format(flightsToExchange.flightsToExchange[key].date, API_DATE_FORMAT);
        }
        return {
            date: date,
            departure: {
                iata: flight.segments[0].departure.airport.iata
            },
            arrival: {
                iata: flight.segments[flight.segments.length - 1].arrival.airport.iata
            }
        };
    });
    var newPassengerParams = [];
    var passengersMap = new Map();
    passengers.forEach(function (passenger) {
        if (passengersMap.has(passenger.type)) {
            passengersMap.set(passenger.type, passengersMap.get(passenger.type) + 1);
        }
        else {
            passengersMap.set(passenger.type, 1);
        }
    });
    passengersMap.forEach(function (count, type) {
        newPassengerParams.push({
            passengerType: type,
            count: count
        });
    });
    var newAviaSearchParams = {
        segments: newSegmentParams,
        passengers: newPassengerParams
    };
    return newAviaSearchParams;
});
