import * as React from 'react';
import { CartService } from '../../Cart/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { groupSeats } from '../../Cart/utils';
import { SeatSelection } from '../../Icons';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getTotalPrice } from '../../utils';
var SeatSummary = function (props) {
    var onDeleteHandler = function (travellerId, serviceId, segmentId) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Seats, {
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            serviceId: serviceId,
            passengerId: travellerId,
            segmentId: segmentId
        });
    };
    var seats = React.useMemo(function () {
        return props.travellers
            .filter(function (traveller) { var _a; return !!((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length); })
            .map(function (traveller) {
            var seats = groupSeats(traveller), options = [];
            Object.values(seats[traveller.id]).forEach(function (seat) {
                if (seat.additional) {
                    seat.additional.forEach(function (seat) {
                        var _a, _b;
                        var segment = props.segmentsById.get((_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id);
                        var price = getTotalPrice(seat.seat.seatServices, function (service) { return service.price; });
                        options.push({
                            id: seat.seat.number,
                            name: "".concat(segment.iataDirection, ", ").concat(seat.seat.number),
                            price: price,
                            included: seat.isConfirmed,
                            status: (_b = seat.product) === null || _b === void 0 ? void 0 : _b.status,
                            onDelete: function () { return onDeleteHandler(traveller.id, seat.seat.number, seat.segment.id); }
                        });
                    });
                }
            });
            return {
                items: options,
                traveller: props.travellersById.get(traveller.id),
                headerType: 'travellerName',
                headerIcon: SeatSelection
            };
        });
    }, props.travellers);
    var content = React.useMemo(function () {
        if (!seats.length) {
            return null;
        }
        return (React.createElement(ServiceSummaryDivider, null, seats.map(function (seat) { return (React.createElement(CartItemOptionAdapter, { option: seat, key: seat.header })); })));
    }, [seats]);
    return props.children(content);
};
export default SeatSummary;
