import { __extends } from "tslib";
import { addMonths, differenceInCalendarMonths, differenceInDays, toDate, parseISO } from 'date-fns';
import * as React from 'react';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { ThemeConsumer } from '../../theme';
import Slider from '../../Slider/components/Slider';
import { RouteType } from '../types';
import Legends from './Legends/Legends';
import { Month } from './Month/Month';
import { safeAddDays, safeStartOfMonth } from '../utils';
import { MonthsList } from './MonthsList/MonthsList';
import { createWithThemeDecorator, format, removeDotsFromDate, initI18n, TABLET_MIN_WIDTH } from '../../utils';
import { Money } from '../../Money';
import { ThemeProvider } from '../../theme';
import Button from '../../Button';
initI18n('PriceGraph');
export var MonthsPrices = React.createContext({
    monthsPricesMap: {},
    prices: {}
});
var SLIDER_STEP = 5;
var PriceGraph = /** @class */ (function (_super) {
    __extends(PriceGraph, _super);
    function PriceGraph(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            openedDate: _this.props.openDate
        };
        _this.monthPricesMap = {};
        _this.onItemCentered = _this.onItemCentered.bind(_this);
        _this.onSelectChange = _this.onSelectChange.bind(_this);
        _this.sliderArrowHandler = _this.sliderArrowHandler.bind(_this);
        _this.mobileArrowHandler = _this.mobileArrowHandler.bind(_this);
        return _this;
    }
    PriceGraph.prototype.getCurrentMonth = function () {
        var date = this.state.openedDate
            ? safeStartOfMonth(this.state.openedDate).toISOString()
            : safeStartOfMonth(this.props.openDate).toISOString();
        if (!this.isMonthExist(parseISO(date))) {
            for (var dateISO in this.monthPricesMap) {
                if (this.monthPricesMap.hasOwnProperty(dateISO)) {
                    return dateISO;
                }
            }
        }
        return date;
    };
    PriceGraph.prototype.onItemCentered = function (startItemIndex, minDate) {
        var day = safeAddDays(minDate, startItemIndex);
        this.setState(function () { return ({
            openedDate: day
        }); });
    };
    PriceGraph.prototype.getCenteredDayIndex = function (minDate) {
        if (this.state.openedDate) {
            return differenceInDays(this.state.openedDate, minDate);
        }
        else if (this.props.backDate) {
            return differenceInDays(this.props.backDate, minDate);
        }
        else if (this.props.outDate) {
            return differenceInDays(this.props.outDate, minDate);
        }
        return differenceInDays(this.props.openDate, minDate);
    };
    PriceGraph.prototype.isMonthExist = function (date) {
        var startOfMonth = safeStartOfMonth(date).toISOString();
        return this.monthPricesMap.hasOwnProperty(startOfMonth);
    };
    PriceGraph.prototype.getContext = function () {
        var _a;
        var months = {};
        for (var date in this.props.prices) {
            if (this.props.prices.hasOwnProperty(date)) {
                var monthDate = safeStartOfMonth(date).toISOString(), price = (_a = this.props.prices[date]) === null || _a === void 0 ? void 0 : _a.price;
                if (price && Object.keys(price).length) {
                    if (months.hasOwnProperty(monthDate)) {
                        if (!months[monthDate].minPrice) {
                            months[monthDate].minPrice = {
                                amount: price.amount,
                                currency: price.currency
                            };
                        }
                        if (!months[monthDate].maxPrice) {
                            months[monthDate].maxPrice = {
                                amount: price.amount,
                                currency: price.currency
                            };
                        }
                        if (months[monthDate].minPrice.amount > price.amount) {
                            months[monthDate].minPrice.amount = price.amount;
                        }
                        if (months[monthDate].maxPrice.amount < price.amount) {
                            months[monthDate].maxPrice.amount = price.amount;
                        }
                    }
                    else {
                        months[monthDate] = {
                            minPrice: {
                                amount: price.amount,
                                currency: price.currency
                            },
                            maxPrice: {
                                amount: price.amount,
                                currency: price.currency
                            }
                        };
                    }
                }
                else if (!months.hasOwnProperty(monthDate)) {
                    months[monthDate] = {
                        minPrice: null,
                        maxPrice: null
                    };
                }
            }
        }
        this.monthPricesMap = months;
        return months;
    };
    PriceGraph.prototype.getMonthsArray = function (monthMap) {
        var monthsArray = [];
        for (var month in monthMap) {
            if (monthMap.hasOwnProperty(month)) {
                monthsArray.push({
                    dateISO: month,
                    minPrice: monthMap[month].minPrice
                        ? {
                            amount: monthMap[month].minPrice.amount,
                            currency: monthMap[month].minPrice.currency
                        }
                        : null
                });
            }
        }
        return monthsArray;
    };
    PriceGraph.prototype.sliderArrowHandler = function (direction, startItemIndex, itemsCount, itemsOutsideBoundary) {
        var _this = this;
        if (itemsOutsideBoundary === 0) {
            var nextMonth_1 = addMonths(parseISO(this.getCurrentMonth()), direction === 'next' ? 1 : -1);
            if (this.isMonthExist(nextMonth_1)) {
                this.setState(function () {
                    _this.props.onMonthChange && _this.props.onMonthChange(nextMonth_1);
                    return {
                        openedDate: toDate(nextMonth_1)
                    };
                });
            }
            else {
                // get prices for previous month only after click on prev button
                this.setState(function () {
                    _this.props.onPricesEnd(addMonths(nextMonth_1, -11), direction);
                    _this.props.onMonthChange && _this.props.onMonthChange(nextMonth_1);
                    return {
                        openedDate: toDate(nextMonth_1)
                    };
                });
            }
        }
    };
    PriceGraph.prototype.mobileArrowHandler = function (direction, startItemIndex, minDate) {
        var date = safeAddDays(minDate, startItemIndex);
        this.setState({
            openedDate: toDate(date)
        });
    };
    PriceGraph.prototype.onSelectChange = function (value) {
        var _this = this;
        this.setState(function () {
            _this.props.onMonthChange && _this.props.onMonthChange(parseISO(value));
            return {
                openedDate: parseISO(value)
            };
        });
    };
    PriceGraph.prototype.isLongPrice = function (money) {
        var _a, _b;
        return ((_b = (_a = money === null || money === void 0 ? void 0 : money.amount) === null || _a === void 0 ? void 0 : _a.toString(10)) === null || _b === void 0 ? void 0 : _b.length) > 7;
    };
    PriceGraph.prototype.isNextMonthExists = function () {
        var nextMonth = addMonths(parseISO(this.getCurrentMonth()), 1);
        var isNextMonthExists = this.isMonthExist(nextMonth);
        if (!isNextMonthExists && this.props.onPricesEnd) {
            this.props.onPricesEnd(parseISO(this.getCurrentMonth()), 'next');
        }
        return isNextMonthExists;
    };
    PriceGraph.prototype.isPrevMonthExists = function () {
        return differenceInCalendarMonths(new Date(), parseISO(this.getCurrentMonth())) !== 0;
    };
    PriceGraph.prototype.render = function () {
        var _a, _b, _c, _d;
        var _this = this;
        var _e;
        var t = this.props.t;
        var _f = this.props.theme.PriceGraph, sliderTheme = _f.SliderStyles, theme = _f.PriceGraphStyles;
        var context = {
            monthsPricesMap: this.getContext(),
            prices: this.props.prices
        };
        var currentMonth = this.getCurrentMonth(), maxPriceOfMonth = context.monthsPricesMap[currentMonth].maxPrice
            ? context.monthsPricesMap[currentMonth].maxPrice
            : { amount: 0, currency: null }, minPriceOfMonth = context.monthsPricesMap[currentMonth].minPrice
            ? context.monthsPricesMap[currentMonth].minPrice
            : { amount: 0, currency: null }, monthsArray = this.getMonthsArray(context.monthsPricesMap), minDate = safeStartOfMonth(monthsArray[0].dateISO);
        var centeredItem = 0;
        if (this.props.openDate) {
            centeredItem = differenceInDays(this.state.openedDate ? this.state.openedDate : this.props.openDate, minDate);
        }
        var selectedPrice = this.props.prices[this.state.openedDate.toISOString()];
        var onSelect = function () {
            _this.props.onSelect(_this.state.openedDate, _this.props.routeType === RouteType.Return);
        };
        return (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.routeType }, t('Price for 1 direction')),
                React.createElement(MonthsList, { months: monthsArray, selectedMonth: this.getCurrentMonth(), onChange: this.onSelectChange })),
            React.createElement(MediaQuery, { maxWidth: +TABLET_MIN_WIDTH + 1 },
                React.createElement("div", { className: classnames(theme.graphHeader, (_a = {},
                        _a[theme.graphHeader_longPrice] = this.isLongPrice(maxPriceOfMonth),
                        _a)) },
                    React.createElement("div", { className: theme.graphHeader__dates },
                        React.createElement("div", { className: classnames(theme.graphHeader__outDate, (_b = {},
                                _b[theme.graphHeader__date_notActive] = this.props.routeType === RouteType.Return,
                                _b)) },
                            !this.props.outDate &&
                                removeDotsFromDate(format(toDate(this.state.openedDate), 'd MMM, EEEEEE')),
                            this.props.outDate &&
                                removeDotsFromDate(format(toDate(this.props.routeType === RouteType.Return
                                    ? this.props.outDate
                                    : this.state.openedDate), 'd MMM, EEEEEE'))),
                        this.props.outDate && (React.createElement("div", { className: classnames(theme.graphHeader__backDate, (_c = {},
                                _c[theme.graphHeader__date_notActive] = this.props.routeType === RouteType.Outbound,
                                _c)) }, this.props.backDate &&
                            removeDotsFromDate(format(toDate(this.props.routeType === RouteType.Outbound
                                ? this.props.backDate
                                : this.state.openedDate), 'd MMM, EEEEEE')))),
                        React.createElement("div", { className: theme.graphHeader__priceWrapper },
                            t('from'),
                            ((_e = selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.price) === null || _e === void 0 ? void 0 : _e.amount) && (React.createElement(Money, { moneyClassName: theme.graphHeader__money, money: selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.price })))))),
            React.createElement("div", { className: classnames(theme.calendar, (_d = {},
                    _d[theme.calendar_longPrice] = this.isLongPrice(maxPriceOfMonth),
                    _d)) },
                React.createElement("div", { className: theme.daysWrapper },
                    React.createElement(Legends, { maxPrice: maxPriceOfMonth, minPrice: minPriceOfMonth }, function (ref) { return (React.createElement(ThemeProvider, { value: { Slider: { SliderTheme: sliderTheme } } },
                        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH + 1, values: window['testMediaQueryValues'] },
                            React.createElement(Slider, { offset: SLIDER_STEP, innerRef: ref, wrapperClassName: theme.days, centeredItem: centeredItem, onRangeChange: _this.sliderArrowHandler, hideDisabledLeftArrow: !_this.isPrevMonthExists(), hideDisabledRightArrow: !_this.isNextMonthExists() },
                                React.createElement(MonthsPrices.Provider, { value: context },
                                    React.createElement(Month, { initialDate: parseISO(_this.getCurrentMonth()), routeType: _this.props.routeType ? _this.props.routeType : RouteType.Outbound, onSelect: _this.props.onSelect, backDate: _this.props.backDate, outDate: _this.props.outDate })))),
                        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                            React.createElement(Slider, { offset: SLIDER_STEP, innerRef: ref, wrapperClassName: theme.days, leftStartItem: _this.getCenteredDayIndex(minDate), onItemCentered: function (startItemIndex) {
                                    return _this.onItemCentered(startItemIndex, minDate);
                                }, onRangeChange: function (direction, startItemIndex) {
                                    return _this.mobileArrowHandler(direction, startItemIndex, minDate);
                                }, touchEvents: true, touchTransition: "by-items", minItemWidth: 17 },
                                React.createElement(MonthsPrices.Provider, { value: context }, monthsArray.map(function (date, index) {
                                    return (React.createElement(Month, { key: index, initialDate: parseISO(date.dateISO), routeType: _this.props.routeType
                                            ? _this.props.routeType
                                            : RouteType.Outbound, onSelect: _this.props.onSelect, backDate: _this.props.backDate, outDate: _this.props.outDate, focusedDate: _this.state.openedDate.toISOString(), isMobileMode: true }));
                                })))))); }))),
            selectedPrice && (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement("div", { className: theme.footer },
                    React.createElement(Button, { className: theme.button, onClick: onSelect }, t('Show flights')))))));
    };
    return PriceGraph;
}(React.Component));
export default createWithThemeDecorator(ThemeConsumer)(withTranslation('PriceGraph')(PriceGraph));
