import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import RetrieveBooking from '@websky/core/src/RetrieveBooking/components/RetrieveBooking';
import { useConfig } from '@websky/core/src/context';
var CheckoutRetrieveBooking = function (props) {
    var t = useTranslation('RetrieveBooking').t;
    var _a = useConfig().global.regex, PASSENGER_LAST_NAME_LATIN_REGEX = _a.PASSENGER_LAST_NAME_LATIN_REGEX, PNR_OR_TICKET_NUMBER_REGEX = _a.PNR_OR_TICKET_NUMBER_REGEX;
    var secretValidation = function (value) {
        if (!PASSENGER_LAST_NAME_LATIN_REGEX.test(value)) {
            return t('Enter latin symbols');
        }
        return undefined;
    };
    var bookingIdValidation = function (value) {
        if (!PNR_OR_TICKET_NUMBER_REGEX.test(value)) {
            return t('Enter 6 latin symbols');
        }
        return undefined;
    };
    var ticketNumberFormat = function (value) {
        return value === null || value === void 0 ? void 0 : value.split(' ').join('').slice(0, 6).toUpperCase();
    };
    return (React.createElement(RetrieveBooking, __assign({}, props, { secretValidation: secretValidation, bookingIdValidation: bookingIdValidation, ticketNumberFormat: ticketNumberFormat })));
};
export default CheckoutRetrieveBooking;
