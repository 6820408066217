import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Modal from '../../../../../Modal';
import Button from '../../../../../Button/Button';
import { useTheme } from '../../../../../theme';
import { useIsMobile } from '../../../../../utils';
var successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "18", cy: "18", r: "18", fill: "#5FCA43" }),
    React.createElement("path", { d: "M11.1738 18.5147L15.5796 23.25L25.3488 12.75", stroke: "white", strokeWidth: "2.1", strokeLinecap: "round" })));
var Dialog = function (props) {
    var _a;
    var _b = __read(React.useState(props.open), 2), isOpen = _b[0], setOpen = _b[1];
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var isMobile = useIsMobile();
    var open = function () { return setOpen(true); };
    var close = function () { return setOpen(false); };
    var onAccept = function () {
        var accept = props.onAccept();
        if (typeof accept === 'object' && typeof accept['then'] === 'function') {
            setLoading(true);
            accept.then(close);
        }
        else {
            close();
        }
    };
    var onCancel = function () {
        props.onCancel();
        close();
    };
    var theme = useTheme('Exchange').Dialog;
    return (React.createElement(React.Fragment, null,
        props.children &&
            props.children({
                open: open,
                close: close,
                isOpen: isOpen
            }),
        React.createElement(Modal, { maxWidth: 'sm', open: isOpen, onClose: close, classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, closeClassName: theme.modalClose, slideUp: isMobile },
            React.createElement("div", { className: cn(theme.icon, props.iconClassName) }, (_a = props.icon) !== null && _a !== void 0 ? _a : successIcon),
            React.createElement("div", { className: theme.content }, props.text),
            React.createElement("div", { className: theme.controls },
                props.cancelText && !isLoading && (React.createElement(Button, { onClick: onCancel, className: cn(theme.button, theme.cancel) }, props.cancelText)),
                React.createElement(Button, { onClick: onAccept, className: theme.button, isLoading: isLoading }, props.acceptText)))));
};
export default Dialog;
