import { __assign, __read, __spreadArray } from "tslib";
import { addDays, format, getDate, getDaysInMonth, parse, parseISO } from 'date-fns';
import { PassengerType } from '../enums';
import { Locale } from '../types';
import { getDateWithoutTimezone } from '../Engine/components/FastSearch/utils';
import { getCurrency } from '../cache';
import { getLocale } from '../utils';
import { AviaPassengerType } from '@websky/graphql';
export var SPECIAL_CATEGORIES = [
    PassengerType.Teenager_GCF,
    PassengerType.YoungAdult,
    PassengerType.RetireeF,
    PassengerType.RetireeM,
    PassengerType.Teenager,
    PassengerType.Teenager_F7,
    PassengerType.Retiree_F7
];
export var setFieldDataMutator = function (args, state) {
    var _a = __read(args, 2), name = _a[0], data = _a[1];
    var field = state.fields[name];
    if (field) {
        field.data = __assign(__assign({}, field.data), data);
    }
};
export var convertPassengersSearchParameters = function (passengers, passengerConfig) {
    var result = {};
    passengers.forEach(function (passenger) {
        if (passenger.extendedPassengerType) {
            if (passengerConfig && !passengerConfig.hasOwnProperty(passenger.extendedPassengerType)) {
                return;
            }
            result[passenger.extendedPassengerType] = passenger.count;
            if (passenger.count > 0 &&
                !passengers.find(function (otherPassenger) {
                    return otherPassenger.passengerType === passenger.passengerType &&
                        !otherPassenger.extendedPassengerType;
                })) {
                result[passenger.passengerType] = 0;
            }
        }
        else {
            result[passenger.passengerType] = passenger.count;
        }
    });
    return result;
};
export var convertSegmentsSearchParameters = function (segments) {
    var simpleRouteSegment = {
        departure: null,
        arrival: null,
        date: null,
        dateBack: null
    };
    var isRT = segments.length === 2 &&
        segments[0].arrival.iata === segments[1].departure.iata &&
        segments[0].departure.iata === segments[1].arrival.iata;
    var isMultiCity = segments.length > 1 && !isRT;
    if (!isMultiCity) {
        simpleRouteSegment.departure = segments[0].departure;
        simpleRouteSegment.arrival = segments[0].arrival;
        simpleRouteSegment.date = getDateWithoutTimezone(new Date(segments[0].date));
        simpleRouteSegment.dateBack = null;
        if (isRT) {
            simpleRouteSegment.dateBack = getDateWithoutTimezone(new Date(segments[1].date));
        }
    }
    return {
        isMultiCity: isMultiCity,
        simpleRouteSegment: simpleRouteSegment,
        multiCitySegments: isMultiCity
            ? segments.map(function (segment) { return ({
                departure: segment.departure,
                arrival: segment.arrival,
                date: getDateWithoutTimezone(new Date(segment.date)),
                dateBack: null
            }); })
            : []
    };
};
export var createSearchParameters = function (passengersState, segmentsState, passengersConfig, promoCode, redeemMiles) {
    var passengers = [];
    for (var passengerType in passengersState) {
        if (passengersState.hasOwnProperty(passengerType) && passengersState[passengerType]) {
            var config = passengersConfig[passengerType];
            passengers.push({
                passengerType: (config === null || config === void 0 ? void 0 : config.parentCode)
                    ? config.parentCode
                    : passengerType,
                extendedPassengerType: (config === null || config === void 0 ? void 0 : config.parentCode) ? passengerType : null,
                count: passengersState[passengerType]
            });
        }
    }
    return {
        promotionCode: promoCode,
        ffpMode: redeemMiles,
        currency: getCurrency(),
        segments: (segmentsState.isMultiCity
            ? segmentsState.multiCitySegments.sort(function (a, b) {
                return a.date && b.date ? a.date.getTime() - b.date.getTime() : 0;
            })
            : segmentsState.simpleRouteSegment.dateBack
                ? [
                    {
                        departure: segmentsState.simpleRouteSegment.departure,
                        arrival: segmentsState.simpleRouteSegment.arrival,
                        date: segmentsState.simpleRouteSegment.date
                    },
                    {
                        departure: segmentsState.simpleRouteSegment.arrival,
                        arrival: segmentsState.simpleRouteSegment.departure,
                        date: segmentsState.simpleRouteSegment.dateBack
                    }
                ]
                : [segmentsState.simpleRouteSegment]).map(function (segment) {
            var date;
            try {
                date = format(segment.date, 'yyyy-MM-dd');
            }
            catch (e) { }
            return {
                date: date !== null && date !== void 0 ? date : null,
                departure: {
                    iata: segment.departure ? segment.departure.iata : ''
                },
                arrival: {
                    iata: segment.arrival ? segment.arrival.iata : ''
                }
            };
        }),
        passengers: passengers
    };
};
export var daysLeftBeforeMonthEnd = function (from) {
    return getDaysInMonth(from) - getDate(from) + 1;
};
export var getMinPricesRequestVariables = function (locations, passengers, date, replaceLocations) {
    if (replaceLocations === void 0) { replaceLocations = false; }
    var segments;
    var passengerTypesToSend = [
        AviaPassengerType.ADT,
        AviaPassengerType.CLD,
        AviaPassengerType.INF,
        AviaPassengerType.INS
    ];
    if (replaceLocations) {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.arrival && locations.arrival.iata },
            arrival: { iata: locations.departure && locations.departure.iata }
        };
    }
    else {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.departure && locations.departure.iata },
            arrival: { iata: locations.arrival && locations.arrival.iata }
        };
    }
    return {
        passengers: passengers.filter(function (passenger) { return passengerTypesToSend.includes(passenger.passengerType); }),
        daysCount: 365,
        segments: [segments]
    };
};
export var getPrices = function (flightsMinPricesResponse) {
    var pricesObject = {};
    if (flightsMinPricesResponse && flightsMinPricesResponse.FlightsMinPricesInPeriod) {
        flightsMinPricesResponse.FlightsMinPricesInPeriod.datesWithLowestPrices.forEach(function (minPrice) {
            var _a;
            if (minPrice) {
                pricesObject[parse(minPrice.date, 'yyyy-MM-dd', new Date()).toISOString()] = {
                    price: __assign({}, minPrice.price),
                    hasFlight: !!((_a = minPrice === null || minPrice === void 0 ? void 0 : minPrice.info) === null || _a === void 0 ? void 0 : _a[0])
                };
            }
        });
    }
    return pricesObject;
};
export var createFastSearchURL = function (parameters) {
    var result = '';
    parameters.segments.forEach(function (segment) {
        result += "".concat(segment.departure.iata).concat(segment.arrival.iata).concat(format(parseISO(segment.date), 'yyyyMMdd'));
    });
    parameters.passengers.forEach(function (_a) {
        var passengerType = _a.passengerType, extendedPassengerType = _a.extendedPassengerType, count = _a.count;
        if (count) {
            result += "".concat(extendedPassengerType ? extendedPassengerType : passengerType).concat(count);
        }
    });
    if (parameters.promotionCode) {
        result += "PROMO".concat(parameters.promotionCode);
    }
    if (parameters.ffpMode) {
        result += 'PAYBYMILES';
    }
    if (parameters.currency) {
        result += parameters.currency;
    }
    return result;
};
var OSTROVOK_MAX_ADULTS = 6;
var OSTROVOK_MAX_CHILDS = 4;
var getChildGuests = function (passengers) {
    return passengers.reduce(function (ages, passenger) {
        switch (passenger.passengerType) {
            case AviaPassengerType.INF:
            case AviaPassengerType.INS:
                return __spreadArray(__spreadArray([], __read(ages), false), __read(new Array(passenger.count).fill(2)), false);
            case AviaPassengerType.CLD:
                return __spreadArray(__spreadArray([], __read(ages), false), __read(new Array(passenger.count).fill(12)), false);
        }
        return ages;
    }, []);
};
var ostrovokSupplierLink = function (data) {
    var url = new URL(data.baseUrl);
    var formatDate = function (date) {
        return format(date, 'dd.MM.yyyy');
    };
    var childGuests = getChildGuests(data.passengers), adultsCount = data.passengersCount - childGuests.length;
    var guestsParam = '';
    if (adultsCount > 0 && adultsCount <= OSTROVOK_MAX_ADULTS && childGuests.length <= OSTROVOK_MAX_CHILDS) {
        guestsParam = adultsCount.toString();
        if (childGuests.length > 0) {
            guestsParam += "and".concat(childGuests.join('.'));
        }
    }
    url.searchParams.set('q', data.destination.codes[0].code.toString());
    url.searchParams.set('dates', [formatDate(data.startDate), formatDate(data.endDate)].join('-'));
    if (!!guestsParam) {
        url.searchParams.set('guests', guestsParam);
    }
    url.searchParams.set('lang', getOstrovokISOLocale(data.iataCode));
    return url.toString();
};
export var oneTwoTripSupplierLink = function (data, additionalId) {
    var apiURl = new URL('https://www.onetwotrip.com/_hotels/api/comparer');
    var formatDate = function (date) {
        return format(date, 'yyyy-MM-dd');
    };
    var childs = getChildGuests(data.passengers);
    apiURl.searchParams.set('iata', data.destination.iata);
    apiURl.searchParams.set('date_start', formatDate(data.startDate));
    apiURl.searchParams.set('date_end', formatDate(data.endDate));
    apiURl.searchParams.set('adults', (data.passengersCount - childs.length).toString());
    if (!!childs.length) {
        apiURl.searchParams.set('children', getChildGuests(data.passengers).join(','));
    }
    return fetch(apiURl.toString())
        .then(function (response) {
        return response.json();
    })
        .then(function (data) {
        var _a;
        if (data.result.options[0].url) {
            return (_a = data.result.options[0].url.match(/(\/search\/.+)/)) === null || _a === void 0 ? void 0 : _a[0];
        }
        throw 'Incorrect response';
    })
        .then(function (parsedUrl) {
        var url = new URL(data.baseUrl);
        url.searchParams.set(additionalId, parsedUrl);
        return url.toString();
    })
        .catch(function () {
        return new URL(data.baseUrl).toString();
    });
};
export var generateOfferHotelLink = function (baseUrl, segments, passengersCount, passengers, iataCode, supplierType, additionalId) {
    if (supplierType === void 0) { supplierType = 'OSTROVOK'; }
    var hotelsData = {
        baseUrl: baseUrl,
        destination: null,
        startDate: null,
        endDate: null,
        passengersCount: passengersCount,
        passengers: passengers,
        iataCode: iataCode
    };
    try {
        var lastSegment = segments[segments.length - 1];
        hotelsData.destination = lastSegment.arrival;
        hotelsData.startDate = lastSegment.date;
        hotelsData.endDate = lastSegment.dateBack;
        if (!hotelsData.endDate) {
            hotelsData.endDate = addDays(hotelsData.startDate, 1);
        }
        if (supplierType === 'OSTROVOK') {
            return Promise.resolve(ostrovokSupplierLink(hotelsData));
        }
        else if (supplierType === 'ONETWOTRIP') {
            return oneTwoTripSupplierLink(hotelsData, additionalId);
        }
    }
    catch (e) {
        return null;
    }
    return null;
};
export var getOstrovokISOLocale = function (iataCode) {
    var locale = getLocale();
    if (iataCode === 'RM' && locale === Locale.Armenian) {
        return Locale.Russian;
    }
    switch (locale) {
        case Locale.Chinese:
            return 'zh_CN';
        default:
            return locale;
    }
};
