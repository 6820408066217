import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
export var Banner = function (_a) {
    var className = _a.className, code = _a.code, popular = _a.popular;
    var t = useTranslation('InsurancePage').t;
    var theme = useTheme('InsurancePage').Banner;
    return (React.createElement("div", { className: cn(theme.banner, className), "data-insurance-program-code": code }, popular && React.createElement("div", { className: theme.popularBadge }, t('Most popular'))));
};
