import { __rest } from "tslib";
import * as React from 'react';
import { forwardRef } from 'react';
import PhoneInputComponent from 'react-phone-number-input';
var formatPhoneNumberIntl = require('react-phone-number-input').formatPhoneNumberIntl;
var parsePhoneNumberFromString = require('libphonenumber-js').parsePhoneNumberFromString;
import Input from '../Input/Input';
import { useTheme } from '../theme';
import { isPossibleNumber } from 'libphonenumber-js';
import { isRusNumber } from './utils';
var Context = React.createContext({});
var InputWrapper = forwardRef(function (_a, ref) {
    var _b;
    var onFocus = _a.onFocus, onKeyDown = _a.onKeyDown, props = __rest(_a, ["onFocus", "onKeyDown"]);
    var onChange = function (e) {
        var value = e.target.value.replace(/[()\s]/g, '');
        var oldValue = props.value.replace(/[()\s]/g, '');
        var ruCountryCodeAlternatives = ['89', '+89'];
        ruCountryCodeAlternatives.forEach(function (code) {
            if (value.startsWith(code)) {
                value = value.replace(code, '+79');
            }
        });
        if (value.startsWith('+7') &&
            value.length > oldValue.length &&
            !isPossibleNumber(value) &&
            isPossibleNumber(oldValue)) {
            props.onChange(oldValue);
        }
        else {
            props.onChange(value);
        }
    };
    var parsedPhone;
    if (isRusNumber(props.value)) {
        parsedPhone = parsePhoneNumberFromString(props.value, 'RU').formatInternational();
    }
    else {
        parsedPhone = formatPhoneNumberIntl(props.value) || formatPhoneNumberIntl("+".concat(props.value));
    }
    var textFieldsProps = React.useContext(Context);
    var _c = (_b = textFieldsProps === null || textFieldsProps === void 0 ? void 0 : textFieldsProps.InputProps) !== null && _b !== void 0 ? _b : {}, value = _c.value, InputProps = __rest(_c, ["value"]);
    var css = useTheme('PhoneInput').PhoneInput;
    return (React.createElement(Input, { absoluteHintPosition: true, className: css.input, inputClassName: textFieldsProps.className, variant: textFieldsProps.variant, TextFieldProps: {
            onFocus: onFocus,
            onKeyDown: onKeyDown,
            onChange: onChange,
            // Do not spread `context.textFieldsProps` there,
            // or `react-phone-number-input` won't work.
            name: textFieldsProps.name,
            onBlur: textFieldsProps.onBlur,
            label: textFieldsProps.label,
            error: textFieldsProps.error,
            helperText: textFieldsProps.helperText,
            disabled: textFieldsProps.disabled || props.disabled,
            placeholder: textFieldsProps.placeholder,
            type: 'tel',
            InputProps: InputProps
        }, disabled: props.disabled, value: parsedPhone ? parsedPhone : props.value, tabIndex: textFieldsProps.tabIndex, inputMode: "tel", autoComplete: "new-phone", autoFocus: props.autoFocus }));
});
var PhoneInput = function (_a) {
    var className = _a.className, value = _a.value, _b = _a.textFieldsProps, textFieldsProps = _b === void 0 ? {} : _b, _c = _a.displayInitialValueAsLocalNumber, displayInitialValueAsLocalNumber = _c === void 0 ? true : _c, disabled = _a.disabled, autoFocus = _a.autoFocus;
    return (React.createElement(Context.Provider, { value: textFieldsProps },
        React.createElement(PhoneInputComponent, { className: className, onChange: textFieldsProps.onChange, onFocus: textFieldsProps.onFocus, onBlur: textFieldsProps.onBlur, showCountrySelect: false, limitMaxLength: true, displayInitialValueAsLocalNumber: displayInitialValueAsLocalNumber, value: value || '', inputComponent: InputWrapper, disabled: disabled, autoFocus: autoFocus })));
};
export default PhoneInput;
