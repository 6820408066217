import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CheckoutAdditionalService from '../..';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import { getBusinessLounge, getOrder, getSegments, getTravellers } from '../../../Checkout/store/order/selectors';
import { getTravellersMap, useIsMobile } from '../../../utils';
import { useOrderService } from '../hooks';
import { getCalculatedVipServicesPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useRenderers } from '../../../renderProps';
import ServiceError from '../ServiceError/ServiceError';
import { useIsCanModifyServices } from '../../hooks';
import VipServiceSelect from '../../../VipServiceSelect';
import Control from '../../../VipServiceSelect/components/Control/Control';
import Money from '../../../Money';
import { closeRender } from '../Close/Close';
import VipSummary from '../../../ServiceSummary/services/VipSummary';
var Business = function (_a) {
    var _b, _c, _d;
    var services = _a.services;
    var theme = useTheme('CheckoutAdditionalService').Business;
    var t = useTranslation('Checkout').t;
    var order = useSelector(getOrder);
    var travellers = useSelector(getTravellers);
    var segments = useSelector(getSegments);
    var businessServices = useSelector(getBusinessLounge);
    var businessLoungeServices = useMemo(function () {
        if (services === null || services === void 0 ? void 0 : services.length) {
            return services;
        }
        return businessServices;
    }, [services, businessServices]);
    var price = useSelector(getCalculatedVipServicesPrice(businessLoungeServices));
    var _e = useOrderService(businessLoungeServices, OrderAdditionalServiceGdsServiceServiceType.BusinessLounge), selectedServices = _e.selectedServices, unconfirmedServices = _e.unconfirmedServices, renderSubheader = _e.renderSubheader, onOpen = _e.onOpen, onClose = _e.onClose, setOpened = _e.setOpened, onSaveHandler = _e.onSaveHandler, onRemoveHandler = _e.onRemoveHandler, onRemoveCartService = _e.onRemoveCartService, passengers = _e.passengers, opened = _e.opened;
    if (!businessLoungeServices.length) {
        return null;
    }
    var isMobile = useIsMobile();
    var isCanModifyServices = useIsCanModifyServices(businessLoungeServices);
    var title = useMemo(function () {
        if ((services === null || services === void 0 ? void 0 : services.length) === 1) {
            return services[0].name;
        }
        return t('Business lounge');
    }, [services]);
    var additionalServices = useMemo(function () {
        var _a, _b, _c, _d;
        if (!(services === null || services === void 0 ? void 0 : services.length)) {
            return order.additionalServices;
        }
        return __assign(__assign({}, order.additionalServices), { gdsServices: __assign(__assign({}, (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices), { services: (_d = (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (gdsService) {
                    return services.some(function (service) { return service.id === gdsService.id; });
                })) !== null && _d !== void 0 ? _d : [] }) });
    }, [services, order.additionalServices]);
    var buttons = useMemo(function () {
        if (unconfirmedServices.length) {
            return [
                {
                    className: theme.button__remove,
                    action: function () { return onRemoveHandler(unconfirmedServices); },
                    text: t('Remove'),
                    buttonType: 'change',
                    variant: 'outline'
                }
            ];
        }
        return [
            {
                action: function () { return setOpened(true); },
                text: isCanModifyServices ? t('Change') : t('More details'),
                buttonType: 'change'
            }
        ];
    }, [unconfirmedServices]);
    var renderPassenger = useCallback(function (_a) {
        var _b;
        var passenger = _a.passenger, type = _a.type, count = _a.count, isConfirmed = _a.isConfirmed, onSetCount = _a.onSetCount;
        var price = (_b = businessLoungeServices.find(function (service) { return service.id === passenger.serviceId; })) === null || _b === void 0 ? void 0 : _b.price;
        return (React.createElement("div", { className: theme.passenger },
            React.createElement(Control, { type: type, count: count, label: React.createElement("div", { className: theme.label }, passenger.name), disabled: isConfirmed, onSetCount: onSetCount(passenger) }),
            React.createElement("div", { className: theme.price }, count > 0 && React.createElement(Money, { money: price }))));
    }, [businessLoungeServices]);
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_b = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _b !== void 0 ? _b : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(VipSummary, { flight: order.flight, travellers: travellers, services: additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onRemoveCartService, serviceName: title, serviceSubType: (_d = (_c = services === null || services === void 0 ? void 0 : services[0]) === null || _c === void 0 ? void 0 : _c.subType) !== null && _d !== void 0 ? _d : GdsServiceVipSubType.BusinessLounge }, function (content) {
            var isSelected = !!content;
            return (React.createElement(ServiceWrapper, { header: title, description: businessLoungeServices[0].description, className: theme.business, priceFrom: isSelected ? price : null, priceTotal: price, isSelected: isSelected, addClick: onOpen, descriptionClassName: theme.descriptions, buttons: buttons, addButtonText: !isCanModifyServices ? t('More details') : null },
                React.createElement(ServiceError, { isShow: !!unconfirmedServices.length }, content)));
        }),
        React.createElement(Modal, { open: opened, classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, slideUp: isMobile, maxWidth: "sm", closeButtonRenderer: closeRender, onClose: onClose },
            React.createElement(VipServiceSelect, { header: title, headerClassName: theme.header, className: theme.serviceSelect, subheader: renderSubheader(), passengers: passengers, services: businessLoungeServices, segments: segments, selectedServices: selectedServices, type: "checkbox", onSave: onSaveHandler, onClose: onClose, renderPassenger: renderPassenger, readOnly: !isCanModifyServices, showTotalPrice: true, showFromPrice: true }))));
};
export default Business;
