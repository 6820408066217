import { __assign } from "tslib";
import * as React from 'react';
import { Field, useFormState } from 'react-final-form';
import Input from '../../Input';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { InputType } from '../../Passenger/types';
import { validate } from '../../Passenger/utils';
import { Select } from '../../Passenger/components/Field/Select/Select';
import { useMediaQuery } from 'react-responsive';
import DocExpireField from './DocExpireField/DocExpireField';
import { DocTypes } from '@websky/graphql';
var FormFields;
(function (FormFields) {
    FormFields["Type"] = "docType";
    FormFields["Number"] = "docNumber";
    FormFields["Expiry"] = "docExpiryDate";
})(FormFields || (FormFields = {}));
initI18n('DocumentCardForm');
initI18n('Passenger');
var removeIcon = (React.createElement("svg", { width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "30", height: "30", rx: "15", fill: "#F6F6F6" }),
    React.createElement("path", { d: "M20.25 10.8075L19.1925 9.75L15 13.9425L10.8075 9.75L9.75 10.8075L13.9425 15L9.75 19.1925L10.8075 20.25L15 16.0575L19.1925 20.25L20.25 19.1925L16.0575 15L20.25 10.8075Z", fill: "#808080" })));
var DocumentForm = function (props) {
    var css = useTheme('DocumentForm').DocumentForm, t = useTranslation('DocumentCardForm').t, tDocuments = useTranslation('Passenger').t, isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var formState = useFormState();
    var onRemoveHandler = function () {
        props.onRemove(props.id);
    };
    var docTypeValidate = function (value) {
        if (!value) {
            return 'Required';
        }
        return undefined;
    };
    var docNumberValidate = function (value, allValues) {
        var _a;
        var error = undefined;
        var validation = (_a = props.validators) === null || _a === void 0 ? void 0 : _a.values.find(function (validation) { return validation.name === 'docNumber'; });
        error = validate(value, __assign(__assign({}, allValues['documents'][props.id]), { nationality: props.nationality }), InputType.Text, validation.validationRules);
        return error;
    };
    var docExpireValidate = function (value, allValues) {
        var _a;
        var error = undefined;
        var validation = (_a = props.validators) === null || _a === void 0 ? void 0 : _a.values.find(function (validation) { return validation.name === 'docExpiryDate'; });
        error = validate(value, __assign(__assign({}, allValues['documents'][props.id]), { nationality: props.nationality }), InputType.Date, validation.validationRules);
        return error;
    };
    var documentOptions = React.useMemo(function () {
        if (!props.validators) {
            return [];
        }
        var docType = props.validators.values.find(function (value) { return value.name === 'docType'; }), documentsForNationality = docType.validationRules.find(function (rule) {
            var _a;
            return (_a = rule.with) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === 'nationality' && item.value === props.nationality; });
        });
        if (documentsForNationality) {
            return documentsForNationality.options.map(function (item) { return item.value; });
        }
        else {
            return docType.validationRules.find(function (rule) { return !rule.with; }).options.map(function (item) { return item.value; });
        }
    }, [props.validators, props.nationality]);
    var selectedDocType = React.useMemo(function () { var _a, _b; return (_b = (_a = formState.values['documents']) === null || _a === void 0 ? void 0 : _a[props.id]) === null || _b === void 0 ? void 0 : _b['docType']; }, [
        formState.values,
        props.id
    ]);
    var isDocExpireRequire = React.useMemo(function () {
        var _a, _b;
        var docExpireValidations = (_a = props.validators.values.find(function (value) { return value.name === 'docExpiryDate'; })) === null || _a === void 0 ? void 0 : _a.validationRules, docTypeRule = docExpireValidations.find(function (validation) { var _a; return (_a = validation.with) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === 'docType' && item.value === selectedDocType; }); });
        return (_b = docTypeRule === null || docTypeRule === void 0 ? void 0 : docTypeRule.required) !== null && _b !== void 0 ? _b : false;
    }, [props.validators, formState.values, selectedDocType]);
    var isBirthRegDocument = selectedDocType === DocTypes.BirthRegDocument;
    React.useEffect(function () { }, [isDocExpireRequire]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.form },
            React.createElement(Field, { name: "documents[".concat(props.id, "].").concat(FormFields.Type), validate: docTypeValidate, render: function (_a) {
                    var input = _a.input, meta = _a.meta;
                    return (React.createElement(Select, { options: documentOptions.map(function (document) { return ({
                            key: document,
                            value: tDocuments(document)
                        }); }), name: input.name, value: input.value, label: t('Document type'), onChange: function (key, e) {
                            input.onChange(key);
                        }, onBlur: function (event) { return input.onChange(event); }, error: meta.touched && tDocuments(meta.error), variant: isMobile ? 'standard' : 'filled' }));
                } }),
            React.createElement(Field, { name: "documents[".concat(props.id, "].").concat(FormFields.Number), validate: docNumberValidate, render: function (_a) {
                    var input = _a.input, meta = _a.meta;
                    return (React.createElement(Input, __assign({}, input, { className: css.form__item, TextFieldProps: {
                            label: t('Document number'),
                            onChange: function (event) {
                                if (isBirthRegDocument) {
                                    event.target.value = event.target.value.toUpperCase();
                                }
                                input.onChange(event);
                            },
                            onBlur: function (event) {
                                input.onBlur(event);
                            },
                            helperText: (meta.modified || meta.submitFailed) && tDocuments(meta.error),
                            error: meta.error && (meta.modified || meta.submitFailed),
                            variant: isMobile ? 'standard' : 'filled'
                        }, variant: isMobile ? 'standard' : 'filled' })));
                } }),
            React.createElement(DocExpireField, { name: "documents[".concat(props.id, "].").concat(FormFields.Expiry), validate: docExpireValidate, required: isDocExpireRequire, label: t('Expiry date'), isMobile: isMobile }),
            React.createElement("div", { className: css.remove, onClick: onRemoveHandler }, isMobile ? t('Remove') : removeIcon))));
};
export default DocumentForm;
