import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../../utils';
import { Checkbox } from '@material-ui/core';
import { useMemo } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '../../../../Tooltip';
import { OrderStatus } from '@websky/graphql';
var SOGAZ_INSURANCE_CODES = ['gen_trans_sogaz_avia_rw'];
initI18n('Consents');
var Consents = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var order = _a.order, checkboxConsentsState = _a.checkboxConsentsState, setCheckboxConsentsState = _a.setCheckboxConsentsState, tooltipConsentsState = _a.tooltipConsentsState, formRef = _a.formRef, setConfirmTooltipChecked = _a.setConfirmTooltipChecked;
    var css = useTheme('Checkout').Consents;
    var t = useTranslation('Consents').t;
    var services = order.priceToPay;
    var insurancesProductsLinks = useMemo(function () {
        var _a, _b, _c;
        if ((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length) {
            var codes_1 = new Set();
            var links_1 = [];
            order.additionalServices.insurances.selectedInsurances.forEach(function (insurance) {
                var _a;
                if (!codes_1.has(insurance.insuranceProgram.code)) {
                    codes_1.add(insurance.insuranceProgram.code);
                    var insuranceName = t('agreement_additionalInsuranceName', {
                        name: insurance.insuranceProgram.name
                    });
                    var formatInsurance = t('agreement_additionalInsuranceRules', {
                        link: "<a href='".concat((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.urlToOffer, "' target=\"_blank\">").concat(insuranceName, "</a>")
                    });
                    links_1.push("<span>".concat(formatInsurance, "</span>"));
                }
            });
            return links_1.join('');
        }
        return '';
    }, [(_c = (_b = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _b === void 0 ? void 0 : _b.insurances) === null || _c === void 0 ? void 0 : _c.selectedInsurances]);
    var isAlphaInsuranceSelected = (_e = (_d = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _d === void 0 ? void 0 : _d.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances.some(function (insurance) { var _a; return !SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); }), isSogazInsuranceSelected = (_g = (_f = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _f === void 0 ? void 0 : _f.insurances) === null || _g === void 0 ? void 0 : _g.selectedInsurances.some(function (insurance) { var _a; return SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); });
    var isNeedToShowInsurancesRules = ((_h = services.insurance) === null || _h === void 0 ? void 0 : _h.amount) > 0;
    var isNeedToShowGdsRules = [
        services === null || services === void 0 ? void 0 : services.vip,
        services === null || services === void 0 ? void 0 : services.businessLounge,
        services === null || services === void 0 ? void 0 : services.seats,
        services === null || services === void 0 ? void 0 : services.meals,
        services === null || services === void 0 ? void 0 : services.baggage
    ].some(function (price) { return (price === null || price === void 0 ? void 0 : price.amount) > 0; });
    var isNeedToShowGeneralRules = ((_j = services.total) === null || _j === void 0 ? void 0 : _j.amount) > 0;
    var interpolatedRulesLink = t('agreement_interpolation', {
        generalRules: isNeedToShowGeneralRules ? t('agreement_generalRules') : '',
        insurancesRules: isNeedToShowInsurancesRules
            ? t('agreement_insurancesRules', {
                insurancesProductsLinks: insurancesProductsLinks,
                // will be ignored unless custom translate contains aliases below (WZ theme)
                alphaGeneralRules: isAlphaInsuranceSelected ? t('agreement_insurancesRules_alpha') : '',
                sogazGeneralRules: isSogazInsuranceSelected ? t('agreement_insurancesRules_sogaz') : ''
            })
            : '',
        additionalServiceRules: isNeedToShowGdsRules ? t('agreement_gdsRules') : ''
    });
    var resultRulesString = interpolatedRulesLink
        ? t('agreement_rules', {
            links: interpolatedRulesLink
        })
        : '';
    var rulesVisible = !!resultRulesString && order.status !== OrderStatus.Cancelled;
    React.useEffect(function () {
        if (!rulesVisible) {
            setCheckboxConsentsState(true);
        }
        else {
            setCheckboxConsentsState(false);
        }
    }, [rulesVisible]);
    return rulesVisible ? (React.createElement("div", { className: css.consents__wrapper },
        React.createElement(FormControlLabel, { classes: {
                label: css.label
            }, checked: checkboxConsentsState, ref: formRef, control: React.createElement(Tooltip, { title: t('Checkout:Please, read the rules of service and accept it'), open: tooltipConsentsState, classes: { popper: css.popper } },
                React.createElement(Checkbox, { classes: {
                        colorPrimary: css.checkbox,
                        checked: css.checkbox_checked
                    }, onClick: function () {
                        setCheckboxConsentsState(!checkboxConsentsState);
                        setConfirmTooltipChecked(checkboxConsentsState);
                    }, color: "primary" })), label: React.createElement("div", { className: css.consents__text, dangerouslySetInnerHTML: {
                    __html: resultRulesString
                } }) }))) : null;
};
export default Consents;
