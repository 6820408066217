import { useMemo } from 'react';
var getPercent = function (total, value) {
    return (total / value) * 100;
};
export var useLoyaltyInfo = function (ffpInfo) {
    var nextLevel = useMemo(function () {
        var availableStatusIndex = ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.availableLevels.indexOf(ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevel);
        if (availableStatusIndex < (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.availableLevels.length) - 1) {
            return ffpInfo.availableLevels[availableStatusIndex + 1];
        }
        return null;
    }, [ffpInfo]);
    var milesPercentage = 100;
    if ((ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.numberOfMiles) < (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevelMaxMiles)) {
        milesPercentage = getPercent(ffpInfo.numberOfMiles, ffpInfo.currentLevelMaxMiles);
    }
    var faresPercentage = 0;
    if ((ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.segmentsForNextLevel) < (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevelMaxSegments)) {
        faresPercentage = getPercent(ffpInfo.segmentsForNextLevel, ffpInfo.currentLevelMaxSegments);
    }
    return {
        nextLevel: nextLevel,
        milesPercentage: milesPercentage,
        faresPercentage: faresPercentage
    };
};
