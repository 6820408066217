import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import Passengers from '../Passengers/Passengers';
import StepsButtons from '../StepsButtons/StepsButtons';
import { useTheme } from '../../../theme';
import MobileStep from '../MobileStep/MobileStep';
import { Person } from '../../../Icons';
import { useCallback } from 'react';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { getPassengers } from '../../store/passengers/selectors';
import { StepType } from '../../types';
import { togglePassenger } from '../../store/passengers/actions';
import { useSteps } from '../Refund/hooks';
import cn from 'classnames';
import { getAviaOrderTravellers } from '../../store/aviaOrder/selectors';
var PassengersPage = function (props) {
    var _a;
    var css = useTheme('Refund').Refund;
    var t = useTranslation('Refund').t;
    var dispatch = useDispatch();
    var passengers = useSelector(getPassengers);
    var aviaOrderTravellers = useSelector(getAviaOrderTravellers);
    var _b = useSteps(), currentStep = _b.currentStep, canGoToNextStep = _b.canGoToNextStep, goToNextStep = _b.goToNextStep, isCurrentActiveStepAfter = _b.isCurrentActiveStepAfter, handleMobileCloseClick = _b.handleMobileCloseClick;
    var handlePassengerSelect = useCallback(function (_a) {
        var id = _a.id;
        return dispatch(togglePassenger(id));
    }, [passengers]);
    var isPassengersStepActive = currentStep.type === StepType.Passengers;
    var isPassengersStepPassed = isCurrentActiveStepAfter(StepType.Passengers);
    var renderMobileTitle = useCallback(function () { return (React.createElement(React.Fragment, null,
        !!passengers.length ? React.createElement(React.Fragment, null,
            t('For'),
            "\u00A0") : React.createElement(React.Fragment, null, t('Select passengers')),
        passengers.map(function (passenger) { return passenger.name; }).join(', '))); }, [passengers]);
    var renderContent = useCallback(function () {
        return isPassengersStepActive && (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(StepTitle, { className: css.stepTitle, icon: Person, title: t('Select passengers') })),
            React.createElement(Passengers, { passengers: passengers, onPassengerSelect: handlePassengerSelect, travellersCount: aviaOrderTravellers.length }),
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(StepsButtons, { className: css.buttons, position: 'center', nextButton: {
                        children: t('Continue'),
                        disabled: !canGoToNextStep,
                        onClick: goToNextStep
                    } }))));
    }, [props]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderContent()),
        React.createElement(MobileStep, { isActive: isPassengersStepActive, renderTitle: renderMobileTitle, icon: Person, onCloseClick: handleMobileCloseClick(StepType.Passengers), className: cn(css.mobileStep, (_a = {},
                _a[css.passed] = isPassengersStepPassed,
                _a)) }, renderContent())));
};
export default PassengersPage;
