export var isOrderServicesResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.SaveOrderServices) !== undefined;
};
export var isCheckinOrderServicesResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.CheckinSaveOrderServices) !== undefined;
};
export var isOrderResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.Order) !== undefined;
};
export var isCheckinOrderResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.CheckinOrder) !== undefined;
};
