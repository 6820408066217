import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, WarningRounded } from '../../../../index';
import { LoginType } from '@websky/graphql';
import { ModalTypesEnum } from '../AuthMethod/AuthMethod';
import { Statuses } from '../../../types';
import { Field, Form } from 'react-final-form';
import { useCustomerContactsValidation } from '../../../../CustomerContactsForm/hooks';
import { initI18n, useIsMobile } from '../../../../utils';
import Modal from '../../../../Modal';
import PhoneInput from '../../../../PhoneInput';
import Input from '../../../../Input';
import Timer from '../../../../Timer';
initI18n('Account');
var AuthDialog = function (props) {
    var css = useTheme('Account').ConfirmDialog, t = useTranslation('Account').t;
    var _a = __read(React.useState(''), 2), activeDescription = _a[0], setActiveDescription = _a[1];
    var _b = props.errors, error = _b.error, verificationError = _b.verificationError;
    var _c = useCustomerContactsValidation(), testEmail = _c.testEmail, testPhone = _c.testPhone, testFFP = _c.testFFP;
    var isMobile = useIsMobile();
    var onAvailableSubmit = props.status >= Statuses.Verification ? props.onSubmit : props.onContinue;
    var loadingStatus = props.status === Statuses.VerificationLoading || props.status === Statuses.Loading;
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var isLoading = loadingStatus || loading;
    var onDelete = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, props.onDelete()];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [props.onDelete]);
    var onValidate = React.useMemo(function () {
        return ((props.fieldType === LoginType.Phone && testPhone) ||
            (props.fieldType === LoginType.Email && testEmail) ||
            (props.fieldType === LoginType.FFP && testFFP));
    }, [props.fieldType]);
    var textFieldPropsFactory = function (renderProps) {
        return {
            label: props.fieldType !== LoginType.FFP ? t(props.fieldType) : t('Loyalty card number'),
            error: (renderProps.meta.touched && renderProps.meta.error) || error,
            helperText: (renderProps.meta.touched && renderProps.meta.error) || t(error === null || error === void 0 ? void 0 : error.message),
            onChange: function (event) {
                renderProps.input.onChange(event);
                props.setError(null);
                props.onChange(event.target.value);
            },
            onFocus: function (event) {
                renderProps.input.onFocus(event);
                if (props.type === ModalTypesEnum.Change && renderProps.input.value === props.fieldValue) {
                    event.target.select();
                }
            },
            onBlur: function (event) {
                if (event.relatedTarget.tagName !== 'BUTTON') {
                    renderProps.input.onBlur(event);
                    if (!!renderProps.input.value.length) {
                        onValidate(renderProps.input.value);
                    }
                }
            }
        };
    };
    React.useEffect(function () {
        if (props.type === ModalTypesEnum.Remove) {
            switch (props.fieldType) {
                case LoginType.Email:
                    setActiveDescription(t("Email will be removed from your login methods", { email: props.fieldValue }));
                    break;
                case LoginType.Phone:
                    setActiveDescription(t("Phone will be removed from your login methods", { phone: props.fieldValue }));
                    break;
                case LoginType.FFP:
                    setActiveDescription(t("FFP will be removed from your login methods", { ffp: props.fieldValue }));
                    break;
                default:
                    setActiveDescription(t('method will be removed from your login methods', { method: props.fieldType }));
            }
        }
        else {
            setActiveDescription(t("A confirmation code will be sent to your ".concat(props.fieldType === LoginType.Phone ? 'phone' : 'address')));
        }
    }, [props.fieldType, props.type]);
    return (React.createElement(Modal, { maxWidth: "xs", bottomDrawer: isMobile, classes: { paper: css.paper }, open: props.isOpen, onClose: props.onClose, closeClassName: css.closeIcon },
        props.type === ModalTypesEnum.Remove && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title },
                React.createElement("span", { className: css.title__icon }, WarningRounded),
                t('Login method removal')),
            React.createElement("div", { className: css.description }, activeDescription),
            React.createElement("div", { className: css.buttons },
                React.createElement(Button, { className: css.button_normal, variant: "flat", onClick: onDelete, isLoading: isLoading }, t('Remove')),
                !isLoading && (React.createElement(Button, { className: css.button_accent, variant: "flat", onClick: props.onClose }, t('Cancel')))))),
        (props.type === ModalTypesEnum.Connect || props.type === ModalTypesEnum.Change) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title }, props.type === ModalTypesEnum.Connect ? t('Add a new login method') : t('Change login method')),
            React.createElement(Form, { onSubmit: onAvailableSubmit, render: function (_a) {
                    var _b;
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        props.status >= Statuses.Verification ? (React.createElement(Field, { name: "confirmationCode", type: "text", render: function (_a) {
                                var input = _a.input;
                                return (React.createElement("div", { className: css.input_wrapper },
                                    React.createElement(Input, __assign({}, input, { TextFieldProps: {
                                            label: props.fieldType !== LoginType.FFP
                                                ? t('Confirmation code')
                                                : t('Password'),
                                            helperText: verificationError &&
                                                t(verificationError.message.replace('Network error: ', '')),
                                            error: props.status === Statuses.VerificationError ||
                                                !!verificationError,
                                            onChange: function (event) {
                                                input.onChange(event);
                                                props.setVerificationError(null);
                                                props.setStatus(Statuses.Verification);
                                            }
                                        } })),
                                    props.fieldType !== LoginType.FFP && (React.createElement(Timer, { classes: {
                                            timer: css.resend_timer,
                                            link: css.resend_timer__link
                                        }, resendCode: props.resendCode }))));
                            } })) : (React.createElement(Field, { name: "authField", initialValue: props.fieldValue, validate: onValidate, render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement("div", { className: css.input_wrapper },
                                    props.fieldType === LoginType.Phone && (React.createElement(PhoneInput, __assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, textFieldsProps: __assign(__assign({}, textFieldPropsFactory({ input: input, meta: meta })), { onChange: function (event) {
                                                input.onChange(event);
                                                props.setError(null);
                                                if (typeof event === 'string') {
                                                    props.onChange(event);
                                                }
                                            } }) }))),
                                    props.fieldType === LoginType.Email && (React.createElement(Input, __assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, TextFieldProps: __assign({}, textFieldPropsFactory({ input: input, meta: meta })) }))),
                                    props.fieldType === LoginType.FFP && (React.createElement(Input, __assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, TextFieldProps: __assign({}, textFieldPropsFactory({ input: input, meta: meta })) }))),
                                    props.fieldType !== LoginType.FFP && (React.createElement("div", { className: css.description }, activeDescription))));
                            } })),
                        React.createElement("div", { className: css.buttons },
                            React.createElement(Button, { className: css.button_normal, variant: "flat", onClick: props.onClose }, t('Cancel')),
                            React.createElement(Button, { className: cn(css.button_accent, (_b = {},
                                    _b[css.button_disabled] = !props.isSubmitAvailable,
                                    _b[css.button_loader] = isLoading,
                                    _b)), variant: "flat", type: "submit", disabled: !props.isSubmitAvailable, isLoading: isLoading }, props.status >= Statuses.Verification ? t('Add') : t('Continue')))));
                } })))));
};
export default AuthDialog;
