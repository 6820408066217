export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["Secondary"] = "secondary";
    ButtonVariant["Action"] = "action";
    ButtonVariant["SmallSecondary"] = "smallSecondary";
    ButtonVariant["SmallPrimary"] = "smallPrimary";
    ButtonVariant["SmallNeitral"] = "smallNeitral";
    ButtonVariant["OptionEdit"] = "optionEdit";
    ButtonVariant["OptionAction"] = "optionAction";
    ButtonVariant["OptionActionPlusIcon"] = "optionActionPlusIcon";
})(ButtonVariant || (ButtonVariant = {}));
export var CloseButtonVariant;
(function (CloseButtonVariant) {
    CloseButtonVariant["CloseFullscreen"] = "closeFullscreen";
    CloseButtonVariant["ClosePopup"] = "closePopup";
})(CloseButtonVariant || (CloseButtonVariant = {}));
