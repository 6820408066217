import { __assign } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../SeatMap/components/Component';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { connect, useSelector } from 'react-redux';
import { getPassengerLabels, getSelectedExtraSeats } from '../../../store/passengers/selectors';
import { getTotalPrice } from '../../../store/priceBreakdown/selectors';
import { getOrderId, getSeatMap, getSegmentsForServices, getShowSeatRecommendations, getTravellers, getBonusForFlight, getIsAllSegmentsAvailableOnlyOnCheckin, getPriceInMiles, getIsNew } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getSeatsPriceToPay } from '../../../store/orderPrice/selectors';
import { setMultipleSelectedServices } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { getSeatReduxCallbacks } from '../../../utils';
import { get, SEATS_RECOMMENDATION_SHOWN } from '../../../../cache';
import { useConfig } from '../../../../context';
import BookingSeatsWrapper from '../BookingSeatsWrapper/BookingSeatsWrapper';
var Seats = function (_a) {
    var totalPrice = _a.totalPrice, passengersState = _a.passengersState, setNextStep = _a.goToNextStep, goToPrevStep = _a.goToPrevStep, fillOrder = _a.fillOrder, suggestSeatSelection = _a.suggestSeatSelection, seatsTotalPrice = _a.seatsTotalPrice, setMultipleSelectedServices = _a.setMultipleSelectedServices;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var segments = useSelector(getSegmentsForServices);
    var selectedExtraSeatsServices = useSelector(getSelectedExtraSeats);
    var showRecommendations = useSelector(getShowSeatRecommendations);
    var orderId = useSelector(getOrderId);
    var isNewOrder = useSelector(getIsNew);
    var seatMap = useSelector(getSeatMap);
    var travellers = useSelector(getTravellers);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var isAllSeatsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    var showRecommendedSeats = useConfig().global.seatMap.showRecommendedSeats;
    var seatMapCallbacks = useMemo(function () { return getSeatReduxCallbacks(passengersState, setMultipleSelectedServices); }, [
        passengersState,
        setMultipleSelectedServices
    ]);
    var seatsRecommendationShown = get("".concat(SEATS_RECOMMENDATION_SHOWN, "_").concat(orderId));
    var confirmRecommendedSeatsWithoutDialog = !seatsRecommendationShown && !isAllSeatsAvailableOnCheckin && !showRecommendedSeats;
    return (React.createElement(BookingSeatsWrapper, { passengers: passengersState, goToNextStep: setNextStep }, function (_a) {
        var goToNext = _a.goToNext;
        return (React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice: totalPrice,
                onBackButtonClick: goToPrevStep,
                onButtonClick: suggestSeatSelection ? goToNext : setNextStep,
                additional: seatsTotalPrice.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                    t('Seats'),
                    ": ",
                    React.createElement(Money, { money: seatsTotalPrice }))) : null,
                miles: bonusForFlight,
                priceInMiles: priceInMiles
            }, mobileToolbarProps: {
                onBack: goToPrevStep,
                onContinue: suggestSeatSelection ? goToNext : setNextStep
            } },
            React.createElement(SeatMap, __assign({ confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog, orderId: orderId, seatMap: seatMap, passengers: passengersState, travellers: travellers, segments: segments, fillOrder: fillOrder, showRecommendations: !isAllSeatsAvailableOnCheckin && showRecommendations, onProceed: suggestSeatSelection ? goToNext : setNextStep, selectedExtraSeats: selectedExtraSeatsServices, isNewOrder: isNewOrder }, seatMapCallbacks))));
    }));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPrice(state), passengerLabels: getPassengerLabels(state), seatsTotalSumFromOrder: getSeatsPriceToPay(state), seatsTotalPrice: getSeatsPriceToPay(state) })); };
};
var mapDispatchToProps = {
    fillOrder: fillOrder,
    setMultipleSelectedServices: setMultipleSelectedServices
};
export default connect(mapStateToProps, mapDispatchToProps)(Seats);
