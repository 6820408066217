import { __awaiter, __generator, __read } from "tslib";
import { useEffect } from 'react';
import { LoginType, OauthServiceType, useAuthenticateMutation } from '@websky/graphql';
import { useHistory, useLocation } from 'react-router-dom';
import { prevSocialAuthKeyFactory, tinkoffCallbackUrlSuffix } from './utils';
import { get } from '../../cache';
export var useTinkoffCallback = function (redirectTo, onSuccessFullAuth) {
    var location = useLocation();
    var _a = __read(useAuthenticateMutation(), 2), authenticate = _a[0], loading = _a[1].loading;
    var history = useHistory();
    var auth = function (state, code) { return __awaiter(void 0, void 0, void 0, function () {
        var response, prevKey, prevUrl, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    loginType: LoginType.TinkoffID,
                                    secureCode: JSON.stringify({ state: state, code: code })
                                }
                            }
                        })];
                case 1:
                    response = _d.sent();
                    if ((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.Authenticate) === null || _c === void 0 ? void 0 : _c.token) {
                        prevKey = prevSocialAuthKeyFactory(OauthServiceType.TinkoffID);
                        prevUrl = get(prevKey);
                        if (prevUrl) {
                            window.location.href = prevUrl;
                        }
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _d.sent();
                    history.push(redirectTo);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (location.pathname.includes(tinkoffCallbackUrlSuffix)) {
            var searchParams = new URL(window.location.href).searchParams;
            var code = searchParams.get('code');
            var state = searchParams.get('state');
            if (code && state) {
                auth(state, code).then(onSuccessFullAuth);
            }
        }
    }, []);
    return {
        isLoading: loading
    };
};
