import * as React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Inner from './Inner';
import { useGraphQLClient } from '../../graphql-client';
import Analytics from './Analytics';
import ExternalLocaleResources from '../../ExternalLocaleResources/ExternalLocaleResources';
import IsDemo from './IsDemo';
import AccessDeniedError from './AccessDeniedError';
var Engine = function (_a) {
    var before = _a.before, after = _a.after;
    var client = useGraphQLClient();
    return (React.createElement(Router, null,
        React.createElement(Analytics, null),
        React.createElement(IsDemo, null),
        React.createElement(AccessDeniedError, null),
        React.createElement(ApolloProvider, { client: client },
            React.createElement(ExternalLocaleResources, null),
            before,
            React.createElement(Inner, null),
            after)));
};
export default Engine;
