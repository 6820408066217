import { OauthServiceType } from '@websky/graphql';
import { SOCIAL_AUTH_REDIRECT_URL } from '../../cache';
export var tinkoffCallbackUrlSuffix = '/tinkoff/callback';
export var getBackRedirectUrl = function (serviceType) {
    switch (serviceType) {
        case OauthServiceType.TinkoffID:
            return "".concat(window.location.href).concat(tinkoffCallbackUrlSuffix);
        default:
            return window.location.href;
    }
};
export var prevSocialAuthKeyFactory = function (serviceType) {
    return "".concat(SOCIAL_AUTH_REDIRECT_URL, "_").concat(serviceType);
};
