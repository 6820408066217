import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { useConfig } from '../../../context';
import { useToggleable } from '../../../hooks';
import { getMinPrice, getTotalPrice, getUserValue } from '../../../utils';
var isCheckoutPassengerGuard = function (passenger) {
    return !passenger.services;
};
export var useInsurance = function (_a) {
    var _b;
    var orderId = _a.orderId, insurances = _a.insurances, insurancePrograms = _a.insurancePrograms, passengers = _a.passengers, onSaveServices = _a.onSaveServices, onUpdateOrder = _a.onUpdateOrder;
    var t = useTranslation('Checkout').t;
    var _c = useConfig().global.companyInfo, multiInsurance = _c.multiInsurance, insuranceRulesUrl = _c.insurance, insuranceDocuments = _c.specialDocumentForInsurance;
    var _d = __read(React.useState(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = __read(React.useState(null), 2), error = _e[0], setError = _e[1];
    var _f = useToggleable(false), insuranceModalIsOpen = _f.isOpen, openInsuranceModal = _f.open, closeInsuranceModal = _f.close;
    var _g = useToggleable(false), downloadModalIsOpen = _g.isOpen, openDownloadModal = _g.open, closeDownloadModal = _g.close;
    var _h = useToggleable(false), confirmationModalIsOpen = _h.isOpen, openConfirmationModal = _h.open, closeConfirmationModal = _h.close;
    var initialState = passengers.map(function (passenger) {
        var _a, _b, _c, _d, _e, _f, _g;
        var insurancePassenger = (_a = insurances === null || insurances === void 0 ? void 0 : insurances.travellerExtraValues) === null || _a === void 0 ? void 0 : _a.find(function (traveller) { return traveller.traveller.id === passenger.id; });
        var firstName = isCheckoutPassengerGuard(passenger)
            ? getUserValue(passenger, TravellerFieldEnum.FirstName)
            : '';
        var lastName = isCheckoutPassengerGuard(passenger)
            ? getUserValue(passenger, TravellerFieldEnum.LastName)
            : '';
        return {
            name: "".concat(lastName, " ").concat(firstName),
            value: ((_c = (_b = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value) || '',
            id: passenger.id,
            regex: (_e = (_d = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.validationRules[0].regExp[0],
            isRequired: insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.needKzIinDocument,
            disabled: !!((_g = (_f = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.value)
        };
    });
    var _j = __read(React.useState(initialState), 2), passengerDocuments = _j[0], setPassengerDocuments = _j[1];
    var availablePrograms = new Set((_b = insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.map(function (program) { return program.code; })) !== null && _b !== void 0 ? _b : []);
    var selectedInsurances = React.useMemo(function () {
        var _a;
        var selectedInsuranceMap = new Map();
        if ((_a = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
            insurances.selectedInsurances.forEach(function (insurance) {
                var insuranceCode = insurance.insuranceProgram.code;
                var key = "".concat(insuranceCode, "-").concat(insurance.passengerName);
                // exclude duplicate selected insurances
                if (availablePrograms.has(insuranceCode) && !selectedInsuranceMap.has(key)) {
                    selectedInsuranceMap.set(key, insurance);
                }
            });
        }
        return __spreadArray([], __read(selectedInsuranceMap.values()), false);
    }, [insurances, availablePrograms]);
    var onSave = function (selectedCodes, passengersWithDoc) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedInsuranceCodes, selectedProgramCodes, programsByPassengers, data, e_1, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    selectedInsuranceCodes = insurances.selectedInsurances
                        .filter(function (insurance) { return !availablePrograms.has(insurance.insuranceProgram.code); })
                        .map(function (insurance) { return insurance.insuranceProgram.code; });
                    selectedProgramCodes = __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(selectedInsuranceCodes), false), __read(selectedCodes), false))), false);
                    programsByPassengers = passengers.map(function (passenger) {
                        var travellerDocumentNumber = passengersWithDoc
                            ? passengersWithDoc.find(function (traveller) { return traveller.id === passenger.id; }).value
                            : '';
                        return {
                            travellerId: passenger.id,
                            programCodes: selectedProgramCodes,
                            travellerDocumentNumber: travellerDocumentNumber
                        };
                    });
                    setPassengerDocuments(passengersWithDoc);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onSaveServices({
                            variables: {
                                params: {
                                    id: orderId,
                                    insurances: [
                                        __assign({ selectedPrograms: selectedProgramCodes }, (multiInsurance ? { selectedProgramsByTravellers: programsByPassengers } : {}))
                                    ]
                                }
                            }
                        })];
                case 2:
                    data = (_b.sent()).data;
                    if (data) {
                        onUpdateOrder(data);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    error_1 = e_1;
                    if ((_a = error_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                        setError(error_1.graphQLErrors[0]);
                    }
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    closeInsuranceModal();
                    return [2 /*return*/];
            }
        });
    }); };
    var onAddInsurance = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!multiInsurance) return [3 /*break*/, 1];
                    openInsuranceModal();
                    return [3 /*break*/, 4];
                case 1:
                    if (!selectedInsurances.length) return [3 /*break*/, 2];
                    openConfirmationModal();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onSave([insurancePrograms[0].code])];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onRemoveInsurance = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSave([])];
                case 1:
                    _a.sent();
                    closeConfirmationModal();
                    return [2 /*return*/];
            }
        });
    }); };
    var clearError = function () {
        setError(null);
    };
    var buttons = React.useMemo(function () {
        var buttons = [
            {
                action: onAddInsurance,
                text: t(multiInsurance ? 'Change' : 'Cancel'),
                isLoading: isLoading,
                buttonType: 'change'
            }
        ];
        if (selectedInsurances.some(function (insurance) { return insurance.policyUrl; }) && !isLoading) {
            buttons = __spreadArray(__spreadArray([], __read(buttons), false), [
                {
                    action: function () {
                        if (selectedInsurances.length > 1) {
                            openDownloadModal();
                        }
                        else {
                            window.open(selectedInsurances[0].policyUrl, '_blank');
                        }
                    },
                    text: t('Download')
                }
            ], false);
        }
        if (insuranceRulesUrl && !isLoading) {
            buttons = __spreadArray(__spreadArray([], __read(buttons), false), [
                {
                    action: function () { return window.open(insuranceRulesUrl); },
                    text: t('AdditionalServiceCard:Terms of service')
                }
            ], false);
        }
        return buttons;
    }, [selectedInsurances, isLoading]);
    var minPrice = React.useMemo(function () {
        if (insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length) {
            return getMinPrice(insurancePrograms, function (program) { return program.totalPrice; });
        }
        return null;
    }, insurancePrograms);
    var totalPrice = React.useMemo(function () {
        return getTotalPrice(selectedInsurances, function (insurance) { return insurance.passengerPrice; });
    }, [selectedInsurances]);
    return {
        // common
        multiInsurance: multiInsurance,
        insuranceDocuments: insuranceDocuments,
        insuranceRulesUrl: insuranceRulesUrl,
        passengerDocuments: passengerDocuments,
        selectedInsurances: selectedInsurances,
        buttons: buttons,
        minPrice: minPrice,
        totalPrice: totalPrice,
        error: error,
        onSave: onSave,
        onAddInsurance: onAddInsurance,
        onRemoveInsurance: onRemoveInsurance,
        clearError: clearError,
        // insurance modal
        insuranceModalIsOpen: insuranceModalIsOpen,
        openInsuranceModal: openInsuranceModal,
        closeInsuranceModal: closeInsuranceModal,
        // confirmation modal
        confirmationModalIsOpen: confirmationModalIsOpen,
        openConfirmationModal: openConfirmationModal,
        closeConfirmationModal: closeConfirmationModal,
        // download modal
        downloadModalIsOpen: downloadModalIsOpen,
        openDownloadModal: openDownloadModal,
        closeDownloadModal: closeDownloadModal
    };
};
