import * as React from 'react';
import { useTheme } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { getBaggageDelivery, getIsBooked, getOrder, getSegments } from '../../../Checkout/store/order/selectors';
import { getCalculatedBaggageDeliveryPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTranslation } from 'react-i18next';
import CheckoutAdditionalService from '../..';
import { useOrderService } from '../hooks';
import VipServiceSelect from '../../../VipServiceSelect';
import Modal from '../../../Modal';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { Money } from '../../../Money';
import { useMemo } from 'react';
import ServiceError from '../ServiceError/ServiceError';
import UpsaleServicesModal from '../../../UpsaleServicesModal/UpsaleServicesModal';
import { addServiceAction } from '../../../Checkout/store/selectedServices/actions';
import { sessionStorageGet, sessionStorageSet } from '../../../cache';
import { useIsCanModifyServices } from '../../hooks';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
var BaggageDelivery = function () {
    var _a, _b;
    var theme = useTheme('CheckoutAdditionalService').BaggageDelivery;
    var segments = useSelector(getSegments);
    var baggageDelivery = useSelector(getBaggageDelivery);
    var price = useSelector(getCalculatedBaggageDeliveryPrice);
    var minPrice = React.useMemo(function () {
        var _a;
        return baggageDelivery.reduce(function (minAmount, service) {
            var _a;
            if (((_a = service.price) === null || _a === void 0 ? void 0 : _a.amount) < minAmount.amount) {
                return service.price;
            }
            return minAmount;
        }, (_a = baggageDelivery === null || baggageDelivery === void 0 ? void 0 : baggageDelivery[0]) === null || _a === void 0 ? void 0 : _a.price);
    }, [baggageDelivery]);
    var t = useTranslation('Checkout').t;
    var dispatch = useDispatch();
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isBooked = useSelector(getIsBooked);
    var order = useSelector(getOrder);
    var _c = useOrderService(baggageDelivery, OrderAdditionalServiceGdsServiceServiceType.BusinessLounge), isSelected = _c.isSelected, selectedServices = _c.selectedServices, getMinPrice = _c.getMinPrice, unconfirmedServices = _c.unconfirmedServices, onOpen = _c.onOpen, onClose = _c.onClose, setOpened = _c.setOpened, onSaveHandler = _c.onSaveHandler, onRemoveHandler = _c.onRemoveHandler, passengers = _c.passengers, opened = _c.opened;
    var isCanModifyServices = useIsCanModifyServices(baggageDelivery);
    var buttons = useMemo(function () {
        if (unconfirmedServices.length) {
            return [
                {
                    className: theme.button__remove,
                    action: function () { return onRemoveHandler(unconfirmedServices); },
                    text: t('Remove'),
                    buttonType: 'change',
                    variant: 'outline'
                }
            ];
        }
        return [
            {
                action: function () { return setOpened(true); },
                text: isCanModifyServices ? t('Change') : t('More details')
            }
        ];
    }, [unconfirmedServices]);
    if (!(baggageDelivery === null || baggageDelivery === void 0 ? void 0 : baggageDelivery.length)) {
        return null;
    }
    var upsaleShowedKey = "BAGGAGE_DELIVERY_UPSALE_SHOWED_".concat(order.id);
    var serviceName = (_b = (_a = baggageDelivery[0]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : t('Baggage delivery');
    var isUpsaleShowed = sessionStorageGet(upsaleShowedKey);
    var setUpsaleShowed = function () { return sessionStorageSet(upsaleShowedKey, 'true'); };
    var onBaggageDeliveryAgree = function (payload) {
        if (payload.length) {
            payload.forEach(function (service) { return dispatch(addServiceAction(service)); });
        }
        setUpsaleShowed();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { header: serviceName, description: baggageDelivery[0].description, className: theme.baggageDelivery, priceFrom: minPrice, priceTotal: price, isSelected: isSelected, isExclusive: true, addClick: onOpen, descriptionClassName: theme.descriptions, exclusivePlateClassName: theme.exclusivePlate, buttons: buttons, addButtonText: !isCanModifyServices ? t('More details') : null },
            React.createElement(ServiceError, { isShow: !!unconfirmedServices.length }, baggageDelivery[0].description)),
        isBooked && !isUpsaleShowed && (React.createElement(UpsaleServicesModal, { travellers: order.travellers, additionalServices: order.additionalServices, serviceType: OrderAdditionalServiceGdsServiceServiceType.Vip, descriptionRender: function (upsaleService, description) { return (React.createElement("div", null, t("Checkout:".concat(description !== null && description !== void 0 ? description : 'We recommend using extra baggage'), {
                name: upsaleService === null || upsaleService === void 0 ? void 0 : upsaleService.name
            }))); }, onAgreeServices: onBaggageDeliveryAgree, onRefuseServices: setUpsaleShowed })),
        React.createElement(Modal, { bottomDrawer: isMobile, open: opened, onClose: onClose, maxWidth: "sm", classes: {
                paper: theme.popup
            } },
            React.createElement(VipServiceSelect, { header: serviceName, passengers: passengers, services: baggageDelivery, segments: segments, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: React.createElement("span", null,
                    React.createElement(Money, { money: getMinPrice }),
                    " ",
                    t('per place')), type: "counter", readOnly: !isCanModifyServices }))));
};
export default BaggageDelivery;
