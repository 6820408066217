import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AeroexpressDirection } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getAeroexpressTripLabel } from '../../CheckoutAdditionalService/utils';
var AeroexpressSummary = function (props) {
    var t = useTranslation('Checkout').t;
    var translateDirection = function (selectedTrip) {
        var direction;
        var fromDirection = selectedTrip.fromStation.direction;
        if (fromDirection === AeroexpressDirection.FromAirport) {
            direction = [t("Aeroexpress:".concat(fromDirection)), t("Aeroexpress:".concat(selectedTrip.toStation.name))];
        }
        else if (fromDirection === AeroexpressDirection.ToAirport) {
            direction = [t("Aeroexpress:".concat(selectedTrip.fromStation.name)), t("Aeroexpress:".concat(fromDirection))];
        }
        else {
            direction = [t("Aeroexpress:".concat(selectedTrip.fromStation.name))];
        }
        return direction.join(' – ');
    };
    var onDeleteHandler = function (tripId) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Aeroexpress, { tripId: tripId });
    };
    var items = React.useMemo(function () {
        return props.selectedTrips.map(function (trip) {
            return {
                header: t('All passengers'),
                items: [
                    {
                        id: trip.id,
                        name: "".concat(getAeroexpressTripLabel(trip), " ").concat(translateDirection(trip), ", ").concat(t("Checkout:Class_".concat(trip.class))),
                        price: trip.price,
                        onDelete: function () { return onDeleteHandler(trip.id); }
                    }
                ]
            };
        });
    }, [props.travellers, props.selectedTrips]);
    var content = React.useMemo(function () {
        if (!items.length) {
            return null;
        }
        return (React.createElement(ServiceSummaryDivider, null, items.map(function (item, index) { return (React.createElement(CartItemOptionAdapter, { option: item, key: index })); })));
    }, [items]);
    return props.children(content);
};
export default AeroexpressSummary;
