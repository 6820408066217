import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { getUserValue, MOBILE_MIN_WIDTH, scrollTo } from '../../../../../utils';
import { StepType } from '../../../../types';
import Toolbar from '../../../../../Toolbar';
import { useTheme } from '../../../../../theme';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import MobileToolbar from '../../../../../MobileToolbar';
import { getBonusForFlight, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmedOrCancelled, getIsContactsInfoIsEmpty, getIsNew, getIsTravellersDataFilled, getOrder as getRootOrder, getOrderId, getPaymentIsRequired, getPriceInMiles, getSelectedInsuranceCodes, getServicesFallbackURL, getTotalInsurancePrice } from '../../../../store/order/selectors';
import { Money as MoneyComponent } from '../../../../../index';
import { useUpsaleWarning } from '../../Upsale/hooks';
import Warning from '../../Upsale/Warning/Warning';
import { getTotalPrice, getTotalPriceToPay } from '../../../../store/orderPrice/selectors';
import PassengersToolbarCheckbox from './PassengersToolbarCheckbox';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { fillOrder } from '../../../../store/order/actions';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useUpdateTravellers } from '../../../../../FareLock/hooks';
import { getPassengerState } from '../../../../store/passengers/selectors';
import { useOrderQuery, useSaveOrderServicesMutation } from '@websky/graphql';
import { OverrideComponent } from '../../../../../renderProps';
import { TravellerFieldEnum } from '@websky/graphql';
import { InsuranceUpsaleModal } from '../../../../../InsurancePage';
import { useInsuranceUpsaleModal } from '../../../../../InsurancePage/components/InsuranceUpsaleModal/InsuranceUpsaleModal.hook';
import { saveOrderServicesParamsFactory } from '../../../../../InsurancePage/store/utils';
var PassengersToolbar = function (_a) {
    var formRef = _a.formRef, isNew = _a.isNew, formIsLocked = _a.formIsLocked, isConfirmedOrCancelled = _a.isConfirmedOrCancelled, isBookedOrConfirmed = _a.isBookedOrConfirmed, isBooked = _a.isBooked, totalInsurancePrice = _a.totalInsurancePrice, totalPrice = _a.totalPrice, currentStep = _a.currentStep, setNextStep = _a.setNextStep, history = _a.history, disableBooking = _a.disableBooking, loading = _a.loading, isNeedConfirmConsents = _a.isNeedConfirmConsents, setConfirmTooltipChecked = _a.setConfirmTooltipChecked;
    var t = useTranslation('Checkout').t;
    var checkoutTheme = useTheme('Checkout').CheckoutStyles;
    var totalPriceToPay = useSelector(getTotalPriceToPay);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var _b = useUpsaleWarning(), toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed, showWarning = _b.showWarning, closeWarning = _b.closeWarning;
    var isOrderCancelled = useSelector(getIsCancelled);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMilesForFlight = useSelector(getPriceInMiles);
    var isPaymentRequired = useSelector(getPaymentIsRequired);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var isContactsInfoEmpty = useSelector(getIsContactsInfoIsEmpty);
    var passengersValues = useSelector(getPassengerState);
    var order = useSelector(getRootOrder);
    var orderId = useSelector(getOrderId);
    var selectedInsuranceCodes = useSelector(getSelectedInsuranceCodes);
    var handleSubmit;
    var updateTravellers;
    try {
        updateTravellers = useUpdateTravellers();
        handleSubmit = updateTravellers.handleSubmit;
    }
    catch (e) { }
    var isToolbarButtonDisabled = false;
    if (typeof updateTravellers !== 'undefined' && !updateTravellers.valid) {
        isToolbarButtonDisabled = true;
    }
    if (isTravellersDataFilled) {
        isToolbarButtonDisabled = false;
    }
    var dispatch = useDispatch();
    var getOrder = useOrderQuery({ skip: true }).refetch;
    var _c = __read(useSaveOrderServicesMutation(), 2), saveOrderServices = _c[0], saveOrderServicesResult = _c[1];
    var _d = useCustomerContactsForm(), saveClientInfo = _d.saveClientInfo, closeContactsModal = _d.closeContactsModal, openContactsModal = _d.openContactsModal, isContactsModalOpen = _d.isContactsModalOpen, isClientInfoSaving = _d.loading;
    var insuranceUpsaleModal = useInsuranceUpsaleModal({ strategy: 'checkout-before-payment' });
    var goToPayment = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isToolbarButtonDisabled) {
                        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
                        return [2 /*return*/];
                    }
                    if (isNeedConfirmConsents) {
                        scrollTo(formRef.current.offsetTop);
                        setConfirmTooltipChecked(true);
                        return [2 /*return*/];
                    }
                    if (!handleSubmit) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleSubmit(passengersValues.map(function (traveller) {
                            var _a;
                            return ({
                                id: traveller.id,
                                values: (_a = traveller.values) === null || _a === void 0 ? void 0 : _a.map(function (value) { return ({
                                    type: value.type,
                                    name: value.name,
                                    value: value.value
                                }); })
                            });
                        }))];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (showWarning) {
                        return [2 /*return*/, toggleWarningIfOrderConfirmed()];
                    }
                    if (isPaymentRequired && isContactsInfoEmpty) {
                        openContactsModal();
                        return [2 /*return*/];
                    }
                    setNextStep();
                    return [2 /*return*/];
            }
        });
    }); }, [totalPriceToPay, passengersValues, isNeedConfirmConsents]);
    var saveClientInfoAndUpdateOrder = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var getOrderResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveClientInfo({ variables: __assign(__assign({}, data), { orderId: orderId }) })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getOrder({ id: orderId })];
                case 2:
                    getOrderResult = (_a.sent()).data;
                    if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.Order) {
                        dispatch(fillOrder(getOrderResult.Order));
                    }
                    closeContactsModal();
                    setNextStep();
                    return [2 /*return*/];
            }
        });
    }); };
    var savePriorityInsurance = useCallback(function (selectedCodes) {
        if (selectedCodes === void 0) { selectedCodes = []; }
        return __awaiter(void 0, void 0, void 0, function () {
            var selectedCodesSet, travellerIds, saveOrderParams, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedCodesSet = new Set(__spreadArray(__spreadArray([], __read(selectedInsuranceCodes), false), __read(selectedCodes), false));
                        if (!selectedCodesSet.size) {
                            return [2 /*return*/];
                        }
                        travellerIds = order.travellers.map(function (traveller) { return traveller.id; });
                        saveOrderParams = saveOrderServicesParamsFactory(travellerIds, __spreadArray([], __read(selectedCodesSet), false));
                        return [4 /*yield*/, saveOrderServices({
                                variables: {
                                    params: __assign(__assign({}, saveOrderParams.params), { id: order.id })
                                }
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data === null || data === void 0 ? void 0 : data.SaveOrderServices) {
                            dispatch(fillOrder(data.SaveOrderServices));
                        }
                        return [4 /*yield*/, goToPayment()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }, [order, selectedInsuranceCodes, goToPayment, dispatch]);
    var customerValues = React.useMemo(function () {
        var customer = order.customer;
        return {
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [order.customer]);
    var goBack = useCallback(function () {
        history.goBack();
    }, [history]);
    var showToolbar = !formIsLocked && (currentStep === null || currentStep === void 0 ? void 0 : currentStep.type) !== StepType.Payment && !isOrderCancelled && totalPriceToPay.amount > 0;
    return (React.createElement(React.Fragment, null, showToolbar && (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(InsuranceUpsaleModal, { insurance: insuranceUpsaleModal.priorityInsurance, isOpen: insuranceUpsaleModal.isUpsaleOpen, onAgree: function () { return insuranceUpsaleModal.onAgree(savePriorityInsurance); }, onDisagree: function () { return insuranceUpsaleModal.onDisagree(goToPayment); } }),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Toolbar, { isLoading: (updateTravellers === null || updateTravellers === void 0 ? void 0 : updateTravellers.loading) || loading || saveOrderServicesResult.loading, actionsRight: isBooked, hideNextButton: !totalPriceToPay.amount, totalPrice: totalPriceToPay, priceInMiles: priceInMilesForFlight, onButtonClick: isBookedOrConfirmed ? function () { return insuranceUpsaleModal.onContinue(goToPayment); } : null, miles: !isConfirmedOrCancelled && bonusForFlight, buttonDisabled: disableBooking, additional: React.createElement(React.Fragment, null,
                    isBooked && totalInsurancePrice && totalInsurancePrice.amount && (React.createElement("div", { className: checkoutTheme.gdsTotalPrice },
                        t('Insurance'),
                        ": ",
                        React.createElement(MoneyComponent, { money: totalInsurancePrice }))),
                    React.createElement("div", null,
                        isNew && React.createElement(PassengersToolbarCheckbox, null),
                        React.createElement(OverrideComponent, { componentProps: {
                                order: order
                            }, component: {
                                renderPassengersToolbarAdditional: function () { return null; }
                            } }))) })),
        React.createElement(CustomerContactsModal, { initialValues: customerValues, onContactsSubmit: saveClientInfoAndUpdateOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen, onClose: closeContactsModal }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", null,
                isNew && React.createElement(PassengersToolbarCheckbox, null),
                React.createElement(OverrideComponent, { componentProps: {
                        order: order
                    }, component: {
                        renderPassengersToolbarAdditional: function () { return null; }
                    } })),
            React.createElement(MobileToolbar, { onBack: goBack, disabled: disableBooking || loading || saveOrderServicesResult.loading, onContinue: isBookedOrConfirmed && totalPriceToPay.amount
                    ? function () { return insuranceUpsaleModal.onContinue(goToPayment); }
                    : isBookedOrConfirmed
                        ? undefined
                        : null }))))));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { isNew: getIsNew(state), isBookedOrConfirmed: getIsBookedOrConfirmed(state), isConfirmedOrCancelled: getIsConfirmedOrCancelled(state), isBooked: getIsBooked(state), formIsLocked: isFormLocked(state), totalInsurancePrice: getTotalInsurancePrice(state), totalPrice: getTotalPrice(state) })); };
export default connect(mapStateToProps)(withRouter(PassengersToolbar));
