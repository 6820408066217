import { __read } from "tslib";
import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckoutAdditionalService from '../..';
import { useTheme } from '../../../theme';
import { getMilesForOrder, getIsFFPUsed } from '../../../Checkout/store/order/selectors';
import icons from '../../icons';
import { useConfig } from '../../../context';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import Modal from '../../../Modal';
import { getUserInfo } from '../../../Checkout/utils';
import { getI18nCountVariation } from '../../../utils';
import { useConfigRedirects } from '../../../hooks';
var LoyaltyCard = function (_a) {
    var userInfo = _a.userInfo, refetchUser = _a.refetchUser;
    var t = useTranslation('Checkout').t;
    var css = useTheme('CheckoutAdditionalService').Loyalty;
    var _b = __read(useState(false), 2), isLoyaltyConnect = _b[0], setIsLoyaltyConnect = _b[1];
    var isFFPProvidedToOrder = useSelector(getIsFFPUsed);
    var milesBonus = useSelector(getMilesForOrder);
    var _c = useConfig().global.companyInfo, loyaltyProgramAccountUrl = _c.loyaltyProgramAccountUrl, loyaltyProgramName = _c.loyaltyProgramName;
    var onGoToAccountSettings = useConfigRedirects().onGoToAccountSettings;
    var openLoyaltyConnect = function () { return setIsLoyaltyConnect(true); };
    var closeLoyaltyConnect = function () { return setIsLoyaltyConnect(false); };
    var handleViewStatusClick = useCallback(function () {
        var loyaltyUrl = loyaltyProgramAccountUrl !== null && loyaltyProgramAccountUrl !== void 0 ? loyaltyProgramAccountUrl : '/account/loyalty';
        window.open(loyaltyUrl, '_blank');
    }, [isFFPProvidedToOrder]);
    var user = useMemo(function () { return getUserInfo(userInfo); }, [userInfo]);
    var onLoyaltyCardSaved = function () {
        closeLoyaltyConnect();
        refetchUser === null || refetchUser === void 0 ? void 0 : refetchUser();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { icon: icons.ffpIcon, className: css.loyalty, header: t(loyaltyProgramName), description: t('Start earning miles now to flight cheaper'), addButtonText: t('Register'), isSelected: !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userFfpInfo), addClick: openLoyaltyConnect, buttons: [{ text: t('View status'), action: handleViewStatusClick }] }, milesBonus > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("p", { className: css.loyalty__awardedInfo }, t('{{number}} {{miles}} will be awarded', {
                number: milesBonus,
                miles: t("miles_".concat(getI18nCountVariation(milesBonus)))
            })),
            React.createElement("p", null, t('Miles will be credited to your account after the flight.')))) : (React.createElement("p", { className: css.loyalty__awardedInfo }, t('Miles are not credited for this flight.')))),
        React.createElement(Modal, { classes: { paper: css.loyalty__paper }, maxWidth: "sm", open: isLoyaltyConnect, onClose: closeLoyaltyConnect },
            React.createElement(LoyaltyConnect, { userInfo: user, authType: "authenticate", onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: closeLoyaltyConnect, onGoToSettings: onGoToAccountSettings }))));
};
export default LoyaltyCard;
