import { useCallback, useMemo } from 'react';
/**
 * model for insurance product
 */
export var useInsuranceProduct = function (insuranceProgram, onToggle, isPopular, productState) {
    var handleToggle = useCallback(function () { return onToggle(insuranceProgram.code); }, [
        insuranceProgram,
        productState,
        onToggle
    ]);
    var isAvailable = productState === 'available';
    var isSelected = productState === 'selected';
    var isConfirmed = productState === 'confirmed';
    var showIconButton = useMemo(function () {
        if (!isPopular) {
            return true;
        }
        else if (productState === 'confirmed') {
            return true;
        }
        return false;
    }, [isPopular, productState]);
    return {
        handleToggle: handleToggle,
        showIconButton: showIconButton,
        isAvailable: isAvailable,
        isSelected: isSelected,
        isConfirmed: isConfirmed
    };
};
