import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import cn from 'classnames';
// import Passenger from '../../../../../Passenger/index';
import Passenger from '../../../../../PassengerForm/components/PassengerForm';
import { AdditionalTravellerFieldEnum, InputType } from '../../../../../Passenger/types';
import { useTheme } from '../../../../../theme';
import memes from '../../../../../memes';
import { getPassengerLabels } from '../../../../store/passengers/selectors';
import { getIsNew, getIsReadonly, getServices, getTravellers, getCustomer } from '../../../../store/order/selectors';
import { getPassengerStateCompatible } from '../../../../store/selectedServices/selectors';
import { getCurrency } from '../../../../../cache';
import { OverrideComponent } from '../../../../../renderProps';
import PassengerSubsidy from './PassengerSubsidy/PassengerSubsidy';
import { getAccompanyingFields } from './utils';
import { TravellerFieldEnum } from '@websky/graphql';
import LoyaltyConnect from '../../../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import Modal from '../../../../../Modal';
import { getUserInfo } from '../../../../utils';
import DisabilitiesContextProvider from '../../../../../PassengerForm/components/components/Disabilities/context';
import { useConfigRedirects } from '../../../../../hooks';
export var getInputType = memes(function (type) {
    switch (type) {
        case TravellerFieldEnum.Gender:
            return InputType.Switch;
        case TravellerFieldEnum.Phone:
            return InputType.Phone;
        case TravellerFieldEnum.BirthDate:
        case TravellerFieldEnum.DocExpiryDate:
            return InputType.Date;
        case TravellerFieldEnum.LoyaltyAirline:
        case TravellerFieldEnum.DocType:
            return InputType.Select;
        case TravellerFieldEnum.FirstName:
        case TravellerFieldEnum.LastName:
            return InputType.Autocomplete;
        case TravellerFieldEnum.Nationality:
            return InputType.Nationality;
        default:
            return InputType.Text;
    }
});
var Passengers = function (_a) {
    var _b;
    var className = _a.className, passengersState = _a.passengersState, isReadonly = _a.isReadonly, isNew = _a.isNew, isAuthorized = _a.isAuthorized, fields = _a.fields, services = _a.services, onFill = _a.onFill, labels = _a.labels, isInputsReadonly = _a.isInputsReadonly, inModal = _a.inModal, isChangePassengers = _a.isChangePassengers, orderSubsidies = _a.orderSubsidies, backToResults = _a.backToResults, autofocus = _a.autofocus, showLoginForm = _a.showLoginForm, userInfo = _a.userInfo, refetchUser = _a.refetchUser, isLoginFormOpen = _a.isLoginFormOpen, isChangeFiles = _a.isChangeFiles, selectedProfileIdsByPassenger = _a.selectedProfileIdsByPassenger, onSwap = _a.onSwap, onToggleChangeFiles = _a.onToggleChangeFiles;
    var theme = useTheme('Checkout').PassengersStyles;
    var _c = __read(React.useState(0), 2), openedPassengerFormId = _c[0], setOpenedPassengerFormId = _c[1];
    var _d = __read(React.useState(null), 2), mobileOpenedPassengerId = _d[0], setMobileOpenedPassengerId = _d[1];
    var _e = __read(React.useState(false), 2), loginBeforeCreateLoyaltyAccount = _e[0], setLoginBeforeCreateLoyaltyAccount = _e[1];
    var _f = __read(React.useState(false), 2), loyaltyConnectOpen = _f[0], setLoyaltyConnectOpen = _f[1];
    var onGoToAccountSettings = useConfigRedirects().onGoToAccountSettings;
    var onCorrectFormFilledHandler = function (passengerId) {
        if (passengerId + 1 > openedPassengerFormId) {
            setOpenedPassengerFormId(passengerId + 1);
        }
    };
    var orderTravellers = useSelector(getTravellers);
    var orderCustomer = useSelector(getCustomer);
    var accompanyingFields = useMemo(function () { return getAccompanyingFields(orderCustomer); }, [orderCustomer]);
    var onSignUpLoyaltyProgram = function () {
        if (!isAuthorized) {
            setLoginBeforeCreateLoyaltyAccount(true);
            showLoginForm();
        }
        else {
            setLoyaltyConnectOpen(true);
        }
    };
    React.useEffect(function () {
        if (isAuthorized && loginBeforeCreateLoyaltyAccount && userInfo.isFfpAuthorizationNeeded) {
            setLoyaltyConnectOpen(true);
        }
    }, [loginBeforeCreateLoyaltyAccount, isAuthorized]);
    React.useEffect(function () {
        if (!isLoginFormOpen) {
            setLoginBeforeCreateLoyaltyAccount(false);
        }
    }, [isLoginFormOpen]);
    React.useEffect(function () {
        if (isAuthorized && loginBeforeCreateLoyaltyAccount && userInfo.isFfpAuthorizationNeeded) {
            setLoyaltyConnectOpen(true);
        }
    }, [loginBeforeCreateLoyaltyAccount, isAuthorized]);
    React.useEffect(function () {
        if (!isLoginFormOpen) {
            setLoginBeforeCreateLoyaltyAccount(false);
        }
    }, [isLoginFormOpen]);
    var onLoyaltyCardSaved = function () {
        setLoyaltyConnectOpen(false);
        refetchUser();
    };
    return (React.createElement("div", { className: cn(theme.passengers, (_b = {}, _b[theme.passengers_readonly] = isReadonly, _b)) }, fields.map(function (passenger, passengerId) {
        var _a, _b, _c;
        var firstName, lastName;
        var profileId = selectedProfileIdsByPassenger.get(passengerId);
        var passengerFields = passenger.values.map(function (_a) {
            var name = _a.name, type = _a.type, validationRules = _a.validationRules, value = _a.value, isEditable = _a.isEditable;
            if (name === 'firstName') {
                firstName = value;
            }
            if (name === 'lastName') {
                lastName = value;
            }
            return {
                name: name,
                readonly: !isEditable,
                type: type,
                input: getInputType(type),
                placeholder: name,
                validations: validationRules,
                defaultValue: value
            };
        });
        if (isChangePassengers) {
            passengerFields = __spreadArray(__spreadArray([], __read(passengerFields), false), [
                {
                    name: 'files',
                    readonly: false,
                    type: AdditionalTravellerFieldEnum.ChangeTravellerFile,
                    input: InputType.Text,
                    placeholder: '',
                    validations: [],
                    defaultValue: null
                }
            ], false);
        }
        var passengerLabel = firstName && lastName ? "".concat(lastName, " ").concat(firstName) : labels[passengerId];
        var currentCurrency = (_b = (_a = services.find(function (service) { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency;
        if (!currentCurrency) {
            currentCurrency = getCurrency();
        }
        var servicesPrice = {
            amount: 0,
            currency: currentCurrency
        };
        passengersState[passengerId].services.forEach(function (selectedService) {
            var service = services.find(function (service) { return service.id === selectedService.serviceId; });
            if (service) {
                servicesPrice.amount +=
                    selectedService.count * selectedService.segmentIds.length * service.price.amount;
                servicesPrice.currency = service.price.currency;
            }
        });
        if (passengersState[passengerId].seats) {
            passengersState[passengerId].seats.forEach(function (seat) {
                if (seat.price) {
                    servicesPrice.amount += seat.price.amount;
                    servicesPrice.currency = seat.price.currency;
                }
            });
        }
        var traveller = orderTravellers.find(function (traveller) { return traveller.id === passenger.id; });
        var passAccompamyingFields = passengerId + 1 === orderTravellers.length ? accompanyingFields : [];
        return (React.createElement(DisabilitiesContextProvider, { value: {
                availableDisabilities: passenger.availableDisabilities,
                passengerId: passengerId
            }, key: passenger.id },
            React.createElement(PassengerSubsidy, { passengerId: passenger.id, subsidyInfo: (_c = traveller.subsidyInfo) === null || _c === void 0 ? void 0 : _c.subsidies, fields: traveller.values, orderSubsidyState: orderSubsidies, backToResults: backToResults }, function (props) { return (React.createElement(OverrideComponent, { key: passengerId, componentProps: {
                    className: className,
                    id: passengerId,
                    onFill: function (values, fieldValue) {
                        return onFill(values, passengerId, fieldValue);
                    },
                    fields: passengerFields,
                    passengerType: passenger.type,
                    supplierType: passenger.supplierTravellerType,
                    selectedProfileId: profileId,
                    isReadonly: isReadonly,
                    isAuthorized: isAuthorized,
                    isChangePassengers: isChangePassengers,
                    servicesTotalPrice: servicesPrice,
                    withSubsidies: props.withSubsidy,
                    noSubsidyAvailable: props.noSubsidyAvailable,
                    subsidyRejectMessage: props.subsidyRejectMessage,
                    label: passengerLabel,
                    isInputsReadonly: isInputsReadonly,
                    onCorrectFilled: onCorrectFormFilledHandler,
                    open: !isNew || inModal || passengerId <= openedPassengerFormId,
                    mobileOpenedPassengerId: mobileOpenedPassengerId,
                    onSetMobileOpenedPassengerId: setMobileOpenedPassengerId,
                    needOthsSsr: passenger.needOthsSsr,
                    autofocus: autofocus && passengerId === 0,
                    isChangeFiles: isChangeFiles,
                    accompanyingFields: passAccompamyingFields,
                    onSignUpLoyaltyProgram: onSignUpLoyaltyProgram,
                    onSwap: onSwap ? function () { return onSwap(passengerId); } : null,
                    onToggleChangeFiles: onToggleChangeFiles
                }, component: {
                    CheckoutFormPassenger: Passenger
                } })); }),
            React.createElement(Modal, { maxWidth: "sm", open: loyaltyConnectOpen, onClose: function () { return setLoyaltyConnectOpen(false); } },
                React.createElement(LoyaltyConnect, { userInfo: getUserInfo(userInfo), showRegisterAction: true, onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: function () { return setLoyaltyConnectOpen(false); }, onGoToSettings: onGoToAccountSettings }))));
    })));
};
var mapStateToProps = function (state, ownProps) {
    return __assign(__assign({}, ownProps), { labels: getPassengerLabels(state), passengersState: getPassengerStateCompatible(state), services: getServices(state), isNew: getIsNew(state), isReadonly: getIsReadonly(state) });
};
export default connect(mapStateToProps)(Passengers);
