import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllRequiredSeatsAreSelected, getCheckinOrderId, getHasAvailableSeats, getSeatMap, getTotalPrice, getTravellers, hasBaggageServices, hasMealServices } from '../../../../store/order/selectors';
import SeatMap from '../../../../../../SeatMap';
import { useTheme } from '../../../../../../theme';
import { CheckinStep, useSteps } from '../../../../utils';
import { Money } from '../../../../../../Money';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { CheckinEmptySeatMapError, CheckinUnavailableSeatMapError } from '../../CheckinErrorBoundary/CheckinErrors';
import { setCheckinOrder } from '../../../../store/order/actions';
import { getSelectedExtraSeats } from '../../../../store/selectedServices/selectors';
import { useToggleable } from '../../../../../../hooks';
import { useConfig } from '../../../../../../context';
var Seats = function (_a) {
    var onProceed = _a.onProceed, travellers = _a.travellers, setSeatCallback = _a.setSeatCallback, passengers = _a.passengers, setClearSeatCallback = _a.setClearSeatCallback, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, seatsPriceToPay = _a.seatsPriceToPay, onSegmentClear = _a.onSegmentClear;
    var orderId = useSelector(getCheckinOrderId);
    var totalPrice = useSelector(getTotalPrice);
    var seatMap = useSelector(getSeatMap);
    var hasAvailableSeats = useSelector(getHasAvailableSeats);
    var hasBaggage = useSelector(hasBaggageServices);
    var hasMeals = useSelector(hasMealServices);
    var orderTravellers = useSelector(getTravellers);
    var dispatch = useDispatch();
    var allRequiredSeatsAreSelected = useSelector(getAllRequiredSeatsAreSelected);
    var selectedExtraSeats = useSelector(getSelectedExtraSeats);
    var canGoBack = hasBaggage || hasMeals;
    var css = useTheme('Checkin').Checkin;
    var t = useTranslation('Checkin').t;
    // const { runServiceRequest } = useServiceRequest();
    var previousStep = useSteps().previousStep;
    var showRecommendedSeats = useConfig().global.seatMap.showRecommendedSeats;
    var _b = useToggleable(true), activeAutomaticSeating = _b.isOpen, disableAutomaticSeating = _b.close;
    var setOrder = function (order) {
        dispatch(setCheckinOrder(order));
    };
    useEffect(function () {
        disableAutomaticSeating();
    }, []);
    useEffect(function () {
        if (!allRequiredSeatsAreSelected && !hasAvailableSeats) {
            throw new CheckinEmptySeatMapError('Empty seat map and travellers without seats');
        }
    }, [allRequiredSeatsAreSelected]);
    useEffect(function () {
        var hasSegmentsWithZeroAvailableSeats = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments.some(function (segment) { return !segment.availableSeatsCount; });
        var allTravellersHaveNotSeats = orderTravellers.every(function (traveller) { var _a, _b; return !((_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length); });
        if (hasSegmentsWithZeroAvailableSeats && allTravellersHaveNotSeats) {
            throw new CheckinUnavailableSeatMapError(t('Service is temporarily unavailable. There are no seats available. Please try again later, or check in at the airport.'));
        }
    }, [seatMap, orderTravellers]);
    var hasPassengersWithSeat = useMemo(function () {
        var passengersIds = passengers.filter(function (passenger) { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length; }).map(function (passenger) { return passenger.id; });
        var travellersIds = orderTravellers
            .filter(function (traveller) { var _a, _b; return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length; })
            .map(function (traveller) { return traveller.id; });
        return new Set(__spreadArray(__spreadArray([], __read(passengersIds), false), __read(travellersIds), false)).size > 0;
    }, [passengers, orderTravellers]);
    var confirmRecommendedSeatsWithoutDialog = activeAutomaticSeating && !hasPassengersWithSeat && !showRecommendedSeats;
    return (React.createElement("div", { className: css.seats },
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice: totalPrice,
                onButtonClick: onProceed,
                onBackButtonClick: canGoBack ? function () { return previousStep(CheckinStep.Seats); } : null,
                additional: seatsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Seats'),
                    ": ",
                    React.createElement(Money, { money: seatsPriceToPay }))) : null
            }, mobileToolbarProps: {
                onContinue: onProceed,
                onBack: canGoBack ? function () { return previousStep(CheckinStep.Seats); } : null
            } },
            React.createElement(SeatMap, { orderId: orderId, seatMap: seatMap, passengers: passengers, segments: seatMap.segments.map(function (segment) {
                    return {
                        segmentId: segment.segmentsInfo.id,
                        departure: segment.segmentsInfo.departure,
                        arrival: segment.segmentsInfo.arrival
                    };
                }), travellers: travellers, showRecommendations: false, confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog, fillOrder: setOrder, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, onProceed: onProceed, onSegmentClear: onSegmentClear, selectedExtraSeats: selectedExtraSeats, isNewOrder: false }))));
};
export default Seats;
