var _a;
import { __assign } from "tslib";
import { createContext, useContext } from 'react';
import getKey from './randomHeaderGenerator';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import errorLink from './apolloErrorLink';
import * as Cookie from 'js-cookie';
import { getLocale, STAY_LOGGED_IN, isRequireCurrencyParam, captureErrorSentry } from './utils';
import introspectionResult from './fragmentTypes';
import './custom-event-polyfill';
import { CUSTOM_SCHEMAS_URL, get, getCurrency, GRAPHQL_KEY } from './cache';
import { Currency } from './enums';
import { v4 as uuidv4 } from 'uuid';
import ignoredErrorsLink from './ingoredErrorsLink';
import paximizerUTMLink from './paximizerUTMLink';
import WebskyAnalyticsApollo from './DataLayerAnalytics/WebskyAnalyticsApollo';
var token = Cookie.get(GRAPHQL_KEY);
var client;
var urlFromLocalStorage = process.env.ACCEPT_CUSTOM_SCHEMAS_URL === 'true' ? get(CUSTOM_SCHEMAS_URL) : null;
var GRAPHQL_URL = (_a = urlFromLocalStorage !== null && urlFromLocalStorage !== void 0 ? urlFromLocalStorage : process.env.API_URL) !== null && _a !== void 0 ? _a : 'https://dv.graphql.mlsd.ru/nemox/graphql/query/nemo';
var GraphQLClientContext;
export var setApiUrl = function (newApiUrl) {
    if (!urlFromLocalStorage) {
        GRAPHQL_URL = newApiUrl;
    }
};
var webskyAnalyticsLink = new ApolloLink(function (operation, forward) {
    return forward(operation).map(function (response) {
        try {
            WebskyAnalyticsApollo.analyze(operation, response, client);
        }
        catch (e) {
            console.error('Error in webskyAnalyticsLink', e);
            captureErrorSentry("Error in webskyAnalyticsLink: ".concat(e));
        }
        return response;
    });
});
export var createClient = function () {
    var isDemo = document.location.href.includes('demo.websky.tech');
    var needToSendCurrencyInHeaders = !isRequireCurrencyParam();
    if (!client) {
        var fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData: introspectionResult
        });
        var getTokenLink = new ApolloLink(function (operation, forward) {
            var context = operation.getContext();
            var currency = getCurrency();
            if (isDemo) {
                currency = Currency.EUR;
            }
            else if (context.currency) {
                currency = context.currency;
            }
            var headers = {
                requestUID: Math.floor(Math.random() * 90000000000000 + 10000000000000),
                'Accept-Language': getLocale(),
                'X-EventId': uuidv4()
            };
            if (needToSendCurrencyInHeaders ||
                ['FlightsMinPricesInPeriod', 'MinPrices'].includes(operation.operationName)) {
                headers['X-Currency'] = currency;
            }
            if (token) {
                headers.authorization = token ? "Bearer ".concat(token) : '';
            }
            operation.setContext(function () { return ({
                headers: __assign(__assign({}, headers), getKey())
            }); });
            return forward(operation).map(function (response) {
                var headers = operation.getContext().response.headers;
                if (headers) {
                    var currentToken = headers.get('X-Token');
                    if (currentToken) {
                        token = currentToken;
                        Cookie.set(GRAPHQL_KEY, token, {
                            expires: !!localStorage.getItem(STAY_LOGGED_IN) ? 90 : undefined,
                            sameSite: 'lax',
                            secure: process.env.NODE_ENV !== 'development'
                        });
                    }
                }
                return response;
            });
        });
        var httpLink = new HttpLink({
            uri: GRAPHQL_URL,
            credentials: 'include'
        });
        client = new ApolloClient({
            link: from([ignoredErrorsLink, errorLink, getTokenLink, paximizerUTMLink, webskyAnalyticsLink, httpLink]),
            cache: new InMemoryCache({ fragmentMatcher: fragmentMatcher })
        });
        WebskyAnalyticsApollo.listenWxViewEvent();
    }
    return client;
};
export var useGraphQLClient = function () {
    if (!GraphQLClientContext) {
        GraphQLClientContext = createContext(createClient());
    }
    return useContext(GraphQLClientContext);
};
