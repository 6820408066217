import { __read, __spreadArray, __values } from "tslib";
import i18n from 'i18next';
import { isAfter, isBefore, isValid, parse, parseISO } from 'date-fns';
import { AdditionalTravellerFieldEnum, InputType } from './types';
import { decapitalizeFirstLetter, getPassengerTypeLabel } from '../utils';
import { TravellerFields } from '../TravellerForm/types';
import { clearPhoneNumber } from '../PhoneInput/utils';
import { levenshtein } from '../levenshtein';
export var fieldsArrayToMap = function (fields) {
    var map = {};
    fields.map(function (field) {
        if (!map.hasOwnProperty(field.name)) {
            map[field.name] = field;
        }
    });
    return map;
};
export var hideText = function (text, divider) {
    if (divider === void 0) { divider = 1.5; }
    if (!text) {
        return '';
    }
    var numOfVisibleChars = Math.floor(text.length / divider);
    var numOfHiddenChars = text.length - numOfVisibleChars;
    return text.split('').fill('*', 0, numOfHiddenChars).join('');
};
export var getAdditionalLabel = function (values, fieldsMap, passengerType, supplierType) {
    var labels = [];
    var personalInfo = '';
    if (values[TravellerFields.FirstName]) {
        personalInfo = values[TravellerFields.FirstName];
        if (values[TravellerFields.LastName]) {
            personalInfo += " ".concat(values[TravellerFields.LastName]);
        }
    }
    else {
        personalInfo = getPassengerTypeLabel(passengerType, supplierType);
    }
    if (values[TravellerFields.BirthDate]) {
        personalInfo += ", ".concat(values[TravellerFields.BirthDate]);
    }
    labels.push(personalInfo);
    if (values.hasOwnProperty('loyaltyName') && fieldsMap['loyaltyName'].options) {
        var option = fieldsMap['loyaltyName'].validations[0].options.find(function (option) { return option.value === values['loyaltyName']; });
        if (option) {
            labels.push(option.value);
        }
    }
    if (values.hasOwnProperty('nationality') && fieldsMap['nationality'].validations[0].options) {
        var option = fieldsMap['nationality'].validations[0].options.find(function (option) { return option.value === values['nationality']; });
        if (option) {
            labels.push(option.label);
        }
    }
    if (values.hasOwnProperty('docType') &&
        values.hasOwnProperty('docNumber') &&
        fieldsMap['docType'].validations[0].options) {
        fieldsMap['docType'].validations.forEach(function (validation) {
            var option = validation.options.find(function (option) { return option.value === values['docType']; });
            if (option) {
                labels.push(i18n.t("Passenger:".concat(option.label)));
                labels.push(hideText(values['docNumber']));
            }
        });
    }
    return labels.join(' - ');
};
export var validateDate = function (value, rule) {
    var date = parse(value, 'dd.MM.yyyy', new Date());
    if (!isValid(date) || value.length !== 'dd.MM.yyyy'.length) {
        return "".concat(i18n.t('Enter date in dd.mm.yyyy format'));
    }
    if (rule.maxDate && isAfter(date, parseISO(rule.maxDate))) {
        return "".concat(i18n.t('Invalid date format'));
    }
    if (rule.minDate && isBefore(date, parseISO(rule.minDate))) {
        return "".concat(i18n.t('Invalid date format'));
    }
};
/**
 * Finds acceptable validation rules based on all values on the form object.
 * Acceptable is a rule where all dependencies in `with` property are
 * contains in allValues and all values is equals.
 *
 * @param allValues
 * @param validations
 */
export var findAcceptableRules = function (allValues, validations) {
    var rules = [];
    validations.forEach(function (validation) {
        if (!validation.with) {
            rules.push(validation);
        }
        else if (validation.with.every(function (_a) {
            var _b;
            var value = _a.value, name = _a.name;
            var valueRegex = new RegExp("^".concat(value, "$"));
            var valueByName = (_b = allValues[decapitalizeFirstLetter(name)]) !== null && _b !== void 0 ? _b : '';
            return valueRegex.test(valueByName);
        })) {
            rules = setValidation(rules, validation);
        }
    });
    return rules;
};
/**
 * In cases when allRules array contains a rule where `with` fields is greater than new `rule.with`, a new rule will be ignored.
 * Likewise, when `rule.with` fields is greater than some item in `allRules` array, old rule will be replaced with a `rule`.
 *
 * A is greater than B, when  A.length > B.length and A contains all items from B.
 * @param allRules validation rules array
 * @param newRule new rule to add
 */
var setValidation = function (allRules, newRule) {
    var newDependencies = newRule.with.map(function (option) { return option.name; });
    var shouldToAdd = true;
    var rules = allRules.filter(function (_rule) {
        if (!_rule.with) {
            return true;
        }
        // Finding a rule is greater than a new
        if (_rule.with.length > newDependencies.length &&
            newDependencies.every(function (optionName) { return _rule.with.some(function (option) { return option.name === optionName; }); })) {
            // Found a greater rule, ignore a new
            shouldToAdd = false;
        }
        if (newDependencies.length > _rule.with.length &&
            _rule.with.every(function (option) { return newDependencies.includes(option.name); })) {
            // A new rule is greater than in array. Remove old rule
            return false;
        }
        return true;
    });
    if (shouldToAdd) {
        return __spreadArray(__spreadArray([], __read(rules), false), [newRule], false);
    }
    return allRules;
};
export var isChangeValidationRule = function (rule) {
    return rule.maxEditDistance !== undefined;
};
export var validate = function (value, allValues, inputType, validations, fieldType, defaultValue) {
    var e_1, _a;
    var _b;
    if (validations === void 0) { validations = []; }
    if (validations && validations.length) {
        var rules = findAcceptableRules(allValues, validations);
        var _loop_1 = function (rule) {
            // Check if field is required.
            if (rule.required && (typeof value === 'boolean' ? !value : !((_b = value === null || value === void 0 ? void 0 : value.trim()) === null || _b === void 0 ? void 0 : _b.length))) {
                return { value: 'Required' };
            }
            if (value && typeof value === 'string') {
                // Check that date is valid.
                if (inputType === InputType.Date) {
                    var error = validateDate(value, rule);
                    if (error) {
                        return { value: error };
                    }
                }
                // Levenshtein distance check
                if (isChangeValidationRule(rule) && rule.maxEditDistance !== null && defaultValue) {
                    var distance = levenshtein(defaultValue, value);
                    if (distance > rule.maxEditDistance) {
                        return { value: 'Exceeded the number of editable characters' };
                    }
                }
                if (fieldType === AdditionalTravellerFieldEnum.OrganizationName ||
                    fieldType === AdditionalTravellerFieldEnum.PersonnelNumber) {
                    if (allValues['organizationName'] === allValues['personnelNumber']) {
                        return { value: 'Check if the input is correct' };
                    }
                }
                if (rule.regExp) {
                    var cleanValue_1 = inputType === InputType.Phone ? clearPhoneNumber(value) : value;
                    var failedRule = rule.regExp.find(function (_a) {
                        var pattern = _a.pattern;
                        return !new RegExp(pattern).test(cleanValue_1);
                    });
                    if (failedRule) {
                        return { value: inputType === InputType.Phone ? 'Incorrect phone number' : failedRule.error };
                    }
                }
            }
        };
        try {
            for (var rules_1 = __values(rules), rules_1_1 = rules_1.next(); !rules_1_1.done; rules_1_1 = rules_1.next()) {
                var rule = rules_1_1.value;
                var state_1 = _loop_1(rule);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (rules_1_1 && !rules_1_1.done && (_a = rules_1.return)) _a.call(rules_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
};
export var fieldIsRequired = function (allValues, validations) {
    var e_2, _a;
    if (validations === void 0) { validations = []; }
    if (validations === null || validations === void 0 ? void 0 : validations.length) {
        try {
            for (var validations_1 = __values(validations), validations_1_1 = validations_1.next(); !validations_1_1.done; validations_1_1 = validations_1.next()) {
                var rule = validations_1_1.value;
                if ((!rule.with ||
                    rule.with.every(function (_a) {
                        var name = _a.name, value = _a.value;
                        return new RegExp("^".concat(value, "$")).test(allValues[decapitalizeFirstLetter(name)]);
                    })) &&
                    rule.required) {
                    return true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (validations_1_1 && !validations_1_1.done && (_a = validations_1.return)) _a.call(validations_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    return false;
};
