import { __assign, __read, __spreadArray } from "tslib";
import { merge } from 'lodash';
import { getTravellersMap } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { mergeDuplicatedTravellerServices } from '../Checkout/utils';
// TODO: add treeshake support. https://stackoverflow.com/questions/58741044/why-webpack-doesnt-tree-shake-the-lodash-when-using-import-as
var getServiceKey = function (service, gdsServices, type) {
    var _a;
    if ((gdsServices === null || gdsServices === void 0 ? void 0 : gdsServices.length) && type === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge) {
        var gdsService = gdsServices.find(function (gdsService) { return gdsService.id === (service === null || service === void 0 ? void 0 : service.serviceId); });
        return (gdsService === null || gdsService === void 0 ? void 0 : gdsService.rfisc) || (service === null || service === void 0 ? void 0 : service.serviceId);
    }
    return (service === null || service === void 0 ? void 0 : service.serviceId) || ((_a = service === null || service === void 0 ? void 0 : service.service) === null || _a === void 0 ? void 0 : _a.rfisc);
};
var calculateServicesBySegments = function (services, gdsServices, type) {
    var servicesIdsSegmentsCount = {};
    services.forEach(function (service) {
        var _a;
        var key = getServiceKey(service, gdsServices, type);
        var serviceBySegments = (_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, segmentId) {
            var _a, _b;
            var updatedAcc = __assign({}, acc);
            updatedAcc[segmentId] = {
                count: (_a = service.count) !== null && _a !== void 0 ? _a : 0,
                confirmedCount: (_b = service.confirmedCount) !== null && _b !== void 0 ? _b : 0
            };
            return updatedAcc;
        }, {});
        servicesIdsSegmentsCount[key] = merge(servicesIdsSegmentsCount[key], serviceBySegments);
    });
    return servicesIdsSegmentsCount;
};
var isServiceForAllSegments = function (serviceSegmentsCount, segmentsCount) {
    var countServicesForSegments = Object.values(serviceSegmentsCount || {});
    if (countServicesForSegments.length !== segmentsCount) {
        return false;
    }
    return (countServicesForSegments.length === segmentsCount &&
        countServicesForSegments.every(function (counter) { return counter.count === countServicesForSegments[0].count; }));
};
var collectServices = function (servicesSegmentIds, traveller, segmentsCount, type, travellerId, gdsServices, collectType) {
    var _a;
    var travellerAllSegmentsMap = new Map();
    var result = {};
    var isAdditional = collectType === 'additional';
    var travellerServices = mergeDuplicatedTravellerServices(isAdditional ? (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services : traveller.services.brandIncludedServices.services, function (service) {
        var _a, _b, _c;
        return "".concat((_b = (_a = service.service) === null || _a === void 0 ? void 0 : _a.rfisc) !== null && _b !== void 0 ? _b : service.serviceId, "_").concat((_c = service.segmentIds) === null || _c === void 0 ? void 0 : _c.toString());
    });
    var servicesSegmentsCounter = calculateServicesBySegments(travellerServices, gdsServices, type);
    servicesSegmentIds.forEach(function (segmentId) {
        var _a, _b, _c;
        var _d;
        var travellerSegments = [];
        travellerServices
            .filter(function (service) {
            var _a;
            if (!((_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.includes(segmentId))) {
                return false;
            }
            return isAdditional
                ? gdsServices.some(function (gdsService) { return gdsService.id === service.serviceId; })
                : service.service.type === type;
        })
            .forEach(function (service) {
            var _a;
            var key = getServiceKey(service, gdsServices, type);
            var segmentsCounter = servicesSegmentsCounter[key];
            var selectedService = service, gdsService = gdsServices.find(function (gdsService) { return gdsService.id === service.serviceId; });
            if (selectedService.count > 0) {
                if (isServiceForAllSegments(segmentsCounter, segmentsCount)) {
                    travellerAllSegmentsMap.set(key, __spreadArray(__spreadArray([], __read((travellerAllSegmentsMap.get(key) || [])), false), [
                        {
                            serviceId: selectedService.serviceId,
                            count: selectedService.count,
                            confirmedCount: selectedService.confirmedCount,
                            products: selectedService.products,
                            service: isAdditional ? gdsService : service.service
                        }
                    ], false));
                }
                else if ((_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.includes(segmentId)) {
                    travellerSegments.push({
                        serviceId: selectedService.serviceId,
                        count: segmentsCounter[segmentId].count,
                        confirmedCount: segmentsCounter[segmentId].confirmedCount,
                        products: selectedService.products,
                        service: isAdditional ? gdsService : service.service
                    });
                }
            }
        });
        result[travellerId] = __assign(__assign({}, result[travellerId]), (_a = {}, _a[segmentId] = (_b = {},
            _b[collectType] = travellerSegments,
            _b), _a.allSegments = __assign(__assign({}, (_d = result[travellerId]) === null || _d === void 0 ? void 0 : _d.allSegments), (_c = {}, _c[collectType] = __spreadArray([], __read(travellerAllSegmentsMap.values()), false).map(function (services) { return (__assign(__assign({}, services[0]), { prices: services.map(function (service) { return service.service.price; }) })); }), _c)), _a));
    });
    return result;
};
export var groupServices = function (additionalServices, traveller, travellerId, type, flightSegments, filter, customFilter) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (filter === void 0) { filter = function () { return true; }; }
    var filterAdditionalServices = (_b = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(function (service) {
        if (customFilter) {
            return customFilter(service);
        }
        return service.type === type && filter(service);
    });
    var additionalServicesSegmentIds = new Set();
    (_e = (_d = (_c = traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(function (service) {
        service.segmentIds.forEach(function (id) { return additionalServicesSegmentIds.add(id); });
    });
    var includedServicesSegmentIds = new Set();
    (_h = (_g = (_f = traveller.services) === null || _f === void 0 ? void 0 : _f.brandIncludedServices) === null || _g === void 0 ? void 0 : _g.services) === null || _h === void 0 ? void 0 : _h.forEach(function (service) {
        service.segmentIds.forEach(function (id) { return includedServicesSegmentIds.add(id); });
    });
    var result = {};
    var additional = collectServices(additionalServicesSegmentIds, traveller, flightSegments.length, type, travellerId, filterAdditionalServices, 'additional');
    result = __assign(__assign({}, result), additional);
    var included = collectServices(includedServicesSegmentIds, traveller, flightSegments.length, type, travellerId, [], 'included');
    return merge(result, included);
};
export var filterServicesByRfisc = function (traveller, services) {
    var _a, _b;
    var additionalKeys = new Set();
    (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.forEach(function (service) {
        service.segmentIds.forEach(function (segmentId) {
            var _a;
            var rfisc = (_a = services.gdsServices.services.find(function (item) { return item.id === service.serviceId; })) === null || _a === void 0 ? void 0 : _a.rfisc;
            additionalKeys.add("".concat(rfisc, "_").concat(segmentId));
        });
    });
    traveller.services.brandIncludedServices.services = (_b = traveller.services.brandIncludedServices.services) === null || _b === void 0 ? void 0 : _b.filter(function (service) {
        return service.segmentIds.some(function (segmentId) {
            var _a;
            return !((_a = service.service.isReplacedBy) === null || _a === void 0 ? void 0 : _a.some(function (rfisc) {
                var additionalKey = "".concat(rfisc, "_").concat(segmentId);
                return additionalKeys.has(additionalKey);
            }));
        });
    });
    return traveller;
};
export var groupSeats = function (traveller) {
    var _a, _b;
    var _c, _d, _e, _f, _g;
    var travellerId = traveller.id;
    var additionalSeats = (_a = {},
        _a[travellerId] = {},
        _a);
    var segmentAdditionalSeatsMap = new Map();
    (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.seats) === null || _d === void 0 ? void 0 : _d.forEach(function (seat) {
        var item = segmentAdditionalSeatsMap.get(seat.segment.id);
        if (item) {
            segmentAdditionalSeatsMap.set(seat.segment.id, __spreadArray(__spreadArray([], __read(item), false), [seat], false));
        }
        else {
            segmentAdditionalSeatsMap.set(seat.segment.id, [seat]);
        }
    });
    // additional seats
    (_f = (_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.seats) === null || _f === void 0 ? void 0 : _f.forEach(function (seat) {
        var _a;
        additionalSeats[travellerId] = __assign(__assign({}, additionalSeats[travellerId]), (_a = {}, _a[seat.segment.id] = {
            additional: segmentAdditionalSeatsMap.get(seat.segment.id) || [],
            included: []
        }, _a));
    });
    var includedServicesSegmentIds = new Set();
    (_g = traveller.services.brandIncludedServices) === null || _g === void 0 ? void 0 : _g.services.filter(function (service) { return service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; }).forEach(function (service) {
        service.segmentIds.forEach(function (id) { return includedServicesSegmentIds.add(id); });
    });
    var includedSeats = (_b = {},
        _b[travellerId] = {},
        _b);
    // included seats
    includedServicesSegmentIds.forEach(function (segmentId) {
        var _a;
        var travellerIncludedSegments = [];
        traveller.services.brandIncludedServices.services
            .filter(function (service) { return service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; })
            .forEach(function (service) {
            travellerIncludedSegments.push(service);
        });
        includedSeats[travellerId] = __assign(__assign({}, includedSeats[travellerId]), (_a = {}, _a[segmentId] = __assign(__assign({}, includedSeats[travellerId][segmentId]), { included: travellerIncludedSegments }), _a));
    });
    return merge(includedSeats, additionalSeats);
};
export var getSegmentsById = function (segments) {
    var segmentsById = new Map();
    segments.forEach(function (_a) {
        var segment = _a.segment;
        segmentsById.set(segment.id, {
            id: segment.id,
            iataDirection: "".concat(segment.departure.airport.iata, " \u2014 ").concat(segment.arrival.airport.iata)
        });
    });
    return segmentsById;
};
export var createCartContext = function (travellers, segments, prices) {
    var segmentsById = new Map();
    segments.forEach(function (_a) {
        var segment = _a.segment;
        segmentsById.set(segment.id, {
            id: segment.id,
            iataDirection: "".concat(segment.departure.airport.iata, " \u2014 ").concat(segment.arrival.airport.iata)
        });
    });
    return {
        travellersById: getTravellersMap(travellers),
        segmentsById: segmentsById,
        totalPrices: {
            baggage: prices === null || prices === void 0 ? void 0 : prices.baggage,
            flights: prices === null || prices === void 0 ? void 0 : prices.airticket,
            seats: prices === null || prices === void 0 ? void 0 : prices.seats,
            meal: prices === null || prices === void 0 ? void 0 : prices.meals,
            total: prices === null || prices === void 0 ? void 0 : prices.total,
            aeroexpress: prices === null || prices === void 0 ? void 0 : prices.aeroexpress,
            insurance: prices === null || prices === void 0 ? void 0 : prices.insurance,
            businessLounge: prices === null || prices === void 0 ? void 0 : prices.businessLounge,
            animals: prices === null || prices === void 0 ? void 0 : prices.animals
        }
    };
};
export var getPassengerValuesMap = function (traveller) {
    var valuesMap = new Map();
    traveller.values.forEach(function (value) {
        valuesMap.set(value.type, value.value);
    });
    return valuesMap;
};
