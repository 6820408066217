import { __assign, __rest } from "tslib";
import * as React from 'react';
import BaseButton from '../BaseButton/BaseButton';
import { useTheme } from '../../../theme';
import cn from 'classnames';
var ModalButton = function (_a) {
    var _b;
    var className = _a.className, _c = _a.color, color = _c === void 0 ? 'primary' : _c, props = __rest(_a, ["className", "color"]);
    var css = useTheme('Button').ModalButton;
    return (React.createElement(BaseButton, __assign({}, props, { className: cn(className, (_b = {},
            _b[css.primary] = color === 'primary',
            _b[css.secondary] = color === 'secondary',
            _b)) })));
};
export default ModalButton;
