import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Amenities from '../../../../Amenities';
import { useTranslation } from 'react-i18next';
import { WarningRounded } from '../../../../Icons';
import { useConfig } from '../../../../context';
import { useSeatAdditionalInfo } from '../hooks';
import SeatAdditionalInfo from '../SeatAdditionalInfo/SeatAdditionalInfo';
import { SeatComfort } from '@websky/graphql';
import SeatPrice from '../../SeatPrice/SeatPrice';
var SeatInfo = function (_a) {
    var _b, _c;
    var _d, _e;
    var seatService = _a.seatService, hideImage = _a.hideImage, fullInfo = _a.fullInfo, amenities = _a.amenities, seat = _a.seat, segmentId = _a.segmentId, preselectedSeat = _a.preselectedSeat, isFreeSeat = _a.isFreeSeat, seatPrice = _a.seatPrice, services = _a.services, passengerId = _a.passengerId;
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var t = useTranslation('SeatMap').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var showSeatPriceNotServicePrice = ['DV', 'A9', 'RM'].includes(iataCode);
    var _f = useSeatAdditionalInfo(seat), restrictions = _f.restrictions, permissions = _f.permissions, additional = _f.additional, withInfo = _f.withInfo;
    var servicePrice = React.useMemo(function () {
        var _a, _b;
        var actualService = (_b = (_a = services === null || services === void 0 ? void 0 : services[segmentId]) === null || _a === void 0 ? void 0 : _a[passengerId]) === null || _b === void 0 ? void 0 : _b[seatService.rfisc];
        if (actualService && !showSeatPriceNotServicePrice) {
            return actualService.price;
        }
        return seatService === null || seatService === void 0 ? void 0 : seatService.price;
    }, [showSeatPriceNotServicePrice, services, segmentId, passengerId]);
    var renderFeatures = function () {
        var _a;
        if ((_a = seatService === null || seatService === void 0 ? void 0 : seatService.parameters) === null || _a === void 0 ? void 0 : _a.length) {
            return (React.createElement("div", { className: theme.features }, seatService.parameters.map(function (param, index) {
                return (React.createElement("div", { className: theme.feature, key: index },
                    param.title,
                    ": ",
                    param.value));
            })));
        }
        return null;
    };
    var seatImage;
    if (!isFreeSeat && (seatService === null || seatService === void 0 ? void 0 : seatService.infoBySegments)) {
        seatImage = (_d = seatService.infoBySegments.find(function (item) { return item.segment.id === segmentId; })) === null || _d === void 0 ? void 0 : _d.image;
    }
    var seatDescription = fullInfo ? renderFeatures() : (_e = seatService === null || seatService === void 0 ? void 0 : seatService.description) === null || _e === void 0 ? void 0 : _e.trim();
    return (React.createElement("div", { className: cn(theme.seat, (_b = {},
            _b[theme.seat_economy] = (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Standart,
            _b[theme.seat_preferred] = (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Preferred,
            _b[theme.seat_business] = (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Comfort,
            _b[theme.seat_free] = isFreeSeat,
            _b)), key: (seatService === null || seatService === void 0 ? void 0 : seatService.id) || (seat === null || seat === void 0 ? void 0 : seat.number) + segmentId, "data-rfisc": seatService === null || seatService === void 0 ? void 0 : seatService.rfisc },
        !isFreeSeat && (seatImage || (seatService === null || seatService === void 0 ? void 0 : seatService.serviceImage)) && !hideImage && (React.createElement("img", { className: theme.image, src: seatImage || (seatService === null || seatService === void 0 ? void 0 : seatService.serviceImage), alt: seatService === null || seatService === void 0 ? void 0 : seatService.name })),
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: cn(theme.title, (_c = {}, _c[theme.title_withDescription] = seatDescription, _c)) },
                (preselectedSeat === null || preselectedSeat === void 0 ? void 0 : preselectedSeat.number) && React.createElement("span", null, preselectedSeat === null || preselectedSeat === void 0 ? void 0 : preselectedSeat.number),
                isFreeSeat ? (seat === null || seat === void 0 ? void 0 : seat.number) : (React.createElement(React.Fragment, null, seatService === null || seatService === void 0 ? void 0 :
                    seatService.name,
                    React.createElement("div", { className: theme.description }, seatDescription))))),
        React.createElement("div", { className: theme.seat__info },
            fullInfo && (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0 ? React.createElement(Amenities, { amenities: amenities }) : renderFeatures(),
            React.createElement("div", { className: theme.price__wrapper }, isFreeSeat ? (t('Free seat')) : (React.createElement(SeatPrice, { className: theme.seatPrice, moneyClassName: theme.price, money: seatPrice !== null && seatPrice !== void 0 ? seatPrice : servicePrice, priceFrom: !seatPrice })))),
        seat && withInfo && (React.createElement("div", { className: theme.note },
            React.createElement("div", { className: theme.note__header },
                WarningRounded,
                " ",
                t('Note')),
            React.createElement(SeatAdditionalInfo, { restrictions: restrictions, permissions: permissions, additional: additional })))));
};
export default SeatInfo;
