import * as React from 'react';
import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cn from 'classnames';
import { useTheme } from '../theme';
var Tabs = function (props) {
    var _a, _b, _c;
    var theme = useTheme('Tabs').Tabs;
    return (React.createElement("div", { ref: props.tabsWrp, className: cn(theme.container, props.className, (_a = {}, _a[theme.container_rounded] = props.style === 'rounded', _a)) },
        React.createElement(MUITabs, { scrollButtons: props.scrollButtons, value: props.selected, classes: {
                root: cn(theme.tab, props.rootTabsClassName, (_b = {}, _b[theme.tab_rounded] = props.style === 'rounded', _b)),
                indicator: cn(theme.tab__indicator, props.indicatorClassName),
                flexContainer: cn((_c = {},
                    _c[theme.tabs__container_center] = props.tabsAlign === 'center',
                    _c[theme.tabs__container_right] = props.tabsAlign === 'right',
                    _c)),
                scroller: theme.scroller
            }, variant: props.variant, onChange: function (e, value) { return props.onChange(value); } }, props.tabs.map(function (tab) {
            var _a;
            return (React.createElement(Tab, { disabled: tab.disabled, disableRipple: props.style !== 'rounded', value: tab.value, key: tab.value, classes: {
                    root: cn(theme.tab, props.tabClassName, (_a = {}, _a[theme.tab_rounded] = props.style === 'rounded', _a)),
                    selected: cn(theme.tab_selected, props.selectedTabClassName)
                }, label: React.createElement("div", { className: theme.tab__content },
                    React.createElement("div", { title: tab.header, className: cn(theme.route, props.tabHeaderClassName) }, tab.header),
                    props.style !== 'rounded' && (React.createElement("div", { className: cn(theme.desc, props.tabLabelClassName) },
                        React.createElement("span", { className: theme.seats__count }, tab.label)))) }));
        }))));
};
export default Tabs;
