import { __assign, __rest } from "tslib";
import * as React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useComputedStyleProperty } from '../../../hooks';
import { CloseButtonVariant } from '../../types';
import { Close } from '../../icons';
var CloseButton = function (_a) {
    var classes = _a.classes, _b = _a.variant, variant = _b === void 0 ? CloseButtonVariant.ClosePopup : _b, _c = _a.disableElevation, disableElevation = _c === void 0 ? true : _c, restProps = __rest(_a, ["classes", "variant", "disableElevation"]);
    var theme = useTheme('BaseComponents').CloseButton;
    var _d = useComputedStyleProperty('--variant', variant), buttonVariant = _d.value, elRef = _d.elRef;
    return (React.createElement(Button, __assign({ variant: "contained", classes: __assign(__assign({}, classes), { root: cn(theme[buttonVariant], classes === null || classes === void 0 ? void 0 : classes.root) }), disableElevation: disableElevation, ref: elRef }, restProps),
        React.createElement("span", { className: theme.closeIcon }, Close)));
};
export default CloseButton;
