import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import Button from '../../Button';
import { WarningRounded, Included, ArrowForward } from '../../Icons';
var Notification = function (_a) {
    var _b;
    var className = _a.className, iconClassName = _a.iconClassName, titleClassName = _a.titleClassName, textClassName = _a.textClassName, contextClassName = _a.contextClassName, globalClassName = _a.globalClassName, title = _a.title, image = _a.image, icon = _a.icon, status = _a.status, type = _a.type, infoMessage = _a.infoMessage, actionText = _a.actionText, onClickAction = _a.onClickAction;
    var css = useTheme('Notification').Notification;
    var isSuccess = ![InfoMessageTypeEnum.Info, InfoMessageTypeEnum.Warning, InfoMessageTypeEnum.Promo].includes(status);
    var isContextType = type === 'context';
    var isGlobalType = type === 'global';
    return (React.createElement("div", { className: cn(css.wrapper, className, (_b = {},
            _b[css.context] = isContextType,
            _b[css.global] = isGlobalType,
            _b[css.success] = isSuccess,
            _b[css.info] = status === InfoMessageTypeEnum.Info,
            _b[css.warning] = status === InfoMessageTypeEnum.Warning,
            _b[contextClassName] = isContextType && contextClassName,
            _b[globalClassName] = isGlobalType && globalClassName,
            _b)), "data-code": infoMessage.code },
        React.createElement("div", { className: css.wrapper__inner },
            image ? (React.createElement("img", { className: css.image, src: image, alt: "" })) : (React.createElement("div", { className: cn(css.icon, iconClassName) }, icon ? icon : isSuccess ? Included : WarningRounded)),
            React.createElement("div", { className: css.content },
                title && React.createElement("div", { className: cn(css.title, titleClassName) }, title),
                React.createElement("div", { className: cn(css.text, textClassName), dangerouslySetInnerHTML: { __html: infoMessage.text } }))),
        actionText && (React.createElement(Button, { className: css.action, onClick: onClickAction },
            actionText,
            ArrowForward))));
};
export default Notification;
