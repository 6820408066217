import { __assign } from "tslib";
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatusComponent from '@websky/core/src/OrderCard/components/OrderStatus/OrderStatus';
import { OrderCardContext } from '@websky/core/src/OrderCard/components/OrderCard';
import Money from '@websky/core/src/Money';
import css from './OrderStatus.css';
var OrderStatus = function (props) {
    var _a;
    var t = useTranslation('OrderCard').t;
    var order = useContext(OrderCardContext).order;
    return (React.createElement(OrderStatusComponent, __assign({}, props, { buttons: React.createElement("div", { className: css.total },
            t('Total'),
            ": ",
            React.createElement(Money, { money: (_a = order.price) === null || _a === void 0 ? void 0 : _a.total })) })));
};
export default OrderStatus;
