import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Button from '../../Button';
import Modal from '../../Modal';
import AddBooking, { FormType } from '../../AddBooking';
import { reachGoal, UserGoal } from '../../analytics';
import { useConfig } from '../../context';
import { getCheckInUrl, MOBILE_MIN_WIDTH } from '../../utils';
import { SlideBottom } from '../../index';
import { useCheckinInfoQuery, useFindOrderMutation } from '@websky/graphql';
var ProfileControls = function (_a) {
    var className = _a.className, onRefetchOrders = _a.onRefetchOrders;
    var css = useTheme('ProfileControls').ProfileControls;
    var t = useTranslation('Account').t;
    var _b = useConfig(), _c = _b.Account, engineURL = _c.engineURL, openCheckinPageOnProfileControlsClick = _c.openCheckinPageOnProfileControlsClick, checkInURL = _b.Engine.checkInURL, _d = _b.global, iataCode = _d.companyInfo.iataCode, reserveEngines = _d.reserveEngines;
    var _e = __read(useState(null), 2), order = _e[0], setOrder = _e[1];
    var _f = __read(useState(false), 2), isLoading = _f[0], setIsLoading = _f[1];
    var _g = __read(useState(false), 2), bookingModal = _g[0], setBookingModal = _g[1];
    var _h = __read(useState(false), 2), checkinModal = _h[0], setCheckinModal = _h[1];
    var openBookingModal = function () { return setBookingModal(true); };
    var closeBookingModal = function () {
        setBookingModal(false);
        setOrder(null);
    };
    var openCheckinModal = function () {
        if (openCheckinPageOnProfileControlsClick) {
            window.open(checkInURL);
        }
        else {
            setCheckinModal(true);
        }
    };
    var closeCheckinModal = function () { return setCheckinModal(false); };
    var onViewOrder = function () { return window.open("".concat(engineURL, "#/booking/").concat(order.id), '_blank'); };
    var _j = __read(useFindOrderMutation(), 1), unlockOrder = _j[0];
    var refetch = useCheckinInfoQuery({ skip: true }).refetch;
    var onBookingSubmit = useCallback(function (_a) {
        var lastName = _a.lastName, ticket = _a.ticket, _b = _a.saveInProfile, saveInProfile = _b === void 0 ? false : _b;
        return __awaiter(void 0, void 0, void 0, function () {
            var _c, data, errors, result, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        setIsLoading(true);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, unlockOrder({
                                variables: {
                                    params: {
                                        id: ticket,
                                        secret: lastName,
                                        saveInProfile: saveInProfile
                                    }
                                }
                            })];
                    case 2:
                        _c = _d.sent(), data = _c.data, errors = _c.errors;
                        result = !errors && data && data.FindOrder;
                        if (result) {
                            onRefetchOrders === null || onRefetchOrders === void 0 ? void 0 : onRefetchOrders();
                            reachGoal(UserGoal.BindOrder);
                            setOrder(result);
                            setIsLoading(false);
                            return [2 /*return*/, true];
                        }
                        else {
                            setIsLoading(false);
                            setOrder(null);
                            return [2 /*return*/, { ticket: t('RetrieveBooking:Order not found') }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _d.sent();
                        return [2 /*return*/, {
                                ticket: t('An error occurred while executing the request. Please try again later or contact a customer support service.')
                            }];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }, []);
    var onCheckinSubmit = useCallback(function (_a) {
        var lastName = _a.lastName, ticket = _a.ticket;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b, data, errors, result, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin))) return [3 /*break*/, 1];
                        window.open(getCheckInUrl(checkInURL, '', ticket, lastName, reserveEngines));
                        return [2 /*return*/, true];
                    case 1:
                        setIsLoading(true);
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, refetch({
                                params: {
                                    id: ticket,
                                    secret: lastName
                                }
                            })];
                    case 3:
                        _b = _c.sent(), data = _b.data, errors = _b.errors;
                        result = !errors && data && data.CheckinInfo;
                        if (result.isAvailable) {
                            closeCheckinModal();
                            window.open(getCheckInUrl(ticket, lastName, checkInURL));
                            return [2 /*return*/, true];
                        }
                        else {
                            return [2 /*return*/, { ticket: t('RetrieveBooking:Order not found') }];
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        e_2 = _c.sent();
                        return [2 /*return*/, {
                                ticket: t('An error occurred while executing the request. Please try again later or contact a customer support service.')
                            }];
                    case 5:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }, []);
    var openSearchFlights = function () { return window.open(engineURL); };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.img, style: {
                backgroundImage: "url(https://cdn.websky.aero/content/frontend/images/aircraftImages/".concat(iataCode.toUpperCase(), "/default.png)")
            } }),
        React.createElement("div", { className: css.actions },
            React.createElement(Button, { onClick: openSearchFlights }, t('Search flights')),
            React.createElement(Button, { onClick: openBookingModal }, t('I have a booking')),
            React.createElement(Button, { onClick: openCheckinModal }, t('Check-in'))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: {
                    paper: css.paper,
                    paperWidthLg: css.paper_width_lg,
                    paperScrollBody: css.paper_scroll_body
                }, open: bookingModal },
                React.createElement(AddBooking, { type: FormType.Booking, isLoading: isLoading, order: order, onClose: closeBookingModal, onSubmit: onBookingSubmit, onView: onViewOrder })),
            React.createElement(Modal, { classes: {
                    paper: css.paper,
                    paperWidthLg: css.paper_width_lg,
                    paperScrollBody: css.paper_scroll_body
                }, open: checkinModal },
                React.createElement(AddBooking, { isLoading: isLoading, type: FormType.Checkin, order: order, onClose: closeCheckinModal, onSubmit: onCheckinSubmit, onView: onViewOrder }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: bookingModal, onClose: closeBookingModal },
                React.createElement(AddBooking, { type: FormType.Booking, isLoading: isLoading, order: order, onClose: closeBookingModal, onSubmit: onBookingSubmit, onView: onViewOrder })),
            React.createElement(SlideBottom, { isOpen: checkinModal, onClose: closeCheckinModal },
                React.createElement(AddBooking, { isLoading: isLoading, type: FormType.Checkin, order: order, onClose: closeCheckinModal, onSubmit: onCheckinSubmit, onView: onViewOrder })))));
};
export default ProfileControls;
