import { __read } from "tslib";
import * as React from 'react';
import { addMonths } from 'date-fns';
import { useTheme } from '../../../theme';
import { getAllYears, getMonthsList, getTimestamp, isMonthAvailable } from '../../utils';
import { Month } from './Month/Month';
import { MonthHeader } from './Month/MonthHeader/MonthHeader';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
export var MonthsWrapper = function (props) {
    var _a;
    var _b = __read(React.useState(props.initialDate), 2), focusedDate = _b[0], setFocusedDate = _b[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var headerRef = React.useRef(null);
    React.useEffect(function () {
        if (isMobile && props.getParentRef) {
            var parent = props.getParentRef().current;
            if (parent &&
                headerRef.current &&
                (parent.hasOwnProperty('scroll') || typeof parent.scroll === 'function')) {
                parent.scroll({
                    behavior: 'auto',
                    top: headerRef.current.offsetTop
                });
            }
        }
    }, []);
    var theme = useTheme('Datepicker').MonthsWrapperStyles, yearsArray = React.useMemo(function () { return getAllYears(getTimestamp(props.minDate), getTimestamp(props.maxDate)); }, [
        props.minDate,
        props.maxDate
    ]);
    var minSelectedDatesTimestamp = Infinity, maxSelectedDatesTimestamp = 0;
    var minHoveredDatesTimestamp = Infinity, maxHoveredDatesTimestamp = 0;
    var setMonth = function (date) {
        setFocusedDate(date);
    };
    var setCurrentDate = function (date, monthOffset) {
        if (monthOffset === void 0) { monthOffset = 0; }
        var newDate = new Date(parseInt(date.toString()));
        if (monthOffset !== 0) {
            newDate = addMonths(newDate, monthOffset);
        }
        setMonth(newDate);
    };
    for (var selectedDate in props.selectedDates) {
        if (props.selectedDates.hasOwnProperty(selectedDate)) {
            var timestamp = parseInt(selectedDate);
            if (timestamp < minSelectedDatesTimestamp) {
                minSelectedDatesTimestamp = timestamp;
            }
            if (timestamp > maxSelectedDatesTimestamp) {
                maxSelectedDatesTimestamp = timestamp;
            }
        }
    }
    for (var hoverDateIndex in props.hoverDates) {
        if (props.hoverDates[hoverDateIndex]) {
            var timestamp = props.hoverDates[hoverDateIndex].getTime();
            if (timestamp < minHoveredDatesTimestamp) {
                minHoveredDatesTimestamp = timestamp;
            }
            if (timestamp > maxHoveredDatesTimestamp) {
                maxHoveredDatesTimestamp = timestamp;
            }
        }
    }
    var monthsToScroll = (_a = props.monthsToScroll) !== null && _a !== void 0 ? _a : 1;
    var renderMonth = function (date, index, addRef) {
        return (React.createElement(Month, { key: index, date: date, selectedDatesMap: props.selectedDates, minDateTimestamp: getTimestamp(props.minDate), maxDateTimestamp: getTimestamp(props.maxDate), minSelectedDateTimestamp: minSelectedDatesTimestamp, maxSelectedDateTimestamp: maxSelectedDatesTimestamp, minHoveredDateTimestamp: minHoveredDatesTimestamp, maxHoveredDateTimestamp: maxHoveredDatesTimestamp, highlightingDates: props.highlightedDates, onSelect: props.onSelect, onHover: props.onHover, monthHeader: React.createElement("div", { ref: addRef && focusedDate.getMonth && focusedDate.getMonth() === date.getMonth()
                    ? headerRef
                    : null },
                React.createElement(MonthHeader, { setCurrentMonth: setCurrentDate, years: yearsArray, monthIndex: index, currentDate: date, maxDate: props.maxDate, minDate: props.minDate })) }));
    };
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("nav", null,
            isMonthAvailable(focusedDate, -1, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_prev, onClick: function () { return setMonth(addMonths(focusedDate, -monthsToScroll)); } }, "\u2190")),
            isMonthAvailable(focusedDate, props.monthCount, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_next, onClick: function () { return setMonth(addMonths(focusedDate, monthsToScroll)); } }, "\u2192"))),
        React.createElement("div", { className: theme.monthsList }, getMonthsList(focusedDate ? focusedDate : props.minDate, props.monthCount).map(function (date, index) {
            return renderMonth(date, index, isMobile);
        }))));
};
