import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import AircraftPopupContent from './AircraftPopupContent/AircraftPopupContent';
import SimpleLoader from '../../SimpleLoader';
import { useAircraftInfoLazyQuery } from '@websky/graphql';
import Modal from '../../Modal';
import Button from '../../Button';
import { useToggleable } from '../../hooks';
import { useTheme } from '../../theme';
import { CloseClear } from '../../Icons';
import { InfoIcon } from '../../FlightCard/components/Icons';
var AircraftPopup = function (_a) {
    var _b;
    var className = _a.className, id = _a.id, operatingIata = _a.operatingIata, name = _a.name, renderButton = _a.renderButton, renderInBody = _a.renderInBody;
    var css = useTheme('AircraftPopup').AircraftPopup;
    var t = useTranslation('Checkout').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var _d = __read(useAircraftInfoLazyQuery({
        variables: { id: id, operatingAirlineIata: operatingIata }
    }), 2), aircraftInfo = _d[0], _e = _d[1], data = _e.data, loading = _e.loading;
    var onOpen = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, aircraftInfo()];
                case 1:
                    _a.sent();
                    open();
                    return [2 /*return*/];
            }
        });
    }); }, [aircraftInfo, open]);
    var renderCloseButton = useCallback(function (onClick) { return (React.createElement(Button, { className: css.close, onClick: onClick }, CloseClear)); }, [isOpen]);
    var renderModal = function () {
        return (React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, onClose: close, disableAutoFocus: true, closeButtonRenderer: renderCloseButton },
            React.createElement(AircraftPopupContent, { name: name, aircraftInfo: data === null || data === void 0 ? void 0 : data.AircraftInfo, onClose: close })));
    };
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(SimpleLoader, null), (_b = renderButton === null || renderButton === void 0 ? void 0 : renderButton(onOpen, loading)) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: cn(className, css.button), variant: "text", onClick: onOpen },
        t('Aircraft info'),
        React.createElement("span", { className: css.icon }, InfoIcon))),
        (data === null || data === void 0 ? void 0 : data.AircraftInfo) && isOpen && (React.createElement(React.Fragment, null, renderInBody ? createPortal(renderModal(), document.body) : renderModal()))));
};
export default AircraftPopup;
