import * as React from 'react';
import { TravellerFieldEnum, InfoMessageTypeEnum } from '@websky/graphql';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { CloseClear, Flight, Person } from '../Icons';
import { useTheme } from '../theme';
import { API_DATE_FORMAT, format, getUserValue, initI18n, MOBILE_MIN_WIDTH } from '../utils';
import Notification from '../Notification';
import { parse } from 'date-fns';
import Button from '../Button/Button';
import { useMediaQuery } from 'react-responsive';
initI18n('PassengerSubsidy');
var SubsidyRejectPopup = function (_a) {
    var open = _a.open, segment = _a.segment, traveller = _a.traveller, rejectMessage = _a.rejectMessage, onClose = _a.onClose, onNewSearch = _a.onNewSearch;
    var t = useTranslation('PassengerSubsidy').t;
    var theme = useTheme('SubsidyRejectPopup').SubsidyRejectPopup;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    if (!traveller) {
        return null;
    }
    var closeButtonRender = function (onClose) {
        return (React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__text }, t('Attention')),
            React.createElement(IconButton, { className: theme.closeButton, onClick: onClose }, CloseClear)));
    };
    var firstName = getUserValue(traveller, TravellerFieldEnum.FirstName), lastName = getUserValue(traveller, TravellerFieldEnum.LastName), birthDate = getUserValue(traveller, TravellerFieldEnum.BirthDate);
    return (React.createElement(Modal, { open: open, isRounded: true, slideUp: isMobile, closeButtonRenderer: closeButtonRender, onClose: onClose, maxWidth: "sm", classes: {
            paper: theme.container
        } },
        segment && (React.createElement("div", { className: theme.flight },
            React.createElement("div", { className: theme.flight__direction },
                Flight,
                segment.departure.airport.city.name,
                " \u2013 ",
                segment.arrival.airport.city.name),
            React.createElement("div", null, format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'd MMM, eeeeee')))),
        React.createElement("div", { className: theme.passenger },
            Person,
            firstName,
            " ",
            lastName,
            ", ",
            birthDate),
        React.createElement(Notification, { title: rejectMessage, status: InfoMessageTypeEnum.Warning, type: "context", titleClassName: theme.notification__title, infoMessage: {
                title: rejectMessage,
                text: t('A subsidized fare requires all passengers on the booking to have benefits.'),
                type: InfoMessageTypeEnum.Warning,
                code: null
            } }),
        React.createElement("div", { className: theme.buttons },
            React.createElement(Button, { variant: "flat", className: theme.newSearch, onClick: onClose }, t('Edit passengers data')),
            React.createElement(Button, { variant: "flat", className: theme.continueButton, onClick: onNewSearch }, t('New search')))));
};
export default SubsidyRejectPopup;
