import React from 'react';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { format } from '../../../../utils';
import { Group } from '../../NotificationCard/Group/Group';
import { Info } from '../../NotificationCard/Info/Info';
import { RouteType } from '@websky/graphql';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { fromUTCToLocalTimeZone } from '../../../utils';
import { useConfig } from '../../../../context';
import { Button } from '../../../../index';
export var OrderCard = function (props) {
    var css = useTheme('Communications').OrderCard;
    var t = useTranslation('Communications').t;
    var engineURL = useConfig().Account.engineURL;
    var passengerList = function () {
        return props.passengers
            .map(function (passenger) {
            return "".concat(passenger.firstName, " ").concat(passenger.lastName);
        })
            .join(', ');
    };
    var sendingDate = fromUTCToLocalTimeZone(props.sendingDate);
    var routeDate = '';
    if (props.routeType === RouteType.OW) {
        routeDate = format(props.arrivalDate, 'dd MMM Y');
    }
    else {
        routeDate = "".concat(format(props.departureDate, 'dd'), " \u2014 ").concat(format(props.arrivalDate, 'dd MMM Y'));
    }
    var onClick = function (e, locator, lastName) {
        e.preventDefault();
        window.location.href = "".concat(engineURL, "#/find/").concat(locator, "/").concat(lastName, "/submit");
    };
    return (React.createElement(NotificationCard, { isRead: props.isRead, title: props.title, date: format(sendingDate, NOTIFICATION_DATE_FORMAT), markAsRead: props.markAsRead, preDescRender: function () { return (React.createElement(Group, { className: css.orderGroup },
            React.createElement(Info, { className: css.destination },
                routeDate,
                React.createElement("span", null, "|"),
                props.departureAirport,
                " \u2014 ",
                props.arrivalAirport,
                " ",
                React.createElement("span", null, "|"),
                passengerList()),
            React.createElement(Button, { variant: "text", className: css.locator, onClick: function (e) { return onClick(e, props.locator, props.passengers[0].lastName); } },
                React.createElement(Info, null,
                    t('Order'),
                    ": ",
                    React.createElement("span", null, props.locator))))); } },
        React.createElement("p", null, props.text)));
};
