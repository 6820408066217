import { __assign } from "tslib";
import React from 'react';
import { initI18n } from '../../../../../utils';
import ModalConfirm from '../../../../../ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
initI18n('Checkout');
var CheckDataModal = function (_a) {
    var classes = _a.classes, isOpen = _a.isOpen, onClose = _a.onClose, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Checkout').CheckDataModal;
    var styles = __assign(__assign({}, theme), classes);
    return (React.createElement(ModalConfirm, { classes: styles, isOpen: isOpen, onClose: onClose, onConfirm: onConfirm, onCancel: onCancel, title: t('Check the correctness of the entered data'), confirmBtn: t('Continue'), cancelBtn: t('Check') },
        React.createElement("div", { className: styles.contentWrapper }, t('You will not be able to change passenger details after purchasing a ticket.'))));
};
export default CheckDataModal;
