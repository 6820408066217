import * as React from 'react';
import Button from '../../../../Button/Button';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var DialogContent = function (_a) {
    var closeConfirmationPopup = _a.closeConfirmationPopup, onInsuranceRemove = _a.onInsuranceRemove, refusingIsInProgress = _a.refusingIsInProgress;
    var css = useTheme('AdditionalServices').Insurance;
    var t = useTranslation('Checkout').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: css.popup__title }, t('Are you sure you are ready to remove insurance?')),
        React.createElement("p", { className: css.popup__text }, t('Inflight insurance or baggage insurance is your travel confidence.')),
        React.createElement("div", { className: css.popup__buttons },
            !refusingIsInProgress && (React.createElement(Button, { onClick: onInsuranceRemove, disabled: refusingIsInProgress }, t('Cancel'))),
            !refusingIsInProgress && (React.createElement(Button, { onClick: closeConfirmationPopup, disabled: refusingIsInProgress }, t('Insure'))),
            refusingIsInProgress && React.createElement(Button, { isLoading: refusingIsInProgress }))));
};
export default DialogContent;
