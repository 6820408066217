import { __awaiter, __generator } from "tslib";
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAvailableInsuranceCodes, getOrder, getSelectedInsuranceCodes, getSortedPriorityInsurancePrograms } from '../../../Checkout/store/order/selectors';
import { useToggleable } from '../../../hooks';
import { useInsuranceUpsaleConfig } from '../../hooks';
import { InsuranceGoal, reachGoal, TransportLegType } from '../../../analytics';
import { getTravellersTypes } from '../../../DataLayerAnalytics/utils';
export var useInsuranceUpsaleModal = function (_a) {
    var _b;
    var strategy = _a.strategy, availableInsuranceCodes = _a.availableInsuranceCodes, selectedInsuranceCodes = _a.selectedInsuranceCodes;
    var order = useSelector(getOrder);
    var insuranceAvailableCodes = useSelector(getAvailableInsuranceCodes);
    var insuranceSelectedCodes = useSelector(getSelectedInsuranceCodes);
    var sortedPriorityInsurance = useSelector(getSortedPriorityInsurancePrograms);
    var _c = useToggleable(false), isUpsaleOpen = _c.isOpen, setUpsaleOpen = _c.setOpen, openUpsaleModal = _c.open, closeUpsaleModal = _c.close;
    var insuranceUpsaleConfig = useInsuranceUpsaleConfig();
    var availableCodes = useMemo(function () {
        return availableInsuranceCodes !== null && availableInsuranceCodes !== void 0 ? availableInsuranceCodes : insuranceAvailableCodes;
    }, [availableInsuranceCodes, insuranceAvailableCodes]);
    var selectedCodes = useMemo(function () {
        return selectedInsuranceCodes !== null && selectedInsuranceCodes !== void 0 ? selectedInsuranceCodes : insuranceSelectedCodes;
    }, [selectedInsuranceCodes !== null && selectedInsuranceCodes !== void 0 ? selectedInsuranceCodes : insuranceSelectedCodes]);
    var priorityInsurance = useMemo(function () { return sortedPriorityInsurance[0]; }, [sortedPriorityInsurance]);
    var isNeedToShowInsuranceUpsale = useMemo(function () {
        if (!insuranceUpsaleConfig.getIsNeedToShow(strategy)) {
            return false;
        }
        if (priorityInsurance) {
            // if the priority insurance is not present in the list, then we skip the step
            if (!availableCodes.includes(priorityInsurance.code)) {
                return false;
            }
            return !selectedCodes.includes(priorityInsurance.code);
        }
        return !selectedCodes.length;
    }, [strategy, priorityInsurance, availableCodes, selectedCodes, insuranceUpsaleConfig.getIsNeedToShow]);
    var from = order.flight.segments[0].segment.departure;
    var to = order.flight.segments[order.flight.segments.length - 1].segment.arrival;
    var flightNumber = "".concat((_b = order.flight.segments[0].segment.marketingAirline) === null || _b === void 0 ? void 0 : _b.iata, "-").concat(order.flight.segments[0].segment.flightNumber);
    var transportLeg = order.flight.segments.length > 1 ? TransportLegType.Transfer : TransportLegType.Direct;
    var passengerTypes = useMemo(function () { return getTravellersTypes(order.travellers); }, [order]);
    var analyticsFunnelPurchase = {
        airport_from: from.airport.iata,
        airport_to: to.airport.iata,
        date_start: from.date,
        date_end: to.date,
        passenger_type: passengerTypes,
        flight_number: flightNumber,
        avia_operator: window.location.hostname,
        transport_leg: transportLeg
    };
    var onCloseUpsaleModal = useCallback(function () {
        insuranceUpsaleConfig.onApplyStrategy(strategy);
        closeUpsaleModal();
    }, [strategy, insuranceUpsaleConfig.onApplyStrategy, closeUpsaleModal]);
    var onAgree = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    reachGoal(InsuranceGoal.InsurancePopupYes, { funnel_purchase: analyticsFunnelPurchase });
                    if (!priorityInsurance) return [3 /*break*/, 2];
                    // if the priority insurance is present in the list, then we skip the request for addition
                    if (selectedCodes.includes(priorityInsurance.code)) {
                        return [2 /*return*/];
                    }
                    onCloseUpsaleModal();
                    return [4 /*yield*/, (callback === null || callback === void 0 ? void 0 : callback(priorityInsurance.code ? [priorityInsurance.code] : undefined))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    onCloseUpsaleModal();
                    return [2 /*return*/];
            }
        });
    }); }, [priorityInsurance, selectedCodes, onCloseUpsaleModal]);
    var onDisagree = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    reachGoal(InsuranceGoal.InsurancePopupNo, { funnel_purchase: analyticsFunnelPurchase });
                    onCloseUpsaleModal();
                    return [4 /*yield*/, (callback === null || callback === void 0 ? void 0 : callback())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onCloseUpsaleModal]);
    var onContinue = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isNeedToShowInsuranceUpsale) {
                        openUpsaleModal();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, callback()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [openUpsaleModal, isNeedToShowInsuranceUpsale]);
    return {
        // data
        priorityInsurance: priorityInsurance,
        isUpsaleOpen: isUpsaleOpen,
        isNeedToShowInsuranceUpsale: isNeedToShowInsuranceUpsale,
        // methods
        setUpsaleOpen: setUpsaleOpen,
        onCloseUpsaleModal: onCloseUpsaleModal,
        onAgree: onAgree,
        onDisagree: onDisagree,
        onContinue: onContinue
    };
};
