import { __assign } from "tslib";
import * as React from 'react';
import { memo, useCallback } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InputType } from '../../types';
import { Switch } from './Switch/Switch';
import Input from '../../../Input';
import { Select } from './Select/Select';
import { DateField } from './Date/Date';
import PhoneInput from '../../../PhoneInput';
import DocumentType from './DocumentType/DocumentType';
import LastName from './LastName/LastName';
import { fieldIsRequired, validate } from '../../utils';
import DocExpiryDate from './DocExpiryDate/DocExpiryDate';
import { Female, Male } from '../../../Icons';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { DocTypes, TravellerFieldEnum, useGetTravellerProfilesQuery } from '@websky/graphql';
import { LinkedTraveller } from './LinkedTraveller/LinkedTraveller';
import Nationality from './Nationality/Nationality';
import { isNameFieldType } from '../../../PassengerForm/utils';
var debounceTimeout = 0;
export var Field = memo(function (props) {
    var _a, _b;
    var t = useTranslation('Passenger').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isDocNumber = props.field.type === TravellerFieldEnum.DocNumber;
    var isBirthRegDocument = props.passengerDocType === DocTypes.BirthRegDocument;
    var escapedT = function (label) {
        return typeof label !== 'undefined'
            ? t(label, {
                // This is necessary to avoid colon problems in error messages.
                nsSeparator: '|',
                keySeparator: '>'
            })
            : label;
    };
    var travellerProfiles = useGetTravellerProfilesQuery({
        skip: !props.loadTravellers
    });
    var selectPassenger = (_a = travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.data) === null || _a === void 0 ? void 0 : _a.TravellerProfiles.find(function (_a) {
        var id = _a.id;
        return id === props.selectedProfileId;
    });
    var refetchTravellers = function (value) {
        clearTimeout(debounceTimeout);
        debounceTimeout = window.setTimeout(function () { return travellerProfiles.refetch({ search: value }); }, 500);
    };
    var fieldState = useField(props.field.name, {
        initialValue: props.field.defaultValue,
        type: props.field.input,
        validate: props.disabled
            ? function () { }
            : function (value, allValues) {
                return escapedT(validate(value, allValues['passengers'][props.passengerId], props.field.input, props.field.validations, props.field.type, props.field.defaultValue));
            },
        parse: isNameFieldType(props.field.type) ? function (value) { return value !== null && value !== void 0 ? value : ''; } : undefined
    });
    var onChangeSelectHandler = useCallback(function (value, event) {
        var _a;
        props.onChange && props.onChange(props.field.name, value);
        fieldState.input.onChange(event);
        (_a = props.onTravellerSelect) === null || _a === void 0 ? void 0 : _a.call(props, selectPassenger, props.field.type, value);
    }, [props.onChange, fieldState]);
    var error = fieldState.meta.error &&
        (fieldState.meta.submitFailed ||
            (fieldState.meta.touched && fieldState.meta.modified) ||
            (fieldState.input.value && !fieldState.meta.modified))
        ? fieldState.meta.error
        : fieldState.meta.submitError && !fieldState.meta.dirtySinceLastSubmit
            ? fieldState.meta.submitError
            : '';
    var isRequired = props.field.validations && ((_b = props.field.validations[0]) === null || _b === void 0 ? void 0 : _b.required);
    var label = isRequired ? "".concat(props.field.placeholder, "*") : props.field.placeholder;
    var inputVariant = props.variant ? props.variant : isMobile ? 'standard' : 'filled';
    var inputMode = 'text';
    // Numeric input mode
    if (['LoyaltyNumber', 'LoyaltyAirline'].includes(props.field.type)) {
        inputMode = 'numeric';
    }
    if (props.field.input === InputType.Text) {
        if (props.field.type === TravellerFieldEnum.LinkedTraveller) {
            return (React.createElement(LinkedTraveller, { value: fieldState.input.value, options: props.field.validations[0].options, name: props.field.name, label: label, className: props.className, onChange: onChangeSelectHandler, onBlur: fieldState.input.onBlur, error: error, disabled: props.disabled, variant: inputVariant }));
        }
        return (React.createElement(Input, { label: label, value: fieldState.input.value, name: props.field.name, className: props.className, disabled: props.disabled, autoFocus: props.autofocus, variant: inputVariant, TextFieldProps: {
                onChange: function (e) {
                    if (isDocNumber && isBirthRegDocument) {
                        e.target.value = e.target.value.toUpperCase();
                    }
                    props.onChange && props.onChange(props.field.name, e.target.value);
                    fieldState.input.onChange(e);
                },
                onBlur: function (e) {
                    fieldState.input.onBlur(e);
                },
                error: !!error,
                helperText: error
            }, absoluteHintPosition: true, inputMode: inputMode, autoComplete: "new-".concat(props.field.name) }));
    }
    if (props.field.input === InputType.Phone) {
        return (React.createElement(PhoneInput, { className: props.className, value: fieldState.input.value, disabled: props.disabled, textFieldsProps: {
                label: label,
                variant: inputVariant,
                name: props.field.name,
                onChange: (function (value) {
                    props.onChange && props.onChange(props.field.name, value);
                    // Fucking `react-phone-number-input` changes the `onChange` handler signature
                    // and passes in string value instead of ChangeEvent,
                    // so we have to create our own event object.
                    fieldState.input.onChange(__assign(__assign({}, new Event('change')), { target: __assign(__assign({}, fieldState.input), { value: value }) }));
                }),
                onBlur: function (e) {
                    fieldState.input.onBlur(e);
                },
                error: !!error,
                helperText: error
            } }));
    }
    if (props.field.type === TravellerFieldEnum.DocExpiryDate) {
        var checkFieldIsRequired = useCallback(function (allValues) {
            return fieldIsRequired(allValues['passengers'][props.passengerId], props.field.validations);
        }, [props.field.validations]);
        return (React.createElement(DocExpiryDate, { checkFieldIsRequired: checkFieldIsRequired, label: props.field.placeholder, value: fieldState.input.value, name: props.field.name, className: props.className, onChange: function (e) {
                props.onChange && props.onChange(props.field.name, e.target.value);
                fieldState.input.onChange(e);
            }, onBlur: function (e) {
                fieldState.input.onBlur(e);
            }, error: error, absoluteHintPosition: true, disabled: props.disabled, variant: inputVariant }));
    }
    if (props.field.input === InputType.Date) {
        return (React.createElement(DateField, { label: label, value: fieldState.input.value, name: props.field.name, className: props.className, onChange: function (e) {
                props.onChange && props.onChange(props.field.name, e.target.value);
                fieldState.input.onChange(e);
            }, onBlur: function (e) {
                fieldState.input.onBlur(e);
            }, error: error, absoluteHintPosition: true, disabled: props.disabled, variant: inputVariant }));
    }
    if (props.field.input === InputType.Switch) {
        return (React.createElement(Switch, { value: fieldState.input.value ? fieldState.input.value[0] : fieldState.meta.initial, name: props.field.name, options: props.field.validations[0].options.map(function (option) {
                return {
                    key: option.value,
                    value: option.label,
                    icon: option.value === 'M' ? Male : Female
                };
            }), className: props.className, onChange: function (value, event) {
                props.onChange && props.onChange(props.field.name, value);
                fieldState.input.onChange(event);
            }, onBlur: function () { return fieldState.input.onBlur(); }, error: error, disabled: props.disabled }));
    }
    if (props.field.input === InputType.Autocomplete) {
        return (React.createElement(LastName, __assign({}, props, { label: label, error: error, field: props.field, onChange: function (value, event) {
                props.onChange && props.onChange(props.field.name, value);
                fieldState.input.onChange(event);
            }, onBlur: function () { return fieldState.input.onBlur(); }, passengerId: props.passengerId, passengerType: props.passengerType, fieldState: fieldState, loadTravellers: props.loadTravellers, disabled: props.disabled, travellerProfiles: travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.data, refetchTravellers: refetchTravellers, variant: inputVariant, onSwap: props.onSwap })));
    }
    if (props.field.input === InputType.Nationality) {
        return (React.createElement(Nationality, __assign({}, props, { field: props.field, label: label, error: error, fieldState: fieldState, options: props.field.validations[0].options, className: props.className, onChange: function (value, event) {
                props.onChange && props.onChange(props.field.name, value);
                fieldState.input.onChange(event);
            }, onBlur: function () { return fieldState.input.onBlur(); }, disabled: props.disabled, variant: inputVariant })));
    }
    if (props.field.input === InputType.Select) {
        var options = props.field.validations[0].options;
        var onChangeDocumentType = useCallback(function (name, value) {
            var _a, _b;
            if (fieldState.input.value === value) {
                return;
            }
            (_a = props.onTravellerSelect) === null || _a === void 0 ? void 0 : _a.call(props, selectPassenger, props.field.type, value);
            (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, name, value);
        }, [fieldState]);
        return props.field.type === TravellerFieldEnum.DocType ? (React.createElement(DocumentType, __assign({ label: label, error: error, fieldState: fieldState, onChange: onChangeDocumentType, variant: inputVariant }, props))) : (React.createElement(Select, { value: fieldState.input.value, options: options.map(function (option) { return ({
                key: option.value,
                value: option.label
            }); }), variant: inputVariant, name: props.field.name, label: label, className: props.className, onChange: onChangeSelectHandler, onBlur: fieldState.input.onBlur, error: error, disabled: props.disabled }));
    }
    return React.createElement(React.Fragment, null);
});
