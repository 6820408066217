import * as React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaggageCard from '../../BaggageCard';
import { Overweight as OverweightIcon } from '../../../Icons';
import { useTheme } from '../../../theme';
import { getOverweightMinPrice } from '../../../Checkout/store/order/selectors';
import { BaggageType } from '@websky/graphql';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import { useToggleable } from '../../../hooks';
import { useSelectedServicesBySegments } from '../../UpgradeBaggageDialog/hooks';
import HeaderEquipmentItem from '../../UpgradeBaggageDialog/components/BaggageDialogHeader/HeaderEquipmentItem/HeaderEquipmentItem';
import { BaggageContext } from '../../../Baggage/context';
var OverweightBaggage = function (_a) {
    var _b;
    var className = _a.className, passengers = _a.passengers, passengerLabels = _a.passengerLabels, segments = _a.segments, services = _a.services, selectedServices = _a.selectedServices, isLoading = _a.isLoading, onUpgrade = _a.onUpgrade, disabled = _a.disabled, dynamicBaggagePricing = _a.dynamicBaggagePricing;
    var css = useTheme('OverweightBaggage').OverweightBaggage;
    var t = useTranslation('AdditionalBaggage').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var overweightMinPrice = useSelector(getOverweightMinPrice);
    var _d = useSelectedServicesBySegments(services, segments, selectedServices), selectedItems = _d.selectedItems, hasSelected = _d.hasSelected, totalPrice = _d.totalPrice;
    var segmentIds = segments.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var isReadOnly = (_b = useContext(BaggageContext)) === null || _b === void 0 ? void 0 : _b.isReadOnly;
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageCard, { className: className, classNameIcon: css.icon, icon: OverweightIcon, title: t('Overweight'), description: t('Need to add another 10-30 kg to your total checked baggage?'), fromPrice: overweightMinPrice, totalPrice: totalPrice, hasSelected: hasSelected, onEdit: open, onAdd: open, readOnly: isReadOnly }, hasSelected && (React.createElement("div", { className: css.flights }, selectedItems.map(function (service, index) {
            var isAllSegments = service.length > 1 &&
                segmentIds.every(function (id) { return service.map(function (_a) {
                    var segmentId = _a.segmentId;
                    return segmentId;
                }).includes(id); });
            var baggage = service[0].service;
            var segment = service[0].segment;
            var segmentAirports = "".concat(segment.departure.airport.iata, " \u2013 ").concat(segment.arrival.airport.iata);
            return (React.createElement("div", { className: css.flight, key: index },
                React.createElement(HeaderEquipmentItem, { className: css.flight_icon, icon: OverweightIcon, isActive: true }),
                React.createElement("div", { className: css.flight_count },
                    "x",
                    service[0].count),
                React.createElement("span", { className: css.flight_info },
                    baggage.value
                        ? "".concat(t('Up to'), " ").concat(baggage.value.amount, " ").concat(baggage.value.measurement)
                        : '',
                    ",",
                    ' ',
                    React.createElement("span", { className: css.flight_direction }, isAllSegments ? t('All flights') : segmentAirports))));
        })))),
        React.createElement(UpgradeBaggageDialog, { open: isOpen, onClose: close, onConfirm: onUpgrade, passengers: passengers, passengerLabels: passengerLabels, segments: segments, includedBaggage: [], upgradeBaggage: services, selectedServices: selectedServices, headerProps: {
                header: t('Overweight'),
                description: ''
            }, disabled: isLoading || disabled, baggageType: BaggageType.BaggageExcess, dynamicBaggagePricing: dynamicBaggagePricing, isLoading: isLoading })));
};
export default OverweightBaggage;
