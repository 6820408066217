import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FareGroup from '../../../FareGroup';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import { Choosed } from '../../../Icons';
import { Money } from '../../../Money';
import CompareFares from '../../../CompareFares/components/CompareFares';
import Modal from '../../../Modal';
import FareConditions from '../../../FareConditions/components/Component';
var SelectedFareGroup = function (_a) {
    var className = _a.className, fare = _a.fare, flightId = _a.flightId, price = _a.price, orderId = _a.orderId, hideConditionsButton = _a.hideConditionsButton;
    var css = useTheme('DesktopFlight').SelectedFareGroup;
    var t = useTranslation('FlightInfoModalContent').t;
    var _b = __read(useState(false), 2), isFareCompareOpen = _b[0], setIsFareCompareOpen = _b[1];
    var _c = __read(useState(false), 2), isFareConditionsOpen = _c[0], setFareConditionsOpen = _c[1];
    var openFareCompare = function () { return setIsFareCompareOpen(true); };
    var closeFareCompare = function () { return setIsFareCompareOpen(false); };
    var openFareConditions = function () { return setFareConditionsOpen(true); };
    var closeFareConditions = function () { return setFareConditionsOpen(false); };
    var renderButton = useCallback(function () { return (React.createElement(Button, { className: css.button, disabled: true },
        Choosed,
        t('Selected'),
        React.createElement(Money, { moneyClassName: css.button__price, amountClassName: css.button__priceAmount, money: price }))); }, [fare]);
    var renderRulesButton = useCallback(function () { return (React.createElement("div", { className: css.rules },
        React.createElement(Button, { className: css.button__rules, variant: "text", onClick: openFareConditions }, t('Fare rules')),
        !hideConditionsButton && (React.createElement(Button, { className: css.button__rules, variant: "text", onClick: openFareCompare }, t('SelectedFareGroup_View full conditions'))))); }, [fare, hideConditionsButton]);
    var renderers = useMemo(function () { return ({
        renderButton: renderButton,
        renderRulesButton: renderRulesButton
    }); }, [fare]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FareGroup, { setTooltipsOpened: function () { return null; }, className: cn(css.fare, className), titleClassName: css.title, optionsClassName: css.options, optionClassName: css.option, flightId: flightId, fare: fare, price: null, variant: "white", isSelected: true, excludeRepeatableKeyOptions: true, renderProps: renderers, disableClassForHeaderPayFeature: true, showUnavailableOptions: true, keyFeatureClassName: css.keyFeature }),
        React.createElement(Modal, { open: isFareCompareOpen, onClose: closeFareCompare, isRounded: true },
            React.createElement(CompareFares, { selectedFareName: fare.title, flightId: flightId, orderId: orderId })),
        React.createElement(Modal, { open: isFareConditionsOpen, maxWidth: "md", onClose: closeFareConditions, closeClassName: css.fareConditions__closeButton },
            React.createElement(FareConditions, { flightId: flightId, orderId: orderId }))));
};
export default SelectedFareGroup;
