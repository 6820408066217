import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckinOrderStatus } from '@websky/graphql';
import { getBaggage, getBaggagePrice, getBaggageServices, getCheckinBrandIncludedBaggage, getUpgradeBaggage, getAdditionalBaggage, getSpecialEquipment, getOverweightBaggage, getCheckinOrder, getPassengersNames, getSegmentsForServices, getTravellers, getTotalPrice } from '../../../../store/order/selectors';
import { CheckinStep, useSteps } from '../../../../utils';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { addService, equalizePassengerBaggage, removeService, replaceService, saveServices, setServices } from '../../../../store/selectedServices/actions';
import { getSelectedBaggage } from '../../../../store/selectedServices/selectors';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useConfig } from '../../../../../../context';
var Baggage = function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var dispatch = useDispatch();
    var order = useSelector(getCheckinOrder);
    var totalPrice = useSelector(getTotalPrice);
    var baggagePriceToPay = useSelector(getBaggagePrice);
    var travellers = useSelector(getTravellers);
    var segments = useSelector(getSegmentsForServices);
    var baggageServices = useSelector(getBaggageServices);
    var baggage = useSelector(getBaggage);
    var passengersNames = useSelector(getPassengersNames);
    var selectedBaggage = useSelector(getSelectedBaggage);
    var includedBaggage = useSelector(getCheckinBrandIncludedBaggage);
    var upgradeBaggage = useSelector(getUpgradeBaggage);
    var additionalBaggage = useSelector(getAdditionalBaggage);
    var specialBaggage = useSelector(getSpecialEquipment);
    var overweightBaggage = useSelector(getOverweightBaggage);
    var _b = useSteps(), goToNextStep = _b.goToNextStep, previousStep = _b.previousStep;
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').Checkin;
    var listOfSegmentIds = segments.map(function (seg) { return seg.id; });
    var dynamicBaggagePricing = useConfig().global.dynamicBaggagePricing;
    var passengerLabels = passengersNames.reduce(function (result, _a) {
        var id = _a.id, name = _a.name;
        result[id] = name;
        return result;
    }, {});
    var servicesByPassengers = travellers.map(function (traveller) {
        var _a, _b, _c;
        return ({
            id: traveller.id,
            services: (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.map(function (_a) {
                var count = _a.count, serviceId = _a.serviceId, segmentIds = _a.segmentIds, confirmedCount = _a.confirmedCount;
                return ({
                    count: count,
                    segmentIds: segmentIds,
                    serviceId: serviceId,
                    confirmedCount: confirmedCount
                });
            })) !== null && _c !== void 0 ? _c : []
        });
    });
    var onIncrease = useCallback(function (baggage, segmentId, passengerId, forAllSegments) {
        setIsLoading(true);
        var segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        var canBeAddedForAllSegments = !baggage.segmentIds.some(function (segmentIds) { return segmentIds.length > 1 && segmentIds.includes(segmentId); });
        batch(function () {
            if (canBeAddedForAllSegments) {
                segmentIds.forEach(function (segmentId) {
                    dispatch(addService({
                        service: baggageServices.find(function (_a) {
                            var id = _a.id;
                            return id === baggage.id;
                        }),
                        segmentId: segmentId,
                        passengerId: passengerId
                    }));
                });
            }
            else {
                dispatch(addService({
                    service: baggageServices.find(function (_a) {
                        var id = _a.id;
                        return id === baggage.id;
                    }),
                    segmentId: segmentId,
                    passengerId: passengerId
                }));
            }
        });
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    var onDecrease = useCallback(function (baggage, segmentId, passengerId, forAllSegments) {
        setIsLoading(true);
        var segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        batch(function () {
            segmentIds.forEach(function (segmentId) {
                dispatch(removeService({
                    service: baggageServices.find(function (_a) {
                        var id = _a.id;
                        return id === baggage.id;
                    }),
                    segmentId: segmentId,
                    passengerId: passengerId
                }));
            });
        });
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    var onReplace = useCallback(function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) {
        setIsLoading(true);
        var segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        dispatch(replaceService({
            passengerId: passengerId,
            segmentsList: new Set(segmentIds),
            oldService: baggageServices.find(function (_a) {
                var id = _a.id;
                return id === oldBaggage.id;
            }),
            newService: baggageServices.find(function (_a) {
                var id = _a.id;
                return id === newBaggage.id;
            })
        }));
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    var onModeChange = useCallback(function (passengerId) {
        setIsLoading(true);
        dispatch(equalizePassengerBaggage({
            passengerId: passengerId,
            baggageList: new Set(baggageServices.map(function (_a) {
                var id = _a.id;
                return id;
            })),
            segmentsList: new Set(listOfSegmentIds)
        }));
        setIsLoading(false);
    }, [baggageServices]);
    var setAdditionalBaggage = useCallback(function (services) {
        dispatch(setServices(services));
        dispatch(saveServices());
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice: totalPrice,
                onButtonClick: function () { return goToNextStep(CheckinStep.Baggage); },
                additional: baggagePriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Baggage'),
                    ": ",
                    React.createElement(Money, { money: baggagePriceToPay }))) : null
            }, mobileToolbarProps: {
                onContinue: function () { return goToNextStep(CheckinStep.Baggage); },
                onBack: function () { return previousStep(CheckinStep.Baggage); }
            } },
            React.createElement(BaggagePage, { baggage: baggage, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, passengers: servicesByPassengers, passengerLabels: passengerLabels, segments: segments, onIncrease: onIncrease, onDecrease: onDecrease, onReplace: onReplace, onModeChange: onModeChange, setAdditionalBaggage: setAdditionalBaggage, disabled: order.status === CheckinOrderStatus.Expired, dynamicBaggagePricing: dynamicBaggagePricing })),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default Baggage;
