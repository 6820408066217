import * as React from 'react';
import { endOfWeek } from 'date-fns';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { format, TABLET_MIN_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import Dropdown from '../../../Dropdown';
import Button from '../../../Button';
import Weekpicker from '../Weekpicker/Weekpicker';
import { CalendarIcon } from '../../../Icons';
import Modal from '../../../Modal';
var DateSwitcher = function (_a) {
    var _b;
    var className = _a.className, date = _a.date, _c = _a.availableDates, availableDates = _c === void 0 ? [] : _c, onChangeDate = _a.onChangeDate, renderTrigger = _a.renderTrigger;
    var theme = useTheme('CoreSchedule').DateSwitcher;
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    return (React.createElement("div", { className: cn(theme.container, className) }, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ open: open })) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: theme.selector, onClick: open },
        React.createElement("div", { className: theme.icon }, CalendarIcon),
        format(date, 'dd'),
        " \u2014 ",
        format(endOfWeek(date, { weekStartsOn: 1 }), 'dd MMM'))),
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH + 1 },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: close },
                React.createElement(Weekpicker, { className: theme.weekpicker, date: date, availableDates: availableDates, onSelect: onChangeDate }))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(Modal, { classes: { scrollBody: theme.scrollBody, paper: theme.paper }, open: isOpen, onClose: close },
                React.createElement(Weekpicker, { className: theme.weekpicker, date: date, availableDates: availableDates, onSelect: onChangeDate, monthCount: 3 })))));
};
export default DateSwitcher;
