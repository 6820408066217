import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CartService } from '../../Cart/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { groupServices } from '../../Cart/utils';
import { Meal } from '../../Icons';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getActualProduct } from '../utils';
var MealSummary = function (props) {
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (travellerId, serviceId, segmentId, allSegments) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Meal, {
            type: OrderAdditionalServiceGdsServiceServiceType.Meal,
            passengerId: travellerId,
            segmentId: segmentId,
            serviceId: serviceId,
            allSegments: allSegments
        });
    };
    var meal = React.useMemo(function () {
        var travellerItems = [];
        props.travellers.forEach(function (traveller) {
            var _a;
            var groupedService = groupServices(props.services, traveller, traveller.id, OrderAdditionalServiceGdsServiceServiceType.Meal, props.flight.segments);
            var items = [];
            (_a = Object.keys(groupedService[traveller.id] || {})) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                if (groupedService[traveller.id][key].additional) {
                    groupedService[traveller.id][key].additional.forEach(function (selectedService) {
                        var segment = props.flight.segments.find(function (segment) { return segment.segment.id === key; }), direction = key === 'allSegments'
                            ? t('All flights')
                            : "".concat(segment.segment.departure.airport.iata, " - ").concat(segment.segment.arrival.airport.iata);
                        var actualProduct = getActualProduct(selectedService.products);
                        items.push({
                            name: "".concat(direction, ", ").concat(selectedService.count, "x ").concat(selectedService.service.name),
                            id: selectedService.service.id,
                            included: selectedService.count === selectedService.confirmedCount,
                            price: selectedService.service.price,
                            status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                            onDelete: function () {
                                return onDeleteHandler(traveller.id, selectedService.serviceId, key, key === 'allSegments');
                            }
                        });
                    });
                }
            });
            if (!!items.length) {
                travellerItems.push({
                    traveller: props.travellersById.get(traveller.id),
                    headerType: 'travellerName',
                    items: items,
                    headerIcon: Meal
                });
            }
        });
        return travellerItems;
    }, [props.services, props.travellers, props.flight]);
    var content = React.useMemo(function () {
        return !!meal.length ? (React.createElement(ServiceSummaryDivider, null, meal.map(function (meal) {
            return React.createElement(CartItemOptionAdapter, { option: meal, key: meal.header });
        }))) : null;
    }, [meal]);
    return props.children(content);
};
export default MealSummary;
