import * as React from 'react';
import { useContext } from 'react';
import PriceBlock from './PriceBlock/PriceBlock';
import Button from '../../../Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { BaggageContext } from '../../../Baggage/context';
var BaggageTotalPriceBlock = function (_a) {
    var _b;
    var _c;
    var min = _a.min, total = _a.total, onClickButton = _a.onClickButton;
    var css = useTheme('BaggageTotalPriceBlock').BaggageTotalPriceBlock;
    var t = useTranslation('AdditionalBaggage').t;
    var isReadOnly = (_c = useContext(BaggageContext)) === null || _c === void 0 ? void 0 : _c.isReadOnly;
    var edit = (total === null || total === void 0 ? void 0 : total.amount) !== undefined;
    return (React.createElement("div", { className: css.root },
        React.createElement(PriceBlock, { className: css.price, total: total, min: min }),
        React.createElement(Button, { className: cn(css.control, (_b = {},
                _b[css.control__edit] = edit && !isReadOnly,
                _b)), onClick: onClickButton, size: 'small' }, isReadOnly ? t('Checkout:More details') : t(edit ? 'Edit' : 'Add'))));
};
export default BaggageTotalPriceBlock;
