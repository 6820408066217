import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import PassengersComponent from '@websky/core/src/SearchForm/components/SearchForm/Passengers/Passengers';
import css from './Passengers.css';
var Passengers = function (props) {
    var t = useTranslation('SearchForm').t;
    return (React.createElement("div", { className: css.container },
        React.createElement("span", { className: css.label }, t('Passengers count')),
        React.createElement(PassengersComponent, __assign({}, props))));
};
export default Passengers;
