var _a;
import { __assign } from "tslib";
import { useContext } from 'react';
import * as React from 'react';
import merge from 'lodash.merge';
import { Locale, Mode } from './types';
import { extendI18n, changeLocale } from './utils';
import { setApiUrl } from './graphql-client';
import { StepsEnum } from './types';
import { OauthServiceType } from '@websky/graphql';
export var defaultPassengersConfig = [
    {
        code: 'ADT',
        isAdult: true,
        defaultCount: 1
    },
    {
        code: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 1
    },
    {
        code: 'INF',
        isAdult: false
    },
    {
        code: 'INS',
        isAdult: false
    }
];
export var defaultConfig = {
    FlightStatus: {
        engineURL: '',
        appStoreURL: 'https://apps.apple.com/id/app/websky-app/id1567545689',
        playMarketURL: 'https://play.google.com/store/apps/details?id=aero.websky.demo'
    },
    SearchForm: {
        redwingsForeignCardPaymentCheckbox: false,
        enableComplexRoute: true,
        mobileFormType: 'alternative',
        engineURL: '',
        payByMiles: false,
        requestCoordintates: {
            desktop: true,
            mobile: true
        },
        passengersConfig: defaultPassengersConfig,
        offerHotels: false,
        offerHotelsLink: 'https://ostrovok.ru/hotels'
    },
    Engine: {
        searchFormURL: '',
        checkInURL: '',
        accountURL: '',
        mealWarning: '',
        promoLoaders: [],
        checkInEnabled: false,
        showRefundRedirectButton: false,
        calendarDaysCount: 7,
        showAvailableFaresOnly: false,
        sortResultPreferCurrentAirline: false,
        resultsFareSelectorInModal: true,
        ignoreBlockedPaymentToReturnOrder: false,
        googleOneTapSignInClientId: null
    },
    Account: {
        engineURL: '',
        showUserPhoneInput: true,
        showCommunications: false,
        showSubscribeCheckbox: false
    },
    Checkin: {
        CheckinConditions: ['OpenCloseCheckinCondition', 'BaggageCondition', 'PassengersCondition']
    },
    global: {
        socialAuthMethods: [
            { service: OauthServiceType.Vkontakte, isAvailable: false },
            { service: OauthServiceType.Google, isAvailable: false },
            { service: OauthServiceType.Facebook, isAvailable: false },
            { service: OauthServiceType.TinkoffID, isAvailable: false }
        ],
        authMethods: {
            phone: true,
            ffp: true,
            email: true
        },
        showIsActualizationResultOk: true,
        allowPromoCodes: false,
        locale: Locale.Russian,
        i18n: {},
        findOrderURL: '',
        seatMap: {
            splitByCabin: false,
            showRecommendedSeats: false
        },
        insurance: {
            upsaleStrategy: ['checkout-order-service']
        },
        reserveEngines: {
            siteUrl: 'https://websky.tech'
        },
        companyInfo: {
            loyaltyProgramName: 'Websky Club',
            iataCode: 'ZZ',
            exarePrivacyPolicyURL: 'https://google.com',
            exareRulesURL: 'https://google.com',
            subsidyFaresConditionURL: 'https://websky.aero/',
            transferM2ConditionURL: null,
            loyaltyProgramRegistrationUrl: '',
            loyaltyProgramAccountUrl: null,
            loyalty: {
                links: [
                    {
                        title: 'Statuses',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'Earn',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'Use',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'FAQ',
                        url: 'https://websky.tech'
                    }
                ]
            },
            multiInsurance: true,
            specialDocumentForInsurance: true
        },
        servicesAvailable: {
            animal: false
        },
        stepsAvailable: (_a = {},
            _a[StepsEnum.Meal] = true,
            _a),
        FFPRegex: /^\d{6}$/,
        roundMoneyAmount: false
    }
};
export var applyConfig = function (config) {
    var _a, _b, _c;
    if ((_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.locale) {
        changeLocale(config.global.locale);
    }
    if ((_b = config === null || config === void 0 ? void 0 : config.global) === null || _b === void 0 ? void 0 : _b.i18n) {
        extendI18n(config.global.i18n);
    }
    if ((_c = config === null || config === void 0 ? void 0 : config.global) === null || _c === void 0 ? void 0 : _c.apiUrl) {
        setApiUrl(config.global.apiUrl);
    }
    var mergedConfig = merge({}, defaultConfig, config);
    window.__webskyEngineConfig = Object.freeze(__assign({}, mergedConfig));
    window['__webskyChangeLocale'] = function (locale) {
        changeLocale(locale).then(window.location.reload);
    };
    window['__webskyCoreVersion'] = process.env.PACKAGE_VERSION;
    return mergedConfig;
};
export var ConfigContext = React.createContext(defaultConfig);
export var useConfig = function () { return useContext(ConfigContext); };
export var ModeContext = React.createContext(Mode.Booking);
