import * as React from 'react';
import MediaQuery from 'react-responsive';
import MobileFlight from '../MobileFlight';
import { TABLET_MIN_WIDTH } from '../utils';
import DesktopFlightRoute from '../DesktopFlightRoute/components/DesktopFlightRoute';
import { TransitTooltipContext } from '../TransitTooltip/utils';
var Flight = function (_a) {
    var _b;
    var flight = _a.flight, singleFlight = _a.singleFlight, onSelect = _a.onSelect, _c = _a.selectedFares, selectedFares = _c === void 0 ? [] : _c, preselectedFlightClass = _a.preselectedFlightClass, hidePrice = _a.hidePrice, readonly = _a.readonly, isRecommended = _a.isRecommended, isSelected = _a.isSelected, canceled = _a.canceled, paid = _a.paid, orderId = _a.orderId, searchParameters = _a.searchParameters, hideOnlyPrice = _a.hideOnlyPrice, onSubsidySuggestPopupOpen = _a.onSubsidySuggestPopupOpen, showSeatsLeftForAllFares = _a.showSeatsLeftForAllFares, hideConditionsButton = _a.hideConditionsButton, isReturnFlight = _a.isReturnFlight, _d = _a.isSelectorInModal, isSelectorInModal = _d === void 0 ? true : _d, onSetViewFlight = _a.onSetViewFlight;
    var isTransitTariff = (_b = flight.pricesForFareGroups) === null || _b === void 0 ? void 0 : _b.some(function (fareGroup) { var _a, _b, _c, _d, _e; return ((_e = (_d = (_c = (_b = (_a = fareGroup.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight) === null || _c === void 0 ? void 0 : _c.fares) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.__typename) === 'FlightFareM2'; });
    return (React.createElement(TransitTooltipContext.Provider, { value: { isTransitTariff: isTransitTariff } },
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(DesktopFlightRoute, { orderId: orderId, selectedFares: selectedFares, flight: flight, isRecommended: isRecommended, singleFlight: singleFlight, onSelect: onSelect, isSelected: isSelected, canceled: canceled, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters, hideOnlyPrice: hideOnlyPrice, onSubsidySuggestModalOpen: onSubsidySuggestPopupOpen, preselectedFlightClass: preselectedFlightClass, showSeatsLeftForAllFares: showSeatsLeftForAllFares, hideConditionsButton: hideConditionsButton, isReturnFlight: isReturnFlight, isSelectorInModal: isSelectorInModal, onSetViewFlight: onSetViewFlight })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(MobileFlight, { showAirplane: singleFlight, isRecommended: isRecommended, isSelected: isSelected, isPreOpen: false, flight: flight, onSelect: onSelect, selectedFares: selectedFares, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters, hideOnlyPrice: hideOnlyPrice, canceled: canceled, orderId: orderId, onSubsidySuggestPopupOpen: onSubsidySuggestPopupOpen, preselectedFlightClass: preselectedFlightClass, onSetViewFlight: onSetViewFlight }))));
};
export default Flight;
