import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import cn from 'classnames';
import { differenceInDays, isBefore, isSameDay, parse, parseISO, subDays, toDate } from 'date-fns';
import { createDatesArray } from '../utils';
import { Day } from './Day/Day';
import Slider from '../../Slider/components/Slider';
import { Currency } from '../../enums';
import { ThemeProvider, useTheme } from '../../theme';
import { getDateWithoutTimezone } from '../../Engine/components/FastSearch/utils';
import { useConfig } from '../../context';
import { API_DATE_FORMAT } from '../../utils';
import { useRenderers } from '../../renderProps';
var WeekCalendar = function (props) {
    var _a, _b, _c, _d;
    var prices = props.prices, pricesMap = new Map(), theme = useTheme('WeekCalendar').WeekCalendarStyles, sliderStyles = useTheme('WeekCalendar').SliderStyles, minPrice = {
        amount: prices.length && prices[0].price ? prices[0].price.amount : 0,
        currency: prices.length && prices[0].price ? prices[0].price.currency : Currency.USD
    };
    var calendarDaysCount = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.Engine.calendarDaysCount;
    var minDate = null;
    var maxDate = null;
    prices.map(function (price) {
        var dateTimestamp = new Date(price.date).getTime();
        if (minDate === null || dateTimestamp < minDate) {
            minDate = dateTimestamp;
        }
        if (maxDate === null || dateTimestamp > maxDate) {
            maxDate = dateTimestamp;
        }
        if (price.price && price.price.amount < minPrice.amount) {
            minPrice.amount = price.price.amount;
        }
        pricesMap.set(dateTimestamp, {
            date: price.date instanceof Date ? toDate(price.date) : parseISO(price.date),
            price: price.price
        });
    });
    var dates = createDatesArray(toDate(minDate), toDate(maxDate));
    var hideDisabledLeftArrow = prices.some(function (item) {
        if (item === null || item === void 0 ? void 0 : item.date) {
            var date = parse(item.date, API_DATE_FORMAT, new Date());
            return isBefore(date, new Date()) && !isSameDay(date, new Date());
        }
        return true;
    });
    var onRangeChange = function (direction, startIndex, itemsCount) {
        props.onRangeChange(direction === 'prev' ? 'decrease' : 'increase', dates[startIndex], itemsCount);
    };
    var centralDateTimestamp = props.centralDate.getTime();
    var selectedDatePriceIsMin = pricesMap.has(centralDateTimestamp) && ((_c = (_b = pricesMap.get(centralDateTimestamp)) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount) === minPrice.amount;
    /**
     * unique prices for this week
     */
    var samePriceAllWeek = useMemo(function () {
        return new Set(__spreadArray([], __read(pricesMap.values()), false).filter(function (dateWithPrice) { var _a; return (_a = dateWithPrice === null || dateWithPrice === void 0 ? void 0 : dateWithPrice.price) === null || _a === void 0 ? void 0 : _a.amount; })
            .map(function (dateWithPrice) { var _a; return (_a = dateWithPrice === null || dateWithPrice === void 0 ? void 0 : dateWithPrice.price) === null || _a === void 0 ? void 0 : _a.amount; })).size === 1;
    }, [pricesMap]);
    var OverrideDay = (_d = useRenderers().CalendarDay) !== null && _d !== void 0 ? _d : Day;
    var compareSelectedDates = function (date) {
        return (props.centralDate &&
            props.centralDate.getDate() === getDateWithoutTimezone(date).getDate() &&
            props.centralDate.getMonth() === getDateWithoutTimezone(date).getMonth());
    };
    return prices.length ? (React.createElement("div", { className: theme.container },
        React.createElement(ThemeProvider, { value: { Slider: { SliderTheme: __assign({}, sliderStyles) } } },
            React.createElement(Slider, { maxItemsCount: calendarDaysCount, minItemWidth: 100, centeredItem: props.centralDate ? differenceInDays(props.centralDate, minDate) + 1 : 0, onRangeChange: props.onRangeChange && onRangeChange, hideDisabledLeftArrow: hideDisabledLeftArrow, hideDisabledRightArrow: false }, dates.map(function (date, index) {
                var _a;
                var _b;
                var timestamp = date.getTime();
                var price = pricesMap.has(timestamp) ? pricesMap.get(timestamp) : null;
                var isSelected = compareSelectedDates(date);
                var isCheap = selectedDatePriceIsMin
                    ? isSelected
                    : !samePriceAllWeek && ((_b = price === null || price === void 0 ? void 0 : price.price) === null || _b === void 0 ? void 0 : _b.amount) === minPrice.amount;
                var isDisabled = props.minDate ? isBefore(date, props.minDate) : false;
                var isAvailable = !isDisabled && props.isMilesSearch
                    ? !isBefore(date, subDays(new Date(), 1))
                    : !!(price === null || price === void 0 ? void 0 : price.price);
                return (React.createElement("div", { className: cn(theme.dayWrapper, (_a = {},
                        _a[theme.dayWrapper_selected] = isSelected,
                        _a)), key: index },
                    React.createElement(OverrideDay, { date: getDateWithoutTimezone(date), price: price && price.price, isAvailable: isAvailable, isCheap: isCheap, isSelected: isSelected, onSelect: props.onSelect, hidePrice: props.hidePrice || props.isMilesSearch })));
            }))))) : null;
};
WeekCalendar.displayName = 'WeekCalendar';
export default WeekCalendar;
