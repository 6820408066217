import { __read, __rest } from "tslib";
import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';
import Login from './Login/Login';
import CreateAccount from './CreateAccount/CreateAccount';
import { useResponseErrorParser } from './hooks';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { OverrideComponent } from '../../../../renderProps';
import SimpleLoader from '../../../../SimpleLoader';
import { LoginType, useCreateFfpAccountMutation, useFfpDefaultAccountQuery, useGetAccountInfoQuery } from '@websky/graphql';
import WarningModal from '../../../../WarningModal';
import { useTheme } from '../../../../theme';
export var ConnectType;
(function (ConnectType) {
    ConnectType["Login"] = "Login";
    ConnectType["Reset"] = "Reset";
    ConnectType["Create"] = "Create";
})(ConnectType || (ConnectType = {}));
var LoyaltyConnect = function (_a) {
    var _b, _c;
    var _d = _a.type, connectType = _d === void 0 ? ConnectType.Login : _d, props = __rest(_a, ["type"]);
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').LoyaltyConnect;
    var _e = __read(React.useState(connectType), 2), type = _e[0], typeChange = _e[1];
    var parseResponse = useResponseErrorParser().parseResponse;
    var _f = __read(React.useState(null), 2), error = _f[0], setError = _f[1];
    var _g = __read(React.useState(false), 2), createAccountSuccess = _g[0], setCreateAccountSuccess = _g[1];
    var _h = useFfpDefaultAccountQuery(), data = _h.data, isDefaultDataLoading = _h.loading;
    var _j = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache',
        skip: type !== ConnectType.Create
    }), accountInfo = _j.data, accountInfoLoading = _j.loading;
    var _k = __read(useCreateFfpAccountMutation(), 1), createAccountMutation = _k[0];
    var createAccountRequest = function (data) {
        return createAccountMutation({
            variables: {
                params: data
            }
        }).then(function (response) {
            var _a;
            var _b, _c;
            if (!((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.CreateFfpAccount) === null || _c === void 0 ? void 0 : _c.result)) {
                // TODO: HACK fix it
                if (response.data.CreateFfpAccount.message === 'Сообщение программы: invalid FFP LK system') {
                    setCreateAccountSuccess(response.data.CreateFfpAccount.result);
                    setTimeout(function () {
                        typeChange(ConnectType.Login);
                    }, 2000);
                    return undefined;
                }
                setError(parseResponse(response.data.CreateFfpAccount.message));
                return _a = {},
                    _a[FORM_ERROR] = parseResponse(response.data.CreateFfpAccount.message),
                    _a;
            }
            else {
                setCreateAccountSuccess(response.data.CreateFfpAccount.result);
            }
            setTimeout(function () {
                typeChange(ConnectType.Login);
            }, 2000);
            return undefined;
        });
    };
    var toCreateAccount = function () {
        if (props.showRegisterAction) {
            typeChange(ConnectType.Create);
        }
    };
    var toResetPassword = function () {
        typeChange(ConnectType.Reset);
    };
    var closeErrorHandler = function () { return setError(null); };
    if (type === ConnectType.Login) {
        return (React.createElement(Login, { onLoyaltyCardSaved: props.onLoyaltyCardSaved, toCreateAccount: toCreateAccount, toResetPassword: toResetPassword, showRegisterAction: props.showRegisterAction, queryType: props.authType }));
    }
    else if (type === ConnectType.Reset) {
        return React.createElement(ForgotPassword, { toLogin: function () { return typeChange(ConnectType.Login); }, email: props.userInfo.email });
    }
    else {
        if (isDefaultDataLoading || accountInfoLoading) {
            return React.createElement(SimpleLoader, null);
        }
        var hasConfirmedEmail = (_c = (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.some(function (method) { return method.loginType === LoginType.Email && method.confirmed; });
        if (!hasConfirmedEmail) {
            return (React.createElement(WarningModal, { classes: { root: css.warning__modal }, title: t('Oops...'), content: t('To continue registering in the loyalty system, fill out and confirm your email in your personal account.'), isOpen: true, onClose: props.onClose, buttonText: t('Fill email'), onButtonClick: props.onGoToSettings }));
        }
        return (React.createElement(OverrideComponent, { component: {
                CreateFfpAccountForm: CreateAccount
            }, componentProps: {
                initialValues: data,
                onCreateAccount: createAccountRequest,
                success: createAccountSuccess,
                toLogin: function () { return typeChange(ConnectType.Login); },
                error: error,
                closeError: closeErrorHandler
            } }));
    }
};
export default LoyaltyConnect;
