export var InputType;
(function (InputType) {
    InputType["Text"] = "text";
    InputType["Date"] = "date";
    InputType["Select"] = "select";
    InputType["Switch"] = "radio";
    InputType["Autocomplete"] = "autocomplete";
    InputType["Phone"] = "phone";
    InputType["Checkbox"] = "checkbox";
    InputType["Nationality"] = "nationality";
})(InputType || (InputType = {}));
export var AdditionalTravellerFieldEnum;
(function (AdditionalTravellerFieldEnum) {
    AdditionalTravellerFieldEnum["OrganizationName"] = "OrganizationName";
    AdditionalTravellerFieldEnum["AccompanyingCheck"] = "AccompanyingCheck";
    AdditionalTravellerFieldEnum["PersonnelNumber"] = "PersonnelNumber";
    AdditionalTravellerFieldEnum["ChangeTravellerFile"] = "ChangeTravellerFile";
})(AdditionalTravellerFieldEnum || (AdditionalTravellerFieldEnum = {}));
export var FieldGroupType;
(function (FieldGroupType) {
    FieldGroupType["Identity"] = "identity";
    FieldGroupType["Citizenship"] = "citizenship";
    FieldGroupType["Loyalty"] = "loyalty";
    FieldGroupType["Other"] = "other";
    FieldGroupType["Contacts"] = "contacts";
})(FieldGroupType || (FieldGroupType = {}));
