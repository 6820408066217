import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from '@websky/core/src';
import { useConfig } from '@websky/core/src/context';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@websky/core/src/theme';
import FareConditions from '@websky/core/src/FareConditions/components/Component';
import Modal from '@websky/core/src/Modal';
import { getOrder } from '@websky/core/src/Checkout/store/order/selectors';
var PassengersToolbarCheckbox = function (_a) {
    var order = _a.order;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').PassengersToolbar;
    var companyInfo = useConfig().global.companyInfo;
    var _b = __read(useState(false), 2), isFareModalOpen = _b[0], setIsFareModalOpen = _b[1];
    var openFareModal = function (e) {
        e.preventDefault();
        setIsFareModalOpen(true);
    };
    var closeFareModal = function () {
        setIsFareModalOpen(false);
    };
    return (React.createElement("span", null,
        t('I accept the'),
        ' ',
        companyInfo.fareRulesURL && (React.createElement(React.Fragment, null,
            React.createElement(Link, { target: "_blank", action: "".concat(companyInfo.fareRulesURL) }, t('fare rules')),
            ",\u00A0")),
        React.createElement("span", { className: css.terms, onClick: openFareModal }, t('terms of tariff')),
        ', ',
        companyInfo.legal ? (React.createElement(React.Fragment, null,
            React.createElement(Link, { target: "_blank", action: "".concat(companyInfo.legal) }, t('terms of agreement')),
            companyInfo.privacyPolicy && (React.createElement(React.Fragment, null,
                "\u00A0",
                React.createElement("span", null, t('and')),
                "\u00A0",
                React.createElement(Link, { target: "_blank", action: "".concat(companyInfo.privacyPolicy) }, t('processing of my personal data')))))) : (t('terms of agreement')),
        React.createElement(Modal, { open: isFareModalOpen, onClose: closeFareModal },
            React.createElement(FareConditions, { segmentId: order.flight.segments[0].segment.id, flightId: order.flight.id, orderId: order.id }))));
};
var mapStateToProps = function (state) { return ({
    order: getOrder(state)
}); };
var connector = connect(mapStateToProps);
export default connector(PassengersToolbarCheckbox);
