import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useTheme } from '../../theme';
import { useRetrieveBookingValidation } from '../../RetrieveBooking/hooks';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Chevron } from '../../Icons';
import Input from '../../Input';
import Button from '../../Button';
import TicketExample from '../../TicketExample';
import BookingPreview from './BookingPreview/BookingPreview';
import Loader from '../../BaseComponents/Button/components/Loader/Loader';
export var FormType;
(function (FormType) {
    FormType["Booking"] = "Booking";
    FormType["Checkin"] = "Checkin";
})(FormType || (FormType = {}));
var AddBooking = function (_a) {
    var className = _a.className, type = _a.type, isLoading = _a.isLoading, onClose = _a.onClose, onSubmit = _a.onSubmit, order = _a.order, onView = _a.onView;
    var css = useTheme('AddBooking').AddBooking;
    var t = useTranslation('Account').t;
    var _b = useRetrieveBookingValidation(), lastNameValidation = _b.lastNameValidation, ticketNumberValidation = _b.ticketNumberValidation, validateByOrderNumber = _b.validateByOrderNumber, ticketNumberFormat = _b.ticketNumberFormat;
    var onAddOrder = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSubmit(__assign(__assign({}, values), { saveInProfile: true }))];
                case 1:
                    _a.sent();
                    onClose();
                    onView();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.close, onClick: onClose }),
        React.createElement("div", { className: css.title }, type === FormType.Booking ? t('Add booking') : t('Online check-in')),
        React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var _b;
                var handleSubmit = _a.handleSubmit, values = _a.values;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.inputs },
                        React.createElement(Field, { name: "lastName", validate: function (value) {
                                if (!value) {
                                    return t('AddPassenger:Required field');
                                }
                                if (type === FormType.Checkin) {
                                    return lastNameValidation(value);
                                }
                            }, render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, __assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                        type: 'lastName',
                                        placeholder: t('Last name'),
                                        error: (meta.error || meta.submitError) && meta.touched,
                                        helperText: !!meta.error && meta.touched ? meta.error : '',
                                        onChange: function (event) {
                                            input.onChange(event);
                                        }
                                    } })));
                            } }),
                        React.createElement(Field, { name: "ticket", parse: ticketNumberFormat, validate: function (value) {
                                if (!value) {
                                    return t('AddPassenger:Required field');
                                }
                                if (type === FormType.Checkin) {
                                    return ticketNumberValidation(value);
                                }
                                if (type === FormType.Booking) {
                                    if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                        return undefined;
                                    }
                                    else {
                                        return ticketNumberValidation(value) || validateByOrderNumber(value);
                                    }
                                }
                            }, render: function (_a) {
                                var _b;
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, __assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                        type: 'ticket',
                                        placeholder: t('Ticket or booking number'),
                                        error: (meta.error || meta.submitError) && meta.touched,
                                        helperText: (meta.error || meta.submitError) && meta.touched
                                            ? (_b = meta.submitError) !== null && _b !== void 0 ? _b : meta.error
                                            : '',
                                        onChange: function (event) {
                                            input.onChange(event);
                                        }
                                    } })));
                            } })),
                    React.createElement("div", { className: css.spoilers },
                        React.createElement(Accordion, { classes: {
                                root: css.spoiler,
                                expanded: css.spoilerExpanded
                            } },
                            React.createElement(AccordionSummary, { classes: {
                                    root: css.spoilerHeader,
                                    content: css.spoilerHeaderContent,
                                    expanded: css.spoilerHeaderExpended
                                } },
                                Chevron,
                                t('How to find a booking id?')),
                            React.createElement(AccordionDetails, { classes: {
                                    root: css.spoilerDetails
                                } },
                                React.createElement("p", null, t('Enter the passengers surname (in latin characters) and ticket number, order ID or booking ID. You can find these in the itinerary receipt sent to purchasers Email after booking.')),
                                React.createElement(TicketExample, null)))),
                    isLoading && !order && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css.preview_title }, t('Searching flight')),
                        React.createElement("div", { className: css.preloader },
                            React.createElement(Loader, null)))),
                    !!order && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css.preview_title }, t('Flight found')),
                        React.createElement(BookingPreview, { accessCode: order === null || order === void 0 ? void 0 : order.accessCode, travellersAmount: order === null || order === void 0 ? void 0 : order.travellers.length, flight: order === null || order === void 0 ? void 0 : order.flight }))),
                    !order ? (React.createElement("div", { className: css.footer },
                        !isLoading && (React.createElement(Button, { className: cn(css.button, css.buttonCancel), onClick: onClose }, t('Cancel'))),
                        React.createElement(Button, { className: cn(css.button, css.buttonContinue, (_b = {}, _b[css.buttonDisabled] = isLoading, _b)), type: 'submit', disabled: isLoading }, t('Continue')))) : (React.createElement("div", { className: css.footer },
                        !isLoading && (React.createElement(Button, { className: cn(css.button, css.buttonCancel), onClick: onView }, t('View booking'))),
                        React.createElement(Button, { className: cn(css.button, css.buttonContinue), isLoading: isLoading, onClick: function () { return onAddOrder(values); } }, t('View and add to cabinet'))))));
            } })));
};
export default AddBooking;
