import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { PromoCodeCard } from './PromoCodeCard/PromoCodeCard';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { PaximizerCommunicationSectionEnum, useGetPaxMyPromoCodeTeasersQuery } from '@websky/graphql';
import InfiniteScroll from 'react-infinite-scroller';
import { useCommunicationList } from '../../hooks';
import Loader from '../../../Loader';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
export var PromoCodesPage = function () {
    var css = useTheme('Communications').Communication;
    var path = useRouteMatch().path;
    var _a = useGetPaxMyPromoCodeTeasersQuery({ skip: true }), loading = _a.loading, refetch = _a.refetch;
    var loadPromoCodes = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetch({
                        offset: values.offset,
                        count: values.count
                    })];
                case 1:
                    data = (_a.sent()).data;
                    notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeTeasers;
                    return [2 /*return*/, {
                            items: (notification === null || notification === void 0 ? void 0 : notification.items.length) ? notification.items : [],
                            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0
                        }];
            }
        });
    }); };
    var _b = useCommunicationList(PaximizerCommunicationSectionEnum.my_promo_codes, loadPromoCodes, loading), readIds = _b.readIds, markAsRead = _b.markAsRead, promoCodes = _b.items, loadMore = _b.loadMore, pagination = _b.pagination, errorMessage = _b.errorMessage;
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.my_promo_codes, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "promocodes-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, promoCodes.map(function (item) { return (React.createElement(Link, { key: item.id, to: "".concat(path, "/").concat(item.id) },
                React.createElement(PromoCodeCard, { title: item.title, code: item.code, endDate: item.endDate, isRead: item.read || readIds[item.id], isReusable: item.isReusable, isPersonal: item.isPersonal, usedAt: item.usedAt, markAsRead: function () { return markAsRead(item.id); } }))); })))));
};
