import { __read } from "tslib";
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from '../LoginForm/LoginForm';
import { Steps } from '../../../types';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import CodeForm from '../CodeForm/CodeForm';
import Welcome from '../Welcome/Welcome';
import WarningModal from '../../../../WarningModal';
var BaseComponent = function (_a) {
    var initialLogin = _a.login, onSuccess = _a.onSuccess;
    var t = useTranslation('LoginPage').t;
    var _b = __read(useState(), 2), loginData = _b[0], setLoginData = _b[1];
    var _c = __read(useState(Steps.LogIn), 2), step = _c[0], setStep = _c[1];
    var firstLogin = useRef(null);
    var _d = __read(useState(false), 2), stayLoggedIn = _d[0], setStayLogged = _d[1];
    var _e = __read(useState(null), 2), error = _e[0], setError = _e[1];
    var onLogIn = useCallback(function (data) {
        setLoginData({
            login: data.login,
            loginType: data.type
        });
        firstLogin.current = data.needRegistration;
        if (data.type === 'FFP') {
            setStep(Steps.EnterCode);
        }
        else {
            if (data.needRegistration) {
                setStep(Steps.Registration);
            }
            else {
                setStep(Steps.EnterCode);
            }
        }
    }, []);
    var onRegistration = useCallback(function () {
        setStep(Steps.EnterCode);
    }, [loginData]);
    var onVerified = useCallback(function () {
        setStep(Steps.Welcome);
        setTimeout(function () {
            if (onSuccess) {
                onSuccess(firstLogin.current);
            }
            else {
                window.location.reload();
            }
        }, 1500);
    }, [onSuccess]);
    var clearError = function () {
        setError(null);
        if (step === Steps.Registration) {
            setStep(Steps.LogIn);
        }
    };
    var renderForm = function () {
        switch (step) {
            case Steps.LogIn:
                return (React.createElement(LoginForm, { setStayLoggedIn: setStayLogged, stayLoggedIn: stayLoggedIn, initialData: { login: initialLogin }, onSuccess: onLogIn, onError: setError }));
            case Steps.Registration:
                return (React.createElement(RegistrationForm, { login: loginData.login, loginType: loginData.loginType, onSuccess: onRegistration, onError: setError }));
            case Steps.EnterCode:
                return (React.createElement(CodeForm, { stayLoggedIn: stayLoggedIn, login: loginData.login, loginType: loginData.loginType, onSuccess: onVerified }));
            case Steps.Welcome:
                return React.createElement(Welcome, null);
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null,
        renderForm(),
        React.createElement(WarningModal, { isOpen: !!error, onClose: clearError, title: t(error), content: t('Unfortunately, something went wrong. Please try again or contact with us') })));
};
export default BaseComponent;
