import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '@websky/core/src/theme';
import { useConfig } from '@websky/core/src/context';
import { format, MOBILE_MIN_WIDTH } from '@websky/core/src/utils';
import { CloseClear } from '@websky/core/src/Icons';
import { DatepickerTab } from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Datepicker';
import { tryParseDate } from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Value/utils';
import ManualInput from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Value/ManualInput/ManualInput';
import theme from './Value.css';
import MediaQuery from 'react-responsive';
var Value = function (_a) {
    var _b, _c, _d, _e, _f;
    var isOpen = _a.isOpen, open = _a.open, close = _a.close, setRouteType = _a.setRouteType, segmentId = _a.segmentId, withDateBack = _a.withDateBack, routeType = _a.routeType, dateBack = _a.dateBack, dateTo = _a.dateTo, onClear = _a.onClear, onDateSelect = _a.onDateSelect, setIsRouteTypeSetManually = _a.setIsRouteTypeSetManually, onClickDatepickerTabReturn = _a.onClickDatepickerTabReturn, onClickDatepickerTabOutbound = _a.onClickDatepickerTabOutbound;
    var css = useTheme('SearchForm').Datepicker;
    var t = useTranslation('SearchForm').t;
    var _g = useConfig().SearchForm, mobileFormType = _g.mobileFormType, proMode = _g.proMode;
    var alternative = mobileFormType !== 'common';
    var onManualDateEnter = function (value) {
        var parseDate = tryParseDate(value);
        if (parseDate) {
            onDateSelect(parseDate, routeType === DatepickerTab.Return);
        }
    };
    var handleRouteTypeChange = useCallback(function (e, routeType) {
        if (isOpen) {
            e.preventDefault();
            e.stopPropagation();
            setRouteType(routeType);
            setIsRouteTypeSetManually(true);
        }
        if (routeType === DatepickerTab.Return && onClickDatepickerTabReturn) {
            onClickDatepickerTabReturn();
        }
        if (routeType === DatepickerTab.Outbound && onClickDatepickerTabOutbound) {
            onClickDatepickerTabOutbound();
        }
    }, [routeType, isOpen, dateTo, dateBack]);
    return (React.createElement("div", { className: cn(css.dates, "".concat(css.dates, "_").concat(segmentId), (_b = {},
            _b[css.oneDate] = !withDateBack,
            _b[css.dates_focused] = isOpen,
            _b[css.alternative] = alternative,
            _b)), onClick: function () {
            setRouteType(DatepickerTab.Outbound);
            isOpen ? close() : open();
        } },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to, (_c = {},
                    _c[css.dates__to_focused] = isOpen && routeType === DatepickerTab.Outbound,
                    _c)) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Outbound, inputClassName: css.date__input },
                    dateTo && (React.createElement("div", { onClick: function (e) {
                            handleRouteTypeChange(e, DatepickerTab.Outbound);
                        } },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                    !dateTo && isOpen ? (React.createElement("span", { onClick: function (e) { return handleRouteTypeChange(e, DatepickerTab.Outbound); } }, t('Outbound'))) : (React.createElement("span", { className: css.placeholder }, t('Outbound'))))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, (_d = {},
                    _d[css.dates__back_focused] = isOpen && routeType === DatepickerTab.Return,
                    _d)) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Return, inputClassName: cn(css.date__input, css.date__input_back) },
                    dateBack && (React.createElement("div", { onClick: function (e) {
                            handleRouteTypeChange(e, DatepickerTab.Return);
                        } },
                        format(dateBack, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))),
                    React.createElement("span", { className: css.placeholder, onClick: function (e) { return handleRouteTypeChange(e, DatepickerTab.Return); } }, t('Date_from')))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to, (_e = {},
                    _e[css.dates__to_focused] = isOpen && routeType === DatepickerTab.Outbound,
                    _e)) },
                dateTo && (React.createElement("div", null,
                    format(dateTo, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                !dateTo && isOpen ? (React.createElement("span", { onClick: function (e) { return handleRouteTypeChange(e, DatepickerTab.Outbound); } }, t('Outbound'))) : (React.createElement("span", { className: css.placeholder }, t('Outbound')))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, (_f = {},
                    _f[css.dates__back_focused] = isOpen && routeType === DatepickerTab.Return,
                    _f)) },
                dateBack && (React.createElement("div", null,
                    format(dateBack, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))),
                React.createElement("span", { className: css.placeholder }, t('Date_from'))))),
        (dateTo || dateBack) && (React.createElement("div", { className: cn(css.returnDelete, theme.clear), onClick: function (e) {
                e.preventDefault();
                e.stopPropagation();
                onClear();
            } }, CloseClear))));
};
export default Value;
