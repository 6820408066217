import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { NotIncluded } from '../../../Icons';
import SelectedServices from '../../../Modules/Exchange/components/Exchange/SelectedServices/SelectedServices';
import CreateRequest from '../../../Modules/Exchange/components/Exchange/CreateRequest/CreateRequest';
import TicketRequest from '../../../TicketRequest';
import WarningModal from '../../../WarningModal';
import Notification from '../Notification/Notification';
import Receipt from './Receipt/Receipt';
import Controls from './Controls/Controls';
import { usePassengerTicket } from './hooks';
import { useServicesRefundInfo } from '../../../Checkout/hooks';
import { getSegmentIds } from '../../../Checkout/store/order/selectors';
initI18n('EditPassenger');
var PassengerTicket = function (_a) {
    var passenger = _a.passenger, changeTravellers = _a.changeTravellers, lastChangePaxData = _a.lastChangePaxData, prices = _a.prices, reason = _a.reason, onRefetchOrder = _a.onRefetchOrder, onGoToForm = _a.onGoToForm, onGoToPayment = _a.onGoToPayment, onReturnToOrder = _a.onReturnToOrder, setChangePaxOrder = _a.setChangePaxOrder;
    var t = useTranslation('EditPassenger').t;
    var theme = useTheme('EditPassenger').PassengerTicket;
    var segmentIds = useSelector(getSegmentIds);
    var _b = usePassengerTicket(passenger, prices, onGoToPayment, onRefetchOrder, onReturnToOrder, setChangePaxOrder, changeTravellers, lastChangePaxData, reason), order = _b.order, requestError = _b.requestError, fullName = _b.fullName, customerValues = _b.customerValues, editableValues = _b.editableValues, actualPrices = _b.actualPrices, availableActions = _b.availableActions, isLoading = _b.isLoading, isAutoMode = _b.isAutoMode, onCreateOrderRequest = _b.onCreateOrderRequest, onRefuseRequest = _b.onRefuseRequest, onConfirmRequest = _b.onConfirmRequest, onClearError = _b.onClearError;
    var _c = useServicesRefundInfo(order.id, order.travellers, [passenger.id], segmentIds, false, !!lastChangePaxData), data = _c.data, servicesRefundLoading = _c.loading;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.wrapper },
                React.createElement(TicketRequest, { className: theme.ticket, status: lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.status, requestId: lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.id, createdDate: (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.createDate) ? new Date(lastChangePaxData.createDate) : null, breadcrumbs: __spreadArray([
                        { title: t('Changes for {{name}}', { name: fullName }) }
                    ], __read(editableValues.map(function (value) { return ({ className: theme.breadcrumb, title: value }); })), false) }),
                React.createElement(SelectedServices, { className: theme.services, order: order, gdsServicesRefund: data, isLoading: servicesRefundLoading, notification: React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, type: "context", icon: NotIncluded, title: t('ServicesRestriction_Title'), infoMessage: {
                            text: t('ServicesRestriction_Description'),
                            type: InfoMessageTypeEnum.Warning,
                            code: 'warning'
                        } }) }),
                actualPrices && React.createElement(Receipt, { className: theme.receipt, totalPrice: actualPrices.priceToPay }),
                React.createElement("div", { className: theme.messages },
                    React.createElement(Notification, { status: InfoMessageTypeEnum.Info, type: "context", title: t(isAutoMode ? 'NotificationTitle_AutoMode' : 'NotificationTitle_ManualMode'), infoMessage: {
                            text: t(isAutoMode ? 'NotificationText_AutoMode' : 'NotificationText_ManualMode'),
                            type: InfoMessageTypeEnum.Info,
                            code: 'promo'
                        } })),
                !lastChangePaxData && (React.createElement(CreateRequest, { className: theme.form, header: t('Your contacts'), description: t('A new route receipt will be sent to this address'), buttonText: t('Request a change'), initialValues: customerValues, isSending: isLoading || servicesRefundLoading, send: onCreateOrderRequest, autoMode: true })),
                ((lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.managerCalculationComment) || (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.managerRejectComment)) && (React.createElement("div", { className: theme.messages },
                    lastChangePaxData.managerRejectComment && (React.createElement(Notification, { icon: NotIncluded, status: InfoMessageTypeEnum.Warning, type: "context", title: t('Rejection reason'), infoMessage: {
                            text: lastChangePaxData.managerRejectComment,
                            type: InfoMessageTypeEnum.Warning,
                            code: 'warning'
                        } })),
                    lastChangePaxData.managerCalculationComment && !lastChangePaxData.managerRejectComment && (React.createElement(Notification, { status: InfoMessageTypeEnum.Info, type: "context", title: t('Comment on the calculation'), infoMessage: {
                            text: lastChangePaxData.managerCalculationComment,
                            type: InfoMessageTypeEnum.Info,
                            code: 'info'
                        } })))),
                lastChangePaxData && (React.createElement(Controls, { onRefuse: availableActions.refuse ? onRefuseRequest : null, onGoToPayment: availableActions.payment ? onGoToPayment : null, onGoToNewRequest: availableActions.createNew ? onGoToForm : null, onConfirm: availableActions.confirm ? onConfirmRequest : null })))),
        React.createElement(WarningModal, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, title: t('Oops...'), content: (requestError === null || requestError === void 0 ? void 0 : requestError.message) || t('Refund:Unknown error'), isOpen: !!requestError, onClose: onClearError })));
};
export default PassengerTicket;
