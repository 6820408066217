import { __assign } from "tslib";
import * as React from 'react';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import cn from 'classnames';
import { useIsMobile } from '../../utils';
var WarningModal = function (_a) {
    var _b;
    var title = _a.title, content = _a.content, className = _a.className, classes = _a.classes, errorMessage = _a.errorMessage, buttonText = _a.buttonText, isOpen = _a.isOpen, hideContentText = _a.hideContentText, bottomDrawer = _a.bottomDrawer, onClose = _a.onClose, onButtonClick = _a.onButtonClick;
    var css = useTheme('WarningModal').WarningModal;
    var isMobile = useIsMobile();
    return (React.createElement(Modal, { maxWidth: "sm", open: isOpen, onClose: onClose, isRounded: bottomDrawer, classes: __assign(__assign({}, classes), { scrollBody: cn(css.scrollBody, classes === null || classes === void 0 ? void 0 : classes.scrollBody), paper: cn(css.paper, className, classes === null || classes === void 0 ? void 0 : classes.paper) }), slideUp: isMobile, bottomDrawer: bottomDrawer },
        title && React.createElement("div", { className: css.header }, title),
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: cn(css.content__text, (_b = {}, _b[css.content__text_hidden] = hideContentText, _b)) }, typeof content === 'string' ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })) : (React.createElement("div", null, content))),
            errorMessage && typeof errorMessage === 'string' && (React.createElement("div", { className: css.content__error, dangerouslySetInnerHTML: { __html: errorMessage } })),
            errorMessage && typeof errorMessage !== 'string' && (React.createElement("div", { className: css.content__error }, errorMessage)),
            buttonText && (React.createElement("div", { className: css.buttons },
                React.createElement(Button, { onClick: onButtonClick }, buttonText))))));
};
export default WarningModal;
