import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import { Mail } from '../../../Icons';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Modal from '../../../Modal';
import ResendTicketsForm from '../../../ResendTicketsForm/components/ResendTicketsForm';
import { OrderCardContext } from '../OrderCard';
import { useResendTickets } from '../../../Checkout/components/Checkout/hooks';
import { hideText } from '../../../Passenger/utils';
import CommonItem from '../CommonItem/CommonItem';
import { SlideBottom } from '../../../index';
import { getUserValue } from '../../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
var OrderTickets = function (props) {
    var t = useTranslation('OrderCard').t;
    var css = useTheme('OrderCard').OrderTickets;
    var order = useContext(OrderCardContext).order;
    var email = useMemo(function () { return getUserValue(order.customer, TravellerFieldEnum.Email); }, [order.id]);
    var _a = useResendTickets(order.id), resendStage = _a.resendStage, handleResendEmail = _a.handleResendEmail, isModalOpen = _a.isModalOpen, closeModal = _a.closeModal, openModal = _a.openModal;
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, { icon: Mail, text: t(!email ? 'Send tickets to the email?' : 'Tickets have been sent to {{email}}', {
                email: hideText(email)
            }), className: cn(css.wrapper, props.className), buttons: React.createElement(Link, { className: css.link, action: openModal }, t('Resend it')) }),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { open: isModalOpen, closeButtonRenderer: function () { return null; }, maxWidth: "sm" },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: email, onSubmit: function (_a) {
                        var email = _a.email;
                        return handleResendEmail(email);
                    }, status: resendStage }))),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(SlideBottom, { isOpen: isModalOpen, onClose: closeModal },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: email, onSubmit: function (_a) {
                        var email = _a.email;
                        return handleResendEmail(email);
                    }, status: resendStage })))));
};
export default OrderTickets;
