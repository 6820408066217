import { __assign } from "tslib";
import * as React from 'react';
import { memo, useContext } from 'react';
import { Collapse } from '@mui/material';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { DisabilitiesContext } from './context';
import { useToggleable } from '../../../../hooks';
import { Field, useFormState } from 'react-final-form';
import Switch from '../Switch/Switch';
import List from './List/List';
import { validateDisabilities } from './utils';
var Disabilities = memo(function () {
    var _a, _b;
    var theme = useTheme('PassengerForm').Disabilities;
    var t = useTranslation('Passenger').t;
    var _c = useContext(DisabilitiesContext), availableDisabilities = _c.availableDisabilities, passengerId = _c.passengerId;
    var _d = useFormState(), passengers = _d.values.passengers, errors = _d.errors, submitFailed = _d.submitFailed;
    var disabilities = passengers[passengerId].disabilities;
    var _e = useToggleable(disabilities === null || disabilities === void 0 ? void 0 : disabilities.enabled), isOpen = _e.isOpen, toggle = _e.toggle;
    var fieldNameBasePath = "passengers.".concat(passengerId, ".disabilities");
    if (!(availableDisabilities === null || availableDisabilities === void 0 ? void 0 : availableDisabilities.length)) {
        return null;
    }
    return (React.createElement("div", { className: theme.container },
        React.createElement(Field, { name: "".concat(fieldNameBasePath, ".enabled"), initialValue: isOpen, type: 'checkbox', key: JSON.stringify(disabilities.values), validate: function (value, allValues) {
                var error = validateDisabilities(allValues['passengers'][passengerId]);
                return error ? t(error) : null;
            }, render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Switch, { label: t('Passenger with disabilities'), isActive: isOpen, onClick: function () {
                            toggle();
                            input.onChange(__assign(__assign({}, new Event('change')), { target: {
                                    value: !isOpen
                                } }));
                        }, className: theme.switchControl }),
                    meta.submitFailed && meta.error && (React.createElement("div", { className: theme.switchControl__errorText }, meta.error))));
            } }),
        React.createElement(Collapse, { in: isOpen },
            React.createElement(List, { availableDisabilities: availableDisabilities, passengerId: passengerId, passengerDisabilitiesState: disabilities, fieldNameBasePath: fieldNameBasePath, isError: submitFailed && !!((_b = (_a = errors['passengers']) === null || _a === void 0 ? void 0 : _a[passengerId]) === null || _b === void 0 ? void 0 : _b['disabilities']) }))));
});
export default Disabilities;
