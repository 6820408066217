import { __assign } from "tslib";
import { ADD_PASSENGER, REMOVE_PASSENGER, SET_PASSENGERS } from './actions';
import { PassengerType } from '../../../enums';
export var MAX_PASSENGERS_COUNT = 9;
var defaultState = {
    ADT: 1,
    PDA: 0,
    CLD: 0,
    INF: 0,
    INS: 0
};
/**
 * Example: if user added ADT, Retirees should be removed according to these rules
 *
 * key - added passenger
 * value array - types to be cleared
 */
export var clearRulesMap = new Map([
    [PassengerType.Adult, [PassengerType.RetireeF, PassengerType.RetireeM, PassengerType.YoungAdult]],
    [PassengerType.YoungAdult, [PassengerType.Adult, PassengerType.Teenager]],
    [PassengerType.Teenager, [PassengerType.YoungAdult, PassengerType.RetireeM, PassengerType.RetireeF]],
    [PassengerType.RetireeM, [PassengerType.Adult, PassengerType.Teenager]],
    [PassengerType.RetireeF, [PassengerType.Adult, PassengerType.Teenager]]
]);
var clearConflictedPassengers = function (state, payload) {
    var newState = __assign({}, state);
    var typesToClear = clearRulesMap.get(payload);
    typesToClear.forEach(function (type) {
        newState[type] = 0;
    });
    return newState;
};
export var passengersReducer = function (state, _a) {
    var _b, _c, _d, _e;
    if (state === void 0) { state = defaultState; }
    var payload = _a.payload, type = _a.type;
    switch (type) {
        case ADD_PASSENGER:
            if (clearRulesMap.has(payload)) {
                var newState = clearConflictedPassengers(state, payload);
                return __assign(__assign({}, newState), (_b = {}, _b[payload] = newState[payload] += 1, _b));
            }
            return __assign(__assign({}, state), (_c = {}, _c[payload] = state[payload] += 1, _c));
        case REMOVE_PASSENGER:
            if (payload === PassengerType.Adult) {
                var adultCount = state[payload] - 1;
                var infantCount = state[PassengerType.Infant] || 0;
                var infantWithSeatCount = state[PassengerType.InfantWithSeat] || 0;
                var newState = __assign(__assign({}, state), (_d = {}, _d[payload] = adultCount, _d));
                if (newState[PassengerType.Infant]) {
                    newState[PassengerType.Infant] = adultCount <= infantCount ? adultCount : infantCount;
                }
                if (newState[PassengerType.InfantWithSeat]) {
                    newState[PassengerType.InfantWithSeat] =
                        adultCount <= infantWithSeatCount ? adultCount : infantWithSeatCount;
                }
                return newState;
            }
            else {
                return __assign(__assign({}, state), (_e = {}, _e[payload] = state[payload] -= 1, _e));
            }
        case SET_PASSENGERS:
            return __assign(__assign({}, state), payload);
        default:
            return state;
    }
};
