import { GdsServiceProductStatus } from '@websky/graphql';
export var getActualProduct = function (products) {
    var actualProduct = null;
    products === null || products === void 0 ? void 0 : products.forEach(function (product) {
        if (!actualProduct || (actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.id) < product.id) {
            actualProduct = product;
        }
    });
    return actualProduct;
};
export var pickRequestedService = function (item) { return item.status === GdsServiceProductStatus.Requested; };
export var omitRequestedService = function (item) { return !pickRequestedService(item); };
