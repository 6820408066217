import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import WeekCalendarComponent from '../../../../../WeekCalendar';
import DummyWeekCalendar from '../DummyWeekCalendar/DummyWeekCalendar';
import { getSegmentsWithOffset, sortSegments } from '../utils';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getDateWithoutTimezone } from '../../../../../Engine/components/FastSearch/utils';
import { useConfig } from '../../../../../context';
import { useMinPricesQuery } from '@websky/graphql';
import { subsHalfCalendarDaysCount } from '../../utils';
var WeekCalendar = function (_a) {
    var _b, _c;
    var searchParameters = _a.searchParameters, legId = _a.legId, startNewSearch = _a.startNewSearch, hidePrice = _a.hidePrice, alternativePrices = _a.alternativePrices, minDate = _a.minDate;
    // This date will be in the center of the calendar.
    var centralDate = getDateWithoutTimezone(searchParameters.segments[legId].date);
    var _d = __read(useState(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = __read(useState(new Map()), 2), prices = _e[0], setPrices = _e[1];
    var calendarDaysCount = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.Engine.calendarDaysCount;
    var daysOffset = Math.floor(calendarDaysCount / 2);
    var _f = __read(React.useState(0), 2), calendarPage = _f[0], setCalendarPage = _f[1];
    var refetch = useMinPricesQuery({
        skip: true,
        context: {
            currency: searchParameters.currency
        }
    }).refetch;
    var loadMinPrices = function (variables) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, refetch(variables)];
                case 2:
                    data_1 = (_a.sent()).data;
                    if (data_1 && data_1.FlightsMinPricesInPeriod && data_1.FlightsMinPricesInPeriod.datesWithLowestPrices.length) {
                        setPrices(function (prevPrices) { return new Map(prevPrices.set(legId, data_1.FlightsMinPricesInPeriod.datesWithLowestPrices)); });
                    }
                    else {
                        console.log('Error while loading new prices');
                    }
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (legId > 0 || calendarPage !== 0) {
            var offset = daysOffset * -1;
            if (calendarPage !== 0) {
                offset += calendarDaysCount * calendarPage;
            }
            var segments = getSegmentsWithOffset(searchParameters.segments.map(function (segment, segmentIndex) {
                if (legId !== segmentIndex) {
                    return subsHalfCalendarDaysCount(segment, calendarDaysCount);
                }
                return segment;
            }), legId, offset);
            loadMinPrices({
                params: __assign(__assign({}, searchParameters), { segments: segments, daysCount: calendarDaysCount, directionId: legId })
            });
        }
        else if (alternativePrices) {
            setPrices(function (prevPrices) { return new Map(prevPrices.set(legId, alternativePrices)); });
        }
    }, [legId, calendarPage, alternativePrices]);
    React.useEffect(function () {
        setCalendarPage(0);
    }, [legId]);
    var loadNewDates = useCallback(function (type) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setCalendarPage(function (oldValue) { return oldValue + (type === 'increase' ? 1 : -1); });
            return [2 /*return*/];
        });
    }); }, []);
    var onDateClick = useCallback(function (date) {
        var segments = __spreadArray([], __read(searchParameters.segments), false);
        segments[legId] = __assign(__assign({}, segments[legId]), { date: format(date, API_DATE_FORMAT) });
        startNewSearch(__assign(__assign({}, searchParameters), { segments: sortSegments(legId, date, segments) }));
    }, [searchParameters, legId]);
    return isLoading || !alternativePrices ? (React.createElement(DummyWeekCalendar, null)) : (React.createElement(WeekCalendarComponent, { prices: (_c = prices.get(legId)) !== null && _c !== void 0 ? _c : [], centralDate: centralDate, onSelect: onDateClick, onRangeChange: loadNewDates, hidePrice: hidePrice, isMilesSearch: searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.ffpMode, minDate: minDate }));
};
export default WeekCalendar;
