import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { Currency, CurrencySymbols } from '../../enums';
import { useTheme } from '../../theme';
import { getFormattedAmount } from '../utils';
import { useConfig } from '../../context';
export var Money = function (_a) {
    var money = _a.money, moneyClassName = _a.moneyClassName, amountClassName = _a.amountClassName, currencyClassName = _a.currencyClassName, _b = _a.multiplyBy, multiplyBy = _b === void 0 ? 1 : _b, amountRenderer = _a.amountRenderer, currencyRenderer = _a.currencyRenderer, _c = _a.showCurrency, showCurrency = _c === void 0 ? true : _c, _d = _a.showCurrencyBeforeAmount, showCurrencyBeforeAmount = _d === void 0 ? false : _d, roundType = _a.roundType;
    var css = useTheme('Money').Money;
    var _e = useConfig().global, roundMoneyAmount = _e.roundMoneyAmount, iataCode = _e.companyInfo.iataCode;
    if (!money) {
        return null;
    }
    // TODO:
    var splitMoneyMin = iataCode === 'Y7' ? 999 : 10000;
    var moneyRoundType = roundType || (roundMoneyAmount && 'round') || null;
    var formattedAmount = React.useMemo(function () { return getFormattedAmount(money, multiplyBy, moneyRoundType, splitMoneyMin); }, [
        moneyRoundType,
        money,
        multiplyBy,
        splitMoneyMin,
        iataCode
    ]);
    var currencySymbol = CurrencySymbols[money.currency];
    var hasCurrency = money && showCurrency;
    var isCurrencyBefore = [Currency.USD].includes(money.currency);
    var showCurrencyBefore = isCurrencyBefore || showCurrencyBeforeAmount;
    var renderCurrency = useCallback(function () {
        var _a;
        if (currencyRenderer) {
            return currencyRenderer(currencySymbol);
        }
        return (React.createElement("span", { className: cn(currencyClassName, (_a = {},
                _a[css.currency_before] = showCurrencyBefore,
                _a[css.currency_after] = !showCurrencyBefore,
                _a)) }, currencySymbol));
    }, [currencySymbol, currencyRenderer, showCurrencyBefore]);
    return (React.createElement("span", { className: cn(css.money, moneyClassName) },
        hasCurrency && showCurrencyBefore && renderCurrency(),
        amountRenderer ? (amountRenderer(money.amount, money.currency)) : (React.createElement("span", { className: amountClassName }, formattedAmount ? formattedAmount : '0')),
        hasCurrency && !showCurrencyBefore && renderCurrency()));
};
export default Money;
